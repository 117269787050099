import {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    ReactNode,
} from 'react';
import { Tabs as TabsCore, withStyles } from '@material-ui/core';

import Tab from './Tab';

type TabType = { label: string; disabled?: boolean };

export interface TabsProps {
    initTab?: number;
    tabs: TabType[];
    children: (value: number) => ReactNode;
    onWillChange?(value: number, nextValue: number): boolean | void;
    onDidChange?(value: number): void;
    disableClick?: boolean | 'right';
}

export interface TabsRef {
    controller: number;
    setController(value: number): void;
}

const TabsTheme = withStyles({
    root: {
        borderBottom: '1px solid rgb(221, 221, 221)',
        padding: '0px 30px',
    },
    indicator: {
        backgroundColor: '#456AEF',
    },
})(TabsCore);

export default forwardRef<TabsRef, TabsProps>(function Tabs(props, ref) {
    const { initTab, tabs, children } = props;
    const [controller, setController] = useState(initTab ?? 0);

    async function onChange(
        _: React.ChangeEvent<{}>,
        value: any,
        reason: 'clickTab' | 'stateController'
    ) {
        if (
            (!(reason === 'clickTab' && props.disableClick) ||
                !(
                    reason === 'clickTab' &&
                    props.disableClick === 'right' &&
                    controller < value
                )) &&
            props.onWillChange?.(controller, value) !== false
        ) {
            setController(value);
        }
    }

    useImperativeHandle(ref, () => ({
        controller,
        setController,
    }));

    useEffect(() => {
        props.onDidChange?.(controller);
    }, [controller]);

    return (
        <>
            <TabsTheme
                value={controller}
                onChange={(...params) => onChange(...params, 'clickTab')}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        disabled={tab.disabled}
                    />
                ))}
            </TabsTheme>
            {children(controller)}
        </>
    );
});
