import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import CKEditorCore, {
    CKEditorProps as CKEditorBaseProps,
    Event,
    Editor,
} from '../components/inputs/CKEditor';

export interface CKEditorProps
    extends Omit<CKEditorBaseProps, 'name' | 'value'> {
    name: string;
}

export default function CKEditor(props: CKEditorProps) {
    const { name, defaultValue = '', ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);

    function onChange(event: Event, editor: Editor) {
        setValue(editor.getData());

        restProps.onChange?.(event, editor);
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue() {
                return value ?? '';
            },
            setValue(_: unknown, value: string) {
                setValue(value ?? '');
            },
        });
    }, [fieldName, registerField, value]);

    return (
        <CKEditorCore
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
        />
    );
}
