import { withStyles } from '@material-ui/core/styles';
import { Theme, Tooltip } from '@material-ui/core';

export default withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#333333',
        color: '#FFFFFF',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(13),
        padding: theme.spacing(1.5, 2),
        textAlign: 'center',
    },
    arrow: {
        color: '#333333',
    },
}))(Tooltip);
