import {
  useState,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import {
  IconButton,
  Button,
  makeStyles,
  Box,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreHorizOutlined, PlayCircleFilled } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import Crop, { CropRef } from 'core/components/Crop';
import AuthContext from 'core/contexts/Auth';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import { Permissions } from 'core/interfaces/page';
import AddVideoModal from 'core/components/AddVideoModal';

export interface ImageProps {
  instance: Record<string, any>;
  permissions: Permissions;
}

export interface ImageRef {
  getData(): Record<string, any>;
}

export default forwardRef<ImageRef, ImageProps>(function Images(props, ref) {
  const { instance, permissions } = props;
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const [images, setImages] = useState<any[]>(instance.images || []);
  const [crop, setCrop] = useState({ open: false, src: '' });
  const auth = useContext(AuthContext);

  const [videoModal, setVideoModal] = useState({
    open: false,
    instance: null as any,
  });
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);

  const handleVideoForm = (data: any) => {
    const { midia_path, midia_thumb_path } = data;

    if (videoModal.instance) {
      const { order } = videoModal?.instance;
      updateProductMidia(order, { ...videoModal?.instance, midia_path, midia_thumb_path });
    } else {
      images.push({
        midia_type: 1,
        midia_path,
        midia_thumb_path,
      });

      setImages([...images]);
    }

    onCloseVideoModal();
  };

  const onCloseVideoModal = () => {
    setVideoModal({
      open: false,
      instance: null,
    });
  };

  const cropRef = useRef<CropRef>(null);

  function createProductMidia(image: string) {
    images.push({ midia_type: 0, midia_path: image });

    setImages([...images]);
  }

  function deleteProductMidia(index: number) {
    images.splice(index, 1);

    setImages([...images]);
  }

  function updateProductMidia(index: number, image: any) {
    images[index - 1] = { ...image };

    setImages([...images]);
  }

  function onDragEnd(result: any) {
    if (result.destination) {
      const _items: any[] = reorder(
        images,
        result.source.index,
        result.destination.index
      );

      for (let i = 0; i < _items.length; i++) {
        const item = _items[i];

        item.order = i + 1;
      }

      setImages(_items);
    }
  }

  function reorder(list: any, startIndex: any, endIndex: any) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  }

  const updateOrders = (images: any[]) => {
    images.forEach((item, index) => {
      item.order = index + 1;
    });

    setImages(images);
  };

  useEffect(() => updateOrders(images), [images]);

  useImperativeHandle(
    ref,
    () => ({
      getData() {
        return images;
      },
    }),
    [images]
  );

  return (
    <>
      <ContainerCustom
        title="Imagens do produto"
        subtitle={
          <>
            Quadrada nos formatos{' '}
            <span style={{ fontWeight: 700 }} children=".png " />
            ou <span style={{ fontWeight: 700 }} children=".jpg " />e com no
            máximo <span style={{ fontWeight: 700 }} children="3mb" /> de
            tamanho.
          </>
        }
        classes={{
          content: classes.noBorder,
        }}
      >
        <div style={{ width: '100%' }}>
          <div className={classes.drag}>
            <img src="/icons/drag.svg" alt="Drag icon" />
            Clique e arraste para ordenar as imagens
          </div>
          <div className={classes.imageWrapper}>
            {permissions?.update && (
              <Box className={classes.uploadContentBox}>
                <label
                  htmlFor="upload-template"
                  style={{ display: 'contents' }}
                >
                  <Button
                    component="span"
                    className={classes.uploadContentButton}
                    style={{
                      backgroundColor: '#F0F4FE',
                      color: '#456AEF',
                    }}
                  >
                    Adicionar imagem
                  </Button>
                </label>
                <Box
                  style={{
                    padding: '0 10px',
                  }}
                >
                  <Button
                    component="span"
                    className={classes.uploadContentButton}
                    style={{
                      color: '#334EB0',
                    }}
                    onClick={() =>
                      setVideoModal({
                        open: true,
                        instance: null,
                      })
                    }
                  >
                    Adicionar vídeo
                  </Button>
                </Box>
              </Box>
            )}
            <AddVideoModal
              open={videoModal}
              onClose={onCloseVideoModal}
              handleSubmit={handleVideoForm}
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="list"
                  >
                    {images.map((value, index) => (
                      <Draggable
                        key={`item-${index}`}
                        draggableId={`item-${index}`}
                        index={index}
                        isDragDisabled={!permissions?.update}
                      >
                        {(provided) => (
                          <div
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.imageContent}
                          >
                            {permissions?.update && (
                              <Box>
                                <IconButton
                                  onClick={(
                                    event: React.MouseEvent<HTMLButtonElement>
                                  ) => setOpenMenu(event.currentTarget)}
                                  children={<MoreHorizOutlined />}
                                  size="medium"
                                  className={classes.iconButton}
                                  title="Visualizar Produtos"
                                  data-key={index}
                                />

                                <Menu
                                  anchorEl={openMenu}
                                  open={Boolean(openMenu)}
                                  onClose={() => setOpenMenu(null)}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      const index =
                                        openMenu?.getAttribute('data-key');
                                      const image = images[Number(index)];

                                      if (image.midia_type === 1) {
                                        setVideoModal({
                                          open: true,
                                          instance: image,
                                        });
                                      }
                                    }}
                                    style={{
                                      display:
                                        images[
                                          Number(
                                            openMenu?.getAttribute(
                                              'data-key'
                                            ) || 0
                                          )
                                        ].midia_type === 1
                                          ? 'block'
                                          : 'none',
                                    }}
                                  >
                                    Editar
                                  </MenuItem>
                                  <MenuItem
                                    key={index}
                                    onClick={() => {
                                      const index =
                                        openMenu?.getAttribute('data-key');

                                      deleteProductMidia(Number(index));
                                      setOpenMenu(null);
                                    }}
                                  >
                                    Excluir
                                  </MenuItem>
                                </Menu>
                              </Box>
                            )}
                            <img
                              className={classes.image}
                              alt={value.midia_path}
                              src={
                                value.midia_type === 0
                                  ? value.midia_path
                                  : value.midia_thumb_path
                              }
                              onError={({ target }: any) =>
                                (target.src = '/no-image.jpg')
                              }
                            />
                            {value.midia_type === 1 && (
                              <PlayCircleFilled className={classes.videoIcon} />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <input
          id="upload-template"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          onChange={(event) => {
            const file = event.target.files?.[0];

            if (file) {
              setCrop({
                open: true,
                src: URL.createObjectURL(file),
              });
            }
          }}
        />
        <Crop
          ref={cropRef}
          {...crop}
          crop={{
            size: {
              height: 1024,
              width: 1024,
            },
          }}
          onClose={async (file) => {
            if (file) {
              cropRef.current?.setLoading(true);

              const formData = new FormData();

              formData.append('file', file);

              try {
                const { data } = await api.post(
                  `productmidias/upload/${auth.extra!.marketplace?.slug_name}`,
                  formData
                );

                createProductMidia(data.url);
              } catch (error: any) {
                alert.error('Não foi possivel carregar a imagem');
              } finally {
                cropRef.current?.setLoading(false);
              }
            }

            setCrop({ open: false, src: '' });
          }}
        />
      </ContainerCustom>
    </>
  );
});

const useStyles = makeStyles((theme) => ({
  addButton: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#456AEF',
    textTransform: 'capitalize',
    marginTop: 20,
  },
  imageWrapper: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    '& .list': {
      display: 'flex',
      overflowX: 'auto',
      width: '100%',
      scrollbarWidth: 'thin',
      scrollbarColor: '#456AEF transparent',
      '&::-webkit-scrollbar': {
        height: 5,
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#456AEF',
        borderRadius: 4,
      },
    },
  },
  drag: {
    width: 325,
    height: 50,
    background: '#F1F1F1',
    borderRadius: 4,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#334EB0',
    fontSize: 12,
    '& img': {
      marginRight: 15,
    },
  },
  uploadContentBox: {
    width: '185px',
    height: '185px',
    position: 'relative',
    borderRadius: 4,
    border: '1px dashed #334EB0',
    '&:not(:last-of-type)': {
      marginRight: 15,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadContentButton: {
    marginBottom: 6,
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'none',
    width: '170px',
    height: '40px',
  },
  imageContent: {
    width: '185px',
    height: '185px',
    position: 'relative',
    borderRadius: 4,
    '&:not(:last-of-type)': {
      marginRight: 15,
    },
    '&.add': {
      background: '#456AEF',
      marginRight: 15,
      color: 'white',
      minWidth: 150,
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        fontSize: 12,
        textTransform: 'none',
        '& .MuiSvgIcon-root': {
          marginBottom: 10,
        },
      },
    },
  },
  iconButton: {
    position: 'absolute',
    right: 5,
    top: 8,
    color: '#456AEF',
    backgroundColor: '#F0F4FE',
    borderRadius: 4,
    transform: 'rotate(90deg)',
    '&:hover': {
      backgroundColor: '#E6EAF7',
    },
  },
  videoIcon: {
    position: 'absolute',
    right: 5,
    bottom: 5,
    color: '#0F172A',
    '&:hover': {
      color: '#6B7280',
    },
  },
  image: {
    height: 'inherit',
    width: 'inherit',
    borderRadius: 'inherit',
    objectFit: 'cover',
    border: '1px dashed #334EB0',
  },
  noBorder: {
    border: 'none',
  },
}));
