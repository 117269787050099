import { useState, useEffect } from 'react';
import BreadCrumbDate from 'core/components/BreadCrumbDate';
import InitialBlankPage from 'core/components/InitialBlankPage';
import Card from 'core/components/Card';
import DashTable from 'core/components/DashTable';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import IDashTable, { exampleTable } from 'core/interfaces/IDashTable';
import formatToBRL from 'utils/formatToBRL';
import COLORS_PAYMENT from 'core/utils/colors_payment';
import moment from 'moment';
import { api } from 'core/lib/api';
import CardGraph from 'components/Home/CardGraph';
import CircleGraph from 'components/Home/CircleGraph';
import BarGraph from 'components/Home/BarGraph';
import useSWR from 'swr';
import SellsInPeriod from 'components/Home/SellsInPeriod';
import Select from 'core/components/inputs/Select';
import Loading from 'core/components/Loading';
import Wishlist from '../Wishlist';
import RecentsRatings from './tables/RecentsRatings';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#F9F9F9',
    height: 'auto',
  },
}));

interface ICardData {
  totalBilling: number;
  totalOrders: number;
  averageTicket: number;
  canceledOrders: number;
}

interface IDataGraphs {
  allSells: {
    date: Date;
    amount: number;
  }[];
  countPaymentByStatus: {
    payment_status_id: number;
    count: number;
    payment_status_name: string;
  }[];
  countPaymentsByMethod: {
    payment_type: number;
    count: number;
    payment_type_name: string;
  }[];
  countDeliveryType: {
    shipping_type_id: number;
    count: number;
    shipping_type_name: string;
  }[];
  averageTicket: {
    month: number;
    average_ticket: number;
  }[];
}

const MOTNHS = {
  Jan: 1,
  Fev: 2,
  Mar: 3,
  Abr: 4,
  Mai: 5,
  Jun: 6,
  Jul: 7,
  Ago: 8,
  Set: 9,
  Out: 10,
  Nov: 11,
  Dez: 12,
};

function HomePage() {
  const initOfYear = moment().startOf('year').toDate();
  const [startDate, setStartDate] = useState(initOfYear);
  const [endDate, setEndDate] = useState(new Date());
  const classes = useStyles();

  const [data, setData] = useState<any>();
  const [cardData, setCardData] = useState<ICardData>();
  const [topProducts, setTopProducts] = useState<IDashTable>(exampleTable);
  const [lastOrders, setLastOrders] = useState<IDashTable>(exampleTable);
  const [expiredBills, setexpiredBills] = useState<IDashTable>(exampleTable);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [sellsGraphPeriodFilter, setSellsGraphPeriodFilter] = useState<
    'year' | 'month' | 'week' | 'last7days'
  >('year');

  const { data: dataGraphs, isValidating: isGraphsValidating } =
    useSWR<IDataGraphs>('/initials/getMarketplaceDataGraphs');

  const BreadCrumbProps = {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };

  useEffect(() => {
    setIsLoadingData(true);
    api
      .get(
        `/initials/getSellerData?start_date=${startDate}&end_date=${endDate}`
      )
      .then((res) => {
        setData(res.data);
        setCardData(res.data.cards);

        setTopProducts({
          title: 'Top 10 Produtos que mais venderam',
          viewMore: {
            label: 'Ver todos',
            route: '/products',
          },
          columns: [
            'Ranking',
            'Referência',
            'Produto',
            'Quantidade',
            'Valor total',
          ],
          rows: res.data.topProducts.map((product: any) => {
            return [
              {
                value: `${res.data.topProducts.indexOf(product) + 1}º`,
              },
              {
                value: product.id,
                route: `products/${product.id}`,
                clickable: true,
                color: 'primary',
                fontWeight: 'bold',
              },
              { value: product.name },
              { value: `${product.quantity} unidades` },
              { value: formatToBRL(product.amount) },
            ];
          }),
        });

        setLastOrders({
          title: 'Últimos pedidos',
          viewMore: {
            label: 'Ver todos os pedidos',
            route: '/orders',
          },
          columns: [
            'ID do pedido',
            'Nome do cliente',
            'Data do pedido',
            'Valor total',
            'Situação do pagamento',
            'Situação da entrega',
            'Forma de pagamento',
            'Prazo',
          ],
          rows: res.data.lastOrders.map((order: any) => {
            return [
              {
                value: order.id,
                route: `orders/${order.id}`,
                clickable: true,
                color: 'primary',
                fontWeight: 'bold',
              },
              { value: order.customer_name },
              { value: order.date },
              { value: formatToBRL(order.total_amount) },
              {
                value: order.payment_status,
                fontWeight: 'bold',
                color: COLORS_PAYMENT[order.payment_status_id],
              },
              { value: order.shipping_status || '-' },
              { value: order.payment_type },
              { value: order.deadline || '-' },
            ];
          }),
        });

        setexpiredBills({
          title: 'Boletos vencidos',
          columns: [
            'ID do pedido',
            'Nome do cliente',
            'Data do pedido',
            'Vencido a',
            'Valor total',
          ],
          rows: res.data.expiredBills.map((bill: any) => {
            return [
              {
                value: bill.id,
                route: `orders/${bill.id}`,
                clickable: true,
                color: 'primary',
                fontWeight: 'bold',
              },
              { value: bill.customer_name },
              { value: bill.date },
              { value: `${bill.expired_days} dias` },
              { value: formatToBRL(bill.total_amount) },
            ];
          }),
          pagination: true,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  }, [startDate, endDate]);

  const isLoading = isLoadingData || (!dataGraphs && isGraphsValidating);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.background}>
      {!cardData || cardData?.totalOrders === 0 ? (
        <InitialBlankPage isSeller={true} />
      ) : (
        <div>
          <BreadCrumbDate {...BreadCrumbProps} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Quantidade de pedidos"
                content={cardData.totalOrders}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Faturamento"
                content={formatToBRL(cardData.totalBilling)}
                color="success"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Ticket médio"
                content={formatToBRL(cardData.averageTicket)}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Compras canceladas"
                content={cardData.canceledOrders}
                color="danger"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DashTable {...topProducts} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <DashTable {...expiredBills} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Wishlist startDate={startDate} endDate={endDate} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DashTable {...lastOrders} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <RecentsRatings data={data.recentsRatings} />
            </Grid>
          </Grid>

          {dataGraphs && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CardGraph
                  title="Vendas por período (Faturamento)"
                  height="600px"
                  titleComponent={
                    <div
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        marginLeft: '25px',
                      }}
                    >
                      <Select
                        style={{
                          padding: '0px',
                          width: '150px',
                        }}
                        value={sellsGraphPeriodFilter}
                        onChange={(v) =>
                          setSellsGraphPeriodFilter(v.target.value as any)
                        }
                      >
                        <MenuItem value={'last7days'}>Últimos 7 dias</MenuItem>
                        <MenuItem value={'week'}>Esta Semana</MenuItem>
                        <MenuItem value={'month'}>Este Mês</MenuItem>
                        <MenuItem value={'year'}>Este Ano</MenuItem>
                      </Select>
                    </div>
                  }
                >
                  <SellsInPeriod
                    allSells={dataGraphs.allSells.map((s) => ({
                      x: s.date,
                      y: s.amount,
                    }))}
                    period={sellsGraphPeriodFilter}
                  />
                </CardGraph>
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardGraph title="Meios de pagamentos utilizados">
                  <CircleGraph
                    series={dataGraphs.countPaymentsByMethod.map(
                      (p) => p.count
                    )}
                    labels={dataGraphs.countPaymentsByMethod.map(
                      (p) => p.payment_type_name
                    )}
                  />
                </CardGraph>
              </Grid>

              <Grid item lg={6} xs={12}>
                <CardGraph title="Situação de pagamentos">
                  <CircleGraph
                    series={dataGraphs.countPaymentByStatus.map((p) => p.count)}
                    labels={dataGraphs.countPaymentByStatus.map(
                      (p) => p.payment_status_name
                    )}
                  />
                </CardGraph>
              </Grid>

              <Grid item lg={6} xs={12}>
                <CardGraph title="Tipos de entrega">
                  <CircleGraph
                    series={dataGraphs.countDeliveryType.map((p) => p.count)}
                    labels={dataGraphs.countDeliveryType.map(
                      (p) => p.shipping_type_name
                    )}
                  />
                </CardGraph>
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardGraph title="Ticket médio">
                  <BarGraph
                    categories={Object.keys(MOTNHS)}
                    series={[
                      {
                        name: 'Ticket médio',
                        data: Object.values(MOTNHS).map(
                          (value) =>
                            dataGraphs.averageTicket.find(
                              (ticket) => ticket.month === value
                            )?.average_ticket || 0
                        ),
                      },
                    ]}
                  />
                </CardGraph>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </div>
  );
}

export default HomePage;
