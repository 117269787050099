import { Page } from 'core/interfaces/page';
import Details from './Details';

const MelhorEnvio: Page = {
    label: 'MelhorEnvio',
    route: 'melhorenvio',
    onlyDetails: true,
    routes: {
        update: 'marketplaces/melhorenvio',
        findOne: 'marketplaces/melhorenvio',
    },
    Details,
};

export default MelhorEnvio;