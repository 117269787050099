import { Page } from 'core/interfaces/page';
import List from './List';
import Details from './Details';

const Order: Page = {
    label: 'Vendas',
    route: 'orders',
    routes: {
        findOne: (id) => `orders/${id}`,
    },
    List,
    Details,
};

export default Order;
