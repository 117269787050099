import { createElement, useContext, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { Fade, Paper } from '@material-ui/core';

import Loading from './components/Loading';
import { Page } from './interfaces/page';
import Details from './pages/Details';
import extraDetails from './pages/extraDetails';
import List from './pages/List';
import NotFound from './pages/NotFound';
import AlertContext from './contexts/Alert';
interface Props {
  menus: any[];
  routes: any[];
  pages: Record<string, Page>;
  children(children: JSX.Element): JSX.Element;
}

interface Permission {
  can_create: boolean;
  can_delete: boolean;
  can_read: boolean;
  can_update: boolean;
}

export default function Routes(props: Props) {
  const { menus, routes, pages, children } = props;

  const location = useLocation();
  const history = useHistory();

  const alert = useContext(AlertContext);

  const existsRoute = (route: string) => {
    return routes.find((r) => r === route);
  };

  const getFirstMenu = (menus: any[]): any => {
    for (const menu of menus!) {
      if (menu.route === null) {
        return getFirstMenu(menu.childs);
      }
      if (menu.permission?.can_read) {
        return menu.route;
      }
    }
  };

  const getPermissions = (
    route: String,
    menus: any[]
  ): Permission | undefined => {
    for (const menu of menus) {
      if (menu.route === null) {
        const permission = getPermissions(route, menu.childs);

        if (permission && Object.keys(permission).length) {
          return permission;
        }
      } else if (menu.route === route) {
        return menu.permission;
      }
    }

    return {} as Permission;
  };

  useEffect(() => {
    const pathname = location.pathname;
    const paths = pathname.split('/').slice(1);
    if (pathname === '/inicio') {
      return;
    }

    if (pathname === '/') {
      history.push('/inicio');
      return;
    }

    if (paths.length > 0 && paths[0] !== 'undefined') {
      const route = paths[0];
      const arg = paths.slice(1).join('/');
      let permission = getPermissions(route, menus);
      const firstMenu = getFirstMenu(menus);
      const urlToGo = firstMenu ? `/${firstMenu}` : '/';
      const sellersRoutePerm = getPermissions('sellers', menus);

      if (sellersRoutePerm?.can_read && route === 'products') {
        permission = {
          can_create: sellersRoutePerm.can_create,
          can_delete: sellersRoutePerm.can_delete,
          can_read: sellersRoutePerm.can_read,
          can_update: sellersRoutePerm.can_update,
        };
      }

      if (route && permission && existsRoute(route)) {
        if (!permission?.can_read) {
          history.push(urlToGo);
          alert.error('Você não tem permissão para acessar essa página');
        } else if (arg === 'new' && !permission?.can_create) {
          history.push(urlToGo);
          alert.error('Você não tem permissão para acessar essa página');
        }
      } else if (route) {
        history.push(urlToGo);
      }
    }
  }, [location]);

  if (!menus) {
    return <Loading />;
  }

  return children(
    <Switch>
      {Object.values(pages).reduce<JSX.Element[]>((acc, page) => {
        const permissions = getPermissions(page.route, menus);

        if (!page.permissions) {
          page.permissions = {};
        }

        page.permissions = page.permissions || {};

        if (page.route === 'products') {
          const sellersRoutePerm = getPermissions('sellers', menus);
          if (sellersRoutePerm?.can_read) {
            page.permissions.create = sellersRoutePerm.can_create;
            page.permissions.delete = sellersRoutePerm.can_delete;
            page.permissions.read = sellersRoutePerm.can_read;
            page.permissions.update = sellersRoutePerm.can_update;
          }
        }

        page.permissions.create =
          page.permissions.create === undefined
            ? permissions?.can_create
            : page.permissions.create;

        page.permissions.read =
          page.permissions.read === undefined
            ? permissions?.can_read
            : page.permissions.read;

        page.permissions.update =
          page.permissions.update === undefined
            ? permissions?.can_update
            : page.permissions.update;

        page.permissions.delete =
          page.permissions.delete === undefined
            ? permissions?.can_delete
            : page.permissions.delete;

        if (page.permissions.read !== false) {
          const path = `/${page.route}`;

          if (page.onlyDetails) {
            acc.push(
              <Route
                key={`${page.route}Crud`}
                path={path}
                render={() => {
                  if (page.Details) {
                    return createElement(Details, {
                      isNew: false,
                      page,
                    });
                  }

                  return <NotFound />;
                }}
              />
            );
          } else {
            acc.push(
              <Route key={`${page.route}Crud`} path={path}>
                <Switch>
                  <Route
                    exact
                    path={path}
                    render={() => {
                      if (page.List) {
                        return createElement(page.List, { page });
                      }

                      return (
                        <Fade in={true} timeout={{ enter: 500 }}>
                          <Paper
                            elevation={0}
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <List page={page} />
                          </Paper>
                        </Fade>
                      );
                    }}
                  />
                  {page.permissions?.create !== false && (
                    <Route
                      path={`${path}/new`}
                      render={() => {
                        if (page.Details) {
                          return createElement(Details, { page, isNew: true });
                        }

                        return <Redirect to={path} />;
                      }}
                    />
                  )}
                  {page.permissions?.read === true && (
                    <Route
                      path={`${path}/details/:id`}
                      render={(route) => {
                        const { id = '' } = route.match.params;

                        if (!isNaN(+id) && page.extraDetails) {
                          return createElement(extraDetails, {
                            page,
                            isNew: false,
                            id: +id,
                          });
                        }

                        return <Redirect to={path} />;
                      }}
                    />
                  )}
                  {(
                    <Route
                      path={`${path}/:id`}
                      render={(route) => {
                        const { id = '' } = route.match.params;

                        if (!isNaN(+id) && page.Details) {
                          return createElement(Details, {
                            page,
                            isNew: false,
                            id: +id,
                          });
                        }

                        return <Redirect to={path} />;
                      }}
                    />
                  )}
                </Switch>
              </Route>
            );
          }
        }

        return acc;
      }, [])}
      <Route path="**">
        <Redirect to={`/${getFirstMenu(menus) || ''}`} />
      </Route>
    </Switch>
  );
}
