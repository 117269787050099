import CheckboxCore, {
    CheckboxProps as CheckboxPropsCore,
} from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';

export type CheckboxProps = CheckboxPropsCore;

const useStyles = makeStyles({
    checkbox: {
        padding: 0,
        color: '#D9D9D9',
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#456AEF',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
        },
    },
    checkboxIconUnchecked: {
        width: 20,
        height: 20,
        background: '#FFFFFF',
        border: '1px solid #456AEF',
        boxSizing: 'border-box',
        borderRadius: 4,
    },
    checkboxIconChecked: {
        borderRadius: 4,
        backgroundColor: '#456AEF',
        '&::before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                `url("data:image/svg+xml;charset=utf-8,%3Csvg ` +
                `xmlns='http://www.w3.org/2000/svg' ` +
                `viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' ` +
                `clip-rule='evenodd' ` +
                `d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 ` +
                `3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' ` +
                `fill='%23fff'/%3E%3C/svg%3E")`,
            content: '""',
        },
    },
});

export default function Checkbox(props: CheckboxProps) {
    const classes = useStyles();

    return (
        <CheckboxCore
            icon={<div className={classes.checkboxIconUnchecked} />}
            checkedIcon={<div className={classes.checkboxIconChecked} />}
            className={classes.checkbox}
            {...props}
        />
    );
}
