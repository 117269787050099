import { CSSProperties } from 'react';

interface IBody {
  value?: string | JSX.Element;
  icon?: string;
  color?: 'primary' | 'success' | 'warning' | 'danger';
  fontWeight?: 'bold' | 'normal';
  clickable?: boolean;
  route?: string;
  className?: string;
  style?: CSSProperties;
}

interface IDashTable {
  title?: string;
  viewMore?: {
    label: string;
    route: string;
  };
  columns: string[];
  rows: IBody[][];
  pagination?: boolean;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  className?: any;
}

const exampleTable: IDashTable = {
  columns: [''],
  rows: [[{ value: '' }]],
};

export { exampleTable };
export type { IBody };
export default IDashTable;
