import ReactDOM from 'react-dom';

import Core from './core';
import { environment } from './environment';
import * as pages from './pages';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

ReactDOM.render(
  <Core
    logo="/logo.png"
    name="Sellerfaces Admin"
    theme={{
      typography: {
        fontFamily: 'Inter, sans-serif',
      },
    }}
    environment={environment}
    pages={{
      ...pages,
      // @ts-ignore
      Login,
      // @ts-ignore
      ForgotPassword,
      // @ts-ignore
      ResetPassword,
    }}
    components={{
      Sidebar,
    }}
  />,
  document.getElementById('root')
);
