import { Page } from 'core/interfaces/page';
import Switchable from 'components/Switchable';
import { currency } from 'utils/currency';
import BreadCrumbAdd from './BreadCrumbAdd';
import Details from './Details';
import DialogClone from './DialogClone';
import DialogDeleteAll from './DialogDeleteAll';
import { Checkbox } from '@material-ui/core';
import Reference from './Reference';

const Product: Page = {
  label: 'Produtos',
  route: 'products',
  routes: {
    find: 'products',
    create: 'products',
    findOne: (id) => `products/${id}`,
    delete: (id) => `products/${id}`,
    update: (id) => `products/${id}`,
  },
  components: {
    BreadCrumbAdd,
  },
  Details,

  listProps: {
    fields: {
      select: {
        renderHeader: () => <DialogDeleteAll />,
        width: 40,
        reference: 'delete',
        renderCell: ({ row: { id } }) => (
          <Checkbox
            id={id}
            name="product_id"
            style={{
              color: '#456aef',
            }}
          />
        ),
      },
      id: {
        headerName: 'Ref.',
        width: 40,
        renderCell: ({ column: { field }, row: { id } }) => (
          <Reference route={Product.route} field={field} rowId={id} />
        ),
      },
      midia_thumb_path: {
        headerName: 'Imagem',
        reference: 'Produto',
        width: 130,
        renderCell: ({ row: { midia_thumb_path } }) => (
          <img
            src={midia_thumb_path || '/no-image.jpg'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/no-image.jpg';
            }}
            alt="Thumb"
            width="50px"
            height="50px"
          />
        ),
      },
      name: {
        headerName: 'Nome',
        reference: 'Produto',
      },
      price: {
        headerName: 'Preço',
        width: 130,
        renderCell: ({ row: { price } }) => currency(price),
      },
      promotional_price: {
        headerName: 'Preço promocional',
        width: 205,
        renderCell: ({ row: { promotional_price } }) =>
          promotional_price
            ? currency(promotional_price)
            : 'Sem preço promocional',
      },
      is_active: {
        headerName: 'Ativo?',
        width: 100,
        renderCell: ({ row: { id, is_active } }) => (
          <Switchable
            id={id}
            routeIsRoot
            value={is_active}
            column="is_active"
            route={`products/${id}/is_active`}
            permissions={!Product.permissions?.update}
          />
        ),
      },
      clone: {
        headerName: 'Clonar',
        width: 80,
        renderCell: ({ row }) => <DialogClone product={row as any} />,
        disableByPermission: 'create',
      },
    },
    dialogOnDelete: 'Tem certeza que deseja excluir?',
  },
};

export default Product;
