import { Box, Button, List, Tooltip, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useAsync, useAsyncFn, useMap } from 'react-use';
import SideBarListItem from './SideBarListItem';
import ColorPickerField from './ColorPickerField';
import SidebarHeading from './SidebarHeading';
import { useContext, useEffect, useState } from 'react';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import Loading from 'core/components/Loading';

const DEFAULT_COLORS = {
  primaryColor: '#456AEF',
  secondaryColor: '#00A245',
  neutralColor: '#6B7280',
};

const ColorsFontsDetails: React.FC = () => {
  const [isCustom, setIsCustom] = useState(false);
  const [isColorsOpen, setIsColorsOpen] = useState(true);

  const [dataThemeConfig, fetchDataThemeConfig] = useAsyncFn(
    async () => await api.get('/marketplaces/theme-config'),
    []
  );

  const dataMaketPlaceInfo = useAsync(
    async () => await api.get('/marketplaces/info'),
    []
  );

  const [theme, controlTheme] = useMap(DEFAULT_COLORS);

  useEffect(() => {
    fetchDataThemeConfig();
  }, []);

  useEffect(() => {
    let data = dataThemeConfig.value?.data;

    if (!data) return;

    // only keep attributes that are in the theme object
    data = Object.keys(data).reduce((acc, key) => {
      if ((theme as any)[key] !== undefined) {
        (acc as any)[key] = data[key];
      }
      return acc;
    }, {});

    controlTheme.setAll({
      ...theme,
      ...data,
    });

    setIsCustom(dataThemeConfig.value?.data.isCustom || false);
  }, [dataThemeConfig]);

  const alert = useContext(AlertContext);

  const onCancel = () => {
    fetchDataThemeConfig()
      .then(() => {
        alert.success('Cancelado');
      })
      .catch(() => {
        alert.error('Erro ao cancelar');
      });
  };

  const onRestoreDefault = () => {
    controlTheme.setAll(DEFAULT_COLORS);
    setIsCustom(false);
  };

  const onSaveTheme = () => {
    api
      .put('/marketplaces/theme-config', theme)
      .then((res) => {
        alert.success('Tema salvo com sucesso!');
      })
      .catch((err) => {
        alert.error('Erro ao salvar o tema!');
      })
      .finally(() => {
        fetchDataThemeConfig();
      });
  };

  if (dataThemeConfig.loading) {
    return <Loading />;
  }
  return (
    <Box height="100%">
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        py="20px"
        px="20px"
        style={{
          borderBottom: '1px solid #E5E7EB',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          Layout: Cores
        </Typography>

        <Box ml="auto">
          <Button
            onClick={() => onCancel()}
            style={{
              color: '#456AEF',
              border: '1px #456AEF solid',
              fontSize: '15px',
              textTransform: 'none',
            }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => onSaveTheme()}
            style={{
              marginLeft: '10px',
              color: 'white',
              backgroundColor: '#456AEF',
              padding: '5px 20px',
              fontSize: '15px',
              textTransform: 'none',
            }}
          >
            Salvar
          </Button>
        </Box>
      </Box>

      {/* Content */}
      <Box display="flex" flexDirection="row" px="20px" height="100%">
        {/* Preview */}
        <Box display="flex" flexDirection="column" py="20px" width="70%">
          {dataMaketPlaceInfo.value && (
            <iframe
              src={`https://${
                dataMaketPlaceInfo.value.data.domain
              }?random=${Math.random()}`}
              title="Preview Theme"
              width="100%"
              height="600"
              style={{ border: 'none' }}
            />
          )}

          <Box
            flexDirection="row"
            alignItems="center"
            display="flex"
            py="31px"
            pl="56px"
            style={{
              borderBottom: '1px solid #E5E7EB',
              borderLeft: '1px solid #E5E7EB',
              borderRight: '1px solid #E5E7EB',
              borderRadius: '4px',
            }}
          >
            <Box>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Sellersfaces
              </Typography>
              {isCustom && (
                <Typography
                  variant="body2"
                  style={{
                    color: '#64748B',
                  }}
                >
                  Customizado
                </Typography>
              )}
            </Box>

            <Box
              style={{
                background: '#A8FFD3',
                borderRadius: '16px',
                padding: '6px 14.5px',
              }}
              ml="10px"
            >
              <Typography
                style={{
                  color: '#0F172A',
                  fontSize: '14px',
                }}
                variant="body2"
              >
                Seu layout atual
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Configurations */}
        <Box
          display="flex"
          flexDirection="column"
          ml="auto"
          height="100%"
          py="20px"
          style={{
            borderLeft: '1px solid #E5E7EB',
            minWidth: '300px',
          }}
        >
          <Box pl="20px" pb="20px">
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              Configurações do tema
            </Typography>
            <Box
              px="16px"
              mt="24px"
              display="flex"
              flexDirection="row"
              width="100%"
              style={{ fontSize: '16px' }}
            >
              <Box>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Você está editando o tema:
                </Typography>
                <Typography
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Padrão Sellersfaces
                </Typography>
              </Box>
              <Box ml="auto">
                <Tooltip
                  title="Configurações de tema"
                  aria-label="theme config"
                >
                  <HelpIcon
                    style={{
                      color: '#64748B',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <List>
            <SideBarListItem
              onClickHeader={() => setIsColorsOpen(!isColorsOpen)}
              title="Cores"
              isOpen={isColorsOpen}
            >
              <Box mb="32px">
                <SidebarHeading
                  title="Cores do tema"
                  subTitle="Customize o tema com cores que pertencem a sua marca"
                />
                <Button
                  onClick={() => onRestoreDefault()}
                  style={{
                    color: '#456AEF',
                    border: '1px #456AEF solid',
                    fontSize: '11px',
                    textTransform: 'none',
                  }}
                >
                  Restaurar padrão
                </Button>

                <SidebarHeading
                  title="Cores do tema"
                  subTitle="Customize o tema com cores que pertencem a sua marca"
                />

                <ColorPickerField
                  value={theme.primaryColor}
                  onChange={(v) => controlTheme.set('primaryColor', v)}
                  label="Cor principal"
                  helperText="Esta é a cor primária da sua marca"
                />
                <ColorPickerField
                  value={theme.secondaryColor}
                  onChange={(v) => controlTheme.set('secondaryColor', v)}
                  label="Cor secundária"
                  helperText="Esta é a cor segundária da sua marca"
                />
                <ColorPickerField
                  value={theme.neutralColor}
                  onChange={(v) => controlTheme.set('neutralColor', v)}
                  label="Cor neutra"
                  helperText="Esta é a cor neutra do tema"
                />
              </Box>
            </SideBarListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default ColorsFontsDetails;
