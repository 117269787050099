import { CSSProperties } from 'react';
import clsx from 'clsx';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';

import Tooltip from './Tooltip';

interface CSS {
    [key: string]: CSS | CSSProperties;
}

interface Style {
    label?: ((theme: Theme) => CSSProperties | CSS) | (CSSProperties | CSS);
    content?: ((theme: Theme) => CSSProperties | CSS) | (CSSProperties | CSS);
}

interface Props {
    label?: string;
    required?: boolean;
    description?: string;
    children: JSX.Element;
    style?: Style;
    classes?: {
        label?: string;
        content?: string;
    };
    positionLabel?: 'before' | 'after';
}

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        ...(props: Style) =>
            typeof props.content === 'function'
                ? props.content(theme)
                : props.content,
    },
    label: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        ...(props: Style) =>
            typeof props.label === 'function'
                ? props.label(theme)
                : props.label,
    },
    required: {
        color: '#E6514A',
    },
}));

export default function InputContainer(props: Props) {
    const classes = useStyles(props.style || ({} as Style));
    const {
        label,
        required,
        description,
        children,
        classes: customClasses,
        positionLabel = 'before',
    } = props;

    return (
        <div className={clsx(classes.content, customClasses?.content)}>
            {positionLabel === 'after' && children}
            <Typography className={clsx(classes.label, customClasses?.label)}>
                {label}
                {required && <span className={classes.required} children="*" />}
                {description && (
                    <Tooltip
                        title={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        }
                    >
                        <HelpIcon
                            style={{
                                fill: '#999999',
                                fontSize: '1rem',
                                marginLeft: '8px',
                            }}
                        />
                    </Tooltip>
                )}
            </Typography>
            {positionLabel === 'before' && children}
        </div>
    );
}
