import { Box, makeStyles, Typography } from '@material-ui/core';
import BoxInfo from 'core/components/BoxInfo';
import BreadCrumbCore from 'core/components/BreadCrumb';
import Button from 'core/components/Button';
import TextField from 'core/components/inputs/TextField';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from 'core/lib/api';
import { v4 as uuid } from 'uuid';
import SearchTable from 'core/components/SearchTable';
import ISearchTable, { exampleTable } from 'core/interfaces/ISearchTable';
import AlertContext from 'core/contexts/Alert';
import moment from 'moment';
import Loading from 'core/components/Loading';
import { CheckOutlined } from '@material-ui/icons';
import Dialog from '../../../core/components/NewDialog';

const useStyles = makeStyles((theme) => ({
  informations: {
    display: 'contents',
  },
  informationsContent: {
    display: 'flex',
    marginLeft: 'auto',
  },
  integrationButton: {
    '&:disabled': {
      backgroundColor: '#f1f1f1',
      border: '1px solid rgb(153, 153, 153)',
    },
  },
  typography: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2.5,
  },
  textField: {
    backgroundColor: '#f1f1f1',
    width: '100%',
  },
  boxField: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    backgroundColor: '#456AEF',
    color: 'white',
    fontSize: 14,
    width: '240px',
    marginLeft: 8,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: '#3F5EFB',
    },
    '&:disabled': {
      backgroundColor: '#f1f1f1',
      border: '1px solid rgb(153, 153, 153)',
    },
  },
}));

const Details = (props: any) => {
  const {
    page: { label, permissions },
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const alert = useContext(AlertContext);

  const [token, setToken] = useState('');
  const [lives, setLives] = useState<ISearchTable>(exampleTable);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [generatedToken, setGeneratedToken] = useState(false);

  const [open, setOpen] = useState(false);

  const generateToken = () => {
    setGeneratedToken(true);
    setOpen(false);

    const hash = uuid();
    setToken(hash);
  };

  const handleIntegration = async () => {
    api
      .post('metalives/setconfig', { token })
      .then((response) => {
        alert.success('Integração realizada com sucesso!');
      })
      .catch((error) => {
        alert.error('Erro ao integrar!');
      });
  };

  const getData = async () => {
    setLoadingButton(true);

    api
      .get('metalives/getconfig')
      .then((response) => {
        const data = response.data;

        setToken(data.token);

        setLives({
          title: 'Lista de transmissões.',
          description:
            'Aqui você encontrará o detalhamento das transmissões ao vivo cadastradas na plataforma Metalives.',
          columns: [
            'ID.',
            'Título',
            'Data da transmissão',
            'Horário',
            'Link',
            'Situação',
          ],
          rows: data.lives.map((row: any) => {
            const momentDate = moment(row.starts_at);
            const startsAt = momentDate.format('DD/MM/YYYY');
            const startsAtTime = momentDate.format('HH:mm');

            return [
              { value: row.id },
              { value: row.title },
              { value: startsAt },
              { value: startsAtTime },
              {
                value: row.url,
                copyable: true,
              },
              { value: row.status },
            ];
          }),
          pagination: true,
          handleUpdate: () => getData(),
        });

        setIsLoading(false);
        setLoadingButton(false);
      })
      .catch(() => {
        alert.error('Erro ao carregar dados.');
      });

    return;
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <Loading />;

  return (
    <Box>
      <BreadCrumbCore
        title={<span children={'Integrações'} style={{ color: '#456AEF' }} />}
        dictionary={{ action: label }}
        slot={{
          end: (
            <div className={classes.informations}>
              <div className={classes.informationsContent}>
                <Button
                  children="Voltar"
                  color="#999999"
                  outline
                  onClick={() => history.goBack()}
                  style={{ marginRight: 20 }}
                />
                <Button
                  children="Integrar"
                  color="#456AEF"
                  onClick={() => handleIntegration()}
                  style={{ marginRight: 20 }}
                  className={classes.integrationButton}
                  disabled={
                    (!token && !permissions.create) ||
                    !permissions.create ||
                    !token
                  }
                />
              </div>
            </div>
          ),
        }}
      />

      <Box
        style={{
          padding: '24px',
        }}
      >
        <BoxInfo
          title={'Integração com o Metalives'}
          description={
            'Utlize essa integração gratuita para vender e divulgar por meio de transmissões ao vivo.'
          }
          info={
            'O Token é gerado automaticamente pelo sistema para integrar com o Metalives. Após a integração, as transmissões ao vivo da plataforma Metalives serão listadas e visualizadas na Sellerfaces.'
          }
        />

        <Box
          style={{
            marginTop: '24px',
            width: '50%',
          }}
        >
          <Typography variant="h6" className={classes.typography}>
            Gere o token para integrar com o Metalives
          </Typography>
          <Box className={classes.boxField}>
            <TextField
              className={classes.textField}
              type="text"
              disabled
              value={token}
            />
            <Button
              className={classes.button}
              onClick={() => setOpen(true)}
              style={
                generatedToken
                  ? {
                      backgroundColor: '#4AA629',
                      color: 'white',
                    }
                  : {}
              }
              disabled={
                (!generatedToken && !permissions.create) ||
                !permissions.create ||
                !generatedToken
              }
            >
              {generatedToken ? (
                <>
                  <CheckOutlined /> Token gerado
                </>
              ) : (
                'Gerar token'
              )}
            </Button>
          </Box>
        </Box>

        {token && (
          <Box
            style={{
              marginTop: '32px',
            }}
          >
            <SearchTable isLoading={loadingButton} {...lives} />
          </Box>
        )}
      </Box>

      <Dialog
        title="Gerar novo token Metalives"
        description="Atenção! Ao gerar um novo token, você precisará atualizar esta informação na plataforma Metalives."
        open={open}
        onClose={() => setOpen(false)}
        cancelButton={{
          text: 'Cancelar',
          color: '#456AEF',
          onClick: () => setOpen(false),
        }}
        confirmButton={{
          text: 'Confirmar',
          backgroundColor: '#456AEF',
          onClick: () => generateToken(),
        }}
      />
    </Box>
  );
};

export default Details;
