import { useContext } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';

import AuthContext from 'core/contexts/Auth';
import Switchable from 'components/Switchable';
import { Page } from 'core/interfaces/page';
import Details from './Details';

function Redirect(props: { value: any }) {
    const { value } = props;
    const auth = useContext(AuthContext);

    let domain: string = auth.extra?.marketplace?.domain || '';

    if (!/^(http|https):\/\//.test(domain)) domain = `//${domain}`;

    return (
        <a
            target="_blank"
            rel="noreferrer"
            style={{
                textDecoration: 'none',
                color: 'inherit',
                display: 'flex',
                alignItems: 'center',
            }}
            href={`${domain}/${value}`}
            title="Acessar loja"
        >
            <LaunchIcon
                htmlColor="#5b70f0"
                style={{ fontSize: 15, marginRight: 5 }}
            />
            {value}
        </a>
    );
}

const Section: Page = {
    label: 'Seções',
    route: 'sections',
    Details,
    listProps: {
        fields: {
            name: {
                headerName: 'Nome da seção',
            },
            order: {
                headerName: 'Sequência',
            },
            slug_name: {
                headerName: 'Acesso',
                renderCell({ column, row }) {
                    const value = row[column.field];

                    return <Redirect value={value} />;
                },
            },
            is_active: {
                headerName: 'Ativo?',
                width: 100,
                renderCell: ({ row: { id, is_active } }) => (
                    <Switchable
                        id={id}
                        value={is_active}
                        column="is_active"
                        route="sections"
                        permissions={!Section.permissions?.update}
                    />
                ),
            },
        },
        limitControlled: [100, 150, 200, 250],
        dialogOnDelete:
            'Deletando está seção você ' +
            'estará deletando todos os, ' +
            'conteúdos atribuídos a ela, ' +
            'deseja continuar?',
    },
};

export default Section;
