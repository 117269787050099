import { useEffect, useState } from 'react';

declare module IUserData {
  export interface Marketplace {
    id: number;
    name: string;
    slug_name: string;
    domain: string;
    logo_path: string;
  }

  export interface Seller {
    name: string;
    logo_path: string;
    id: number;
  }

  export interface RootObject {
    userId: number;
    created: Date;
    marketplace: Marketplace;
    seller_id: number;
    seller: Seller;
    hasWholesale: boolean;
  }
}

export const getUserData = (): IUserData.RootObject => {
  const rawUserData = localStorage.getItem('@Auth:extra');
  if (!rawUserData) throw new Error('No user data found');

  return JSON.parse(rawUserData) as IUserData.RootObject;
};

export const useUserData = () => {
  const [userData, setUserData] = useState<IUserData.RootObject | null>(null);

  useEffect(() => {
    try {
      setUserData(getUserData());
    } catch (error) {
      console.log(error);
    }
  }, []);

  return userData;
};
