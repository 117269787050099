import { Button } from '@material-ui/core';
import moment from 'moment';

import { Page } from 'core/interfaces/page';

const ImportProduct: Page = {
    label: 'Backups de exportações',
    route: 'exportproductbackups',
    permissions: {
        create: false,
        delete: false,
        update: false,
    },
    listProps: {
        removeEditAndDelete: true,
        fields: {
            id: {
                headerName: 'Id',
                width: 50,
                renderCell: ({ row: { id } }) => id,
            },
            date: {
                headerName: 'Data',
                width: 100,
                renderCell({ row: { created_at } }) {
                    return moment(created_at).format('DD/MM/YYYY');
                },
            },
            hour: {
                headerName: 'Hora',
                renderCell({ row: { created_at } }) {
                    return moment(created_at).format('HH:mm');
                },
            },
            path: {
                headerName: ' ',
                width: 1,
                renderCell({ row: { path } }) {
                    function onClick() {
                        const a = document.createElement('a');

                        a.href = path;
                        a.download = 'export-product.xlsx';
                        a.click();
                    }

                    return (
                        <Button
                            style={{
                                fontWeight: 600,
                                background: '#456AEF',
                                textTransform: 'unset',
                                color: 'white',
                                width: 100,
                            }}
                            onClick={onClick}
                        >
                            Baixar
                        </Button>
                    );
                },
            },
        },
    },
};

export default ImportProduct;
