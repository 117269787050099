import { forwardRef, useState, useImperativeHandle } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonBaseProps } from '@material-ui/core';

import Button from './Button';

export interface ButtonActionRef {
    title: string;
    setTitle(title: string): void;
    disabled: boolean;
    setDisabled(disabled: boolean): void;
    loading: boolean;
    setLoading(loading: boolean): void;
}

export interface ButtonActionProps extends ButtonBaseProps {
    title: string;
    onClick(): void;
}

export default forwardRef<ButtonActionRef, ButtonActionProps>(function (
    props,
    ref
) {
    const { title: _title, onClick, ...rest } = props;

    const [title, setTitle] = useState(_title);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        title,
        setTitle(title: string) {
            setTitle(title);
        },
        disabled,
        setDisabled(disabled: boolean) {
            setDisabled(disabled);
        },
        loading,
        setLoading(loading: boolean) {
            setLoading(loading);
        },
    }));

    return (
        <Button
            {...rest}
            children={
                loading ? (
                    <CircularProgress
                        style={{ color: 'white' }}
                        size="1.5rem"
                    />
                ) : (
                    title
                )
            }
            color="#456AEF"
            onClick={onClick}
            disabled={disabled}
        />
    );
});
