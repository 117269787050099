import { Page } from 'core/interfaces/page';
import Switchable from 'components/Switchable';
import Details from './Details';

const Attribute: Page = {
  label: 'Atributos',
  route: 'attributes',
  description:
    'Atributos são os campos que podem ser ' +
    'adicionados aos produtos, como por ' +
    'exemplo: cor, tamanho, peso, etc.',

  Details,
  listProps: {
    fields: {
      name: {
        headerName: 'Nome',
        reference: 'Atributo',
      },
      is_active: {
        headerName: 'Ativo?',
        width: 100,
        renderCell: ({ row: { id, is_active } }) => (
          <Switchable
            id={id}
            value={is_active}
            column="is_active"
            route="attributes"
            permissions={!Attribute.permissions?.update}
          />
        ),
      },
    },
    limitControlled: [100, 150, 200, 250],
    dialogOnDelete:
      'Deletando este atributo ele será ' +
      'excluído automaticamente dos ' +
      'produtos a qual ele foi atribuído, ' +
      'deseja continuar?',
  },
};

export default Attribute;
