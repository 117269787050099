import { Box, Button, Typography } from '@material-ui/core';
import BreadCrumbCatalogWithSubTitle from 'core/toolbox/BreadCrumbCatalogWithSubTitle';
import InfoIcon from '@material-ui/icons/Info';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckIcon from '@material-ui/icons/Check';
import { useState, useContext, useEffect } from 'react';
import AlertContext from 'core/contexts/Alert';
import { getUserData } from 'core/lib/getUserData';
import { environment } from 'environment';

export default function Details() {
    const [linkXmlFacebook, setLinkXmlFacebook] = useState<string>('');

    useEffect(() => {
        const isDev = !!(environment as any).isDevelopment;

        const userData = getUserData();

        let baseUrl = isDev
            ? 'https://api.atn.sellerfaces.com.br/marketplace/rss/'
            : 'https://api.sellerfaces.com.br/marketplace/rss/';

        setLinkXmlFacebook(baseUrl + userData.marketplace.slug_name);
    }, []);

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const alert = useContext(AlertContext);

    const onCopy = () => {
        navigator.clipboard
            .writeText(linkXmlFacebook)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1000);
            })
            .catch(() => {
                alert.error('Não foi possível copiar o link');
            });
    };

    return (
        <>
            <BreadCrumbCatalogWithSubTitle
                returnRoute="/integrations"
                subtitle="Catálogo Facebook"
                label="Integrações"
            />
            <Box
                style={{
                    padding: '24px',
                }}
            >
                <Typography
                    variant="h6"
                    style={{ fontWeight: 700, fontSize: 18, lineHeight: 2 }}
                >
                    Integração com o Catálogo Facebook
                </Typography>
                <Typography style={{ fontSize: 14, lineHeight: 2 }}>
                    Utilize essa integração gratuita para vender e divulgar na
                    rede social! Além disso, explore o potencial de grupos e
                    fanpages para atrair clientes.
                </Typography>

                <Box
                    style={{
                        color: '#334EB0',
                        backgroundColor: '#E6EAFC',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'left',
                        padding: '16px',
                        borderRadius: '4px',
                    }}
                >
                    <InfoIcon />
                    <Typography
                        variant="body1"
                        style={{
                            fontWeight: 600,
                            fontSize: 14,
                            display: 'inline-block',
                            marginLeft: 8,
                        }}
                    >
                        O feed XML para o catálogo do Facebook, é gerado
                        automaticamente pela plataforma para clientes que
                        utilizam a solução de Marketplace Sellerfaces.
                    </Typography>
                </Box>
                <Box style={{ marginTop: 60 }}>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <label
                            style={{
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: 2.5,
                            }}
                        >
                            Feed XML para o Facebook
                        </label>
                        <input
                            style={{
                                border: '1px solid #CCCCCC',
                                backgroundColor: '#f1f1f1',
                                display: 'block',
                                padding: '8px',
                                width: '100%',
                            }}
                            type="text"
                            disabled
                            value={linkXmlFacebook}
                        />
                    </div>
                    {isCopied ? (
                        <Button
                            disabled
                            style={{
                                backgroundColor: '#4AA629',
                                color: 'white',
                                fontSize: 14,
                                padding: '8px 78px',
                                width: '384px',
                                marginLeft: 8,
                                textTransform: 'unset',
                            }}
                        >
                            <CheckIcon />
                            Link copiado
                        </Button>
                    ) : (
                        <Button
                            onClick={() => onCopy()}
                            style={{
                                backgroundColor: '#456AEF',
                                color: 'white',
                                fontSize: 14,
                                padding: '8px 78px',
                                width: '384px',
                                marginLeft: 8,
                                textTransform: 'unset',
                            }}
                        >
                            <FileCopyOutlinedIcon />
                            Copiar feed XML
                        </Button>
                    )}
                </Box>
            </Box>
        </>
    );
}
