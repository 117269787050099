import {
  AppBar,
  Box,
  Dialog,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useState } from 'react';
import Button from './Button';

interface IProps {
  setShowDropdown: (showDropdown: boolean) => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  showDropdown: boolean;
  startDate: Date;
  endDate: Date;
}

interface ITabPanel {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme) => ({
  divPicker: {
    position: 'absolute',
    maxWidth: '50%',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  button: {
    fontWeight: 400,
    color: '#456AEF',
  },
  confirmButton: {
    fontWeight: 400,
    color: '#FFFFFF',
    backgroundColor: '#456AEF',
  },
  Panel: {
    backgroundColor: '#FFFFFF',
    fontWeight: 400,
    color: '#333333',
  },
  PanelIndicator: {
    backgroundColor: '#456AEF',
  },
}));

function TabPanel(props: ITabPanel) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function StaticDatePicker(props: IProps) {
  const {
    setEndDate,
    setStartDate,
    startDate,
    endDate,
    showDropdown,
    setShowDropdown,
  } = props;
  const [page, setPage] = useState(0);

  const [tempDate, setTempDate] = useState({
    startDate,
    endDate,
  });

  const classes = useStyles();

  const handlePageChange = (event: React.ChangeEvent<{}>, newPage: number) => {
    setPage(newPage);
  };

  const handleDateChange = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <div className={classes.divPicker}>
      <Dialog open={showDropdown} onClose={() => setShowDropdown(false)}>
        <AppBar position="static">
          <Tabs
            value={page}
            onChange={handlePageChange}
            className={classes.Panel}
            classes={{ indicator: classes.PanelIndicator }}
          >
            <Tab label="Mensal" />
            <Tab label="Anual" />
          </Tabs>
        </AppBar>

        <TabPanel value={page} index={0}>
          <DatePicker
            name="date_montly"
            views={['month']}
            value={tempDate.startDate}
            clearable
            disableFuture
            animateYearScrolling
            disableToolbar
            variant="static"
            onChange={(date: any) => {
              const init_date = moment(date).startOf('month').toDate();
              const end_date = moment(date).endOf('month').toDate();

              setTempDate({
                startDate: init_date,
                endDate: end_date,
              });
            }}
          />
        </TabPanel>
        <TabPanel value={page} index={1}>
          <DatePicker
            name="date_yearly"
            views={['year']}
            value={tempDate.startDate}
            clearable
            disableFuture
            animateYearScrolling
            disableToolbar
            minDate={new Date('2021-01-01')}
            maxDate={new Date()}
            variant="static"
            onChange={(date: any) => {
              const init_date = moment(date).startOf('year').toDate();
              const end_date = moment(date).endOf('year').toDate();

              setTempDate({
                startDate: init_date,
                endDate: end_date,
              });
            }}
          />
        </TabPanel>

        <div className={classes.buttons}>
          <Button
            onClick={() => {
              setShowDropdown(false);

              const initOfYear = moment().startOf('year').toDate();
              handleDateChange(initOfYear, new Date());
              setTempDate({
                startDate: initOfYear,
                endDate: new Date(),
              });
            }}
            className={classes.button}
          >
            Limpar
          </Button>
          <Button
            onClick={() => {
              setShowDropdown(false);
            }}
            className={classes.button}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setShowDropdown(false);
              handleDateChange(tempDate.startDate, tempDate.endDate);
            }}
            className={classes.confirmButton}
          >
            Confirmar
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default StaticDatePicker;
