import {
  useRef,
  useState,
  useContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button,
} from '@material-ui/core';
import {
  Add as AddIcon,
  EditOutlined as EditOutlinedIcon,
} from '@material-ui/icons';
import * as Yup from 'yup';
import clsx from 'clsx';

import TextField from 'core/components/inputs/TextField';
import InputContainer from 'core/components/InputContainer';
import Container from 'core/components/Container';
import ContainerMaster from 'core/toolbox/Container';
import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import TextFieldUnform from 'core/unform/TextField';
import NumberFieldUnform from 'core/unform/NumberField';
import CheckboxUnform from 'core/unform/Checkbox';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import { mergeDeep } from 'utils/mergeDeep';
import Dialog from 'components/Dialog';
import ModalCubage from './ModalCubage';
import Tooltip from 'core/components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import useIsSeller from 'core/lib/useIsSeller';
import disableFields from 'core/lib/disableFields';
import { Permissions } from 'core/interfaces/page';

export interface SkusProps {
  instance: Record<string, any>;
  onUpdate(variations: any[]): void;
  onWillExit(tab: number): void;
  permissions: Permissions;
}

export interface SkusRef {
  onWillExit(tab: number): void;
}

export default forwardRef<SkusRef, SkusProps>(function Skus(props, ref) {
  const { instance, onUpdate, onWillExit, permissions } = props;
  const [skus, setSkus] = useState(instance.skus);
  const [dialogSkuExit, setDialogSkuExit] = useState({
    open: false,
    tab: null as null | number,
  });
  const classes = useStyles();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);

  const getLocalStorageSeller = localStorage.getItem('@Auth:extra') as string;
  const updateLocalStorage = JSON.parse(getLocalStorageSeller);

  const [isSeller] = useIsSeller();

  const params: Record<string, any> = {};

  if (!isSeller && updateLocalStorage.seller_id !== null) {
    params.seller = updateLocalStorage.seller_id;
  }

  function skusModified(index?: number) {
    if (index) {
      const stateSkus = [...skus];
      stateSkus[index].modified = true;
    }

    const stateSkus = [...skus];
    stateSkus[0].modified = true;
  }

  async function upsertSkus() {
    try {
      await VALIDATIONS.validate(skus, { context: { skus } });

      const modifiedSkus = skus.filter((sku: any) => sku.modified === true);

      if (modifiedSkus.length !== 0) {
        const { data } = await api.put(
          `products/${instance.id}/skus`,
          modifiedSkus,
          { params }
        );

        onUpdate(data.variations);

        alert.success('Informações salvas com sucesso', 10000);
      } else {
        alert.warning('Nenhuma alteração realizada', 10000);
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        alert.error(error.message);
      } else if (error.isAxiosError) {
        alert.error(error.response.data.error.message);
      }

      // eslint-disable-next-line
      throw null;
    }
  }

  useEffect(() => {
    disableFields(permissions);
  }, [skus]);

  function SingleSku() {
    const setSkuModified = skusModified;

    return (
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={skus}
        onSubmit={() => {}}
      >
        <Field
          label="Código"
          name="0.sku"
          field={{
            type: 'text',
            componentProps: {
              InputProps: {
                inputProps: {
                  maxLength: 50,
                },
              },
            },
          }}
          slot="6"
        />
        <Field
          label="Quantidade"
          name="0.inventory"
          field={{
            type: 'number',
            numberFormat: {
              limitOfBytes: '4',
              allowNegative: false,
            },
          }}
          required
          slot="6"
          onChange={() => {
            setSkuModified();
          }}
        />
        <Field
          label="Preço"
          name="0.price"
          field={{
            type: 'number',
            numberFormat: {
              decimalSeparator: ',',
              decimalScale: 2,
              thousandSeparator: '.',
              isNumericString: true,
              fixedDecimalScale: true,
              precision: 10,
              allowNegative: false,
            },
          }}
          required
          slot="6"
          onChange={() => {
            setSkuModified();
          }}
        />
        <Field
          label="Preço promocional"
          name="0.promotional_price"
          field={{
            type: 'number',
            numberFormat: {
              decimalSeparator: ',',
              decimalScale: 2,
              thousandSeparator: '.',
              isNumericString: true,
              fixedDecimalScale: true,
              precision: 10,
              allowNegative: false,
            },
          }}
          slot="6"
          onChange={() => {
            setSkuModified();
          }}
        />
        {instance.hasWholesale && (
          <>
            <Field
              label="Preço de atacado"
              name="0.wholesale_price"
              field={{
                type: 'number',
                numberFormat: {
                  decimalSeparator: ',',
                  decimalScale: 2,
                  thousandSeparator: '.',
                  isNumericString: true,
                  fixedDecimalScale: true,
                  precision: 10,
                  allowNegative: false,
                },
              }}
              slot="6"
              onChange={() => {
                setSkuModified();
              }}
            />
            <Field
              label="Preço promocional de atacado"
              name="0.wholesale_promotional_price"
              field={{
                type: 'number',
                numberFormat: {
                  decimalSeparator: ',',
                  decimalScale: 2,
                  thousandSeparator: '.',
                  isNumericString: true,
                  fixedDecimalScale: true,
                  precision: 10,
                  allowNegative: false,
                },
              }}
              slot="6"
              onChange={() => {
                setSkuModified();
              }}
            />
          </>
        )}
        <Field
          label="Inativar item?"
          name="0.inactive"
          field={{
            type: 'boolean',
          }}
          positionLabel="right"
          slot="6"
          onChange={() => {
            setSkuModified();
          }}
        />
      </Form>
    );
  }

  function MultipleSku() {
    const inputRef = useRef<HTMLInputElement>(null);

    const setSkuModified = skusModified;

    function onSubmitCode() {
      // @ts-ignore
      const { value } = inputRef.current;

      if (value) {
        // @ts-ignore
        const data = formRef.current?.getData();
        const productsSave = (instance.skus || []).length;

        for (let i = 0; i < skus.length; i++) {
          // @ts-ignore
          if (!data[i].sku) {
            const value = `${inputRef.current?.value}-${i + 1 + productsSave}`;

            // @ts-ignore
            formRef.current?.setFieldValue(`${i}.sku`, value);
          }
        }
      } else {
        alert.error('Digite um prefixo para o código');
      }
    }

    function RowSku(props: { sku: Record<string, any>; index: number }) {
      const { sku, index } = props;
      const [open, setOpen] = useState(false);

      async function onClose(_: unknown, reason: string) {
        try {
          if (reason === 'confirmClick') {
            // @ts-ignore
            const { cubage } = formRef.current?.getData()[index];
            const _cubage = { ...skus[index].cubage, ...cubage };

            await VALIDATIONS_MODAL_CUBAGE.validate(_cubage, {
              abortEarly: true,
            });

            if (!skus[index].cubage) {
              skus[index].cubage = {};
            }

            skus[index].cubage.weight_kg = cubage.weight_kg;
            skus[index].cubage.length_cm = cubage.length_cm;
            skus[index].cubage.width_cm = cubage.width_cm;
            skus[index].cubage.height_cm = cubage.height_cm;
          }

          setOpen(false);
        } catch (error: any) {
          if (error instanceof Yup.ValidationError) {
            alert.error(error.message);
          }
        }
      }

      return (
        <>
          <TableRow key={index}>
            <TableCell className="sku-id">
              <TextFieldUnform
                name={`${index}.sku`}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                style={{ width: '100%', background: '#F2F2F2' }}
                disabled
              />
            </TableCell>
            <TableCell className="variation">
              <TextFieldUnform
                name={`${index}.variation_value_name_1`}
                defaultValue={sku.variation_value_name_1}
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                style={{ width: '100%', background: '#F2F2F2' }}
                disabled
              />
            </TableCell>
            {sku.product_variation_value_id_2 && (
              <TableCell className="variation">
                <TextFieldUnform
                  name={`${index}.variation_value_name_2`}
                  defaultValue={sku.variation_value_name_2}
                  InputProps={{
                    inputProps: {
                      maxLength: 50,
                    },
                  }}
                  style={{ width: '100%', background: '#F2F2F2' }}
                  disabled
                />
              </TableCell>
            )}
            {sku.product_variation_value_id_3 && (
              <TableCell className="variation">
                <TextFieldUnform
                  name={`${index}.variation_value_name_3`}
                  defaultValue={sku.variation_value_name_3}
                  InputProps={{
                    inputProps: {
                      maxLength: 50,
                    },
                  }}
                  style={{ width: '100%', background: '#F2F2F2' }}
                  disabled
                />
              </TableCell>
            )}
            <TableCell>
              <NumberFieldUnform
                name={`${index}.inventory`}
                numberFormat={{
                  allowNegative: false,
                }}
                onChange={() => {
                  setSkuModified(index);
                }}
              />
            </TableCell>
            <TableCell>
              <NumberFieldUnform
                name={`${index}.price`}
                numberFormat={{
                  decimalSeparator: ',',
                  decimalScale: 2,
                  thousandSeparator: '.',
                  isNumericString: true,
                  fixedDecimalScale: true,
                  allowNegative: false,
                }}
                onChange={() => {
                  setSkuModified(index);
                }}
              />
            </TableCell>
            <TableCell>
              <NumberFieldUnform
                name={`${index}.promotional_price`}
                numberFormat={{
                  decimalSeparator: ',',
                  decimalScale: 2,
                  thousandSeparator: '.',
                  isNumericString: true,
                  fixedDecimalScale: true,
                  allowNegative: false,
                }}
                onChange={() => {
                  setSkuModified(index);
                }}
              />
            </TableCell>
            {instance.hasWholesale && (
              <>
                <TableCell>
                  <NumberFieldUnform
                    name={`${index}.wholesale_price`}
                    numberFormat={{
                      decimalSeparator: ',',
                      decimalScale: 2,
                      thousandSeparator: '.',
                      isNumericString: true,
                      fixedDecimalScale: true,
                      allowNegative: false,
                    }}
                    onChange={() => {
                      setSkuModified(index);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <NumberFieldUnform
                    name={`${index}.wholesale_promotional_price`}
                    numberFormat={{
                      decimalSeparator: ',',
                      decimalScale: 2,
                      thousandSeparator: '.',
                      isNumericString: true,
                      fixedDecimalScale: true,
                      allowNegative: false,
                    }}
                    onChange={() => {
                      setSkuModified(index);
                    }}
                  />
                </TableCell>
              </>
            )}
            <TableCell>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <CheckboxUnform
                  name={`${index}.inactive`}
                  onChange={() => {
                    setSkuModified(index);
                  }}
                />
              </div>
            </TableCell>
            <TableCell className="dimensions">
              <div>
                <IconButton
                  size="small"
                  className={clsx({ active: !!sku.cubage })}
                  onClick={() => setOpen(true)}
                >
                  {sku.cubage ? <EditOutlinedIcon /> : <AddIcon />}
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
          <ModalCubage title="Dimensões" open={open} onClose={onClose}>
            <Field
              label="Peso (Kg) do produto com sua embalagem"
              name={`${index}.cubage.weight_kg`}
              description="Se forem 3kg escreva 3,000. Caso seja 300g escreva 0,300"
              field={{
                type: 'number',
                numberFormat: {
                  decimalSeparator: ',',
                  decimalScale: 3,
                  thousandSeparator: '.',
                  isNumericString: true,
                  precision: 10,
                  fixedDecimalScale: true,
                  allowNegative: false,
                },
              }}
              required
              slot="12"
              onChange={() => {
                setSkuModified(index);
              }}
            />
            <Field
              label="Comprimento (cm)"
              name={`${index}.cubage.length_cm`}
              field={{
                type: 'number',
                numberFormat: {
                  decimalSeparator: ',',
                  decimalScale: 0,
                  thousandSeparator: '.',
                  isNumericString: true,
                  precision: 7,
                  fixedDecimalScale: true,
                  allowNegative: false,
                },
              }}
              required
              slot="12"
              onChange={() => {
                setSkuModified(index);
              }}
            />
            <Field
              label="Largura (cm)"
              name={`${index}.cubage.width_cm`}
              field={{
                type: 'number',
                numberFormat: {
                  decimalSeparator: ',',
                  decimalScale: 0,
                  thousandSeparator: '.',
                  isNumericString: true,
                  precision: 7,
                  fixedDecimalScale: true,
                  allowNegative: false,
                },
              }}
              required
              slot="12"
              onChange={() => {
                setSkuModified(index);
              }}
            />
            <Field
              label="Altura (cm)"
              name={`${index}.cubage.height_cm`}
              field={{
                type: 'number',
                numberFormat: {
                  decimalSeparator: ',',
                  decimalScale: 0,
                  thousandSeparator: '.',
                  isNumericString: true,
                  precision: 7,
                  fixedDecimalScale: true,
                  allowNegative: false,
                },
              }}
              required
              slot="12"
              onChange={() => {
                setSkuModified(index);
              }}
            />
          </ModalCubage>
        </>
      );
    }

    return (
      <>
        <div
          className={classes.generateSkusContainer}
          style={{ display: 'none' }}
        >
          <InputContainer
            label="Prefixo do código"
            classes={{ content: classes.inputContainer }}
            description="Gere automaticamente o código identificador para todos os produtos que não possuem sku identificador"
            children={
              <TextField
                inputRef={inputRef}
                InputProps={{ inputProps: { maxLength: 50 } }}
              />
            }
          />
          <Button onClick={onSubmitCode} className={classes.generateSkusButton}>
            Gerar códigos
          </Button>
        </div>
        <Form
          ref={formRef}
          style={{ display: 'contents' }}
          initialData={skus}
          onSubmit={() => {}}
        >
          <div className={classes.tableScroll}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell width={200}>Cod.</TableCell>
                  {instance.variations.reduce(
                    (acc: any, variation: any, index: number) => {
                      acc.push(
                        <TableCell key={index} width={180}>
                          {variation.name}
                        </TableCell>
                      );

                      return acc;
                    },
                    []
                  )}
                  <TableCell width={60}>Quant.</TableCell>
                  <TableCell width={180}>Preço (R$)</TableCell>
                  <TableCell width={180}>Promoção (R$)</TableCell>
                  {instance.hasWholesale && (
                    <>
                      <TableCell width={180}>Preço de Atacado (R$)</TableCell>
                      <TableCell width={215}>
                        Promoção de Atacado (R$)
                      </TableCell>
                    </>
                  )}

                  <TableCell align="center" width={150}>
                    Inativar item
                    <Tooltip
                      title="O item marcado como inativo ficará oculto no marketplace."
                      style={{
                        marginBottom: '-6px',
                        marginLeft: '4px',
                      }}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </TableCell>

                  <TableCell width={90}>Dimensões</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {skus.reduce((acc: any, sku: any, index: number) => {
                  acc.push(<RowSku key={index} sku={sku} index={index} />);

                  return acc;
                }, [])}
              </TableBody>
            </Table>
          </div>
        </Form>
      </>
    );
  }

  useImperativeHandle(
    ref,
    () => ({
      async submitForm() {
        const target = { ...skus };
        const data = formRef.current!.getData();

        mergeDeep(target, data);

        setSkus(
          Object.entries(target).map(([_, value]) => {
            return value;
          })
        );

        upsertSkus();
      },
      onWillExit(tab: number) {
        const target = { ...skus };
        const data = formRef.current!.getData();

        mergeDeep(target, data);

        setSkus(
          Object.entries(target).map(([_, value]) => {
            return value;
          })
        );

        // await VALIDATIONS.validate(skus, { context: { skus } });

        const modifiedSkus = skus.filter((sku: any) => sku.modified === true);

        if (modifiedSkus.length > 0) {
          setDialogSkuExit({ open: true, tab });
          return false;
        } else {
          setDialogSkuExit({ open: false, tab });

          onWillExit(tab!);
          return true;
        }
      },
    }),
    [skus]
  );

  useEffect(() => {
    api.get(`products/${instance.id}/skus`, { params }).then(({ data }) => {
      setSkus(data);
    });
  }, []);

  if (!skus) {
    return null;
  }

  return (
    <ContainerMaster>
      <Container
        title="Estoque (SKU)"
        subtitle="Controle de estoque do produto por variação."
        classes={{
          content: {
            borderTop: 'none !important',
            paddingTop: 10,
            width: !instance.has_variation ? 'calc(100% + 40px)' : '100%',
            ...(!instance.has_variation
              ? {
                  paddingRight: 10,
                  paddingBottom: 0,
                  marginBottom: -10,
                }
              : {
                  paddingBottom: 10,
                }),
          },
        }}
      >
        {!instance.has_variation ? <SingleSku /> : <MultipleSku />}
      </Container>
      <Dialog
        open={dialogSkuExit.open}
        onClose={async (_, reason) => {
          try {
            if (reason === 'confirmClick') {
              await upsertSkus();

              onWillExit(dialogSkuExit.tab!);
            } else {
              onWillExit(dialogSkuExit.tab!);
            }
          } finally {
            setDialogSkuExit({ open: false, tab: null });
          }
        }}
        title="Deseja salvar a aba estoque?"
        subtitle={null}
        cancelTitle="Não"
        confirmTitle="Sim"
      />
    </ContainerMaster>
  );
});

const VALIDATIONS = Yup.array().of(
  Yup.object().shape({
    sku: Yup.string()
      .test(
        'duplicatedSku',
        'Por favor, escreva diferentes códigos para todas as linhas',
        (sku, { options, parent }) => {
          if (sku) {
            for (const _sku of options.context!.skus) {
              if (
                parent.product_variation_value_id_1 !==
                  _sku.product_variation_value_id_1 ||
                parent.product_variation_value_id_2 !==
                  _sku.product_variation_value_id_2 ||
                parent.product_variation_value_id_3 !==
                  _sku.product_variation_value_id_3
              ) {
                if (sku === _sku.sku) {
                  return false;
                }
              }
            }
          }

          return true;
        }
      )
      .nullable(),
    inventory: Yup.number()
      .required('Por favor, preencha a quantidade com um valor')
      .nullable(),
    price: Yup.number()
      .required('O campo preço está vazio')
      .moreThan(0, 'O preço deve ser maior que zero')
      .nullable(),
    promotional_price: Yup.number()
      .when('price', (price, schema) =>
        schema.lessThan(
          price,
          'O preço promocional não pode ser maior que o preço'
        )
      )
      .moreThan(0, 'O preço promocional deve ser maior que zero')
      .nullable(),
    wholesale_price: Yup.number()
      .moreThan(0, 'O preço de atacado deve ser maior que zero')
      .nullable(),
    wholesale_promotional_price: Yup.number()
      .when('wholesale_price', (wholesale_price, schema) =>
        schema.lessThan(
          wholesale_price,
          'O preço promocional de atacado não pode ser maior que o preço de atacado'
        )
      )
      .moreThan(0, 'O preço promocional de atacado deve ser maior que zero')
      .nullable(),
  })
);

const VALIDATIONS_MODAL_CUBAGE = Yup.object({
  weight_kg: Yup.string().required('O campo peso está vazio').nullable(),
  length_cm: Yup.string().required('O campo comprimento está vazio').nullable(),
  width_cm: Yup.string().required('O campo largura está vazio').nullable(),
  height_cm: Yup.string().required('O campo altura está vazio').nullable(),
});

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    alignItems: 'unset',
    flexDirection: 'column',
    '& p': {
      marginBottom: 10,
    },
  },
  generateSkusContainer: {
    marginBottom: 30,
    display: 'flex',
    alignItems: 'flex-end',
    width: '40%',
  },
  generateSkusButton: {
    marginLeft: 10,
    backgroundColor: '#456AEF',
    color: '#fff',
    textTransform: 'capitalize',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    height: 40,
    padding: '6px 16px',
    width: 150,
    minWidth: 150,
    '&:hover': {
      transition: 'background-color 500ms linear',
      backgroundColor: 'rgba(69, 106, 239, 0.5)',
    },
  },
  tableScroll: {
    paddingBottom: 10,
    overflowX: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#456AEF transparent',
    '&::-webkit-scrollbar': {
      height: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#456AEF',
      borderRadius: 4,
    },
  },
  table: {
    width: 1630,
  },
  tableHead: {
    '& tr': {
      '& th': {
        border: 0,
        paddingTop: 0,
        paddingBottom: 0,
        height: 40,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        color: '#444444',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        '& span': {
          whiteSpace: 'nowrap',
        },
        '&:first-of-type': {
          paddingLeft: 0,
        },
        '&:last-of-type': {
          textAlign: 'center',
          paddingRight: 0,
        },
      },
    },
  },
  tableBody: {
    '& tr': {
      '& td': {
        border: 0,
        paddingTop: theme.spacing(1.5),
        paddingBottom: 0,
        height: 30,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        '& input': {
          padding: '0 12px',
          height: 30,
        },
        '&:first-of-type': {
          paddingLeft: 0,
        },
        '&:last-of-type': {
          paddingRight: 0,
        },
        '&.dimensions': {
          width: '90px',
          '&>div': {
            display: 'flex',
            justifyContent: 'center',
            '&>button': {
              background: '#456aef',
              color: 'white',
              '&.active': {
                background: 'transparent',
                color: '#456aef',
              },
            },
          },
        },
        '&.variation': {
          '& .MuiInputBase-input.Mui-disabled': {
            cursor: 'no-drop',
          },
        },
        '&.sku-id': {
          '& .MuiInputBase-input.Mui-disabled': {
            cursor: 'no-drop',
          },
        },
      },
      '&:not(:last-of-type) td': {
        paddingBottom: theme.spacing(0.5),
      },
    },
  },
}));
