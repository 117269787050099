import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

import AuthContext from '../core/contexts/Auth';

const useStyles = makeStyles((theme) => ({
    url: {
        height: '38.86px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRight: '1px solid #CCCCCC',
        padding: theme.spacing(0, 1.9),
        marginRight: theme.spacing(1.9),
        backgroundColor: '#F9F9F9',
        '& span': {
            fontSize: theme.typography.pxToRem(14),
            color: '#444444',
            userSelect: 'none',
        },
    },
}));

interface IProps {
    urlPath?: string;
}

export default function (props: IProps) {
    const classes = useStyles();
    const auth = useContext(AuthContext);

    let domain: string = auth.extra!.marketplace?.domain || '';
    let redirect = auth.extra!.marketplace?.domain || '';

    if (!/^(http|https):\/\//.test(domain)) {
        domain = `//${domain}`;
    }

    if (redirect[redirect.length - 1] !== '/') {
        redirect += `/${props.urlPath || ''}`; 
    }

    return (
        <span className={classes.url}>
            <span>{redirect}</span>
        </span>
    );
}
