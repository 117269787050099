import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import { useContext, useEffect, useRef } from 'react';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';

import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import disableFields from 'core/lib/disableFields';

const VALIDATIONS = {
  internal_title: Yup.string()
    .required('Nome é obrigatório')
    .typeError('Nome é obrigatório'),
  code: Yup.string()
    .required('Código do cupom é obrigatório')
    .typeError('Código do cupom é obrigatório'),
  discount: Yup.number()
    .required('Porcentagem de desconto é obrigatório')
    .typeError('Porcentagem de desconto é obrigatório'),
  start_at: Yup.date()
    .required('Data inicial é obrigatório')
    .typeError('Data inicial é obrigatório'),
  end_at: Yup.date()
    .required('Data final é obrigatório')
    .typeError('Data final é obrigatório')
    .min(Yup.ref('start_at'), 'Data final deve ser maior que a data inicial'),
};

const Details = (props: any) => {
  const {
    isNew,
    page: { label, route, routes, permissions },
    instance,
  } = props;

  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const history = useHistory();

  const onSubmit = async (data: Record<string, any>) => {
    if (isNew) {
      api
        .post(routes.create, data)
        .then((response) => {
          history.replace(route);
          alert.success('Cupom criado com sucesso');
        })
        .catch((error) => {
          alert.error(error.message);
        });
    } else {
      const routeUpdate = routes.update(instance.id);

      api
        .put(routeUpdate, data)
        .then((response) => {
          history.replace(route);
          alert.success('Cupom atualizado com sucesso');
        })
        .catch((error) => {
          alert.error(error.message);
        });
    }
  };

  const onError = (error: Yup.ValidationError) => {
    alert.error(error.message);
  };

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions.update}
      />

      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome"
            name="internal_title"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            slot="4"
          />

          <Field
            label="Código do cupom"
            name="code"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            slot="4"
          />

          <Field
            label="Porcentagem de desconto"
            name="discount"
            field={{
              type: 'number',
              numberFormat: {
                allowNegative: false,
                decimalSeparator: '.',
                decimalScale: 2,
                fixedDecimalScale: true,
                isNumericString: true,
                suffix: '%',
                limit: {
                  min: 0,
                  max: 100,
                },
              },
            }}
            required
            slot="4"
          />

          <Field
            label="Data inicial"
            name="start_at"
            field={{
              type: 'datetime',
              format: 'DD/MM/YYYY HH:mm',
            }}
            required
            slot="6"
          />

          <Field
            label="Data Final"
            name="end_at"
            field={{
              type: 'datetime',
              format: 'DD/MM/YYYY HH:mm',
            }}
            required
            slot="6"
          />
          <Field
            label="Ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
        </Container>
      </Form>
    </>
  );
};

export default Details;
