import {
    useRef,
    forwardRef,
    useImperativeHandle,
    useEffect,
    useState,
} from 'react';
import {
    CircularProgress as CircularProgressBase,
    withStyles,
} from '@material-ui/core';

import ContainerMaster from 'core/toolbox/Container';
import Container from 'core/components/Container';
import { api } from 'core/lib/api';
import Added, { AddedRef } from './Added';
import Sugestion, { SugestionRef } from './Sugestion';
import { Permissions } from 'core/interfaces/page';

export interface AttributesProps {
    instance: Record<string, any>;
    permissions: Permissions
}

export interface AttributesRef {
    getData(): any[];
}

export default forwardRef<any, AttributesProps>(function Attributes(
    props,
    ref
) {
    const { instance, permissions } = props;
    const addedRef = useRef<AddedRef>(null);
    const sugestionRef = useRef<SugestionRef>(null);

    const [{ loading, attributesPerCategory, otherAttributes }, setState] =
        useState({
            loading: true,
            attributesPerCategory: [],
            otherAttributes: [],
        });

    useImperativeHandle(
        ref,
        () => ({
            getData() {
                return addedRef.current?.getData();
            },
        }),
        []
    );

    useEffect(() => {
        const params = {
            categories: instance.categories.map((category: any) => category.id),
            productId: instance.id,
        };

        api.get('attributes/per-categories', { params }).then(({ data }) => {
            if (instance.attributes) {
                for (const attribute of instance.attributes) {
                    for (
                        let i = 0;
                        i < data.attributesPerCategory.length;
                        i++
                    ) {
                        const { sugestionAttributes } = data
                            .attributesPerCategory[i] as any;

                        for (let i = 0; i < sugestionAttributes.length; i++) {
                            const sugestionAttribute = sugestionAttributes[i];

                            if (attribute.id === sugestionAttribute.id) {
                                sugestionAttributes.splice(i, 1);
                            }
                        }
                    }

                    for (let i = 0; i < data.otherAttributes.length; i++) {
                        const otherAttribute = data.otherAttributes[i] as any;

                        if (attribute.id === otherAttribute.id) {
                            data.otherAttributes.splice(i, 1);
                        }
                    }
                }
            }

            setState({ loading: false, ...data });
        });
    }, [instance.categories]);

    return (
        <ContainerMaster>
            <Container
                title="Atributos adicionados"
                subtitle="Atributos são características mais específicas que permitem definir dados adicionais aos produtos como (Tecnologia, Peso, Número de portas...)."
                classes={{
                    content: {
                        borderTop: 'none !important',
                        paddingTop: 10,
                        width: 'calc(100% + 40px)',
                    },
                    wrapper: {
                        marginBottom: -30,
                    },
                }}
            >
                <Added
                    ref={addedRef}
                    attributes={instance.attributes}
                    onRemove={(attribute) => {
                        sugestionRef.current?.addAttribute(attribute);
                    }}
                />
            </Container>
            <Container
                title="Atributos sugeridos"
                subtitle="Atributos sugeridos para as categorias selecionadas para o produto."
                classes={{
                    content: {
                        paddingBottom: 0,
                        width: 'calc(100% + 40px)',
                    },
                }}
            >
                {loading ? (
                    <CircularProgress style={{}} />
                ) : (
                    <Sugestion
                        ref={sugestionRef}
                        productId={instance.id}
                        categories={attributesPerCategory}
                        otherAttributes={otherAttributes}
                        onAdd={(attribute) => {
                            addedRef.current?.addAttribute(attribute);
                        }}
                        permissions={permissions}
                    />
                )}
            </Container>
        </ContainerMaster>
    );
});

const CircularProgress = withStyles({
    root: {
        height: 10,
        borderRadius: 5,
        color: '#456AEF',
    },
})(CircularProgressBase);
