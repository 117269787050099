import { useEffect, useState } from 'react';
import BreadCrumbDate from 'core/components/BreadCrumbDate';
import InitialBlankPage from 'core/components/InitialBlankPage';
import Card from 'core/components/Card';
import DashTable from 'core/components/DashTable';
import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import IDashTable, { exampleTable } from 'core/interfaces/IDashTable';
import formatToBRL from 'utils/formatToBRL';
import COLORS_PAYMENT from 'core/utils/colors_payment';
import moment from 'moment';
import { api } from 'core/lib/api';
import CardGraph from 'components/Home/CardGraph';
import BarGraph from 'components/Home/BarGraph';
import CircleGraph from 'components/Home/CircleGraph';
import useSWR from 'swr';
import Select from 'core/components/inputs/Select';
import SellsInPeriod from 'components/Home/SellsInPeriod';
import Loading from 'core/components/Loading';
import Wishlist from '../Wishlist';
import RecentsRatings from './tables/RecentsRatings';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#F9F9F9',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headerTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
  },
}));

interface ICardData {
  totalBilling: number;
  totalOrders: number;
  waitingOrders: number;
  canceledOrders: number;
}

interface IDataGraphs {
  allSells: {
    date: Date;
    amount: number;
  }[];
  countSellsInPeriod: {
    month: number;
    count: number;
  }[];
  countPaymentByStatus: {
    payment_status_id: number;
    count: number;
    payment_status_name: string;
  }[];
  countPaymentsByMethod: {
    payment_type: number;
    count: number;
    payment_type_name: string;
  }[];
  countDeliveryType: {
    shipping_type_id: number;
    count: number;
    shipping_type_name: string;
  }[];
  countNewAccounts: {
    month: number;
    count: number;
  }[];
  countDeletedAccounts: {
    month: number;
    count: number;
  }[];
  registeredStoresInPeriod: {
    month: number;
    count: number;
  }[];
  countUsersByState: {
    state: string;
    count: number;
  }[];
  averageTicket: {
    month: number;
    average_ticket: number;
  }[];
}

const MOTNHS = {
  Jan: 1,
  Fev: 2,
  Mar: 3,
  Abr: 4,
  Mai: 5,
  Jun: 6,
  Jul: 7,
  Ago: 8,
  Set: 9,
  Out: 10,
  Nov: 11,
  Dez: 12,
};

function HomePage() {
  const initOfYear = moment().startOf('year').toDate();
  const [startDate, setStartDate] = useState(initOfYear);
  const [endDate, setEndDate] = useState(new Date());
  const classes = useStyles();

  const [data, setData] = useState<any>();
  const [cardData, setCardData] = useState<ICardData>();
  const [topSellers, setTopSellers] = useState<IDashTable>(exampleTable);
  const [lastOrders, setLastOrders] = useState<IDashTable>(exampleTable);
  const [expiredBills, setExpiredBills] = useState<IDashTable>(exampleTable);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [sellsGraphPeriodFilter, setSellsGraphPeriodFilter] = useState<
    'year' | 'month' | 'week' | 'last7days'
  >('year');

  const BreadCrumbProps = {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };

  const { data: dataGraphs, isValidating: isGraphsValidating } =
    useSWR<IDataGraphs>(
      '/initials/getMarketplaceDataGraphs?year=' + startDate.getFullYear()
    );

  useEffect(() => {
    setIsLoadingData(true);
    api
      .get(
        `/initials/getMarketplaceData?start_date=${startDate}&end_date=${endDate}`
      )
      .then((res) => {
        setData(res.data);

        setCardData(res.data.cards);

        setTopSellers({
          title: 'Top 10 Sellers que mais venderam',
          columns: ['Ranking', 'Loja', 'Cidade', 'Faturamento', 'Vendas'],
          rows: res.data.topSellers.slice(0, 10).map((seller: any) => {
            return [
              {
                value: `${res.data.topSellers.indexOf(seller) + 1}º`,
              },
              { value: seller.name },
              { value: seller.city },
              { value: formatToBRL(seller.amount) },
              { value: `${seller.quantity} unidades` },
            ];
          }),
        });

        setLastOrders({
          title: 'Últimos pedidos',
          viewMore: {
            label: 'Ver todos os pedidos',
            route: '/orders',
          },
          columns: [
            'ID do pedido',
            'Nome do cliente',
            'Data do pedido',
            'Valor total',
            'Situação do pagamento',
            'Forma de pagamento',
          ],
          rows: res.data.lastOrders.slice(0, 5).map((order: any) => {
            return [
              {
                value: order.id,
                route: `orders/${order.id}`,
                clickable: true,
                color: 'primary',
                fontWeight: 'bold',
              },
              { value: order.customer_name },
              { value: order.date },
              { value: formatToBRL(order.total_amount) },
              {
                value: order.payment_status,
                fontWeight: 'bold',
                color: COLORS_PAYMENT[order.payment_status_id],
              },
              { value: order.payment_type },
            ];
          }),
        });

        setExpiredBills({
          title: 'Boletos pendentes',
          columns: [
            'ID do pedido',
            'Nome do cliente',
            'Email do cliente',
            'Data do pedido',
            'Valor total',
          ],
          rows: res.data.pendingBills.map((bill: any) => {
            return [
              {
                value: bill.id,
                route: `orders/${bill.id}`,
                clickable: true,
              },
              { value: bill.customer_name },
              { value: bill.customer_email },
              { value: bill.date },
              { value: formatToBRL(bill.total_amount) },
            ];
          }),
          pagination: true,
          rowsPerPage: 10,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoadingData(false);
      });
  }, [endDate, endDate]);

  const isLoading = isLoadingData && !dataGraphs && isGraphsValidating;
  const showNoDataPage = !cardData || cardData?.totalOrders === 0;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      className={classes.background}
      style={{
        height: showNoDataPage ? '100vh' : 'auto',
      }}
    >
      <BreadCrumbDate {...BreadCrumbProps} />
      {showNoDataPage ? (
        <InitialBlankPage isSeller={false} />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Quantidade de pedidos"
                content={cardData.totalOrders}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Faturamento"
                content={formatToBRL(cardData.totalBilling)}
                color="success"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Aguardando pagamento"
                content={cardData.waitingOrders}
                color="warning"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                title="Compras canceladas"
                content={cardData.canceledOrders}
                color="danger"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DashTable {...topSellers} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DashTable {...lastOrders} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Wishlist startDate={startDate} endDate={endDate} isAdmin />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DashTable {...expiredBills} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <RecentsRatings data={data.recentsRatings} />
            </Grid>
          </Grid>

          {dataGraphs && (
            <Grid container>
              <Grid
                item
                style={{
                  width: '100%',
                }}
              >
                <CardGraph
                  title="Vendas por período (Faturamento)"
                  height="600px"
                  titleComponent={
                    <div
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        marginLeft: '25px',
                      }}
                    >
                      <Select
                        style={{
                          padding: '0px',
                          width: '150px',
                        }}
                        value={sellsGraphPeriodFilter}
                        onChange={(v) =>
                          setSellsGraphPeriodFilter(v.target.value as any)
                        }
                      >
                        <MenuItem value={'last7days'}>Últimos 7 dias</MenuItem>
                        <MenuItem value={'week'}>Esta Semana</MenuItem>
                        <MenuItem value={'month'}>Este Mês</MenuItem>
                        <MenuItem value={'year'}>Este Ano</MenuItem>
                      </Select>
                    </div>
                  }
                >
                  <SellsInPeriod
                    allSells={dataGraphs.allSells.map((s) => ({
                      x: s.date,
                      y: s.amount,
                    }))}
                    period={sellsGraphPeriodFilter}
                  />
                </CardGraph>
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardGraph title="Quantidade de vendas">
                  <BarGraph
                    categories={Object.keys(MOTNHS)}
                    series={[
                      {
                        name: 'Quantidade de Vendas',
                        data: Object.entries(MOTNHS).map(([name, num]) => {
                          return (
                            dataGraphs.countSellsInPeriod.find(
                              (m) => m.month === num
                            )?.count || 0
                          );
                        }),
                      },
                    ]}
                  />
                </CardGraph>
              </Grid>

              <Grid item lg={6} xs={12}>
                <CardGraph title="Pagamentos por situação">
                  <CircleGraph
                    series={dataGraphs.countPaymentByStatus.map((p) => p.count)}
                    labels={dataGraphs.countPaymentByStatus.map(
                      (p) => p.payment_status_name
                    )}
                  />
                </CardGraph>
              </Grid>

              <Grid item lg={6} xs={12}>
                <CardGraph title="Meios de pagamentos utilizados">
                  <CircleGraph
                    series={dataGraphs.countPaymentsByMethod.map(
                      (p) => p.count
                    )}
                    labels={dataGraphs.countPaymentsByMethod.map(
                      (p) => p.payment_type_name
                    )}
                  />
                </CardGraph>
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardGraph title="Tipos de entrega">
                  <CircleGraph
                    series={dataGraphs.countDeliveryType.map((d) => d.count)}
                    labels={dataGraphs.countDeliveryType.map(
                      (d) => d.shipping_type_name
                    )}
                  />
                </CardGraph>
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardGraph title="Ticket médio">
                  <BarGraph
                    categories={Object.keys(MOTNHS)}
                    series={[
                      {
                        name: 'Ticket médio R$',
                        data: Object.values(MOTNHS).map(
                          (value) =>
                            dataGraphs.averageTicket.find(
                              (ticket) => ticket.month === value
                            )?.average_ticket || 0
                        ),
                      },
                    ]}
                  />
                </CardGraph>
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardGraph title="Novos clientes e contas deletadas">
                  <BarGraph
                    series={[
                      {
                        name: 'Novas contas',
                        data: Object.entries(MOTNHS).map(
                          ([name, num]) =>
                            dataGraphs.countNewAccounts.find(
                              (m) => m.month === num
                            )?.count || 0
                        ),
                      },
                      {
                        name: 'Contas deletadas',
                        data: Object.entries(MOTNHS).map(
                          ([name, num]) =>
                            dataGraphs.countDeletedAccounts.find(
                              (m) => m.month === num
                            )?.count || 0
                        ),
                      },
                    ]}
                    categories={Object.keys(MOTNHS)}
                  />
                </CardGraph>
              </Grid>
              <Grid item lg={6} xs={12}>
                <CardGraph title="Lojas cadastradas">
                  <BarGraph
                    series={[
                      {
                        name: 'Lojas cadastradas',
                        data: Object.values(MOTNHS).map((m) => {
                          return (
                            dataGraphs.registeredStoresInPeriod.find(
                              (c) => c.month === m
                            )?.count || 0
                          );
                        }),
                      },
                    ]}
                    categories={Object.keys(MOTNHS)}
                  />
                </CardGraph>
              </Grid>

              <Grid item lg={6} xs={12}>
                <CardGraph title="Distribuição de usuários por Estado">
                  <CircleGraph
                    series={dataGraphs.countUsersByState.map((u) => u.count)}
                    labels={dataGraphs.countUsersByState.map((s) => s.state)}
                  />
                </CardGraph>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

export default HomePage;
