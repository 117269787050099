import { ReactNode } from 'react';
import {
    Button,
    Dialog as DialogBase,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

import { DialogProps as DialogPropsBase } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

export interface DialogProps {
    open: DialogPropsBase['open'];
    onClose?(
        event: {},
        reason:
            | 'backdropClick'
            | 'escapeKeyDown'
            | 'confirmClick'
            | 'cancelClick'
    ): void;
    title: ReactNode;
    subtitle: ReactNode;
    cancelTitle?: string;
    confirmTitle?: string;
}

const useStyles = makeStyles((theme) => ({
    'MuiPaper-root': {
        width: 420,
    },
    'MuiDialogActions-root': {
        justifyContent: 'center',
        padding: theme.spacing(2, 1),
    },
    'MuiButtonBase-root': {
        width: 100,
        border: '1px solid',
        fontWeight: 600,
        textTransform: 'unset',
    },
    buttonCancel: {
        borderColor: '#456AEF',
        color: '#456AEF',
    },
    buttonCopy: {
        borderColor: '#456AEF',
        background: '#456AEF',
        color: 'white',
    },
}));

export const DialogText = DialogContentText;

export default function Dialog(props: DialogProps) {
    const {
        open,
        onClose,
        title,
        subtitle,
        cancelTitle = 'Cancelar',
        confirmTitle = 'Continuar',
    } = props;
    const classes = useStyles();

    return (
        <DialogBase
            open={open}
            onClose={onClose}
            classes={{ paper: classes['MuiPaper-root'] }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{subtitle}</DialogContent>
            <DialogActions classes={{ root: classes['MuiDialogActions-root'] }}>
                <Button
                    className={classes.buttonCancel}
                    classes={{ root: classes['MuiButtonBase-root'] }}
                    onClick={(e) => onClose?.(e, 'cancelClick')}
                >
                    {cancelTitle}
                </Button>
                <Button
                    className={classes.buttonCopy}
                    classes={{ root: classes['MuiButtonBase-root'] }}
                    onClick={(e) => onClose?.(e, 'confirmClick')}
                >
                    {confirmTitle}
                </Button>
            </DialogActions>
        </DialogBase>
    );
}
