import { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import AuthContext from 'core/contexts/Auth';
import MarketplaceUrl from 'components/MarketplaceUrl';
import { slugify } from 'utils/slugify';
import cnpjValidation from 'validations/cnpj';
import getCEP from 'utils/getCep';
import { api } from 'core/lib/api';
import Connection from './Connection';
import disableFields from 'core/lib/disableFields';

cnpjValidation(Yup);

const VALIDATIONS = {
  name: Yup.string().required('O campo nome está vazio'),
  email: Yup.string()
    .required('O campo e-mail está vazio')
    .email('Insira um email válido'),
  // @ts-ignore
  cnpj: Yup.string()
    .required('O campo cnpj está vazio')
    // @ts-ignore
    // TODO: Validate CNPJ function
    .cnpj('O cnpj ou cpf não é válido'),
  slug_name: Yup.string().required('O campo nome está vazio'),
  marketplace_commission_perc: Yup.string()
    .required('O campo comissão percentual está vazio')
    .nullable(),
  address: Yup.object({
    zip_code: Yup.string()
      .required('O campo cep está vazio')
      .matches(/^[\d]{5}(-|)[\d]{3}/, {
        excludeEmptyString: true,
        message: 'CEP inválido',
      }),
    street: Yup.string().required('O campo endereço está vazio'),
    street_number: Yup.string().required('O campo número está vazio'),
    district: Yup.string().required('O campo bairro está vazio'),
    city: Yup.string().required('O campo cidade está vazio'),
    state: Yup.string().required('O campo estado está vazio'),
  }),
};

export default function Details(props: any) {
  const {
    isNew,
    id,
    page: { label, route, routes, permissions },
  } = props;
  const [instance, setIntance] = useState(props.instance);
  const [wholesale, setWholesale] = useState(instance.wholesale);
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const auth = useContext(AuthContext);

  async function onSubmit(data: Record<string, any>) {
    const { update: routeUpdate = `${route}/custom/${id}` } = routes || {};

    try {
      data.id = id;

      const { data: instance } = await api.put(routeUpdate, {
        ...data,
        id,
      });

      formRef.current?.setFieldValue(
        'credits_approved',
        instance.credits_approved
      );

      setIntance(instance);

      auth.changeHasWholesale(instance.wholesale);

      alert.success('Alterações salvas com sucesso', 10000);
    } catch (err: any) {
      const {
        request: { status },
      } = err;
      let message;

      switch (status) {
        case 401:
          message = 'Email já está em uso';
          break;

        case 409:
          message = 'URL amigável já está em uso!';
          break;

        default:
          message = 'Erro no servidor';
          break;
      }

      alert.error(message);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableCancel
        disableCreator
        disableSave={!permissions.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            slot="4"
            onChange={(value) => {
              formRef.current?.setFieldValue('slug_name', slugify(value));
            }}
          />
          <Field
            label="E-mail"
            name="email"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            }}
            required
            slot="4"
          />
          <Field
            label="CNPJ/CPF"
            name="cnpj"
            field={{
              type: 'text',
              maskFormat: {
                mask(rawValue: any) {
                  if (rawValue.length <= 14) {
                    return [
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ];
                  }

                  return [
                    /[0-9]/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ];
                },
              },
            }}
            required
            slot="4"
          />
          <Field
            label="URL amigável"
            name="slug_name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  startAdornment: <MarketplaceUrl />,
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            onChange={(value) => {
              formRef.current?.setFieldValue('slug_name', slugify(value));
            }}
            slot="8"
          />
          <Field
            label="Comissão Percentual (%)"
            name="marketplace_commission_perc"
            description="Taxas e acordos configurados entre a loja e o marketplace"
            field={{
              type: 'number',
              numberFormat: {
                decimalSeparator: ',',
                decimalScale: 2,
                thousandSeparator: '.',
                isNumericString: true,
                precision: 10,
                allowNegative: false,
                fixedDecimalScale: true,
                limit: {
                  max: 100,
                },
              },
              componentProps: {
                disabled: true,
              },
            }}
            required
            slot="4"
          />
          <Field
            label="Telefone"
            name="phone"
            field={{
              type: 'text',
              maskFormat: {
                mask: [
                  '(',
                  /[1-9]/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ],
              },
            }}
            slot="4"
          />
          <Field
            label="Celular"
            name="cell_phone"
            field={{
              type: 'text',
              maskFormat: {
                mask: [
                  '(',
                  /[1-9]/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ],
              },
            }}
            slot="4"
          />
          <Field
            label="Modelo de negócio"
            name="business_model"
            field={{
              type: 'list',
              data: [
                { value: '0', label: 'B2B' },
                { value: '1', label: 'B2C' },
                { value: '2', label: 'Ambos' },
              ],
              componentProps: {
                disabled: true,
              },
            }}
            slot="4"
          />
          <Field
            label="CEP"
            name="address.zip_code"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 10,
                  },
                },
              },
              maskFormat: {
                mask: [
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  '-',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ],
              },
            }}
            slot="4"
            onChange={async (value) => {
              if (/^[\d]{2}[\d]{3}-[\d]{3}/.test(value)) {
                const cep = await getCEP(value.replace('-', ''));

                formRef.current?.setFieldValue(
                  'address.street',
                  cep.logradouro
                );

                formRef.current?.setFieldValue('address.district', cep.bairro);

                formRef.current?.setFieldValue('address.city', cep.localidade);

                formRef.current?.setFieldValue('address.state', cep.uf);
              }
            }}
            required
          />
          <Field
            label="Endereço"
            name="address.street"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              },
            }}
            slot="8"
            required
          />
          <Field
            label="Número"
            name="address.street_number"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 20,
                  },
                },
              },
            }}
            slot="3"
            required
          />
          <Field
            label="Complemento"
            name="address.complement"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            slot="3"
          />
          <Field
            label="Bairro"
            name="address.district"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              },
            }}
            slot="3"
            required
          />
          <Field
            label="Cidade"
            name="address.city"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              },
            }}
            slot="3"
            required
          />
          <Field
            label="Estado"
            name="address.state"
            field={{
              type: 'list',
              data: [
                { value: 'AC', label: 'Acre' },
                { value: 'AL', label: 'Alagoas' },
                { value: 'AP', label: 'Amapá' },
                { value: 'AM', label: 'Amazonas' },
                { value: 'BA', label: 'Bahia' },
                { value: 'CE', label: 'Ceará' },
                { value: 'DF', label: 'Distrito Federal' },
                { value: 'ES', label: 'Espírito Santo' },
                { value: 'GO', label: 'Goiás' },
                { value: 'MA', label: 'Maranhão' },
                { value: 'MT', label: 'Mato Grosso' },
                { value: 'MS', label: 'Mato Grosso do Sul' },
                { value: 'MG', label: 'Minas Gerais' },
                { value: 'PA', label: 'Pará' },
                { value: 'PB', label: 'Paraíba' },
                { value: 'PR', label: 'Paraná' },
                { value: 'PE', label: 'Pernambuco' },
                { value: 'PI', label: 'Piauí' },
                { value: 'RJ', label: 'Rio de Janeiro' },
                { value: 'RN', label: 'Rio Grande do Norte' },
                { value: 'RS', label: 'Rio Grande do Sul' },
                { value: 'RO', label: 'Rondônia' },
                { value: 'RR', label: 'Roraima' },
                { value: 'SC', label: 'Santa Catarina' },
                { value: 'SP', label: 'São Paulo' },
                { value: 'SE', label: 'Sergipe' },
                { value: 'TO', label: 'Tocantins' },
              ],
            }}
            slot="3"
            required
          />
          <Field
            label="Logo"
            name="logo_path"
            field={{
              type: 'upload',
              accept: 'image/*',
              crop: {
                size: {
                  height: 256,
                  width: 256,
                },
              },
              route: `sellers/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue('logo_path', value.url);
            }}
            slot="3"
          />
          <Field
            label="Imagem de capa"
            name="header_image_path"
            field={{
              type: 'upload',
              accept: 'image/*',
              crop: {
                size: {
                  height: 400,
                  width: 1920,
                },
              },
              route: `sellers/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue('header_image_path', value.url);
            }}
            slot="3"
          />
          <Field
            label="Imagem de capa mobile"
            name="mobile_header_image_path"
            field={{
              type: 'upload',
              accept: 'image/*',
              crop: {
                size: {
                  height: 300,
                  width: 640,
                },
              },
              route: `sellers/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue(
                'mobile_header_image_path',
                value.url
              );
            }}
            slot="3"
          />
          <Field
            label="Sobre a loja"
            name="about"
            field={{
              type: 'text',
              componentProps: {
                multiline: true,
                rows: 4,
                InputProps: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              },
            }}
          />
          <Field
            label="Ativar loja ?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
          {instance.hasWholesale && (
            <>
              <Field
                label="Ativar modo atacado?"
                name="wholesale"
                field={{
                  type: 'boolean',
                  isSwitch: true,
                }}
                positionLabel="right"
                onChange={(value) => {
                  setWholesale(value);

                  if (!value) {
                    formRef.current?.setFieldValue('wholesale_min_value', null);
                  }
                }}
              />
              {wholesale && (
                <Field
                  label="Valor Mínimo de Atacado"
                  name="wholesale_min_value"
                  field={{
                    type: 'number',
                    numberFormat: {
                      allowNegative: false,
                      decimalSeparator: ',',
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      thousandSeparator: '.',
                      isNumericString: true,
                      prefix: 'R$ ',
                    },
                  }}
                  slot="6"
                />
              )}
            </>
          )}
          <Field
            label="Valor Mínimo para Venda"
            name="min_value_for_sale"
            field={{
              type: 'number',
              numberFormat: {
                allowNegative: false,
                decimalSeparator: ',',
                decimalScale: 2,
                fixedDecimalScale: true,
                thousandSeparator: '.',
                isNumericString: true,
                prefix: 'R$ ',
              },
            }}
            slot="6"
          />
        </Container>
        <Connection instance={instance} />
      </Form>
    </>
  );
}
