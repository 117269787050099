import { Page } from 'core/interfaces/page';
import QuestionsDetails from './QuestionsDetails';

const Questions: Page = {
  label: 'Perguntas',
  route: 'questions',
  onlyDetails: true,
  passInstance: true,
  Details: QuestionsDetails as any,
};

export default Questions;
