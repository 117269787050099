import { useContext } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';

import AuthContext from 'core/contexts/Auth';
import Switchable from 'components/Switchable';
import { Page } from 'core/interfaces/page';
import Details from './Details';
import { IconButton } from '@material-ui/core';
import { MoreHorizOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

function Redirect(props: { value: any }) {
  const { value } = props;
  const auth = useContext(AuthContext);

  let domain: string = auth.extra?.marketplace?.domain || '';

  if (!/^(http|https):\/\//.test(domain)) domain = `//${domain}`;

  return (
    <a
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
      }}
      href={`${domain}/${value}`}
      title="Acessar loja"
    >
      <LaunchIcon
        htmlColor="#5b70f0"
        style={{ fontSize: 15, marginRight: 5 }}
      />
      {value}
    </a>
  );
}

const ViewProducts = ({ id, name }: any) => {
  const history = useHistory();

  const MasterEdit = () => {
    const getLocalStorageSeller = localStorage.getItem('@Auth:extra') as string;
    const updateLocalStorage = JSON.parse(getLocalStorageSeller);

    const changedState = {
      ...updateLocalStorage,
      seller: id,
      seller_id: id,
      sellerName: name,
    };

    localStorage.setItem('@Auth:extra', JSON.stringify(changedState));

    history.push('/products');
  };

  return (
    <>
      <IconButton
        onClick={() => MasterEdit()}
        children={<MoreHorizOutlined />}
        title="Visualizar Produtos"
      />
    </>
  );
};

const Seller: Page = {
  label: 'Lojas',
  route: 'sellers',
  routes: {
    find: 'sellers/search',
    create: 'sellers/post-seller',
    findOne: (id) => `sellers/${id}`,
    update: (id) => `sellers/put-seller/${id}`,
    delete: (id) => `sellers/delete-seller/${id}`,
  },
  Details,
  listProps: {
    fields: {
      name: {
        headerName: 'Nome',
      },
      email: {
        headerName: 'Email',
      },
      slug_name: {
        headerName: 'Acesso',
        renderCell({ column, row }) {
          const value = row[column.field];

          return <Redirect value={value} />;
        },
      },
      marketplace_commission_perc: {
        headerName: 'Comissão Percentual (%)',
        renderCell({ column, row }) {
          if (row[column.field]) {
            return String(row[column.field]).replace('.', ',');
          }

          return 'Sem comissão';
        },
      },
      business_model: {
        headerName: 'Modelo de negócio',
        renderCell({ column, row }) {
          const data = [
            { value: 0, label: 'B2B' },
            { value: 1, label: 'B2C' },
            { value: 2, label: 'Ambos' },
          ];

          return (
            data.find((data) => data.value === row[column.field])?.label ||
            'Sem modelo de negócio'
          );
        },
      },
      is_active: {
        headerName: 'Ativo?',
        width: 100,
        renderCell: ({ row: { id, is_active } }) => (
          <Switchable
            id={id}
            value={is_active}
            column="is_active"
            route="sellers"
            permissions={!Seller.permissions?.update}
          />
        ),
      },
      official_store: {
        headerName: 'Loja oficial?',
        width: 100,
        renderCell: ({ row: { id, official_store } }) => (
          <Switchable
            id={id}
            value={official_store}
            column="official_store"
            route="sellers"
            permissions={!Seller.permissions?.update}
          />
        ),
      },
      view_products: {
        headerName: 'Visualizar produtos',
        reference: 'actions',
        width: 100,
        renderCell: ({ row: { id, name } }) => (
          <ViewProducts id={id} name={name} />
        ),
        disableByPermission: 'read',
      },
    },
    limitControlled: [10, 25, 50, 100],
    dialogOnDelete:
      'Deletando esta loja você ' +
      'estará deletando todos dados ' +
      'vinculados a ela, deseja continuar?',
  },
};

export default Seller;
