import { forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckboxBase from 'core/components/inputs/Checkbox';
import disableFields from 'core/lib/disableFields';


export interface AuthenticationBlingProps {
    instance: Record<string, any>;
    integrated?: any;
    isNew?: boolean;
    permissions?: any;
}

export interface AuthenticationBlingRef {
    getData(): Record<string, any>;
}

export default forwardRef<AuthenticationBlingRef, AuthenticationBlingProps>(
    function Authentication(props, ref) {
        const { instance, integrated, isNew, permissions } = props;
        const classes = useStyles();
        const formRef = useRef<FormHandles>(null);

        useImperativeHandle(
            ref,
            () => ({
                getData() {
                    try {
                        const data = formRef.current!.getData();

                        VALIDATIONS.validateSync(data);

                        return data;
                    } catch (error: any) {
                        throw error;
                    }
                },
            }),
            []
        );

        useEffect(() => {
            disableFields(permissions);
        }, [instance]);

        return (
            <Form
                ref={formRef}
                style={{ display: 'contents' }}
                initialData={instance}
                abortEarly
            >
                <ContainerCustom
                    title="Integração do módulo e-commerce da Bling"
                    subtitle="Configure o usuário API no Bling para começar a sincronizar produtos com o Bling."
                    classes={{ content: classes.content, wrap: classes.wrap }}
                >
                    <div className={classes.info}>
                        <Alert style={{ width: '100%' }} severity="info">
                            <span>
                                Somente produtos com Código SKU definidos e
                                cadastrados no Bling serão importados.
                            </span>
                        </Alert>
                    </div>

                    <div className={classes.contentForm}>
                        <div className={classes.activeIntegration}>
                            <CheckboxBase
                                name="is_active"
                                onChange={() => [
                                    integrated.setIsActive(
                                        !integrated.isActive
                                    ),
                                ]}
                                checked={!!integrated.isActive}
                                disabled={!isNew}
                            />
                            <Typography className={classes.title}>
                                Ativar integração com o Bling
                            </Typography>
                        </div>

                        {integrated.isActive && (
                            <>
                                <Field
                                    label="Chave de API (API key)"
                                    name="seller_token"
                                    field={{
                                        type: 'text',
                                        componentProps: {
                                            InputProps: {
                                                inputProps: {
                                                    maxLength: 200,
                                                },
                                            },
                                        },
                                        disabled: !isNew,
                                    }}
                                    required
                                    slot="5"
                                />

                                <Field
                                    label="Código da Loja API Bling"
                                    name="seller_code"
                                    field={{
                                        type: 'number',
                                        numberFormat: {
                                            allowNegative: false,
                                            isNumericString: false,
                                        },
                                        disabled: !isNew,
                                    }}
                                    required
                                    slot="3"
                                />
                            </>
                        )}
                    </div>
                </ContainerCustom>
            </Form>
        );
    }
);

const VALIDATIONS = Yup.object().shape({
    seller_token: Yup.string().required('O campo API Key está vazio'),
    seller_code: Yup.number()
        .required('O campo Código da Loja está vazio')
        .moreThan(0, 'O código deve ser maior que zero')
        .nullable(),
});

const useStyles = makeStyles({
    content: {
        border: 'none',
    },
    wrap: {
        width: 'calc(100% + 20px)',
        flexDirection: 'column',
    },
    title: {
        marginLeft: '10px',
    },
    activeIntegration: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '20px',
        marginBottom: '10px',
    },
    contentForm: {
        display: 'flex',
    },
    info: {
        marginBottom: '20px',
    },
});
