import { Page } from 'core/interfaces/page';
import Details from './Details';

const Information: Page = {
    label: 'Termos e políticas',
    route: 'terms',
    onlyDetails: true,
    routes: {
        update: 'marketplaces/terms',
        findOne: 'marketplaces/terms',
    },
    Details,
};

export default Information;
