import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import Loading from 'core/components/Loading';
import { api } from 'core/lib/api';
import { getUserData } from 'core/lib/getUserData';
import moment from 'moment';
import { useAsyncFn, useAsyncRetry } from 'react-use';
import RegisterLivesModal from './RegisterLivesModal';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#0F172A',
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 440,
    marginTop: '40px',
  },
  tableHeaderItem: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  inputSequence: {
    width: '45px',
    alignItems: 'center',
    justifyContent: 'center',

    '& input': {
      padding: '8px 0px',

      textAlign: 'center',
    },
  },
  tableCellItem: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Open Sans',
    lineHeight: '24px',
    color: '#0F172A',
  },
}));

interface ILive {
  id: number;
  title: string;
  starts_at: Date;
  status: string;
  order: number;
}

interface Props {
  showcase_id: number;
}

const RegisterLives: React.FC<Props> = ({ showcase_id }) => {
  const classes = styles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateSequenceProm, updateSequence] = useAsyncFn(
    async (liveId: number, order: number) => {
      const resp = await api.put('/lives/update-order', {
        live_id: liveId,
        order,
      });

      return resp.data;
    }
  );

  const lives = useAsyncRetry(async () => {
    const userData = getUserData();
    const res = await api.get<ILive[]>('/lives/added-lives', {
      params: {
        showcase_id,
        marketplace_id: userData.marketplace.id,
      },
    });

    return res.data;
  }, []);

  if (lives.loading) {
    return <Loading />;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" alignItems="center">
        <Typography className={classes.title}>
          Transmissões adicionadas
        </Typography>
        <RegisterLivesModal
          onUpdateLive={() => lives.retry()}
          showcase_id={showcase_id}
        />
      </Box>
      {lives.value && (
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderItem}>
                  Título
                </TableCell>
                <TableCell className={classes.tableHeaderItem}>Data</TableCell>
                <TableCell align="center" className={classes.tableHeaderItem}>
                  Horário
                </TableCell>
                <TableCell className={classes.tableHeaderItem}>
                  Situação
                </TableCell>
                <TableCell align="center" className={classes.tableHeaderItem}>
                  Sequência
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lives.value.map((row) => (
                <TableRow key={row.title}>
                  <TableCell className={classes.tableCellItem}>
                    {row.title}
                  </TableCell>
                  <TableCell className={classes.tableCellItem}>
                    {moment(row.starts_at).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellItem}>
                    {moment(row.starts_at).format('hh:mm:ss')}
                  </TableCell>
                  <TableCell className={classes.tableCellItem}>
                    {row.status}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCellItem}>
                    <TextField
                      defaultValue={row.order}
                      onChange={(e) =>
                        updateSequence(row.id, parseInt(e.target.value))
                      }
                      className={classes.inputSequence}
                      variant="outlined"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default RegisterLives;
