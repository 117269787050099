import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Permissions } from 'core/interfaces/page';

export interface AttributeProps {
  name: string;
  values: { name: string }[];
  onAdd(): void;
  permissions: Permissions;
}

export default function Attribute(props: AttributeProps) {
  const { name, values, permissions } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography className="title">
        {name}
        {permissions.update && (
          <IconButton
            className="close"
            size="small"
            style={{ background: '#456AEF' }}
            onClick={() => props.onAdd()}
          >
            <AddIcon className="icon" htmlColor="#ffffff" />
          </IconButton>
        )}
      </Typography>
      <div>
        <div className="value">
          {values.slice(0, 2).map((value: any, index: number) => (
            <Typography key={index} children={value.name} />
          ))}
          {values.length >= 2 && <Typography children="..." />}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 180,
    marginRight: 60,
    marginBottom: 30,
    '&>p': {
      fontWeight: 700,
      color: '#444444',
      fontSize: theme.typography.pxToRem(15),
      marginBottom: 15,
      lineHeight: 1,
    },
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .close': {
        backgroundColor: '#CCCCCC',
        '& .icon': {
          fontSize: theme.typography.pxToRem(15),
        },
      },
    },
    '& .value': {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      '& p': {
        fontWeight: 400,
        color: '#444444',
        fontSize: theme.typography.pxToRem(15),
        lineHeight: 1,
        '&:not(:last-of-type)': {
          marginBottom: theme.spacing(1),
        },
      },
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1.5),
      },
    },
  },
}));
