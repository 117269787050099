import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { DatePickerProps as DatePickerBaseProps } from '@material-ui/pickers';

import DatePickerCore from '../components/inputs/DatePicker';

export interface DatePickerProps
    extends Omit<DatePickerBaseProps, 'name' | 'value' | 'onChange'> {
    name: string;
    onChange?: DatePickerBaseProps['onChange'];
}

export default function DatePicker(props: DatePickerProps) {
    const { name, defaultValue = null, ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);
    const [defaultInputValue, setDefaultInputValue] = useState(value);

    const onChange = (date: any) => {
        setValue(date ? date.toISOString() : null);

        setDefaultInputValue(date);

        restProps.onChange?.(date);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value ?? null;
                },
                setValue(_: unknown, value: any) {
                    onChange(value);
                },
            });
        }
    }, [fieldName, registerField, defaultInputValue]);

    return (
        <DatePickerCore
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
            format="DD/MM/yyyy"
        />
    );
}
