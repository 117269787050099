import { useState, forwardRef, useImperativeHandle } from 'react';
import {
    Tabs,
    Tab,
    withStyles,
    createStyles,
    Theme,
    makeStyles,
    Button,
} from '@material-ui/core';
import { SearchOutlined as SearchOutlinedIcon } from '@material-ui/icons';

import Attribute from './Attribute';
import SearchAttributes from './SearchAttributes';
import { Permissions } from 'core/interfaces/page';

export interface SugestionProps {
    productId: number;
    categories: any[];
    otherAttributes: any[];
    onAdd(attribute: any): void;
    permissions: Permissions;
}

export interface SugestionRef {
    addAttribute(attribute: any): void;
}

export default forwardRef<SugestionRef, SugestionProps>(function Sugestion(
    props,
    ref
) {
    const classes = useStyles();
    const [otherAttributes, setOtherAttributes] = useState(
        props.otherAttributes
    );
    const [categories, setCategories] = useState(props.categories);
    const [tabs, setTabs] = useState(0);
    const [modalSearchAttr, setModalSearchAttr] = useState(false);

    useImperativeHandle(ref, () => ({
        addAttribute(attribute) {
            for (const category of categories) {
                if (
                    attribute.categories?.some(
                        (_category: any) => _category.id === category.id
                    )
                ) {
                    category.sugestionAttributes.push(attribute);

                    setCategories([...categories]);

                    return;
                }
            }

            otherAttributes.push(attribute);

            setOtherAttributes([...otherAttributes]);
        },
    }));

    return (
        <div style={{ width: '100%' }}>
            {!!categories.length && (
                <AntTabs value={tabs} onChange={(_, value) => setTabs(value)}>
                    {categories.map((category, index) => (
                        <AntTab
                            key={index}
                            value={index}
                            label={category.name}
                        />
                    ))}
                </AntTabs>
            )}
            {categories[tabs]?.sugestionAttributes && (
                <div style={{ marginTop: 30, display: 'flex' }}>
                    {categories[tabs].sugestionAttributes.map(
                        (attribute: any, index: number) => (
                            <Attribute
                                key={index}
                                name={attribute.name}
                                values={attribute.attributeValues}
                                onAdd={() => {
                                    categories[tabs].sugestionAttributes.splice(
                                        index,
                                        1
                                    );

                                    props.onAdd(attribute);

                                    setCategories([...categories]);
                                }}
                                permissions={props.permissions}
                            />
                        )
                    )}
                </div>
            )}
            <Button
                className={classes.searchButton}
                startIcon={<SearchOutlinedIcon htmlColor="#456AEF" />}
                onClick={() => setModalSearchAttr(true)}
            >
                Pesquisar outros
            </Button>
            <SearchAttributes
                attributes={otherAttributes}
                open={modalSearchAttr}
                onClose={(attributes) => {
                    for (const attribute of attributes) {
                        for (let i = 0; i < otherAttributes.length; i++) {
                            const _attribute = otherAttributes[i];

                            if (_attribute.id === attribute.id) {
                                otherAttributes.splice(i, 1);
                            }
                        }

                        delete attribute.checked;
                    }

                    props.onAdd(attributes);

                    setOtherAttributes([...otherAttributes]);

                    setModalSearchAttr(false);
                }}
            />
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    searchButton: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(15),
        color: '#456AEF',
        textTransform: 'capitalize',
        marginTop: 20,
    },
}));

const AntTabs = withStyles({
    indicator: {
        backgroundColor: '#456AEF',
    },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#BBBBBB',
            fontSize: theme.typography.pxToRem(15),
            textTransform: 'uppercase',
            minWidth: 'auto',
            fontWeight: 'bold',
            marginRight: theme.spacing(4),
            '&$selected': {
                color: '#333333',
            },
            padding: '6px 0',
        },
        selected: {},
    })
)((props: any) => <Tab disableRipple {...props} />);
