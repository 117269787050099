import { Page } from 'core/interfaces/page';
import Details from './Details';

const Metalives: Page = {
    label: 'Metalives',
    route: 'metalives',
    onlyDetails: true,
    passInstance: true,
    Details,
};

export default Metalives;