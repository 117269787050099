export function currency(
    value: string,
    prefix?: string | false,
    amountDecimalPlaces?: string
) {
    let currency = parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
        'pt-BR',
        { currency: 'BRL' }
    );

    if (currency.indexOf(',') === -1) {
        currency += `,${amountDecimalPlaces || '00'}`;
    } else if (currency[currency.length - 2] === ',') {
        currency += '0';
    }

    switch (prefix) {
        case false:
            return currency;

        case undefined:
            return `R$ ${currency}`;

        default:
            return `${prefix}${currency}`;
    }
}
