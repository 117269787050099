const Reference = ({ route, field, rowId }: any) => {
  return (
    <a
      href={`/${route}/${rowId}`}
      style={{
        color: '#456AEF',
        cursor: 'pointer',
        textDecoration: 'none',
        outline: 'none',
      }}
      children={rowId}
    />
  );
};

export default Reference;
