import { makeStyles } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import moment from 'moment';
import { useState } from 'react';
import IBreadCrumbDate from '../interfaces/IBreadCrumbDate';
import StaticDatePicker from './StaticDatePicker';

const useStyles = makeStyles((theme) => ({
    breadCrumb: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontSize: 28,
        fontFamily: 'Open Sans',
        margin: '20px 0 20px 0px',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    icon: {
        position: 'relative',
        fontSize: 32,
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
    },
}));

function BreadCrumbDate(props: IBreadCrumbDate) {
    const classes = useStyles();

    const { startDate, endDate } = props;

    const [showDropdown, setShowDropdown] = useState(false);

    const verifyDateFormat = (startDate: Date, endDate: Date) => {
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const difference = endDateMoment.diff(startDateMoment, 'months');
        return difference > 1 ? 'YYYY' : 'MMMM [de] YYYY';
    };

    const formattedDate = moment(props.startDate)
        .format(verifyDateFormat(startDate, endDate))
        .replace(/^./, (str) => str.toUpperCase());

    return (
        <div className={classes.breadCrumb}>
            Resumo de <span className={classes.fontBold}> {formattedDate}</span>
            <CalendarToday
                onClick={() => setShowDropdown(!showDropdown)}
                className={classes.icon}
            />
            <StaticDatePicker
                {...props}
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
            />
        </div>
    );
}

export default BreadCrumbDate;
