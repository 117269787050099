export function slugify(text: string) {
    return text
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w\u002d]+/g, '')
        .replace(/\u002d\u002d+/g, '-');
}
