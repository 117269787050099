import { Page } from 'core/interfaces/page';

const Integration: Page = {
    label: 'Integrações',
    route: 'integrations',
    onlyDetails: true,
    passInstance: true,
};

export default Integration;
