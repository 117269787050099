import axios from 'axios';
import { environment } from 'environment';

export const api = axios.create({ baseURL: environment.apiUrl });

api.interceptors.response.use(
    (response) => {

        if (response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }

        return Promise.resolve(response);
    },
    (error) => {
        return Promise.reject(error);
    }
);