import { makeStyles } from '@material-ui/core';
import Chart from 'react-apexcharts';
interface Props {
  series: number[];
  labels: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
}));

const CircleGraph: React.FC<Props> = ({ series, labels }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Chart
        series={series}
        type="donut"
        height={400}
        options={{
          chart: {
            type: 'donut',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '0',
              },
            },
          },
          labels,

          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        }}
      />
    </div>
  );
};

export default CircleGraph;
