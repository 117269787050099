import { Box, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

interface IProps {
  title: string;
  description?: string;
  info?: string;
}

const BoxInfo = (props: IProps) => {
  return (
    <Box>
      <Typography
        variant="h6"
        style={{ fontWeight: 700, fontSize: 18, lineHeight: 2 }}
      >
        {props.title}
      </Typography>
      <Typography style={{ fontSize: 14, lineHeight: 2 }}>
        {props.description}
      </Typography>

      <Box
        style={{
          color: '#334EB0',
          backgroundColor: '#E6EAFC',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          padding: '16px',
          borderRadius: '4px',
        }}
      >
        <InfoIcon />
        <Typography
          variant="body1"
          style={{
            fontWeight: 600,
            fontSize: 14,
            display: 'inline-block',
            marginLeft: 8,
          }}
        >
          {props.info}
        </Typography>
      </Box>
    </Box>
  );
};

export default BoxInfo;
