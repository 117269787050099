import { useRef } from 'react';
import TextFieldCore, {
    OutlinedTextFieldProps as TextFieldPropsCore,
} from '@material-ui/core/TextField';
import { makeStyles, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export interface ColorPickerProps
    extends Omit<TextFieldPropsCore, 'variant' | 'size' | 'type'> {
    type?:
        | 'button'
        | 'checkbox'
        | 'color'
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'file'
        | 'hidden'
        | 'image'
        | 'month'
        | 'number'
        | 'password'
        | 'radio'
        | 'range'
        | 'reset'
        | 'search'
        | 'submit'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week';
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        '& input, & textarea': {
            fontSize: theme.typography.pxToRem(15),
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 0,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& .colorContent': {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            '& .color': {
                width: 25,
                height: 25,
                background: 'red',
                borderRadius: '50%',
                margin: theme.spacing(0, 1.5),
                border: '1px solid #D3D3D3',
            },
            '& .hex': {
                textTransform: 'uppercase',
                color: '#444444',
                fontSize: theme.typography.pxToRem(15),
                fontWeight: '600',
            },
        },
        '& input': {
            opacity: 0,
            cursor: 'pointer',
        },
    },
    remove: {
        marginRight: theme.spacing(1.5),
    },
}));

export default function ColorPicker(props: ColorPickerProps) {
    const { InputProps = {}, ...rest } = props;
    const value =
        (props.defaultValue as string) || (props.value as string) || '#000000';
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null);

    InputProps.startAdornment = (
        <>
            {props.InputProps?.startAdornment || null}
            <div className="colorContent">
                <div className="color" style={{ background: value }} />
                <div className="hex">{value}</div>
            </div>
        </>
    );

    InputProps.endAdornment = (
        <>
            <IconButton
                size="small"
                className={classes.remove}
                onClick={onClean}
            >
                <HighlightOffIcon fontSize="small" />
            </IconButton>
            {props.InputProps?.endAdornment || null}
        </>
    );

    function onClean() {
        if (inputRef.current) {
            inputRef.current.value = '';

            // @ts-ignore
            const [color, hex]: HTMLDivElement[] =
                inputRef.current.parentNode!.children[0].children;

            color.style.background = '#000000';

            hex.innerText = '#000000';
        }
    }

    return (
        <TextFieldCore
            {...rest}
            inputRef={inputRef}
            InputProps={InputProps}
            classes={{
                root: classes.root,
            }}
            variant="outlined"
            size="small"
        />
    );
}
