import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import MarketplaceUrl from 'components/MarketplaceUrl';
import AlertContext from 'core/contexts/Alert';
import AuthContext from 'core/contexts/Auth';
import { api } from 'core/lib/api';
import { slugify } from 'utils/slugify';
import disableFields from 'core/lib/disableFields';

const VALIDATIONS = {
  name: Yup.string().required('O campo nome está vazio'),
  slug_name: Yup.string().required('O campo URL amigável está vazio'),
};

export default function Details(props: any) {
  const {
    isNew,
    id,
    page: { label, route, routes, permissions },
    instance,
  } = props;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const auth = useContext(AuthContext);

  async function onSubmit(data: Record<string, any>) {
    const {
      create: routeCreate = `${route}/custom`,
      update: routeUpdate = `${route}/custom/${id}`,
    } = routes || {};

    try {
      if (isNew) {
        data = (await api.post(routeCreate, data)).data;

        alert.success('Informações salvas com sucesso', 10000);
      } else {
        await api.put(routeUpdate, { ...data, id });

        alert.success('Alterações salvas com sucesso', 10000);
      }

      history.push(`/${route}`);
    } catch ({ request: { status } }: any) {
      let message;

      switch (status) {
        case 409:
          message = 'URL amigável já está em uso!';
          break;

        default:
          message = 'Erro no servidor';
          break;
      }

      alert.error(message);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions?.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome da seção"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            slot="6"
            onChange={(value) => {
              formRef.current?.setFieldValue('slug_name', slugify(value));
            }}
          />
          <Field
            label="Sequência"
            name="order"
            field={{
              type: 'number',
              numberFormat: {
                limitOfBytes: '2',
                allowNegative: false,
              },
            }}
            slot="6"
          />
          <Field
            label="URL amigável"
            name="slug_name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  startAdornment: <MarketplaceUrl />,
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            onChange={(value) => {
              formRef.current?.setFieldValue('slug_name', slugify(value));
            }}
          />
          <Field
            label="Faça o upload do ícone (Opcional)"
            name="icon_path"
            field={{
              type: 'upload',
              accept: 'image/*',
              route: `sellers/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue('icon_path', value.url);
            }}
            slot="12"
          />
          <Field
            label="Ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
            slot={{
              xs: '12',
              lg: '2',
              xl: '1',
            }}
          />

          <Field
            label="Exibir no rodapé?"
            name="show"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
            slot={{
              sm: '12',
              lg: '3',
              xl: '2',
            }}
          />
        </Container>
      </Form>
    </>
  );
}
