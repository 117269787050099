import { Ref, useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import Autocomplete, {
  AutocompleteProps as AutocompleteBaseProps,
} from './Autocomplete';

export interface AutocompleteProps
  extends Omit<AutocompleteBaseProps, ExcludeProps> {
  options(): Promise<any[]>;
  inputRef?: Ref<any>;
  getOptionSelected(option: any, value: any): boolean;
  getOptionLabel: (option: any) => string;
  delayLoad?: number;
  noOptionsText?: string;
}

type ExcludeProps =
  | 'open'
  | 'onOpen'
  | 'onClose'
  | 'options'
  | 'loading'
  | 'renderInput'
  | 'getOptionSelected'
  | 'getOptionLabel'
  | 'noOptionsText';

export default function AutocompleteAsync(props: AutocompleteProps) {
  const { delayLoad = 0, ...rest } = props;
  const {
    options: optionsAsync,
    inputRef,
    getOptionLabel,
    getOptionSelected,
    noOptionsText = 'No results',
  } = rest;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    optionsAsync().then((data) => {
      setTimeout(() => {
        if (active) {
          if (!data.length) {
            data.push({
              name: noOptionsText,
              disabled: true,
            });
          }

          setOptions(data);
        }
      }, delayLoad);
    });

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...rest}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      getOptionDisabled={(option) =>
        typeof option === 'object' && option.disabled
      }
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options.filter((option) =>
        option.name.toLowerCase().includes(currentFilter.toLowerCase())
      )}
      loading={loading}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          onChange={(e) => {
            setCurrentFilter(e.target.value);
            rest.onChange?.(e as any, e.target.value, 'select-option');
          }}
          variant="outlined"
          placeholder={rest.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
