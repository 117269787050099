// @ts-ignore
import dotAccess from 'dot-access';

interface Field {
    [key: string]: any;
}

export interface Validations {
    required?: string;
    email?: string;
    isArray?: boolean;
    regex?: {
        message: string;
        pattern: RegExp;
    };
    compareWith?: {
        message: string;
        field: string;
    };
    custom?: {
        message: string;
        function(value: string, values?: any): boolean | Promise<boolean>;
    };
}

export interface Control {
    [key: string]: Validations;
}

export class Form {
    // eslint-disable-next-line
    static emailExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    private validations: Control;

    private data: any;

    private fields?: { [key: string]: Field };

    constructor(
        data: any,
        validations: Control,
        fields?: { [key: string]: Field }
    ) {
        this.data = data;

        this.validations = validations;

        this.fields = fields;
    }

    static isEmpty = (text: any) =>
        text === null || text === undefined || text === '';

    static isValidField = async (
        key: string,
        field: any,
        data: any,
        validations: Control
    ) => {
        if (typeof field === 'string') field = field.trim();

        if (validations[key].required && Form.isEmpty(field))
            return String(validations[key].required);

        if (
            validations[key].email &&
            !Form.emailExp.test(String(field).toLowerCase())
        )
            return String(validations[key].email);

        if (
            validations[key].regex &&
            !validations[key].regex?.pattern.test(field)
        )
            return String(validations[key].regex?.message);

        if (
            validations[key].custom &&
            !(await validations[key].custom!.function(field, data))
        )
            return String(validations[key].custom?.message);

        return false;
    };

    static isValid = async (
        data: any,
        validations: Control,
        fields?: { [key: string]: Field }
    ) => {
        const compares = [];

        for (const key in validations) {
            if (fields && fields[key].editable === false) continue;

            if (validations[key].isArray) {
                for (const iterator of data) {
                    const message = await Form.isValidField(
                        key,
                        iterator.text,
                        data,
                        validations
                    );

                    if (message) return { error: true, message, field: key };
                }
            } else {
                const message = await Form.isValidField(
                    key,
                    dotAccess.get(data, key),
                    data,
                    validations
                );

                if (message) return { error: true, message, field: key };
            }

            if (validations[key].compareWith)
                compares.push({ key, field: validations[key] });
        }

        for (const compare of compares)
            if (
                dotAccess.get(data, compare.key) !==
                dotAccess.get(data, String(compare.field.compareWith?.field))
            )
                return {
                    error: true,
                    message: String(compare.field.compareWith?.message),
                };

        return { error: false };
    };

    isValid = async () => {
        return Form.isValid(this.data, this.validations, this.fields);
    };

    isValidField = async (key: string, field: any) => {
        return Form.isValidField(key, field, this.data, this.validations);
    };

    resetData(data: any) {
        this.data = data;
    }
}
