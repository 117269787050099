import { Box, makeStyles, Popover, Typography } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import Loading from '../Loading';

interface Props {
  open: boolean;
  onClose(): void;
  id: string;
  anchorEl: HTMLElement | null;
}

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: '26px',
    borderRadius: '8px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#0F172A',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',

    color: '#1F2937',

    marginTop: '8px',
    marginLeft: '4px',
  },
  mainTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-1%',
  },
  iconChat: {
    paddingTop: '8px',
    color: '#1F2937',
    fontSize: '25px',
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    overflow: 'auto',
    maxHeight: '300px',
  },
  itemWrapper: {
    '&:hover': {
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
}));

interface Items {
  id: number;
  product_id: number;
  name: string;
  midia_path: string;
  question: string;
}

const QuestionsNotificationsPopover: React.FC<Props> = ({
  open,
  onClose,
  id,
  anchorEl,
}) => {
  const classes = useStyles();

  const { data: items, isValidating } = useSWR<Items[]>(
    '/productQuestions/getNotifications'
  );

  const history = useHistory();

  const onGotoQuestion = (id: number) => {
    history.push(`/questions?questionId=${id}`);
  };

  const isLoading = !items || isValidating;

  return (
    <Popover
      id={id}
      open={open}
      onClose={onClose}
      className={classes.popover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        paddingX="12px"
        paddingY="15px"
        width="304px"
      >
        <Box pt="15px" pb="12px" borderBottom="1px solid #E5E7EB">
          <Typography className={classes.mainTitle}>Notificações</Typography>
        </Box>
        <Box className={classes.itemsWrapper}>
          {isLoading ? (
            <Loading />
          ) : (
            items.map((item) => (
              <Box
                paddingY="20px"
                key={item.id}
                display="flex"
                flexDirection="column"
                borderBottom="1px solid #E5E7EB"
                className={classes.itemWrapper}
                onClick={() => onGotoQuestion(item.id)}
              >
                <Box display="flex" alignItems="center">
                  <Box marginRight="8px">
                    <img
                      src={item.midia_path}
                      width={56}
                      height={56}
                      alt={item.name}
                    />
                  </Box>
                  <Typography className={classes.title}>{item.name}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <ChatBubbleOutlineIcon className={classes.iconChat} />

                  <Typography className={classes.subtitle}>
                    {item.question}
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export default QuestionsNotificationsPopover;
