import { Page } from 'core/interfaces/page';
import List from './List';
import Details from './Details';

const Category: Page = {
    label: 'Categorias',
    route: 'categorys',
    List,
    Details,
};

export default Category;
