import { useEffect, useState } from 'react';
import { useField } from '@unform/core';

import TextFieldBase, {
    ColorPickerProps as ColorPickerBaseProps,
} from '../components/inputs/ColorPicker';

export interface ColorPickerProps
    extends Omit<ColorPickerBaseProps, 'name' | 'value' | 'type'> {
    name: string;
}

export default function ColorPicker(props: ColorPickerProps) {
    const { name, defaultValue = '', ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value);

        restProps.onChange?.(event);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value ?? '';
                },
                setValue(_: unknown, value: string) {
                    setValue(value ?? '');
                },
            });
        }
    }, [fieldName, registerField, value]);

    return (
        <TextFieldBase
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
            type="color"
        />
    );
}
