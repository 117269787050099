import {
  Box,
  Button,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useSet, useAsyncFn, useAsyncRetry } from 'react-use';
import { api } from 'core/lib/api';
import { getUserData } from 'core/lib/getUserData';
import Loading from 'core/components/Loading';
import moment from 'moment';

const styles = makeStyles((theme) => ({
  buttonMoreLives: {
    marginLeft: '5px',
    width: '24px',
    height: '24px',
    minWidth: 'auto',
    color: '#fff',
    borderRadius: '100%',
    backgroundColor: '#456AEF',
    '&:hover': {
      backgroundColor: '#456AEF',
    },
    '& span': {
      marginLeft: '-1px',
      marginTop: '-1px',
    },
  },
  paperModal: {
    position: 'absolute',
    width: '70%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: '500px',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 440,
    marginTop: '40px',
  },
  tableHeaderItem: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonAddLives: {
    backgroundColor: '#456AEF',
    '&:hover': {
      backgroundColor: '#456AEF',
    },
    padding: '8px 32px',
    color: '#fff',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
  },
  titleOfModal: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
  },
  tableCellItem: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Open Sans',
    lineHeight: '24px',
    color: '#0F172A',
  },
  selectTransmissionTitle: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '21px',
  },
  autocompleteInputText: {
    width: '60%',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    '& input': {
      padding: '11px 8px',
    },
  },
}));

interface ILive {
  id: number;
  title: string;
  starts_at: Date;
  status: string;
  order: number;
}

interface Props {
  onUpdateLive: () => void;
  showcase_id: number;
}

const RegisterLivesModal: React.FC<Props> = ({ onUpdateLive, showcase_id }) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const [selected, controlSelected] = useSet<number>(new Set());
  const classes = styles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const lives = useAsyncRetry(async () => {
    const userData = getUserData();
    const res = await api.get<ILive[]>('/lives/lives-to-add', {
      params: {
        showcase_id,
        marketplace_id: userData.marketplace.id,
      },
    });

    return res.data;
  }, [open]);

  const [selectLivesProm, selectLives] = useAsyncFn(
    async (lives_ids: number[]) => {
      const resp = await api.post('/lives/select-lives', {
        lives_ids,
        showcase_id,
      });

      return resp.data;
    },
    []
  );

  const onClickAddlives = async () => {
    const lives_ids = Array.from(selected).filter(
      (id) => lives.value && lives.value.find((live) => live.id === id)
    );
    await selectLives(lives_ids);
    onUpdateLive();
    handleClose();
  };

  return (
    <Box>
      <Button className={classes.buttonMoreLives} onClick={handleOpen}>
        +
      </Button>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={classes.paperModal}>
          {lives.error && (
            <Typography style={{ color: 'red' }}>
              Erro ao carregar lives: {lives.error}
            </Typography>
          )}
          {lives.value ? (
            <>
              <Box className={classes.header}>
                <Typography
                  variant="h6"
                  className={classes.selectTransmissionTitle}
                >
                  Selecionar Transmissão
                </Typography>
                <TextField
                  className={classes.autocompleteInputText}
                  variant="outlined"
                  placeholder="Buscar"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  disabled={selectLivesProm.loading}
                  className={classes.buttonAddLives}
                  onClick={() => onClickAddlives()}
                >
                  Adicionar transmissões
                </Button>
              </Box>

              <TableContainer className={classes.tableContainer}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeaderItem}>
                        <Checkbox
                          checked={lives.value.every((f) => selected.has(f.id))}
                          onChange={() => {
                            if (!lives.value) return;

                            const allSelected = lives.value.every((f) =>
                              selected.has(f.id)
                            );

                            if (allSelected) {
                              controlSelected.reset();
                            } else {
                              lives.value.forEach((f) => {
                                controlSelected.add(f.id);
                              });
                            }
                          }}
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableHeaderItem}>
                        Título
                      </TableCell>
                      <TableCell className={classes.tableHeaderItem}>
                        Data
                      </TableCell>
                      <TableCell className={classes.tableHeaderItem}>
                        Horário
                      </TableCell>
                      <TableCell className={classes.tableHeaderItem}>
                        Situação
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lives.value
                      .filter((f) => {
                        if (filter === '') return true;
                        return f.title
                          .toLowerCase()
                          .includes(filter.toLowerCase());
                      })
                      .map((row) => (
                        <TableRow key={row.title}>
                          <TableCell className={classes.tableCellItem}>
                            <Checkbox
                              checked={selected.has(row.id)}
                              onChange={() => controlSelected.toggle(row.id)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox',
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.tableCellItem}>
                            {row.title}
                          </TableCell>
                          <TableCell className={classes.tableCellItem}>
                            {moment(row.starts_at).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell className={classes.tableCellItem}>
                            {moment(row.starts_at).format('HH:mm:ss')}
                          </TableCell>
                          <TableCell className={classes.tableCellItem}>
                            {row.status}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <Loading />
            </>
          )}
        </Paper>
      </Modal>
    </Box>
  );
};

export default RegisterLivesModal;
