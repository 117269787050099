import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '90px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    /* identical to box height, or 150% */
    textAlign: 'center',

    /* Cores neutras/900 */
    color: '#0F172A',
  },
  sellerName: {
    fontFamily: 'Open Sans',
    fontStyle: 'italic',
    fontWeight: 800,
    fontsize: '24px',
    lineHeight: '36px',
    align: 'center',
    color: '#456AEF',
  },
  projectName: {
    width: '120px',
    height: 'auto',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    color: '#999999',
    marginTop: '4px',
    padding: '0 10px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    backgroundColor: '#456AEF',
    color: '#FFFFFF',
    height: 'auto',
    width: 'auto',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#456AEF',
      color: '#FFFFFF',
    },
  },
  spaceItems: {
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  isSeller: boolean;
}

const InitialBlankPage = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src="/icons/bro.svg" alt="" />

      {!props.isSeller ? (
        <>
          <div className={classes.title}>Olá, Bem-vindo (a)</div>
          <div className={classes.description}>
            <span>
              Por enquanto não temos dados suficientes para exibir qualquer
              informação por aqui.
            </span>
          </div>
        </>
      ) : (
        <>
          <div className={classes.title}>Olá, Bem-vindo (a)</div>
          <div className={classes.description}>
            <span>
              Por enquanto não temos dados suficientes para exibir qualquer
              informação por aqui.
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default InitialBlankPage;
