import { Page } from 'core/interfaces/page';
import GeneralConfigDetails from './GeneralConfigDetails';

const GeneralConfig: Page = {
  label: 'Configurações gerais',
  route: 'general-config',
  onlyDetails: true,
  passInstance: true,
  Details: GeneralConfigDetails,
};

export default GeneralConfig;
