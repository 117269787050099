import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TreeView } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
    Typography,
    Dialog,
    Button,
    makeStyles,
} from '@material-ui/core';

import { Page } from 'core/interfaces/page';
import BreadCrumbAdd from 'core/components/BreadCrumbAdd';
import { api } from 'core/lib/api';
import TreeItemCategory from './TreeItemCategory';

const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        '& .MuiPaper-root': {
            padding: 20,
            '& p': {
                color: 'rgb(68, 68, 68)',
                fontWeight: 700,
                fontSize: '1.2em',
                textAlign: 'center',
                marginTop: 0,
            },
            '& button': {
                backgroundColor: '#456AEF',
                color: 'white',
                fontWeight: 700,
                width: 100,
                '&.cancel': {
                    border: '1px solid #456AEF',
                    background: 'white',
                    color: '#456AEF',
                },
                '& + button': {
                    marginLeft: '20px',
                },
            },
            '& .buttonsFooter': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
        },
    },
    closeIcon: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: '#333',
        cursor: 'pointer',
        '&:hover': {
            color: '#888',
        },
    },
    columnLabel: {
        color: '#444444',
        fontSize: '0.925rem',
        fontWeight: 600,
        marginBottom: 15,
        '& + p': {
            marginLeft: theme.spacing(1),
        },
    },
    icon: {
        color: '#999999',
        fontSize: '1rem',
        width: 'auto',
    },
    treeItem: {
        '& .MuiTreeItem-content': {
            borderBottom: '1px solid rgb(221, 221, 221)',
            '& .MuiTreeItem-label': {
                backgroundColor: 'transparent !important',
                paddingLeft: 6,
            },
        },
    },
}));

export default function List(props: { page: Page }) {
    const { page } = props;

    const classes = useStyles();
    const history = useHistory();
    const [dialog, setDialog] = useState<
        boolean | { id: number; key: number; group: any[] }
    >(false);
    const [details, setDetails] = useState<any[]>([]);
    const [expanded, setExpanded] = useState<string[]>([]);

    const userCanCreate = page.permissions?.create === true;
    const userCanUpdate = page.permissions?.update === true;
    const userCanDelete = page.permissions?.delete === true;

    async function updateColumn(id: number, column: string, data: any) {
        await api.put(`${page.route}/custom/${id}/${column}`, {
            [column]: data,
        });
    }

    async function getData(search?: string) {
        try {
            const params = {} as any;

            if (search) {
                params.search = search;
            }

            const { data } = await api.get(`${page.route}/custom`, { params });

            setDetails([...data]);
        } catch ({ response }) {
            console.error(response);
        }
    }

    function onEdit(e: any, id: number) {
        history.push(`${page.route}/${id}`);

        e.stopPropagation();
    }

    async function onDelete(id: number, key: number, group: any[]) {
        try {
            await api.delete(`${page.route}/custom/${id}`);

            group.splice(key, 1);

            setDetails([...details]);

            setDialog(false);
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleToggle = (event: any, nodeIds: string[]) => {
        if (
            event.target.closest('.expandButton') ||
            event.target.closest('.collapseButton')
        ) {
            setExpanded(nodeIds);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <BreadCrumbAdd
                title={page.label}
                route={`${page.route}/new`}
                onTextChange={(text) => getData(text)}
                disableButtonAdd={!userCanCreate || undefined}
            />
            <div style={{ padding: 30 }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="body1" className={classes.columnLabel}>
                        Nome
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.columnLabel}
                        style={{ marginLeft: 132 }}
                    >
                        Exibir Menu ?
                    </Typography>

                    <div style={{ display: 'flex', width: 240 }}>
                        <Typography
                            variant="body1"
                            className={classes.columnLabel}
                        >
                            Sequência
                        </Typography>

                        <Typography
                            variant="body1"
                            className={classes.columnLabel}
                        >
                            Ativo?
                        </Typography>
                    </div>
                </div>
                <TreeView
                    defaultCollapseIcon={
                        <RemoveCircleIcon
                            style={{ color: '#CCCCCC' }}
                            className="collapseButton"
                        />
                    }
                    defaultExpandIcon={
                        <AddCircleIcon
                            style={{ color: '#CCCCCC' }}
                            className="expandButton"
                        />
                    }
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                >
                    {details.map((data, index) => (
                        <TreeItemCategory
                            key={index}
                            index={index}
                            data={data}
                            childs={data.childs}
                            group={details}
                            userCanCreate={userCanCreate}
                            userCanUpdate={userCanUpdate}
                            userCanDelete={userCanDelete}
                            onEdit={onEdit}
                            updateColumn={updateColumn}
                            setDialog={setDialog}
                        />
                    ))}
                </TreeView>
                <Dialog
                    className={classes.dialog}
                    onClose={() => setDialog(false)}
                    open={dialog !== false}
                >
                    <p>
                        <span>
                            Tem certeza
                            <br />
                            que deseja excluir ?
                        </span>
                    </p>
                    <div className="buttonsFooter">
                        <Button
                            children="Cancelar"
                            className="cancel"
                            onClick={() => setDialog(false)}
                        />
                        <Button
                            onClick={() =>
                                typeof dialog === 'object' &&
                                onDelete(dialog.id, dialog.key, dialog.group)
                            }
                            children="Sim"
                        />
                    </div>
                </Dialog>
            </div>
        </>
    );
}
