import { api } from 'core/lib/api';
import { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export interface AuthContextData {
    isLogged: boolean;
    user?: Record<string, any>;
    extra?: Record<string, any>;
    login(user: any, token: any, ttl: any, extra: any): void;
    logout(): Promise<void>;
    forgotPassword(email: string): Promise<boolean>;
    resetPassword(token: string, password: string): Promise<boolean>;
    changeHasWholesale(hasWholesale: boolean): void;
}

export interface AuthProps {
    children?: React.ReactNode;
}

type State = {
    isReady: boolean;
    isLogged: boolean;
    user?: Record<string, any>;
    extra?: Record<string, any>;
};

const AuthContext = createContext({} as AuthContextData);

export function AuthProvider(props: AuthProps) {
    const { children } = props;
    const [state, setState] = useState<State>({
        isReady: false,
        isLogged: false,
    });

    const history = useHistory();

    async function login(user: any, token: any, ttl: any, extra: any) {
        ttl = new Date().getTime() + ttl * 1000;

        localStorage.setItem('@Auth:user', JSON.stringify(user));

        localStorage.setItem('@Auth:token', token);

        localStorage.setItem('@Auth:ttl', ttl.toString());

        api.defaults.headers['X-Access-Token'] = token;

        const { data } = await api.get('users/has-wholesale');

        extra.hasWholesale = data.hasWholesale;

        localStorage.setItem('@Auth:extra', JSON.stringify(extra));

        setState({
            isReady: true,
            isLogged: true,
            user,
            extra,
        });
    }

    async function logout() {
        try {
            await api.get('users/logout');
        } finally {
            localStorage.clear();

            delete api.defaults.headers['X-Access-Token'];

            setState({
                isReady: true,
                isLogged: false,
                user: undefined,
                extra: undefined,
            });
        }
    }

    async function forgotPassword(email: string) {
        try {
            const { data } = await api.post('users/forgot-password', { email });

            if (data.sendEmail) {
                return true;
            }

            return false;
        } catch (error: any) {
            throw error;
        }
    }

    async function resetPassword(token: string, password: string) {
        try {
            api.defaults.headers['X-Access-Token'] = token;

            const { data } = await api.post('users/reset-password-user', {
                password,
            });

            if (data.resetPassword) {
                return true;
            }

            return false;
        } catch (error: any) {
            throw error;
        }
    }

    function changeHasWholesale(hasWholesale: boolean) {
        state.extra!.hasWholesale = hasWholesale;

        setState({ ...state });
    }

    useEffect(() => {
        const user = localStorage.getItem('@Auth:user');
        const extra = localStorage.getItem('@Auth:extra');
        const token = localStorage.getItem('@Auth:token');
        const ttl = parseInt(String(localStorage.getItem('@Auth:ttl')));

        if (user && extra && token && ttl && new Date().getTime() < ttl) {
            const _extra = JSON.parse(extra);

            api.defaults.headers['X-Access-Token'] = token;

            api.get('users/check-session')
                .then(({ data }) => {
                    setState({
                        isReady: true,
                        isLogged: true,
                        user: JSON.parse(user),
                        extra: _extra,
                    });
                })
                .catch(() => {
                    localStorage.clear();

                    delete api.defaults.headers['X-Access-Token'];

                    history.push('/');

                    setState({ isReady: true, isLogged: false });
                });
        } else {
            localStorage.clear();

            delete api.defaults.headers['X-Access-Token'];

            setState({ isReady: true, isLogged: false });
        }
    }, [api.defaults.headers]);

    if (!state.isReady) {
        return null;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                forgotPassword,
                resetPassword,
                changeHasWholesale,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;
