import {
    forwardRef,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from 'react';

import { api } from 'core/lib/api';
import Button from 'core/components/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import ContainerCustom from 'core/toolbox/ContainerCustom';
import { makeStyles } from '@material-ui/core/styles';
import AlertContext from 'core/contexts/Alert';

import DashTable from 'core/components/DashTable';
import IDashTable, { exampleTable } from 'core/interfaces/IDashTable';
import moment from 'moment';

import { CheckCircle, Warning, Error } from '@material-ui/icons';
import disableFields from 'core/lib/disableFields';

export interface ActiveRegisterBlingProps {
    instance: Record<string, any>;
    integrated?: any;
    isNew?: boolean;
    permissions?: any;
}

export interface ActiveRegisterBlingRef {
    getData(): Record<string, any>;
}

export default forwardRef<ActiveRegisterBlingRef, ActiveRegisterBlingProps>(
    function ActiveRegister(props, ref) {
        const { instance, permissions } = props;

        const classes = useStyles();
        const alert = useContext(AlertContext);

        const [dashTableProps, setDashTablesProps] =
            useState<IDashTable>(exampleTable);
        const [isLoading, setIsLoading] = useState(true);

        const StatusIcon: { [key: number]: ReactElement } = {
            0: <CheckCircle style={{ color: '#2ECC71', marginRight: '5px' }} />,
            1: <Warning style={{ color: '#F1C40F', marginRight: '5px' }} />,
            2: <Error style={{ color: 'E74C3C', marginRight: '5px' }} />,
        };

        async function getLog() {
            setIsLoading(true);

            await api.get('sellerblingsynclogs/bling-logs').then((res) => {
                setDashTablesProps({
                    columns: [
                        'Código de sincronização',
                        'Data da criação',
                        'Horário',
                        'Mensagem',
                    ],
                    rows: res.data.map((data: any) => {
                        return [
                            { value: data.id },
                            {
                                value: moment(data.created_at).format(
                                    'DD/MM/YYYY'
                                ),
                            },
                            {
                                value: moment(data.created_at).format(
                                    'HH:mm:ss'
                                ),
                            },
                            {
                                value: (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data.message,
                                        }}
                                    />
                                ),
                                icon: StatusIcon[data.status],
                                style: {
                                    display: 'flex',
                                },
                            },
                        ];
                    }),
                    pagination: true,
                    rowsPerPage: 25,
                    rowsPerPageOptions: [25, 50, 75, 100],
                    className: 'Synchronization',
                });

                setIsLoading(false);
            });
        }

        useEffect(() => {
            getLog();
        }, []);

        async function onSubmit() {
            try {
                const { data } = await api.get(
                    'sellerblingsynclogs/bling-logs'
                );

                if (data.length === 0) {
                    alert.warning('Nenhuma sincronização encontrada');
                } else {
                    alert.success('Registros atualizados com sucesso', 10000);
                    getLog();
                }
            } catch (error) {
                alert.error('Erro ao realizar atualizar registros', 10000);
            }
        }

        useEffect(() => {
            disableFields(permissions);
          }, [instance]);

        return (
            <ContainerCustom
                title="Detalhamento das sincronizações"
                subtitle="Aqui você encontrará o detalhamento das sincronizações."
                classes={{ content: classes.content, wrap: classes.wrap }}
                button={
                    <Button
                        color="#456AEF"
                        // @ts-ignore
                        component="span"
                        children="Atualizar Registros"
                        style={{ marginTop: 10, display: !permissions.update ? 'none' : '' }}
                        className={classes.button}
                        onClick={onSubmit}
                    />
                }
            >
                {!isLoading ? (
                    dashTableProps.rows.length > 0 ? (
                        <DashTable {...dashTableProps} />
                    ) : (
                        <div className={classes.empty}>
                            <h3>Nenhuma sincronização encontrada</h3>
                        </div>
                    )
                ) : (
                    <div className={classes.empty}>
                        <CircularProgress />
                    </div>
                )}
            </ContainerCustom>
        );
    }
);

const useStyles = makeStyles((theme) => ({
    content: {
        border: 'none',
    },
    wrap: {
        width: 'calc(100% + 20px)',
    },
    title: {
        marginLeft: '10px',
    },
    empty: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    button: {
        color: '#fff',
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        height: 38.1,
        padding: theme.spacing(0, 1),
        borderRadius: 4,
        width: '100%',
    },
}));
