import useSWR from 'swr';
import Card from 'core/components/Card';
import { Box, Grid, makeStyles, Typography, Button } from '@material-ui/core';
import BreadCrumbCore from 'core/components/BreadCrumb';
import Loading from 'core/components/Loading';
import { useContext, useEffect, useState } from 'react';
import AlertContext from 'core/contexts/Alert';
import { useHistory } from 'react-router-dom';
import Chart from 'react-apexcharts';
import Paper from '@material-ui/core/Paper';
import formatToBRL from 'utils/formatToBRL';
import Table from 'core/components/Table';
import moment from 'moment';
import Form from 'core/components/Form';
import Pickers from './Pickers';
import exportOrders from './exportOrders';
import { api } from 'core/lib/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headerTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
  },
  background: {
    backgroundColor: '#F9F9F9',
    height: '100%',
  },
  breadCrumb: {
    fontSize: 28,
    fontFamily: 'Open Sans',
    margin: '0px 0px 20px 0px',
  },
  title: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    display: 'inline-block',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  paper: {
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  paperHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
    '& > p': {
      marginRight: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
    },
  },
  paperFilters: {
    display: 'flex',
    flexDirection: 'row',
  },
  filterButton: {
    backgroundColor: '#456AEF',
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    padding: '6px 16px',
    height: 40,
    width: 105,
    '&:hover': {
      backgroundColor: '#456aef84',
      transition: 'background-color 500ms linear',
    },
  },
  table: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

const Dashboard = ({ instance: affiliate }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const alert = useContext(AlertContext);
  const [table, setTable] = useState<any[]>([]);

  let { data, error } = useSWR(`/affiliates/dashboard?id=${affiliate.id}`);
  const isLoading = !data && !error;

  useEffect(() => {
    setTable(data?.table || []);
  }, [data]);

  if (isLoading) return <Loading />;
  if (error) alert.error('Erro ao carregar dados');

  const handleFilterSubmit = async (form: any) => {
    const { date_start, date_end } = form;

    const response = await api.get('/affiliates/orders', {
      params: {
        id: affiliate.id,
        date_start,
        date_end,
      },
    });

    setTable(response.data);
  };

  return (
    <Box className={classes.background}>
      <Box className={classes.breadCrumb}>
        <BreadCrumbCore
          title={
            <span
              children={'Detalhes do afiliado'}
              style={{ color: '#456AEF' }}
            />
          }
          dictionary={{ action: affiliate?.name }}
          onClickTitle={() => history.push('/affiliates')}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            title="Comissão do mês anterior"
            content={formatToBRL(
              data.cards?.previousMonth_ComissionAmount || 0
            )}
            color="neutral"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            title="Comissão do mês atual"
            content={formatToBRL(data.cards?.lastMonth_ComissionAmount || 0)}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            title="Quantidade de pedidos do mês atual"
            content={data.cards?.lastMonth_OrdersCount || 0}
            color="neutral"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card
            title="Total de comissões desde o início"
            content={formatToBRL(data.cards?.allTime_ComissionAmount || 0)}
            color="success"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignContent: 'center',
              }}
            >
              <h1 className={classes.title}>Vendas (Meses)</h1>
            </div>
            <span
              style={{
                width: '100%',
                display: 'block',
                borderBottom: '1px solid #E0E0E0',
                marginBottom: '5px',
              }}
            />
            <Chart
              options={{
                chart: {
                  id: 'basic-bar',
                },
                xaxis: {
                  categories: [
                    'Jan',
                    'Fev',
                    'Mar',
                    'Abr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Out',
                    'Nov',
                    'Dez',
                  ],
                },
              }}
              series={[
                {
                  name: 'Vendas',
                  data: data.chart,
                },
              ]}
              type="area"
              width="100%"
              height={400}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className={classes.root}>
            <Box className={classes.header}>
              <Typography className={classes.headerTitle}>
                Vendas do afiliado
              </Typography>
            </Box>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  children="Filtrar:"
                />
                <Form onSubmit={handleFilterSubmit}>
                  <Pickers />
                  <Button
                    type="submit"
                    className={classes.filterButton}
                    children="Filtrar"
                  />
                </Form>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: '#456AEF',
                    cursor: 'pointer',
                    marginLeft: 20,
                  }}
                  children="Exportar pedidos em planilha"
                  onClick={() => {
                    exportOrders(affiliate.id)
                      .then(() => alert.success('Exportação iniciada'))
                      .catch(() => alert.error('Erro ao exportar pedidos'));
                  }}
                />
              </Box>
              <Table
                className={classes.table}
                columns={[
                  {
                    field: 'order_id',
                    headerName: 'ID do pedido',
                    renderCell: ({ row: { order_id } }) => (
                      <span
                        style={{ color: '#456AEF', cursor: 'pointer' }}
                        onClick={() => history.push(`/orders/${order_id}`)}
                      >
                        {order_id}
                      </span>
                    ),
                  },
                  {
                    field: 'created_at',
                    headerName: 'Data do pedido',
                    renderCell({ row: { created_at } }) {
                      return moment(created_at).format('DD/MM/YYYY');
                    },
                  },
                  {
                    field: 'customer_name',
                    headerName: 'Cliente',
                  },
                  {
                    field: '',
                    headerName: 'Valor total',
                    renderCell({ row: { order_amount } }) {
                      return formatToBRL(Number(order_amount));
                    },
                  },
                  {
                    field: 'commission',
                    headerName: 'Comissão em %',
                    renderCell({ row: { commission } }) {
                      return `${Number(commission).toFixed(2)}%`;
                    },
                  },
                  {
                    field: 'commission_amount',
                    headerName: 'Comissão sobre a venda',
                    renderCell({ row: { commission_amount } }) {
                      return formatToBRL(Number(commission_amount));
                    },
                  },
                ]}
                rows={[...table]}
                paginationMode="client"
                pagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250, 500, 1000]}
                rowsPerPage={10}
                rowCount={table.length}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
