import { useState, useImperativeHandle, forwardRef } from 'react';

import Dialog, { DialogText, DialogProps } from 'components/Dialog';

export interface DialogStatusRef {
    open(
        title: string,
        subtitle: string,
        onClose?: DialogProps['onClose']
    ): void;
}

export default forwardRef<DialogStatusRef, Pick<DialogProps, 'onClose'>>(
    function (props, ref) {
        const [[open, title, subtitle, _onClose], setOpen] = useState([
            false,
            '',
            '',
            undefined as DialogProps['onClose'],
        ]);

        function onClose(...args: any) {
            _onClose?.(args[0], args[1]);

            props.onClose?.(args[0], args[1]);

            setOpen([false, '', '', undefined]);
        }

        useImperativeHandle(ref, () => ({
            open(title, subtitle, onClose) {
                setOpen([true, title, subtitle, onClose as any]);
            },
        }));

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={title}
                subtitle={
                    <DialogText style={{ color: '#0000008a' }}>
                        {subtitle}
                    </DialogText>
                }
            />
        );
    }
);
