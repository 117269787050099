import React, { useState, useContext } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreHorizOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import exportOrders from './exportOrders';
import AlertContext from 'core/contexts/Alert';

const ActionsMenu = ({ id }: any) => {
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const alert = useContext(AlertContext);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  return (
    <Box>
      <IconButton
        onClick={handleOpenMenu}
        children={<MoreHorizOutlined />}
        title="Visualizar Produtos"
      />

      <Menu
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => history.push(`/affiliates/details/${id}`)}>
          Ver detalhes
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportOrders(id)
              .then(() => alert.success('Exportação iniciada'))
              .catch(() => alert.error('Erro ao exportar pedidos'));
          }}
        >
          Exportar pedidos
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ActionsMenu;
