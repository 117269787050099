import { ReactNode } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

export interface ContainerCustomProps {
    title: string;
    subtitle?: ReactNode;
    button?: any;
    classes?: {
        content?: string;
        title?: string;
        subtitle?: string;
        wrap?: string;
    };
    children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: `30px`,
        borderTop: '1px solid rgb(221, 221, 221)',
    },
    title: {
        fontWeight: 600,
        fontSize: 17,
        color: '#444444',
    },
    subtitle: {
        fontWeight: 400,
        fontSize: 15,
        color: '#444444',
        marginBottom: theme.spacing(4),
    },
    wrap: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
        position: 'relative',
        top: '-80px',
    },
    buttonWheaper: {
        width: '200px',
    },
}));

export default function ContainerCustom(props: ContainerCustomProps) {
    const { title, subtitle, classes: classesCustom, children, button } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.content, classesCustom?.content)}>
            <Typography
                className={clsx(classes.title, classesCustom?.title)}
                children={title}
            />
            {subtitle && (
                <Typography
                    className={clsx(classes.subtitle, classesCustom?.subtitle)}
                    children={subtitle}
                />
            )}

            {button && (
                <div className={classes.buttonContainer}>
                    <div className={classes.buttonWheaper}>{button}</div>
                </div>
            )}
            <div className={clsx(classes.wrap, classesCustom?.wrap)}>
                {children}
            </div>
        </div>
    );
}
