import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';
import { useList } from 'react-use';

import BreadCrumb, { BreadCrumbRef } from 'core/toolbox/BreadCrumb';
import Tabs, { TabsRef } from 'core/components/Tabs';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import Dialog, { DialogText } from 'components/Dialog';
import Geral from './tabs/Geral';
import Cubage from './tabs/Cubage';
import Images from './tabs/Images';
import Attributes from './tabs/Attributes';
import Variations from './tabs/Variations';
import VariationsMedia from './tabs/VariationsMedia';
import Skus from './tabs/Skus';
import useIsSeller from 'core/lib/useIsSeller';

interface FormRef {
  getData(): Record<string, any>;
}

export default function Details(props: any) {
  const {
    page: { label, route, routes, permissions },
  } = props;
  const history = useHistory();
  const formRef = useRef<FormRef>(null);
  const tabsRef = useRef<TabsRef>(null);
  const breadCrumbRef = useRef<BreadCrumbRef>(null);
  const { current: instance } = useRef(props.instance);
  const [isNew, setIsNew] = useState(props.isNew);
  const [openDialogSkuEnter, setOpenDialogSkuEnter] = useState(false);
  const id = props.id || instance.id;
  const alert = useContext(AlertContext);

  const [isSeller] = useIsSeller();

  const getLocalStorageSeller = localStorage.getItem('@Auth:extra') as string;
  const updateLocalStorage = JSON.parse(getLocalStorageSeller);

  const [pendingRequests, pendingRequestsAction] = useList<Promise<void>>([]);

  async function onSubmit(data: Record<string, any>) {
    breadCrumbRef.current?.setDisabled(true);

    try {
      await Promise.all(pendingRequests);

      const params: Record<string, any> = {};
      data.brand_id = data.brand?.id;

      if (!isSeller && updateLocalStorage.seller_id !== null) {
        params.seller = updateLocalStorage.seller_id;
        data.params = params;
      }

      if (isNew) {
        data = (await api.post(routes.create, data)).data;

        Object.assign(instance, data);

        setIsNew(false);

        history.replace(`/${route}/${data.id}`);

        alert.success('Informações salvas com sucesso', 10000);
      } else {
        data = { ...instance, ...data, ...params };

        await api.put(routes.update(id), data);

        const { data: updated } = await api.get(
          routes.findOne(id) + '?seller=' + updateLocalStorage.seller_id
        );
        let oldInstanceDump = JSON.stringify(instance);
        console.log({ instance });

        Object.assign(instance, updated);

        console.log({ instance, updated });

        if (oldInstanceDump !== JSON.stringify(instance)) {
          console.log('instance changed');
        }

        alert.success('Alterações salvas com sucesso', 10000);
      }
    } catch {
      alert.error('Erro ao salvar produto', 10000);
    } finally {
      breadCrumbRef.current?.setDisabled(false);
    }
  }

  async function onSave() {
    breadCrumbRef.current?.setDisabled(true);

    await Promise.all(pendingRequests);

    if (tabsRef.current!.controller === 6) {
      // @ts-ignore
      formRef.current!.submitForm();
    } else {
      try {
        const data = formRef.current!.getData();

        switch (tabsRef.current?.controller) {
          case 0: {
            Object.assign(instance, data);

            break;
          }

          case 1: {
            instance.cubage = {
              ...instance.cubage,
              ...data,
            };

            break;
          } 

          case 2: {
            instance.images = data;

            break;
          }

          case 3: {
            instance.attributes = data;

            break;
          }

          case 4: {
            instance.variations = data[0];

            instance.has_variation = data[1];

            instance.gridConfig = data[2];

            break;
          }
          case 5: {
            Object.assign(instance, data);
            break;
          }
        }

        if (isNew && tabsRef.current!.controller < 4) {
          tabsRef.current?.setController(tabsRef.current.controller + 1);
        } else {
          await onSubmit(instance);
        }
      } catch (error: any) {
        if (error instanceof ValidationError) {
          alert.error(error.message);
        }
      }
    }
    breadCrumbRef.current?.setDisabled(false);
  }

  return (
    <>
      <BreadCrumb
        id={id}
        ref={breadCrumbRef}
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={onSave}
        localeText={{
          buttonSaveLabel: isNew ? 'Continuar' : 'Salvar',
        }}
        disableSave={!permissions.update}
      />
      <Tabs
        ref={tabsRef}
        tabs={[
          { label: 'Geral' },
          { label: 'Peso e dimensões' },
          { label: 'Imagens e Vídeos' },
          { label: 'Atributos' },
          { label: 'Variações' },
          { label: 'Mídia das Variações' },
          ...(isNew ? [] : [{ label: 'Estoque' }]),
        ]}
        disableClick={isNew ? 'right' : false}
        onWillChange={(value, nextValue) => {
          try {
            if (!isNew) {
              if (value < 6) {
                const data = formRef.current!.getData();

                switch (value) {
                  case 0: {
                    Object.assign(instance, data);

                    break;
                  }

                  case 1: {
                    instance.cubage = {
                      ...instance.cubage,
                      ...data,
                    };

                    break;
                  }

                  case 2: {
                    instance.images = data;

                    break;
                  }

                  case 3: {
                    instance.attributes = data;

                    break;
                  }

                  case 4: {
                    instance.variations = data[0];

                    instance.has_variation = data[1];

                    instance.gridConfig = data[2];

                    break;
                  }

                  case 5: {
                    Object.assign(instance, data);
                  }
                }
              } else {
                (formRef.current as any).onWillExit(nextValue);
                return false;
              }

              if (value === 4 && nextValue === 5 && instance.has_variation) {
                setOpenDialogSkuEnter(true);

                return false;
              }
            }
          } catch (error: any) {
            if (error instanceof ValidationError) {
              alert.error(error.message);
            }
            console.log({ error });

            return false;
          }
        }}
        onDidChange={(value) => {
          if (isNew) {
            if (value < 4) {
              breadCrumbRef.current?.setTitle('Continuar');
            } else {
              breadCrumbRef.current?.setTitle('Salvar');
            }
          }
        }}
      >
        {(controller) => {
          switch (controller) {
            case 0: {
              return (
                <Geral
                  ref={formRef as any}
                  isNew={isNew}
                  instance={instance}
                  permissions={permissions}
                />
              );
            }

            case 1: {
              return (
                <Cubage
                  ref={formRef as any}
                  instance={instance}
                  permissions={permissions}
                />
              );
            }

            case 2: {
              return (
                <Images
                  ref={formRef as any}
                  instance={instance}
                  permissions={permissions}
                />
              );
            }

            case 3: {
              return (
                <Attributes
                  ref={formRef as any}
                  instance={instance}
                  permissions={permissions}
                />
              );
            }

            case 4: {
              return (
                <Variations
                  ref={formRef as any}
                  instance={instance}
                  addTask={(promise) => pendingRequestsAction.push(promise)}
                  onSubmit={(variations, hasVariation, gridConfig) => {
                    instance.variations = variations;

                    instance.has_variation = hasVariation;

                    instance.gridConfig = gridConfig;

                    onSubmit(instance);
                  }}
                  permissions={permissions}
                />
              );
            }
            case 5: {
              return (
                <VariationsMedia
                  ref={formRef as any}
                  instance={instance}
                  permissions={permissions}
                />
              );
            }

            case 6: {
              return (
                <Skus
                  ref={formRef as any}
                  instance={instance}
                  onUpdate={(variations) => {
                    instance.hasSku = true;

                    instance.variations = variations;
                  }}
                  onWillExit={(tab) => {
                    tabsRef.current?.setController(tab);
                  }}
                  permissions={permissions}
                />
              );
            }
          }
        }}
      </Tabs>
      <Dialog
        open={openDialogSkuEnter}
        onClose={async (_, reason) => {
          if (reason === 'confirmClick') {
            await onSubmit(instance);

            tabsRef.current?.setController(5);
          }

          setOpenDialogSkuEnter(false);
        }}
        title="Deseja entrar na aba de estoque?"
        subtitle={
          <DialogText style={{ color: '#000000' }}>
            Para entrar na aba de estoque, você deve salvar
            <br />o produto, deseja salvar o produto?
          </DialogText>
        }
        confirmTitle="Salvar"
      />
    </>
  );
}
