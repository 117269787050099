import { useEffect, useState, useContext, useRef } from 'react';
import { Button, Dialog, IconButton, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DeleteOutlineOutlined, EditOutlined } from '@material-ui/icons';

import Switch from 'core/components/inputs/Switch';
import Loading from 'core/components/Loading';
import BreadCrumbAdd from 'core/components/BreadCrumbAdd';
import Table, { ColDef } from 'core/components/Table';
import Container from 'core/components/Container';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import CompanyManager, { CompanyManagerRef } from './CompanyManager';
import Switchable from 'components/Switchable';

interface Data {
    shippingTypes?: any[];
    marketplaceShippingTypes?: any[];
}

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(3.5, 4),
    },
    dialog: {
        position: 'relative',
        '& .MuiPaper-root': {
            padding: 20,
            '& p': {
                color: 'rgb(68, 68, 68)',
                fontWeight: 700,
                fontSize: '1.2em',
                textAlign: 'center',
                marginTop: 0,
            },
            '& button': {
                backgroundColor: '#456AEF',
                color: 'white',
                fontWeight: 700,
                width: 100,
                '&.cancel': {
                    border: '1px solid #456AEF',
                    background: 'white',
                    color: '#456AEF',
                },
                '& + button': {
                    marginLeft: '20px',
                },
            },
            '& .buttonsFooter': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
        },
    },
}));

export default function List(props: any) {
    const {
        page: { permissions, route },
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState<Data>({});
    const [dialog, setDialog] = useState<boolean | number>(false);
    const alert = useContext(AlertContext);

    const companyManagerRef = useRef<CompanyManagerRef>(null);

    const shippingTypesColumns: ColDef[] = [
        {
            field: 'name',
            headerName: 'Nome',
            width: '25%',
            renderCell({ row, column: { field } }) {
                return (
                    <span
                        onClick={() => {
                            if (permissions.update) {
                                history.push(`/shippingtypes/${row.id}`);
                            } else {
                                alert.error(
                                    'Você não tem permissão para editar'
                                );
                            }
                        }}
                        style={{ color: '#456AEF', cursor: 'pointer' }}
                        children={row[field]}
                    />
                );
            },
        },
        {
            field: 'order',
            headerName: 'Sequência',
            renderCell: ({ row, column: { field } }) =>
                row[field] || 'Sem sequência',
        },
        {
            field: 'is_active',
            headerName: 'Ativo?',
            width: 100,
            renderCell: ({ row: { id, is_active } }) => (
                <Switchable
                    id={id}
                    value={is_active}
                    column="is_active"
                    route="shippingtypes"
                    permissions={permissions?.update !== true}
                />
            ),
        },
        {
            field: 'edit',
            width: 115,
            renderHeader: () => <></>,
            renderCell: ({ row }) => (
                <>
                    {permissions.update && (
                        <IconButton
                            onClick={() =>
                                history.push(`/shippingtypes/${row.id}`)
                            }
                            children={<EditOutlined />}
                            title="Editar"
                        />
                    )}
                    {permissions.delete && (
                        <IconButton
                            onClick={() => setDialog(row.id)}
                            children={<DeleteOutlineOutlined />}
                            title="Excluir"
                        />
                    )}
                </>
            ),
        },
    ];

    const marketplaceShippingTypesColumns: ColDef[] = [
        {
            field: 'name',
            headerName: 'Nome',
            width: '100%',
        },
        {
            field: 'is_active',
            headerName: 'Utilizar?',
            renderCell: ({ row, index }) => (
                <Switch
                    checked={Boolean(row.seller_shipping_type)}
                    onChange={async ({ target: { checked } }) => {
                        if (checked) {
                            if (
                                row.shipping_service?.service_code === 3 ||
                                row.shipping_service?.service_code === 4
                            ) {
                                companyManagerRef.current?.open({ index });
                            } else {
                                row.seller_shipping_type = await api.post(
                                    `shippingtypes/${row.id}/seller-shipping-type`
                                );

                                setData({ ...data });
                            }
                        } else {
                            await api.delete(
                                `shippingtypes/${row.id}/seller-shipping-type`
                            );

                            delete row.seller_shipping_type;

                            setData({ ...data });
                        }
                    }}
                />
            ),
        },
    ];

    async function onDelete(id: number) {
        try {
            await api.delete(`${route}/${id}/shipping-type`);

            getData();

            setDialog(false);
        } catch (error) {
            console.log(error);
        }
    }

    async function getData(search?: string) {
        try {
            const params = {} as any;

            if (search) {
                params.search = search;
            }

            const { data } = await api.get(
                'shippingtypes/seller-shipping-type',
                { params }
            );

            data.shippingTypes = data.shippingTypes || [];

            setData({ ...data });
        } catch (err) {
            alert.error('Não foi possível carregar as opções de frete');

            history.push('/');
        }
    }

    useEffect(() => {
        getData();
    }, []);

    if (!data.shippingTypes) {
        return <Loading />;
    }

    return (
        <>
            <BreadCrumbAdd
                title="Opções de frete"
                route="/shippingtypes/new"
                onTextChange={getData}
                disableButtonAdd={!permissions.create}
            />
            <div className={classes.content}>
                <div style={{ width: '100%', marginBottom: 28 }}>
                    <Table
                        columns={shippingTypesColumns}
                        rows={data.shippingTypes}
                    />
                </div>
                {data.marketplaceShippingTypes &&
                    data.marketplaceShippingTypes.length && (
                        <Container
                            title="Opcões do marketplace"
                            subtitle="Estas opções foram configuradas pelo marketplace mas você pode optar por não utilizá-las."
                            classes={{
                                content: {
                                    paddingBottom: 30,
                                },
                            }}
                            children={
                                <div style={{ width: '100%' }}>
                                    <Table
                                        columns={
                                            marketplaceShippingTypesColumns
                                        }
                                        rows={data.marketplaceShippingTypes}
                                        style={{ minHeight: 'unset' }}
                                    />
                                </div>
                            }
                        />
                    )}
            </div>
            <Dialog
                className={classes.dialog}
                onClose={() => setDialog(false)}
                open={dialog !== false}
            >
                <p>Deseja apagar a opção de frete?</p>
                <div className="buttonsFooter">
                    <Button
                        children="Cancelar"
                        className="cancel"
                        onClick={() => setDialog(false)}
                    />
                    <Button
                        children="Sim"
                        onClick={() =>
                            typeof dialog === 'number' && onDelete(dialog)
                        }
                    />
                </div>
            </Dialog>
            <CompanyManager
                ref={companyManagerRef}
                onClose={async (company, { index }) => {
                    if (company) {
                        const row = data.marketplaceShippingTypes![index];

                        row.seller_shipping_type = await api.post(
                            `shippingtypes/${row.id}/seller-shipping-type`,
                            {
                                jadlog_agency_id: company.id,
                            }
                        );

                        setData({ ...data });
                    }
                }}
            />
        </>
    );
}
