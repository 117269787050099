import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    message: {
        color: '#456AEF',
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(90),
    },
}));

export default function ApiCrash() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Typography variant="h1" className={classes.message}>
                API Crash :(
            </Typography>
        </div>
    );
}
