import { forwardRef, useState, useImperativeHandle } from 'react';
import { Button, makeStyles } from '@material-ui/core';

export interface FooterButtonsRef {
    setDisabled(disabled: boolean): void;
}

export interface FooterButtonsProps {
    action: string;
    disabled?: boolean;
    onCancel(): void;
    onSubmit(): void;
}

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid #DDDDDD',
        paddingTop: theme.spacing(4),
        marginTop: theme.spacing(1.5),
    },
    button: {
        backgroundColor: '#456AEF',
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        height: 40,
        padding: '6px 16px',
        width: 100,
        '&:hover': {
            transition: 'background-color 500ms linear',
            backgroundColor: 'rgba(69, 106, 239, 0.5)',
        },
        '&.cancel': {
            border: '1px solid #456AEF',
            background: 'white',
            color: '#456AEF',
        },
        '& + button': {
            marginLeft: '20px',
        },
    },
}));

export default forwardRef<FooterButtonsRef, FooterButtonsProps>(function (
    props,
    ref
) {
    const { action, onCancel, onSubmit } = props;
    const classes = useStyles();
    const [disabled, setDisabled] = useState(props.disabled);

    useImperativeHandle(ref, () => ({
        setDisabled(disabled: boolean) {
            setDisabled(disabled);
        },
    }));

    return (
        <div className={classes.content}>
            <Button
                children="Cancelar"
                className={`${classes.button} cancel`}
                disabled={disabled}
                onClick={onCancel}
            />
            <Button
                children={action}
                className={classes.button}
                disabled={disabled}
                onClick={onSubmit}
            />
        </div>
    );
});
