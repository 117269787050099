import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from 'core/components/inputs/TextField';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';

const useStyles = makeStyles((theme) => ({
    input: {
        width: 45,
        marginRight: '20px',
        '& input': {
            padding: theme.spacing(1),
            fontSize: theme.typography.pxToRem(15),
            textAlign: 'center',
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
            },
            '&[type=number]': {
                MozAppearance: 'textfield',
            },
        },
    },
}));

interface Props {
    id: number;
    defaultValue: number;
    permissions?: boolean;
}

const DisplayOrderCategory: React.FC<Props> = ({
    id,
    defaultValue,
    permissions,
}) => {
    const classes = useStyles();

    const alert = useContext(AlertContext);

    const [value, setValue] = useState<number>();

    const setCategoryDisplayOrder = async (value: number) => {
        try {
            await api.put(`categorys/custom/${id}/order`, {
                order: value,
            });

            alert.success('Sequência alterada com sucesso');
        } catch (error: any) {
            alert.error('Não foi possível mudar a sequência');
        }
    };

    useEffect(() => {
        const delaDebounce = setTimeout(() => {
            if (value !== undefined) setCategoryDisplayOrder(value);
        }, 2000);
        return () => clearTimeout(delaDebounce);
    }, [value, defaultValue]);


    return (
        <TextField
            type="number"
            autoFocus
            defaultValue={defaultValue}
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
            className={classes.input}
            disabled={permissions}
        />
    );
};

export default DisplayOrderCategory;
