import { Page } from 'core/interfaces/page';
import List from './List';
import Details from './Details';

const ShippingType: Page = {
    label: 'Opções de frete',
    route: 'shippingtypes',
    List,
    Details,
};

export default ShippingType;
