import { CSSProperties } from 'react';

interface IBody {
    value?: string;
    icon?: string;
    color?: 'primary' | 'success' | 'warning' | 'danger';
    fontWeight?: 'bold' | 'normal';
    clickable?: boolean;
    route?: string;
    className?: string;
    style?: CSSProperties;
    copyable?: boolean;
}

interface IDashTable {
    title?: string;
    description?: string;
    columns: string[];
    rows: IBody[][];
    pagination?: boolean;
    rowsPerPage?: number;
    rowsPerPageOptions?: number[];
    className?: any;
    handleUpdate?: () => void;
    isLoading?: boolean
}

const exampleTable: IDashTable = {
    columns: [''],
    rows: [[{ value: '' }]],
};

export { exampleTable };
export default IDashTable;
