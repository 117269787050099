import { Page } from 'core/interfaces/page';
import Details from './Details';

const Bling: Page = {
    label: 'Bling',
    route: 'bling',
    routes: {
        create: 'sellerblingconfigs',
        findOne: 'sellerblingconfigs/get-integration',
    },
    onlyDetails: true,
    Details,
};

export default Bling;
