import { Page } from 'core/interfaces/page';
import Details from './Details';

const GroupUser: Page = {
    label: 'Grupos de usuários',
    route: 'userprofiles',
    routes: {
        create: 'userprofiles/user-profiles-from',
        update: (id) => `userprofiles/user-profiles-from/${id}`,
        find: 'userprofiles/user-profiles-from',
    },
    Details,
    listProps: {
        fields: {
            name: {
                headerName: 'Nome',
            },
        },
        dialogOnDelete: 'Tem certeza que deseja excluir?',
    },
};

export default GroupUser;
