import { ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';

type fieldSize =
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';

type Slot =
    | fieldSize
    | {
          xs?: fieldSize;
          sm?: fieldSize;
          md?: fieldSize;
          lg?: fieldSize;
          xl?: fieldSize;
      };

export interface InputResponsiveProps {
    slot?: Slot;
    noMargin?: boolean;
    children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        marginBottom: 10,
        '&.w': ((theme: Theme) =>
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce((acc, cur) => {
                let size: string | number = (cur * 100) / 12;

                size = `calc((${size} * 1%) - 20px) !important`;

                // @ts-ignore
                acc[`&-${cur}`] = {
                    '&-xs': {
                        [theme.breakpoints.down('xs')]: {
                            width: size,
                        },
                        [theme.breakpoints.up('xs')]: {
                            width: size,
                        },
                    },
                    '&-sm': {
                        [theme.breakpoints.up('sm')]: {
                            width: size,
                        },
                    },
                    '&-md': {
                        [theme.breakpoints.up('md')]: {
                            width: size,
                        },
                    },
                    '&-lg': {
                        [theme.breakpoints.up('lg')]: {
                            width: size,
                        },
                    },
                    '&-xl': {
                        [theme.breakpoints.up('xl')]: {
                            width: size,
                        },
                    },
                };

                return acc;
            }, {}))(theme),
    },
    noMargin: {
        margin: '0px !important',
    },
}));

export default function InputResponsive(props: InputResponsiveProps) {
    const classes = useStyles();
    const { noMargin, children } = props;

    let { slot } = props;
    let breakpoint = '';

    if (typeof slot === 'string') {
        slot = {
            xs: slot,
        };
    }

    for (const key in slot)
        if (slot.hasOwnProperty(key))
            // @ts-ignore
            breakpoint += `w-${slot[key]}-${key} `;

    return (
        <div
            className={clsx(classes.content, breakpoint, {
                [classes.noMargin]: noMargin,
            })}
        >
            {children}
        </div>
    );
}
