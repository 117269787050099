import { Page } from 'core/interfaces/page';
import Details from './Details';

const ConfigSeller: Page = {
    label: 'Minha loja',
    route: 'config-seller',
    onlyDetails: true,
    routes: {
        findOne: 'sellers',
        update: 'sellers/put-seller',
    },
    Details,
};

export default ConfigSeller;
