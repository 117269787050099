import { useRef, useImperativeHandle, forwardRef } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Button from '../components/Button';
import ButtonAction, { ButtonActionRef } from '../components/ButtonAction';
import BreadCrumbCore from '../components/BreadCrumb';

export interface BreadCrumbProps {
    id?: any;
    route: string;
    label: string;
    isNew: boolean;
    creator?: {
        name: string;
        when: string | Date;
    };
    onSave?(): Promise<void> | void;
    onCancel?(): Promise<void> | void;
    disableSave?: boolean;
    disableCancel?: boolean;
    disableCreator?: boolean;
    localeText?: {
        buttonSaveLabel?: string;
        buttonCancelLabel?: string;
    };
}

const useStyles = makeStyles({
    informations: {
        display: 'contents',
    },
    informationsContent: {
        display: 'flex',
        marginLeft: 'auto',
    },
    created: {
        marginRight: 20,
        '& p': {
            fontSize: '0.875rem',
            color: '#444444',
        },
    },
    productRef: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'middle',
        padding: '4px 7px 4px 7px',
        borderRadius: '10px',
        marginLeft: '15px',
        marginTop: '4px',
        fontSize: 'small',
        color: '#898989',
        background: '#efefef',
    },
});

export type BreadCrumbRef = ButtonActionRef;

export default forwardRef<BreadCrumbRef, BreadCrumbProps>(function BreadCrumb(
    props,
    ref
) {
    const {
        id,
        route,
        label,
        isNew,
        creator,
        disableSave = false,
        disableCancel = false,
        disableCreator = false,
        localeText,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const buttonActionRef = useRef<ButtonActionRef>(null);

    async function onSave() {
        buttonActionRef.current?.setDisabled(true);
        buttonActionRef.current?.setLoading(true);

        await props.onSave?.();

        setTimeout(() => {
            buttonActionRef.current?.setDisabled(false);
            buttonActionRef.current?.setLoading(false);
        }, 500);
    }

    async function onCancel() {
        buttonActionRef.current?.setDisabled(true);

        history.push(`/${route}`);

        await props.onCancel?.();

        buttonActionRef.current?.setDisabled(false);
    }

    useImperativeHandle(ref, () => {
        if (buttonActionRef.current) {
            return buttonActionRef.current;
        }

        return {} as any;
    });

    return (
        <BreadCrumbCore
            title={
                <span
                    onClick={() => history.push(`/${route}`)}
                    children={label}
                    style={{ color: '#456AEF' }}
                />
            }
            dictionary={{ action: isNew ? 'Incluir' : 'Editar' }}
            slot={{
                end: (
                    <div className={classes.informations}>
                        {!isNew &&
                            label === 'Produtos' &&
                            creator &&
                            creator.name &&
                            creator.when && (
                                <div className={classes.productRef}>
                                    <span>Ref.: {id}</span>
                                </div>
                            )}
                        <div className={classes.informationsContent}>
                            {!disableCreator &&
                                !isNew &&
                                creator &&
                                creator.name &&
                                creator.when && (
                                    <div className={classes.created}>
                                        <Typography
                                            style={{ textAlign: 'end' }}
                                        >
                                            Criado em:{' '}
                                            <span>
                                                {moment(creator.when).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </span>
                                        </Typography>
                                        <Typography
                                            style={{ textAlign: 'end' }}
                                        >
                                            Por: <span>{creator.name}</span>
                                        </Typography>
                                    </div>
                                )}
                            {!disableCancel && (
                                <Button
                                    children={
                                        localeText?.buttonCancelLabel ??
                                        'Cancelar'
                                    }
                                    color="#999999"
                                    outline
                                    onClick={onCancel}
                                    style={{ marginRight: 20 }}
                                />
                            )}
                            {!disableSave && (
                                <ButtonAction
                                    ref={buttonActionRef as any}
                                    title={
                                        localeText?.buttonSaveLabel ?? 'Salvar'
                                    }
                                    onClick={async () => {
                                        if (!buttonActionRef.current?.disabled)
                                            await onSave();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ),
            }}
        />
    );
});
