import { useImperativeHandle, forwardRef, useRef, useEffect,useState  } from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';

import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import { Permissions } from 'core/interfaces/page';
import disableFields from 'core/lib/disableFields';

import { getUserData } from 'core/lib/getUserData';

export interface CubageProps {
    instance: Record<string, any>;
    permissions: Permissions
}

export interface CubageRef {
    getData(): Record<string, any>;
}

export default forwardRef<CubageRef, CubageProps>(function Cubage(props, ref) {
    const { instance, permissions } = props;
    const classes = useStyles();
    const formRef = useRef<FormHandles>(null);

    const marketplaceId = getUserData().marketplace.id;
    const [isDisabled, setIsDisabled] = useState(false);
    

    useImperativeHandle(
        ref,
        () => ({
            getData() {
                try {
                    const data = formRef.current!.getData();

                    VALIDATIONS.validateSync(data);

                    return data;
                } catch (error: any) {
                    throw error;
                }
            },
        }),
        []
    );
    useEffect(() => {
        disableFields(permissions);

        
        const currentData = formRef.current?.getData();
        //marketplace 4 é livea e o 12 é o da linx. código temporário para não exibir a cubagem
        if (marketplaceId === 4 || marketplaceId === 12) {
            setIsDisabled(true);
            if (
                !currentData?.weight_kg &&
                !currentData?.length_cm &&
                !currentData?.width_cm &&
                !currentData?.height_cm
            ) {
                
                formRef.current?.setData({
                    weight_kg: 1,
                    length_cm: 1,
                    width_cm: 1,
                    height_cm: 1,
                });

            }
        }
       
       
    }, [marketplaceId, permissions]);
    // código comentado para mvp linx
    // useEffect(() => {
    //     disableFields(permissions);
    //   }, [instance.cubage]);

    return (
        <div style={{ display: isDisabled ? 'none' : 'block' }}>
        <Form
            ref={formRef}
            style={{ display: 'contents' }}
            initialData={instance.cubage}
            abortEarly
        >
            <ContainerCustom
                title="Peso e dimensões"
                subtitle="Medidas da embalagem do produto. Será usado para cálculo de frete."
                classes={{ wrap: classes.normalize, content: classes.noBorder }}
            >
                <Field
                    label="Peso (Kg) do produto com sua embalagem"
                    name="weight_kg"
                    description="Se forem 3kg escreva 3,000. Caso seja 300g escreva 0,300"
                    field={{
                        type: 'number',
                        numberFormat: {
                            decimalSeparator: ',',
                            decimalScale: 3,
                            thousandSeparator: '.',
                            isNumericString: true,
                            precision: 10,
                            fixedDecimalScale: true,
                            allowNegative: false,
                        },
                        disabled: isDisabled
                    }}
                    required
                    slot="6"
                />
                <Field
                    label="Comprimento (cm)"
                    name="length_cm"
                    field={{
                        type: 'number',
                        numberFormat: {
                            decimalSeparator: ',',
                            decimalScale: 0,
                            thousandSeparator: '.',
                            isNumericString: true,
                            precision: 7,
                            fixedDecimalScale: true,
                            allowNegative: false,
                        },
                        disabled: isDisabled
                    }}
                    required
                    slot="6"

                />
                <Field
                    label="Largura (cm)"
                    name="width_cm"
                    field={{
                        type: 'number',
                        numberFormat: {
                            decimalSeparator: ',',
                            decimalScale: 0,
                            thousandSeparator: '.',
                            isNumericString: true,
                            precision: 7,
                            fixedDecimalScale: true,
                            allowNegative: false,
                        },
                        disabled: isDisabled
                    }}
                    required
                    slot="6"
                />
                <Field
                    label="Altura (cm)"
                    name="height_cm"
                    field={{
                        type: 'number',
                        numberFormat: {
                            decimalSeparator: ',',
                            decimalScale: 0,
                            thousandSeparator: '.',
                            isNumericString: true,
                            precision: 7,
                            fixedDecimalScale: true,
                            allowNegative: false,
                        },
                        disabled: isDisabled
                    }}
                    required
                    slot="6"
                />
            </ContainerCustom>
        </Form>
        </div>
    );
});

const useStyles = makeStyles({
    normalize: {
        width: 'calc(100% + 20px)',
    },
    noBorder: {
        border: 'none',
    },
});

const VALIDATIONS = Yup.object().shape({
    weight_kg: Yup.number()
        .required('O campo peso está vazio')
        .moreThan(0, 'O peso deve ser maior que zero')
        .nullable(),
    length_cm: Yup.number()
        .required('O campo comprimento está vazio')
        .moreThan(0, 'O comprimento deve ser maior que zero')
        .nullable(),
    width_cm: Yup.number()
        .required('O campo largura está vazio')
        .moreThan(0, 'A largura deve ser maior que zero')
        .nullable(),
    height_cm: Yup.number()
        .required('O campo altura está vazio')
        .moreThan(0, 'A altura deve ser maior que zero')
        .nullable(),
});
