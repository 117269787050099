import RadioBase, { RadioProps } from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    icon: {
        borderRadius: '50%',
        width: theme.typography.pxToRem(20),
        height: theme.typography.pxToRem(20),
        backgroundColor: '#ffffff',
        border: '1px solid #D9D9D9',
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
    },
    checkedIcon: {
        backgroundColor: '#456AEF',
        '&:before': {
            display: 'block',
            width: theme.typography.pxToRem(20),
            height: theme.typography.pxToRem(20),
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#456AEF',
        },
    },
}));

export default function (props: RadioProps) {
    const classes = useStyles();

    return (
        <RadioBase
            {...props}
            disableRipple
            icon={<span className={classes.icon} />}
            checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
        />
    );
}
