import { useEffect, useRef, useState, useContext } from 'react';
import { conformToMask } from 'react-text-mask';
import { IconButton } from '@material-ui/core';
import {
    AddCircleOutlineOutlined,
    DeleteOutlineOutlined,
} from '@material-ui/icons';
import { useField } from '@unform/core';
import * as Yup from 'yup';

import Table, { ColDef } from 'core/components/Table';
import Form, { FormHandles } from 'core/components/Form';
import AlertContext from 'core/contexts/Alert';
import TextField from 'core/unform/TextField';
import NumberField from 'core/unform/NumberField';
import { currency } from 'utils/currency';
import cpfValidation from 'validations/cpf';

export default function CpfTable() {
    const {
        fieldName,
        defaultValue: defaultFieldValue = [],
        registerField,
    } = useField('credits_approved');
    const formRef = useRef<FormHandles>(null);
    const alert = useContext(AlertContext);

    const [costumerCredits, setCostumerCredits] = useState<any[]>([
        { name: '', document: '', credit_limit: '' },
        ...defaultFieldValue,
    ]);

    const columns: ColDef[] = [
        {
            field: 'name',
            headerName: 'Nome',
            renderCell({ index, column, row }) {
                if (index === 0) {
                    return (
                        <TextField
                            name="name"
                            InputProps={{
                                inputProps: {
                                    maxLength: 200,
                                },
                            }}
                            style={{ width: '100%' }}
                        />
                    );
                }

                return row[column.field];
            },
        },
        {
            field: 'document',
            headerName: 'CPF/CNPJ',
            renderCell({ index, column, row }) {
                if (index === 0) {
                    return (
                        <TextField
                            name="document"
                            maskFormat={{
                                mask: [
                                    /[0-9]/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '.',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                ],
                            }}
                            style={{ width: '100%' }}
                        />
                    );
                }

                return conformToMask(row[column.field], MASK_CPF)
                    .conformedValue;
            },
        },
        {
            field: 'credit_limit',
            headerName: 'Limite de crédito',
            renderCell({ index, column, row }) {
                if (index === 0) {
                    return (
                        <NumberField
                            name="credit_limit"
                            numberFormat={{
                                allowNegative: false,
                                decimalSeparator: ',',
                                decimalScale: 2,
                                fixedDecimalScale: true,
                                thousandSeparator: '.',
                                isNumericString: true,
                                prefix: 'R$ ',
                            }}
                            style={{ width: '100%' }}
                        />
                    );
                }

                return currency(row[column.field]);
            },
        },
        {
            field: 'edit',
            width: 50,
            renderHeader: () => <span />,
            renderCell({ index, row }) {
                if (index === 0) {
                    return (
                        <IconButton
                            onClick={() => formRef.current!.submitForm()}
                            children={<AddCircleOutlineOutlined />}
                            title="Adicionar"
                        />
                    );
                }

                return (
                    <IconButton
                        onClick={() => deleteItem(index)}
                        children={<DeleteOutlineOutlined />}
                        title="Remover"
                    />
                );
            },
        },
    ];

    function addItem(data: Record<string, any>) {
        setCostumerCredits([...costumerCredits, data]);
    }

    function deleteItem(index: number) {
        costumerCredits.splice(index, 1);

        setCostumerCredits([...costumerCredits]);
    }

    function onError(error: Yup.ValidationError) {
        alert.error(error.message);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return costumerCredits.slice(1);
                },
                setValue(_, value: any[]) {
                    value.unshift({ name: '', document: '', credit_limit: '' });

                    setCostumerCredits(value);
                },
            });
        }
    }, [fieldName, registerField, costumerCredits]);

    return (
        <Form
            abortEarly
            ref={formRef}
            onSubmit={addItem}
            onError={onError}
            style={{ width: '100%' }}
            validations={validations(costumerCredits)}
        >
            <Table
                columns={columns}
                rows={costumerCredits}
                paginationMode="client"
                pagination={costumerCredits.length > 10 || undefined}
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250, 500, 1000]}
                rowsPerPage={10}
                rowCount={costumerCredits.length}
            />
        </Form>
    );
}

const MASK_CPF = [
    /[0-9]/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];

const validations = (
    data: any[]
): Record<string, Yup.AnySchema<any, any, any>> => {
    return {
        name: Yup.string().required('O campo nome está vazio').nullable(),
        document: Yup.string()
            .required('O campo cpf/cnpj está vazio')
            .nullable()
            // @ts-ignore
            .test('checkCPF', 'O cpf não é válido', cpfValidation)
            .test(
                'uniqueCPF',
                'O cpf ${originalValue} já está cadastrado',
                (value = '') => {
                    for (let i = 1; i < data.length; i++) {
                        if (
                            data[i].document.replace(/[^\d]+/g, '') ===
                            value?.replace(/[^\d]+/g, '')
                        ) {
                            return false;
                        }
                    }

                    return true;
                }
            ),
        credit_limit: Yup.string()
            .required('O campo limite de crédito está vazio')
            .nullable(),
    };
};
