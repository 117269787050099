import { api } from 'core/lib/api';
import useIsSeller from 'core/lib/useIsSeller';
import { useEffect, useState, createElement } from 'react';

import Loading from '../components/Loading';
import { Page } from '../interfaces/page';

export interface DetailsProps {
    isNew: boolean;
    id?: number;
    page: Page;
}

export default function ExtraDetails(props: DetailsProps) {
    const { id, page } = props;
    const [instance, setInstance] = useState<Record<string, any>>();

    const [ isSeller ] = useIsSeller();

    const getLocalStorageSeller = localStorage.getItem('@Auth:extra') as string;
    const updateLocalStorage = JSON.parse(getLocalStorageSeller);

    useEffect(() => {
        (async () => {
            const { routes: { findOne = `${page.route}/custom/${id}` } = {} } =
                page;

            let data = {};

            if ((id || page.onlyDetails) && !page.passInstance) {
                const route =
                    typeof findOne === 'string' ? findOne : findOne(id);
                const params: Record<string, any> = {};

                if (
                    !isSeller &&
                    updateLocalStorage.seller_id !== null
                ) {
                    params.seller = updateLocalStorage.seller_id;
                }

                data = (await api.get(route, { params })).data;
            }

            setInstance(data);
        })();
    }, []);

    if (!instance) {
        return <Loading />;
    }

    return createElement(page.extraDetails!, { ...props, instance });
}
