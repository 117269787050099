import Switchable from 'components/Switchable';
import { Page } from 'core/interfaces/page';
import Details from './Details';
import extraDetails from './Dashboard';
import ActionsMenu from './ActionsMenu';

const Affiliates: Page = {
  label: 'Afiliados',
  route: 'affiliates',
  routes: {
    find: 'affiliates',
    create: 'affiliates/custom',
    update: (id) => `affiliates/custom/${id}`,
    delete: (id) => `affiliates/${id}`,
  },
  Details,
  extraDetails,
  listProps: {
    fields: {
      name: {
        headerName: 'Nome do afiliado',
      },
      orders_amount: {
        headerName: 'Quantidade de pedidos',
      },
      commission: {
        headerName: 'Comissão a receber (%)',
      },
      is_active: {
        headerName: 'Ativo?',
        width: 100,
        renderCell: ({ row: { id, is_active } }) => (
          <Switchable
            id={id}
            value={is_active}
            column="is_active"
            route="affiliates"
            permissions={!Affiliates.permissions?.update}
          />
        ),
      },
      actions: {
        headerName: 'Opções',
        width: 100,
        renderCell: ({ row: { id } }) => <ActionsMenu id={id} />,
      },
    },
    limitControlled: [10, 20, 50, 100],
    dialogOnDelete: 'Tem certeza que deseja excluir?',
  },
};

export default Affiliates;
