import { Box, Typography } from '@material-ui/core';

interface IProps {
  title: string;
  subTitle: string;
}

const SidebarHeading: React.FC<IProps> = ({ title, subTitle }) => {
  return (
    <Box>
      <Typography style={{ fontSize: '14px' }}>{title}</Typography>
      <Typography style={{ color: '#94A3B8', fontSize: '14px' }}>
        {subTitle}
      </Typography>
    </Box>
  );
};

export default SidebarHeading;
