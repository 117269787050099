import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import Categories from 'components/Categories';
import Banners from './Banners';
import { makeStyles, Typography } from '@material-ui/core';
import disableFields from 'core/lib/disableFields';

const VALIDATIONS = {
  name: Yup.string().required('O campo nome está vazio'),

  expire_at: Yup.date().nullable(),
};

const useStyles = makeStyles((theme) => ({
  divExpireAt: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  divExpireAtLabel: {
    cursor: 'pointer',
    color: '#456AEF',
    fontWeight: 700,
    fontSize: '1em',
    marginBottom: '10px',
  },
}));

export default function Details(props: any) {
  const {
    isNew,
    id,
    page: { label, route, routes, permissions },
    instance,
  } = props;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);

  const classes = useStyles();

  async function onSubmit(data: Record<string, any>) {
    const {
      create: routeCreate = `${route}/custom`,
      update: routeUpdate = `${route}/custom/${id}`,
    } = routes || {};

    if (isNew) {
      data = (await api.post(routeCreate, data)).data;

      alert.success('Informações salvas com sucesso', 10000);
    } else {
      await api.put(routeUpdate, { ...data, id });

      alert.success('Alterações salvas com sucesso', 10000);
    }

    history.push(`/${route}`);
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions?.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            }}
            required
            slot="6"
          />
          <Categories
            label="Categorias"
            relation="categories"
            instance={instance}
          />
          <Field
            label="Ativo"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
          <Field
            label="Será exibido na home"
            name="show_home"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
          <div className={classes.divExpireAt}>
            <Field
              label="Retirar banner em"
              name="expire_at"
              field={{
                type: 'datetime',
                format: 'DD/MM/YYYY HH:mm',
              }}
              positionLabel="right"
            />
            <Typography
              className={classes.divExpireAtLabel}
              onClick={() => formRef.current?.setFieldValue('expire_at', null)}
              aria-labelledby="expire_at"
            >
              Limpar campo
            </Typography>
          </div>
        </Container>
        <Banners />
      </Form>
    </>
  );
}
