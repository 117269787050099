import { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

interface CSS {
    [key: string]: CSS | CSSProperties;
}

interface Props {
    childrenBefore?: React.ReactNode;
    title?: string;
    subtitle?: string | JSX.Element;
    paddingVertical?: number;
    children: React.ReactNode;
    classes?: {
        content?:
            | ((theme: Theme) => CSSProperties | CSS)
            | (CSSProperties | CSS);
        title?: ((theme: Theme) => CSSProperties | CSS) | (CSSProperties | CSS);
        subtitle?:
            | ((theme: Theme) => CSSProperties | CSS)
            | (CSSProperties | CSS);
        wrapper?:
            | ((theme: Theme) => CSSProperties | CSS)
            | (CSSProperties | CSS);
    };
    noWrap?: true;
}

const useStyles = makeStyles((theme) => ({
    content: (props: any) => ({
        display: 'flex',
        flexDirection: 'column',
        margin: `0 -${props.paddingVertical}px 0`,
        width: 'calc(100% + 60px)',
        padding: `40px ${props.paddingVertical}px`,
        borderTop: '1px solid rgb(221, 221, 221)',
        ...(typeof props.content === 'function'
            ? props.content(theme)
            : props.content),
    }),
    title: (props: any) => ({
        fontWeight: 600,
        fontSize: 17,
        color: '#444444',
        ...(typeof props.title === 'function'
            ? props.title(theme)
            : props.title),
    }),
    subtitle: (props: any) => ({
        fontWeight: 400,
        fontSize: 15,
        color: '#444444',
        marginBottom: theme.spacing(4),
        ...(typeof props.subtitle === 'function'
            ? props.subtitle(theme)
            : props.subtitle),
    }),
    wrapper: (props: any) => ({
        ...(typeof props.wrapper === 'function'
            ? props.wrapper(theme)
            : props.wrapper),
    }),
}));

export default function Container(props: Props) {
    const classes = useStyles({
        ...(props.classes || {}),
        paddingVertical: props.paddingVertical || 30,
    });
    const { childrenBefore, title, subtitle, children, noWrap } = props;

    return (
        <div className={classes.content}>
            {childrenBefore}
            {title && <Typography className={classes.title} children={title} />}
            {subtitle && (
                <Typography className={classes.subtitle} children={subtitle} />
            )}
            <div
                className={classes.wrapper}
                style={
                    noWrap
                        ? { display: 'contents' }
                        : { width: '100%', display: 'flex', flexWrap: 'wrap' }
                }
            >
                {children}
            </div>
        </div>
    );
}
