import { useState, forwardRef, useImperativeHandle } from 'react';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Drawer,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import * as Icons from '@material-ui/icons';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export interface Menu {
  route?: string;
  title: string;
  icon: string;
  collapsed?: boolean;
  submenu?: Omit<Menu, 'submenu' | 'collapsed'>[];
}

export interface SidebarProps {
  menus: Menu[];
  logo: string;
  onClickItemMenu: (route: string) => void;
}

export interface SidebarRef {
  open: boolean;
  toggle(): void;
}

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    background: '#f9f9f9',
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    width: '100%',
  },
  drawerHeaderImage: {
    height: 'auto',
    objectFit: 'contain',
    objectPosition: 'center',
    width: '80px',
    marginTop: theme.spacing(2),
  },
  drawerList: {
    padding: theme.spacing(0, 0, 2),
    marginTop: theme.spacing(2),
    scrollbarColor: '#E8E8E8',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E8E8E8',
    },
    overflow: 'auto',
  },
  drawerListItemText: {
    '& span': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      marginBottom: 1,
    },
  },
  drawerListItemIcon: {
    minWidth: 30,
    '& span': {
      fontSize: theme.typography.pxToRem(20),
      margin: 2,
    },
  },
  drawerListItemChildIcon: {
    marginLeft: 25,
  },
  drawerListItem: {
    height: 40,
    padding: theme.spacing(0.5, 3, 0.5, 4),
    color: '#999999',
  },
  drawerListItemChild: {
    backgroundColor: '#E8E8E8',
    color: '#606060',
  },
  drawerListItemSelected: {
    color: '#333333 !important',
  },
}));

export default forwardRef<SidebarRef, SidebarProps>(function (props, ref) {
  const { menus, logo, onClickItemMenu } = props;
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const currentMenu = pathname.split('/').slice(1)[0];
  const [open, setOpen] = useState(true);

  function ItemIcon(props: { icon: string; color?: string }) {
    try {
      const { icon, color = '#999999' } = props;
      // @ts-ignore
      const IconCustom = Icons[icon];

      return <IconCustom style={{ color }} />;
    } catch (error: any) {
      return null;
    }
  }

  function ListItemFake(props: {
    route?: string;
    children: any;
    onClick?: () => any;
    collapsed?: boolean;
  }) {
    const { route, children, onClick, collapsed } = props;

    function toPage() {
      history.push(`/${route}`);
    }

    if (route) {
      return (
        <ListItem button onClick={toPage} className={classes.drawerListItem}>
          {children}
        </ListItem>
      );
    }

    return (
      <ListItem
        button
        onClick={onClick}
        className={clsx(classes.drawerListItem, {
          [classes.drawerListItemChild]: collapsed,
        })}
      >
        {children}
      </ListItem>
    );
  }

  function Menu(
    props: Menu & {
      index: number;
      hasChilds?: true;
      onClick?: () => any;
      isChild?: true;
    }
  ): any {
    const {
      index,
      route,
      title,
      icon,
      collapsed,
      submenu,
      hasChilds,
      onClick,
      isChild,
    } = props;

    const [menuCollapse, setMenuCollapse] = useState(collapsed);

    function handleClick() {
      menus[index].collapsed = !menuCollapse;

      setMenuCollapse(!menuCollapse);
    }

    if (submenu) {
      return (
        <>
          <Menu
            index={index}
            route={route}
            title={title}
            icon={icon}
            hasChilds
            onClick={() => handleClick()}
          />
          <Collapse in={menuCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {submenu.map((menu, index: number) => (
                <Menu
                  key={index}
                  index={index}
                  route={menu.route}
                  onClick={() => onClickItemMenu(menu.route || '')}
                  title={menu.title}
                  icon={menu.icon}
                  isChild
                />
              ))}
            </List>
          </Collapse>
        </>
      );
    }

    return (
      <ListItemFake
        route={route}
        onClick={onClick}
        collapsed={hasChilds && menus[index].collapsed}
      >
        <ListItemIcon
          className={clsx(classes.drawerListItemIcon, {
            [classes.drawerListItemChildIcon]: isChild,
          })}
        >
          <ItemIcon
            icon={icon}
            color={currentMenu === route ? '#333333' : '#999999'}
          />
        </ListItemIcon>
        <ListItemText
          className={clsx(classes.drawerListItemText, {
            [classes.drawerListItemSelected]: currentMenu === route,
          })}
          primary={title}
        />
        {hasChilds &&
          (menus[index].collapsed ? <ExpandLess /> : <ExpandMore />)}
      </ListItemFake>
    );
  }

  useImperativeHandle(ref, () => ({
    open,
    toggle() {
      setOpen(!open);
    },
  }));

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.drawerHeader}>
        <img className={classes.drawerHeaderImage} src={logo} alt="Logo" />
      </div>
      <List className={classes.drawerList}>
        {menus.map((menu, index) => {
          let collapsed;

          if (menu.submenu && menu.submenu.length) {
            collapsed =
              menu.submenu.some((menu) => currentMenu === menu.route) ||
              menu.collapsed;
          }

          return (
            <Menu
              key={index}
              index={index}
              route={menu.route}
              title={menu.title}
              icon={menu.icon}
              collapsed={collapsed}
              submenu={menu.submenu}
            />
          );
        })}
      </List>
    </Drawer>
  );
});
