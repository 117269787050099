import SwitchCore, {
    SwitchProps as SwitchPropsCore,
} from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';

export type SwitchProps = SwitchPropsCore;

const useStyles = makeStyles({
    root: {
        width: 55,
        height: 25,
        padding: 0,
        '& .Mui-checked': {
            color: '#52C16B !important',
            transform: 'translateX(30px)',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    switchBase: {
        width: 25,
        height: 25,
        padding: 3,
        color: '#CCCCCC',
        background: 'transparent',
        borderRadius: 0,
        '& .MuiIconButton-label': {
            height: '100%',
            width: '100%',
            '& .MuiSwitch-thumb': {
                height: '100%',
                width: '100%',
                borderRadius: 5,
                boxShadow: 'none',
                position: 'relative',
                '&::before': {
                    content: '"NÃO"',
                    color: '#919191',
                    fontSize: '9px',
                    position: 'absolute',
                    display: 'flex',
                    right: -25,
                    alignItems: 'center',
                    bottom: 0,
                    top: 0,
                    margin: 'auto 0',
                    paddingTop: 2,
                },
                '&::after': {
                    content: '"SIM"',
                    color: '#287039',
                    fontSize: '9px',
                    position: 'absolute',
                    display: 'flex',
                    left: -25,
                    alignItems: 'center',
                    bottom: 0,
                    top: 0,
                    margin: 'auto 0',
                    paddingTop: 2,
                },
            },
        },
    },
    track: {
        backgroundColor: '#EEEEEE !important',
        opacity: '1 !important',
        borderRadius: 5,
    },
});

export default function Switch(props: SwitchProps) {
    const classes = useStyles();

    return (
        <SwitchCore
            {...props}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                track: classes.track,
            }}
        />
    );
}
