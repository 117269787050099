import { Page } from 'core/interfaces/page';
import InicioDetails from './InicioDetails';

const GeneralConfig: Page = {
  label: 'Início',
  route: 'inicio',
  onlyDetails: true,
  passInstance: true,
  Details: InicioDetails as any,
};

export default GeneralConfig;
