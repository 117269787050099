import { ReactNode, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

import SidebarBase, { SidebarRef, Menu } from './Sidebar';
import MainBase, { MainRef } from './Main';
import HeaderBase, { HeaderRef } from './Header';
import { isMobile } from 'react-device-detect';

export interface LayoutProps {
  logo: string;
  menus: Menu[];
  children?: ReactNode;
  components?: {
    Header?: typeof HeaderBase;
    Main?: typeof MainBase;
    Sidebar?: typeof SidebarBase;
  };
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
  },
});

export default function Layout(props: LayoutProps) {
  const { logo, menus, children, components } = props;
  const classes = useStyles();
  const headerRef = useRef<HeaderRef>(null);
  const sidebarRef = useRef<SidebarRef>(null);
  const mainRef = useRef<MainRef>(null);
  const Header = components?.Header || HeaderBase;
  const Sidebar = components?.Sidebar || SidebarBase;
  const Main = components?.Main || MainBase;

  function onClickMenu() {
    headerRef.current?.toggle();

    sidebarRef.current?.toggle();

    mainRef.current?.toggle();
  }

  useEffect(() => {
    // If the device is mobile, close the sidebar when the page is loaded
    if (isMobile && sidebarRef.current?.open) {
      headerRef.current?.toggle();

      sidebarRef.current?.toggle();

      mainRef.current?.toggle();
    }
  }, []);

  const onClickItemMenu = (route: string) => {
    // If the device is mobile, when the user clicks on a menu item, close the sidebar
    if (isMobile && sidebarRef.current?.open) {
      headerRef.current?.toggle();

      sidebarRef.current?.toggle();

      mainRef.current?.toggle();
    }
  };

  return (
    <div className={classes.root}>
      <Header ref={headerRef} onClickMenu={onClickMenu} />
      <Sidebar
        ref={sidebarRef}
        logo={logo}
        menus={menus}
        onClickItemMenu={onClickItemMenu}
      />
      <Main ref={mainRef}>{children}</Main>
    </div>
  );
}
