import { useEffect, useState } from 'react';
import { useField } from '@unform/core';

import NumberFieldBase, {
    NumberFieldProps as NumberFieldBaseProps,
} from '../components/inputs/NumberField';

export interface NumberFieldProps
    extends Omit<NumberFieldBaseProps, 'name' | 'onChange' | 'value'> {
    name: string;
    onChange?(event: { target: { name: string; value: string } }): void;
}

export default function NumberField(props: NumberFieldProps) {
    const { name, defaultValue = null, ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);

    function onChange(event: { target: { name: string; value: string } }) {
        setValue(event.target.value);

        restProps.onChange?.(event);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value === '' || value === undefined ? null : value;
                },
                setValue(_: unknown, value: string) {
                    setValue(value ?? '');
                },
            });
        }
    }, [fieldName, registerField, value]);

    return (
        <NumberFieldBase
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
        />
    );
}
