import { useState, useContext, useRef } from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Button,
  MenuItem,
  Box,
} from '@material-ui/core';

import moment from 'moment';
import { conformToMask } from 'react-text-mask';
import { useHistory } from 'react-router-dom';

import Select from 'core/components/inputs/Select';
import BreadCrumb from 'core/components/BreadCrumb';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import { currency } from 'utils/currency';
import formatToBRL from 'utils/formatToBRL';
import DialogStatus, { DialogStatusRef } from './DialogStatus';
import useIsSeller from 'core/lib/useIsSeller';
import Loading from 'core/components/Loading';

const MASK_CEP = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
const MASK_CPF = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '.',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
];
const MASK_CNPJ = [
  /[0-9]/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '25px 30px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonBack: {
    border: '1px solid #999999',
    color: '#999999',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    marginLeft: 'auto',
    width: 90,
    textTransform: 'unset',
  },
  divGeralData: {
    width: '50%',
    '& .labelTitle': {
      color: '#444444',
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(17),
      marginBottom: theme.spacing(4),
    },
    '& .labelItem': {
      display: 'flex',
      fontSize: theme.typography.pxToRem(14),
      color: '#333333',
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1),
      },
      '& strong': {
        width: 200,
      },
    },
  },
  divShippingData: {
    width: '50%',
    '& .labelTitle': {
      color: '#444444',
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(17),
      marginBottom: theme.spacing(4),
    },
    '& .labelItem': {
      display: 'flex',
      fontSize: theme.typography.pxToRem(14),
      color: '#333333',
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(1),
      },
      '& strong': {
        width: 150,
      },
    },
  },
  divTrackerCode: {
    width: '100%',
    marginTop: 35,
    '& .labelTitle': {
      color: '#444444',
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(17),
      marginBottom: theme.spacing(4),
    },
    '& .divCode': {
      background: '#F9F9F9',
      width: '100%',
      padding: 20,
      borderRadius: 4,
      position: 'relative',
      '& .loading': {
        position: 'absolute',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        color: '#456AEF',
      },
      '& .labelCode': {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(17),
      },
      '& .buttonGenerate': {
        background: '#ffffff',
        border: '1px solid #456AEF',
        textTransform: 'none',
        color: '#456AEF',
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 1,
        padding: theme.spacing(1, 1.5),
      },
      '& .labelExplanation': {
        color: '#333333',
        fontSize: theme.typography.pxToRem(13),
        marginTop: theme.spacing(1.5),
      },
    },
  },
  divProducts: {
    width: '100%',
    marginTop: 35,
    '& .labelTitle': {
      color: '#444444',
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(17),
      marginBottom: theme.spacing(4),
    },
    '& .tableProducts': {
      '& thead': {
        '& tr': {
          '& th': {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(15),
            color: '#444444',
            border: 'none',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            '&:first-of-type': {
              paddingLeft: 0,
            },
            '&:last-of-type': {
              paddingRight: 0,
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            fontSize: theme.typography.pxToRem(14),
            color: '#444444',
            height: 50,
            '&:first-of-type': {
              paddingLeft: 0,
              '& div': {
                display: 'flex',
                alignItems: 'center',
                '& img': {
                  width: 30,
                  height: 30,
                  objectFit: 'cover',
                  marginRight: theme.spacing(1),
                },
              },
            },
            '&:last-of-type': {
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
      },
    },
    '& .labelAmount': {
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(16),
      color: '#444444',
      marginTop: theme.spacing(1.5),
      marginLeft: 'auto',
      textAlign: 'center',
      width: 150,
      paddingLeft: theme.spacing(2),
    },

    '& .containerItemsValue': {
      padding: '10px 56px',
      marginLeft: 'auto',
      display: 'flex',
      flexDirection: 'column',
      width: 400,
    },

    '& .containerItemValue': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      fontFamily: 'Inter',
      marginBottom: 1,
      '& .itemValue': {
        color: '#0F172A',
        fontSize: 14,
        fontFamily: 'Inter',
        '&:not(:last-of-type)': {
          float: 'right',
        },
      },
      '& .couponItem': {
        fontFamily: 'Inter',
        color: '#00A245',
        fontSize: 12,
        fontWeight: 400,
      },
      '& .itemLabelBold': {
        fontWeight: 600,
      },
      '& .itemValueBold': {
        fontWeight: 700,
      },
      '& .itemValueDiscount': {
        color: '#00A245',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 400,
      },
    },
  },
  divSellers: {
    width: '100%',
    marginTop: 35,
    '& .divSeller': {
      border: '1px solid #DFDFDF',
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(2.5),
      },
      '& .divSellerHeader': {
        padding: '25px 30px',
        '& .labelTitle': {
          color: '#444444',
          fontWeight: 'bold',
          fontSize: theme.typography.pxToRem(17),
          marginBottom: theme.spacing(1),
        },
        '& .labelItem': {
          display: 'flex',
          fontSize: theme.typography.pxToRem(14),
          color: '#333333',
          '&:not(:last-of-type)': {
            marginRight: theme.spacing(2.5),
          },
        },
      },
      '& .divSellerBody': {
        padding: theme.spacing(3, 4),
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        '& .tableSellers': {
          '& thead': {
            '& tr': {
              '& th': {
                fontWeight: 600,
                fontSize: theme.typography.pxToRem(15),
                color: '#444444',
                border: 'none',
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                '&:first-of-type': {
                  paddingLeft: 0,
                },
                '&:last-of-type': {
                  paddingRight: 0,
                },
              },
            },
          },
          '& tbody': {
            '& tr': {
              '&:not(:last-of-type) td': {
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
              },
              '& td': {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                fontSize: theme.typography.pxToRem(14),
                color: '#444444',
                height: 50,
                border: 0,
                '&:first-of-type': {
                  paddingLeft: 0,
                  '& div': {
                    display: 'flex',
                    alignItems: 'center',
                    '& img': {
                      width: 60,
                      height: 60,
                      objectFit: 'cover',
                      marginRight: theme.spacing(1),
                    },
                  },
                },
                '&:last-of-type': {
                  paddingRight: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              },
            },
          },
        },
      },
      '& .labelAmount': {
        textAlign: 'center',
        color: '#4AA629',
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(15),
        margin: theme.spacing(2, 4),
        marginLeft: 'auto',
        width: 150,
        paddingLeft: theme.spacing(2),
      },

      '& .containerItemsValue': {
        padding: '10px 56px',
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: 400,
      },

      '& .containerItemValue': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontFamily: 'Inter',
        marginBottom: 1,
        '& .itemValue': {
          color: '#0F172A',
          fontSize: 14,
          fontFamily: 'Inter',
          '&:not(:last-of-type)': {
            float: 'right',
          },
        },
        '& .couponItem': {
          fontFamily: 'Inter',
          color: '#00A245',
          fontSize: 12,
          fontWeight: 400,
        },
        '& .itemLabelBold': {
          fontWeight: 600,
        },
        '& .itemValueBold': {
          fontWeight: 700,
        },
        '& .itemValueDiscount': {
          color: '#00A245',
          fontSize: 14,
          fontFamily: 'Inter',
          fontWeight: 400,
        },
      },
    },
  },
}));

export default function Details(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState<Record<string, any>>(props.instance);
  const [loadingCode, setLoadingCode] = useState(false);
  const alert = useContext(AlertContext);
  const { shipping_status: shippingStatus, paymentStatus, products } = data;
  const [{ address, ...details }, setDetails] = useState(data.details);
  const [sellers, setSellers] = useState(data.sellers);
  const dialogStatusRef = useRef<DialogStatusRef>(null);

  const [isSeller, isLoading] = useIsSeller();

  const onCloseDialogPaymentStatus = async (value: any) => {
    await api.put(`orders/${details.id}/payment-status`, {
      payment_status_id: value,
    });

    details.payment_status_id = value;

    setDetails({ address, ...details });
  }

  const onCloseDialogShippingStatus = async (value: any) => {
    await api.put(`orders/${details.id}/shipping-status`, {
      shipping_status_id: value,
    });

    details.shipping_status_id = value;

    setDetails({ address, ...details });
  }

  const onCloseDialogShippingStatusSeller = async (value: any, index: number) => {
    await api.put(`orders/${details.id}/shipping-status`, {
      shipping_status_id: value,
      seller_id: sellers[index].id,
    });

    sellers[index].shipping_status_id = value;

    setSellers([...sellers]);
  }

  const generateTrackerCode = async () => {
    try {
      setLoadingCode(true);

      const { data: response } = await api.get(
        `orders/${details.id}/tracker-code`
      );

      if (response.error) {
        throw response;
      }

      data!.details.tag_full_code = response.code;

      data!.details.pdf_path = response.path;

      setData({ ...data });

      window.open(response.path);
    } catch (error: any) {
      if (error.message) {
        alert.error(error.message);
      } else {
        alert.error('Erro ao gerar etiqueta');
      }
    } finally {
      setLoadingCode(false);
    }
  }

  const showTrackCode = (details: any) => {
    if (details.tag_code !== null) {
      return details.tag_code;
    }

    return 'Sem código atribuído';
  }

  const phoneMask = (v: string) => {
    let r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (v.trim() !== '') {
      r = r.replace(/^(\d*)/, '($1');
    }
    return r;
  }

  if (isLoading) return <Loading />;

  return (
    <>
      <BreadCrumb
        title={
          <span onClick={() => history.push('/orders')} children="Vendas" />
        }
        dictionary={{
          action: (
            <span>
              Detalhes do pedido
              <span style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                {' '}
                ({details.id})
              </span>
            </span>
          ),
        }}
        slot={{
          end: (
            <Button
              onClick={() => history.push('/orders')}
              className={classes.buttonBack}
              children="Voltar"
            />
          ),
        }}
      />
      <div className={classes.content}>
        <DialogStatus ref={dialogStatusRef} />
        <div className={classes.divGeralData}>
          <Typography
            className="labelTitle"
            children="Dados gerais da compra"
          />
          <Typography className="labelItem">
            <strong children="Nome:" />
            {details.customer_name}
          </Typography>
          <Typography className="labelItem">
            <strong children="E-mail:" />
            {details.customer_email}
          </Typography>
          <Typography className="labelItem">
            <strong children="Telefone:" />
            {details.customer_phone ? phoneMask(details.customer_phone) : ''}
          </Typography>
          <Typography className="labelItem">
            <strong children="CPF/CNPJ:" />
            {details.customer_cpf
              ? conformToMask(details.customer_cpf, MASK_CPF).conformedValue
              : details.customer_cnpj
              ? conformToMask(details.customer_cnpj, MASK_CNPJ).conformedValue
              : ''}
          </Typography>
          <Typography className="labelItem">
            <strong children="Data/Hora:" />
            {moment(details.date).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
          {/* <Typography className="labelItem">
            <strong children="Valor total:" />
            {isSeller ? (
              currency(details.total_amount)
            ) : (
              <>
                {`${currency(details.total_amount)} (em ${
                  details.number_installments
                }x de ${formatToBRL(
                  Number(details.total_amount) /
                    Number(details.number_installments)
                )})`}
              </>
            )}
          </Typography> */}
          {/* Coloquei uma validação para implementar o MVP da LINX */}
          <Typography className="labelItem">
            <strong children="Valor total:" />
            {isSeller ? (
              currency(details.total_amount)
              ) : (
              <>
                {`${currency(details.total_amount)}`}
                {details.payment_status_id !== 5 && (
                  ` (em ${details.number_installments}x de ${formatToBRL(
                    Number(details.total_amount) / Number(details.number_installments)
                  )})`
                )}
              </>
            )}
        </Typography>
          {details.payment_status_id !==5 && (
            <Typography className="labelItem">
            <strong children="Tipo de pagamento:" />
            {details.payment_type_name}
          </Typography>
          )}
          {details.payment_status_id !==5 && (
            <Typography className="labelItem">
            <strong children="Afiliado:" />
            <span style={{color: '#456AEF'}}>
              {details.affiliate.affiliate_name}
            </span>
          </Typography>
          )}
          {details.payment_status_id !==5 && (
             <Typography className="labelItem">
             <strong children="Ganho do afiliado:" />
             {formatToBRL(details.affiliate.amount)} ({`${Number(details.affiliate.percentage).toFixed(2)}%`})
           </Typography>
          )}
             {details.payment_status_id !==5 && (
            <Typography
            className="labelItem"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <strong children="Status de pagamento:" />
            <Select
              value={details.payment_status_id || ''}
              disabled={
                !(
                  details.payment_type_id === 2 &&
                  !isSeller &&
                  details.payment_status_id !== 3 &&
                  details.payment_status_id !== 4
                )
              }
              variant="outlined"
              onChange={async ({ target: { value } }) => {
                if (!isNaN(value as any)) {
                  dialogStatusRef.current?.open(
                    'Deseja mudar o status do pagamento?',
                    'Após confirmada a mudança do status ela não poderá ser desfeita.',
                    (_, reason) =>
                      reason === 'confirmClick' &&
                      onCloseDialogPaymentStatus(value)
                  );
                }
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Sem status
              </MenuItem>
              {paymentStatus.map((paymentStatus: any, index: number) => (
                <MenuItem key={index} value={paymentStatus.id}>
                  {paymentStatus.name}
                </MenuItem>
              ))}
            </Select>
          </Typography>
          )}
          
         
          
        </div>
        {/* Implementei essa parte para retirar alguns campos relacionados ao MVP da Linx  */}
        {details.payment_status_id !==5 && (

            <div className={classes.divShippingData}>
            <Typography className="labelTitle" children="Dados para envio" />
            <Typography className="labelItem">
              {conformToMask(address.zip_code, MASK_CEP).conformedValue}
            </Typography>
            <Typography className="labelItem">
              {address.street}, {address.street_number}{' '}
              {address.complement ? `- ${address.complement}` : ''}
            </Typography>
            <Typography className="labelItem">{address.district}</Typography>
            <Typography className="labelItem">
              {address.city}, {address.state}
            </Typography>
            {isSeller && (
              <>
                <Typography className="labelItem">
                  <strong children="Frete:" />
                  {details.shipping_type_name}
                </Typography>
                <Typography className="labelItem">
                  <strong children="Preço frete:" />
                  {currency(details.amount)}
                </Typography>
                <Typography className="labelItem">
                  <strong children="Peso Total:" />
                  {currency(details.total_weight, '', '000')} kg
                </Typography>
                <Typography className="labelItem">
                  <strong children="Prazo de entrega:" />
                  {moment(details.deadline).format('DD/MM/YYYY')}
                </Typography>
                <Typography
                  className="labelItem"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <strong children="Status de entrega:" />
                  <Select
                    style={{ width: 250 }}
                    disabled={details.payment_status_id !== 3}
                    value={details.shipping_status_id || ''}
                    variant="outlined"
                    onChange={async ({ target: { value } }) => {
                      if (!isNaN(value as any)) {
                        dialogStatusRef.current?.open(
                          'Deseja mudar o status de entrega?',
                          'Após confirmada a mudança do status ela não poderá ser desfeita.',
                          (_, reason) =>
                            reason === 'confirmClick' &&
                            onCloseDialogShippingStatus(value)
                        );
                      }
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Sem status
                    </MenuItem>
                    {shippingStatus.map((shippingStatus: any, index: number) => (
                      <MenuItem key={index} value={shippingStatus.id}>
                        {shippingStatus.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Typography>
              </>
            )}
            </div>
        )}
       
        {isSeller && details.payment_status_id === 3 && (
          <div className={classes.divTrackerCode}>
            <Typography className="labelTitle" children="Código de rastreio" />
            <div className="divCode">
              {loadingCode && <CircularProgress className="loading" />}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography className="labelCode">
                  {showTrackCode(details)}
                </Typography>
                {details.pdf_path ? (
                  <Button
                    className="buttonGenerate"
                    children={
                      <a
                        target="_blank"
                        href={details.pdf_path}
                        download="Template"
                        children="Abrir etiqueta"
                        style={{
                          color: 'inherit',
                          textDecoration: 'none',
                        }}
                        rel="noreferrer"
                      />
                    }
                  />
                ) : (
                  <Button
                    className="buttonGenerate"
                    children="Gerar etiqueta"
                    onClick={generateTrackerCode}
                  />
                )}
              </div>
              <Typography className="labelExplanation">
                O código de rastreio pode ser atualizado automaticamente quando
                <br />
                o sistema automatizado nosso identificar que o pedido foi
                <br />
                despachado
              </Typography>
            </div>
          </div>
        )}
        {isSeller && (
          <div className={classes.divProducts}>
            <Typography className="labelTitle" children="Itens do pedido" />
            <Table className="tableProducts">
              <TableHead>
                <TableRow>
                  <TableCell>Produto</TableCell>
                  <TableCell width={140} align="center">
                    Ref
                  </TableCell>
                  <TableCell width={140} align="center">
                    Qtde
                  </TableCell>
                  <TableCell width={200} align="center">
                    Valor unitário
                  </TableCell>
                  <TableCell width={150} align="center">
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div>
                        <img
                          src={product.midia_thumb_path}
                          alt={product.name}
                        />
                        <div
                          style={{
                            flexDirection: 'column',
                            alignItems: 'initial',
                            marginLeft: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                            }}
                          >
                            {product.name}
                          </span>
                          <span>{product.description}</span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{product.id}</TableCell>
                    <TableCell align="center">{product.quantity}</TableCell>
                    <TableCell align="center">
                      {currency(product.unit_price)}
                    </TableCell>
                    <TableCell align="center">
                      {currency(product.total)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box className="containerItemsValue">
              <Box className="containerItemValue">
                <Typography className="itemValue">Subtotal </Typography>
                <Typography className="itemValue">
                  {currency(
                    products?.reduce(
                      (acc: any, cur: any) => acc + Number(cur.total),
                      0
                    )
                  )}
                </Typography>
              </Box>
              {details.coupon_code && (
                <Box className="containerItemValue">
                  <Typography className="itemValue">
                    Desconto
                    <Box className="containerItemValue">
                      <Typography className="couponItem">
                        (Cupom: {details.coupon_code})
                      </Typography>
                    </Box>
                  </Typography>
                  <Typography className="itemValue itemValueDiscount">
                    -{currency(details.discount_value.toString())}
                  </Typography>
                </Box>
              )}
                 {details.payment_status_id !==5 && (
                     <Box className="containerItemValue">
                     <Typography className="itemValue">Entrega e Frete</Typography>
                     <Typography className="itemValue">
                       {currency(details.amount)}
                     </Typography>
                   </Box>
                  )}
             

              <Box className="containerItemValue" mt="11px">
                <Typography className="itemValue itemLabelBold">
                  Total do pedido
                </Typography>
                <Typography className="itemValue itemValueBold">
                  {currency(details.total_amount)}
                </Typography>
              </Box>
            </Box>
          </div>
        )}
        {!isSeller && (
          <div className={classes.divSellers}>
            {sellers.map((seller: any, index: number) => (
              <div key={index} className="divSeller">
                <div className="divSellerHeader">
                  <Typography className="labelTitle">
                    {seller.seller_name} - {seller.order_shipping_id}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    
                    {details.payment_status_id !==5 &&(
                       <Typography className="labelItem">
                       <strong>Frete:&nbsp;</strong>
                       {currency(seller.order_shipping_amount)} (
                       {seller.shipping_type_name})
                     </Typography>
                    )}
                     {details.payment_status_id !==5 &&(
                        <Typography className="labelItem">
                        <strong>Prazo de entrega:&nbsp;</strong>
                        {moment(seller.order_shipping_deadline).format(
                          'DD/MM/YYYY'
                        )}
                      </Typography>
                    )}
                    <Typography
                      className="labelItem"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {details.payment_status_id !==5 &&(
                         <strong>Status de entrega:&nbsp;</strong>
                      )}
                      {details.payment_status_id !==5 &&(
                         <Select
                         style={{ width: 250 }}
                         disabled={details.payment_status_id !== 3}
                         value={seller.shipping_status_id || ''}
                         variant="outlined"
                         onChange={async ({ target: { value } }) => {
                           if (!isNaN(value as any)) {
                             dialogStatusRef.current?.open(
                               'Deseja mudar o status de entrega?',
                               '',
                               (_, reason) =>
                                 reason === 'confirmClick' &&
                                 onCloseDialogShippingStatusSeller(value, index)
                             );
                           }
                         }}
                         displayEmpty
                       >
                         <MenuItem value="" disabled>
                           Sem status
                         </MenuItem>
                         {shippingStatus.map(
                           (shippingStatus: any, index: number) => (
                             <MenuItem key={index} value={shippingStatus.id}>
                               {shippingStatus.name}
                             </MenuItem>
                           )
                         )}
                       </Select>
                      )}
                     
                     
                    </Typography>
                    {details.payment_status_id !==5 &&(
                         seller.tag_code && (
                          <Typography className="labelItem">
                            <strong>Rastreio:&nbsp;</strong>
                            {seller.tag_code}
                          </Typography>
                        )
                      )}
                   
                  </div>
                </div>
                <div className="divSellerBody">
                  <Table className="tableSellers">
                    <TableHead>
                      <TableRow>
                        <TableCell>Produto</TableCell>
                        <TableCell width={140} align="center">
                          Ref
                        </TableCell>
                        <TableCell width={140} align="center">
                          Qtde
                        </TableCell>
                        <TableCell width={200} align="center">
                          Valor unitário
                        </TableCell>
                        <TableCell width={150} align="center">
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {seller.products?.map((product: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>
                            <div>
                              <img
                                src={product.midia_thumb_path}
                                alt={product.product_name}
                              />
                              <div
                                style={{
                                  flexDirection: 'column',
                                  alignItems: 'initial',
                                  marginLeft: '5px',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                  }}
                                >
                                  {product.product_name}
                                </span>
                                <span>{product.description}</span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="center">{product.id}</TableCell>
                          <TableCell align="center">
                            {product.quantity}
                          </TableCell>
                          <TableCell align="center">
                            {currency(product.unit_price)}
                          </TableCell>
                          <TableCell align="center">
                            {currency(product.total)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <Box className="containerItemsValue">
                  <Box className="containerItemValue">
                    <Typography className="itemValue">Subtotal </Typography>
                    <Typography className="itemValue">
                      {currency(
                        seller.products?.reduce(
                          (acc: any, cur: any) => acc + Number(cur.total),
                          0
                        )
                      )}
                    </Typography>
                  </Box>
                  {seller.coupon_code && (
                    <Box className="containerItemValue">
                      <Typography className="itemValue">
                        Desconto
                        <Box className="containerItemValue">
                          <Typography className="couponItem">
                            (Cupom: {seller.coupon_code})
                          </Typography>
                        </Box>
                      </Typography>
                      <Typography className="itemValue itemValueDiscount">
                        -{currency(seller.discount_value.toString())}
                      </Typography>
                    </Box>
                  )}
                  {details.payment_status_id !==5 && (
                    <Box className="containerItemValue">
                    <Typography className="itemValue">
                      Entrega e Frete
                    </Typography>
                    <Typography className="itemValue">
                      {currency(seller.order_shipping_amount)}
                    </Typography>
                  </Box>

                  )}
                  <Box className="containerItemValue" mt="11px">
                    <Typography className="itemValue itemLabelBold">
                      Total do pedido
                    </Typography>
                    <Typography className="itemValue itemValueBold">
                      {currency(
                        (
                          Number(
                            seller.products?.reduce(
                              (acc: any, cur: any) => acc + Number(cur.total),
                              0
                            )
                          ) -
                          Number(seller.discount_value) +
                          Number(seller.order_shipping_amount)
                        ).toString()
                      )}
                    </Typography>
                  </Box>
                </Box>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
