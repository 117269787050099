import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Box } from '@material-ui/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import TextField from '../unform/TextField';
import AuthContext from '../contexts/Auth';
import AlertContext from '../contexts/Alert';
import Button from '../components/Button';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        height: 'fit-content',
    },
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    imageLogo: {
        width: '180px',
        marginBottom: 24,
    },
    buttonSend: {
        marginTop: theme.spacing(3),
        width: '100%',
    },
    labelCopyright: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(14),
    },
}));

export default function (props: { logo: string; name: string }) {
    const { logo, name } = props;
    const classes = useStyles();
    const history = useHistory();

    const alert = useContext(AlertContext);
    const { forgotPassword } = useContext(AuthContext);

    async function onSubmit(data: any) {
        try {
            const schema = Yup.object({
                email: Yup.string()
                    .email('Preencha com um email válido')
                    .required('Preencha o campo de email'),
                password: Yup.string().required('Preencha o campo de senha'),
            });

            await schema.validate(data, {
                abortEarly: true,
            });

            if (await forgotPassword(data.email)) {
                history.push('/');

                alert.success(
                    'Email com a requisição de troca de senha enviado'
                );
            } else {
                throw String('Erro ao enviar email');
            }
        } catch (error: any) {
            let message = '';

            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        message = 'O email digitado não foi encontrado';
                        break;

                    default:
                        message = 'Erro no servidor por favor tente mais tarde';
                        break;
                }
            } else {
                message = String(error);
            }

            alert.error(message);
        }
    }

    return (
        <Container component="main" maxWidth="xs" className={classes.main}>
            <div className={classes.containerWrapper}>
                <img
                    src={logo}
                    alt={name}
                    title={name}
                    className={classes.imageLogo}
                    onClick={() => history.push('/')}
                />
                <Form onSubmit={onSubmit} style={{ width: '100%' }}>
                    <TextField
                        name="email"
                        fullWidth
                        type="text"
                        autoComplete="email"
                        autoFocus
                        placeholder="Email"
                    />
                    <Button
                        type="submit"
                        className={classes.buttonSend}
                        color="#333333"
                        children="Enviar"
                    />
                </Form>
            </div>
            <Box mt={8}>
                <Typography
                    className={classes.labelCopyright}
                    variant="body2"
                    align="center"
                >
                    Todos os Direitos Reservados © {new Date().getFullYear()}
                </Typography>
            </Box>
        </Container>
    );
}
