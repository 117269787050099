import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';

import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import Dialog, { DialogProps, DialogText } from 'components/Dialog';
import useIsSeller from 'core/lib/useIsSeller';

interface DialogCloneProps {
    product: {
        id: number;
        name: string;
    };
}

export default function DialogClone(props: DialogCloneProps) {
    const { product } = props;
    const history = useHistory();

    const alert = useContext(AlertContext);
    const [open, setOpen] = useState(false);

    const [ isSeller ] = useIsSeller();

    const getLocalStorageSeller = localStorage.getItem('@Auth:extra') as string;
    const updateLocalStorage = JSON.parse(getLocalStorageSeller);

    const params: Record<string, any> = {};

    if (!isSeller && updateLocalStorage.seller_id !== null) {
        params.seller = updateLocalStorage.seller_id;
    }

    async function onClose(
        _: unknown,
        reason: Parameters<Required<DialogProps>['onClose']>[1]
    ) {
        if (reason === 'confirmClick') {
            try {
                const {
                    data: { id },
                } = await api.post(`products/${product.id}/clone`, { params });

                setOpen(false);

                history.push(`/products/${id}`);
            } catch (error: any) {
                alert.error(
                    `Não foi possivel clonar o produto ${product.name}`
                );
            }
        }

        setOpen(false);
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <FileCopyOutlined />
            </IconButton>
            <Dialog
                open={open}
                onClose={onClose}
                title={`Deseja clonar o produto ${product.name}?`}
                subtitle={
                    <>
                        <DialogText
                            children="Itens a serem clonados:"
                            style={{ color: '#000000' }}
                        />
                        <DialogText
                            children="• Informações gerais"
                            style={{ color: '#0000008a' }}
                        />
                        <DialogText
                            children="• Cubagem"
                            style={{ color: '#0000008a' }}
                        />
                        <DialogText
                            children="• Imagens/Vídeos"
                            style={{ color: '#0000008a' }}
                        />
                    </>
                }
            />
        </>
    );
}
