import { Ref } from 'react';
import TextField from '@material-ui/core/TextField';
import AutocompleteBase, {
    AutocompleteProps as AutocompleteBaseProps,
    AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        '& .MuiChip-root': {
            margin: '0 2px 0 0',
        },
    },
    input: {
        fontSize: theme.typography.pxToRem(15),
    },
    inputRoot: {
        backgroundColor: 'white',
    },
}));

export interface AutocompleteProps
    extends Omit<AutocompleteBaseProps<any, any, any, any>, 'renderInput'> {
    inputRef?: Ref<any>;
    renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

export default function Autocomplete(props: AutocompleteProps) {
    const { inputRef, className, classes: _classes = {}, ...rest } = props;
    const classes = useStyles();

    return (
        <AutocompleteBase
            size="small"
            classes={{
                input: classes.input,
                inputRoot: classes.inputRoot,
                ..._classes,
            }}
            className={`${classes.autocomplete} ${className}`}
            // @ts-ignore
            renderInput={(params) => (
                <TextField
                    inputRef={inputRef}
                    {...params}
                    onChange={(e) => {
                        props.onChange?.(
                            e as any,
                            e.target.value,
                            'select-option'
                        );
                    }}
                    placeholder={rest.placeholder}
                    variant="outlined"
                />
            )}
            {...rest}
        />
    );
}
