/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect, useContext, useRef } from 'react';
import { useField } from '@unform/core';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { Delete, Edit, AddCircle } from '@material-ui/icons';
import * as Yup from 'yup';

import AuthContext from 'core/contexts/Auth';
import AlertContext from 'core/contexts/Alert';
import ModalBuilder, { ModalBuilderRef } from 'core/toolbox/ModalBuilder';
import Field from 'core/toolbox/Field';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import getImageSize from 'core/utils/imageSize';
import FileInput from './InputFile';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  noBanners: {
    fontSize: 15,
    color: '#444444',
    width: '100%',
    textAlign: 'left',
  },
  addButton: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#456AEF',
    textTransform: 'capitalize',
    marginTop: 20,
  },
  image: {
    height: 140,
    borderRadius: 4,
    objectFit: 'cover',
  },
  iconButton: {
    position: 'absolute',
    right: 15,
    top: 15,
    color: 'white',
    backgroundColor: '#456AEF',
    '&:hover': {
      backgroundColor: 'rgba(69, 106, 239, 0.5)',
    },
  },
  adornamentHttp: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    background: '#F9F9F9',
    borderRight: '1px solid #CCCCCC',
    padding: theme.spacing(0, 2),
    fontSize: theme.typography.pxToRem(14),
    color: '#444444',
    marginRight: theme.spacing(1.75),
  },
  displayNone: {
    display: 'none',
  },
  imageWrapper: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    '& .list': {
      display: 'flex',
      overflowX: 'auto',
      width: '100%',
      scrollbarWidth: 'thin',
      scrollbarColor: '#456AEF transparent',
      '&::-webkit-scrollbar': {
        height: 5,
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#456AEF',
        borderRadius: 4,
      },
    },
  },
  drag: {
    width: 325,
    height: 50,
    background: '#F1F1F1',
    borderRadius: 4,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#334EB0',
    fontSize: 12,
    '& img': {
      marginRight: 15,
    },
  },
  imageContent: {
    height: 140,
    position: 'relative',
    borderRadius: 4,
    '&:not(:last-of-type)': {
      marginRight: 15,
    },
    '&.add': {
      width: 150,
      background: '#456AEF',
      marginRight: 15,
      color: 'white',
      minWidth: 150,
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        fontSize: 12,
        textTransform: 'none',
        '& .MuiSvgIcon-root': {
          marginBottom: 10,
        },
      },
    },
  },
  noBorder: {
    border: 'none',
  },
}));

const VALIDATIONS = {
  desktop_image_path: Yup.string()
    .nullable()
    .required('Banner desktop não selecionado'),
  mobile_image_path: Yup.string()
    .nullable()
    .required('Banner mobile não selecionado'),
  url: Yup.string().url('Link inválido'),
};

export default function Banners(props: any) {
  const classes = useStyles();
  const { fieldName, defaultValue = [], registerField } = useField('banners');
  const [banners, setBanners] = useState<any[]>(defaultValue);
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  const modalBuilderRef = useRef<ModalBuilderRef>(null);
  const [currentBanner, setCurrentBanner] = useState<any>(null);

  const createBanner = (bannerData?: any, extra?: any) => {
    if (bannerData) {
      let banner = bannerData;

      const whenDesktopBannerIsString =
        typeof bannerData.desktop_image_path === 'string';
      const whenMobileBannerIsString =
        typeof bannerData.mobile_image_path === 'string';

      if (whenDesktopBannerIsString && whenMobileBannerIsString) {
        const desktopImagePath = bannerData.desktop_image_path;
        const desktopWidth = bannerData.desktop_image_width;
        const desktopHeight = bannerData.desktop_image_height;

        const mobileImagePath = bannerData.mobile_image_path;
        const mobileWidth = bannerData.mobile_image_width;
        const mobileHeight = bannerData.mobile_image_height;

        banner = {
          ...bannerData,
          desktop_image_path: desktopImagePath,
          mobile_image_path: mobileImagePath,
          desktop_image_width: desktopWidth,
          desktop_image_height: desktopHeight,
          mobile_image_width: mobileWidth,
          mobile_image_height: mobileHeight,
        };
      }

      if (extra) {
        if (banners[extra.index]?.id) {
          banners[extra.index] = {
            id: banners[extra.index].id,
            ...banner,
            $updated: true,
          };
        } else {
          banners[extra.index] = {
            id: banners[extra.index].id,
            ...banner,
            $created: true,
          };
        }
      } else {
        banners.push({ ...banner, $created: true });
      }

      setBanners([...banners]);
    }
  };

  const editBanner = (banner: Record<string, any>, index: number) => {
    modalBuilderRef.current?.open(banner, { index });
    setCurrentBanner(banner);
  };

  const deleteBanner = (id: number, index: number) => {
    const row = banners.filter((row) => row.$deleted !== true)[index];
    const trueIndex = banners.indexOf(row);
    if (row.id) {
      banners[trueIndex] = { id, $deleted: true };
    } else {
      banners.splice(trueIndex, 1);
    }
    setBanners((banners) => [...banners]);
  };

  const onDragEnd = (result: any) => {
    if (result.destination) {
      const _items: any[] = reorder(
        banners,
        result.source.index,
        result.destination.index
      );

      for (let i = 0; i < _items.length; i++) {
        const item = _items[i];
        item.row = i + 1;
        item.order = item.row;
        item.$updated = true;
      }

      setBanners(_items);
    }
  };

  function reorder(list: any, startIndex: any, endIndex: any) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  }

  const updateOrders = (banners: any[]) => {
    banners.forEach((item, index) => {
      if (!item.order) {
        item.order = banners.indexOf(item) + 1;
        if (item.id) item.$updated = true;
      }
    });

    setBanners(banners);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue() {
        return banners;
      },
      setValue(_, value: any[]) {
        setBanners([...value]);
      },
    });

    updateOrders(banners);
  }, [fieldName, registerField, banners]);

  const beforeAddNewBanner = () => {
    setCurrentBanner(null);
    modalBuilderRef.current?.open();
  };

  const ordenedBanners = banners
    .sort((a: any, b: any) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      return a.order ? -1 : 1;
    })
    .filter((banner: any) => banner.$deleted !== true);

  return (
    <ContainerCustom
      title="Banners"
      subtitle="Banners pertencentes a este grupo."
      classes={{
        content: classes.noBorder,
      }}
    >
      <div style={{ width: '100%' }}>
        <div className={classes.drag}>
          <img src="/icons/drag.svg" alt="Drag icon" />
          Clique e arraste para ordenar as imagens
        </div>
        <div className={classes.imageWrapper}>
          <label htmlFor="upload-template" style={{ display: 'contents' }}>
            <Button
              className={clsx(classes.imageContent, 'add')}
              component="span"
              onClick={beforeAddNewBanner}
            >
              <AddCircle />
              Adicionar novo banner
            </Button>
          </label>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="list"
                >
                  {ordenedBanners.map((banner: any, index: number) => (
                    <Draggable
                      key={`item-${index}`}
                      draggableId={`item-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.imageContent}
                        >
                          <IconButton
                            onClick={() => deleteBanner(banner.id, index)}
                            size="medium"
                            className={classes.iconButton}
                            style={{ right: 65 }}
                            title="remover"
                          >
                            <Delete style={{ fontSize: 20 }} />
                          </IconButton>
                          <IconButton
                            onClick={() => editBanner(banner, index)}
                            size="medium"
                            className={classes.iconButton}
                            title="editar"
                          >
                            <Edit style={{ fontSize: 20 }} />
                          </IconButton>
                          <img
                            className={classes.image}
                            alt={banner.name}
                            src={banner.desktop_image_path}
                            onError={({ target }: any) =>
                              (target.src = '/no-image.jpg')
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <ModalBuilder
        ref={modalBuilderRef}
        title="Banner"
        validations={VALIDATIONS}
        onClose={createBanner}
      >
        <FileInput
          name="desktop_image_path"
          label="Desktop"
          required
          value={currentBanner?.desktop_image_path}
          uploadUrl={`banners/upload/${auth.extra!.marketplace?.slug_name}`}
          onUpload={(url, name) => {
            getImageSize(url)
              .then(({ width, height }) => {
                modalBuilderRef.current?.setFieldValue(
                  'desktop_image_path',
                  url
                );
                modalBuilderRef.current?.setFieldValue(
                  'desktop_image_width',
                  width
                );
                modalBuilderRef.current?.setFieldValue(
                  'desktop_image_height',
                  height
                );
              })
              .catch((error) => {
                console.log(error);
                alert.error('Erro ao carregar imagem desktop');
              });
          }}
        />

        <div style={{ display: 'none' }}>
          <Field
            label="Desktop"
            name="desktop_image_path"
            field={{
              type: 'text',
            }}
          />

          <Field
            label="Desktop"
            name="desktop_image_width"
            field={{
              type: 'number',
            }}
          />

          <Field
            label="Desktop"
            name="desktop_image_height"
            field={{
              type: 'number',
            }}
          />

          <Field
            label="Mobile"
            name="mobile_image_path"
            field={{
              type: 'text',
            }}
          />

          <Field
            label="Mobile"
            name="mobile_image_width"
            field={{
              type: 'number',
            }}
          />

          <Field
            label="Mobile"
            name="mobile_image_height"
            field={{
              type: 'number',
            }}
          />
        </div>

        <FileInput
          name="mobile_image_path"
          label="Mobile"
          required
          value={currentBanner?.mobile_image_path}
          uploadUrl={`banners/upload/${auth.extra!.marketplace?.slug_name}`}
          onUpload={(url, name) => {
            getImageSize(url)
              .then(({ width, height }) => {
                //get index from item
                modalBuilderRef.current?.setFieldValue(
                  'mobile_image_path',
                  url
                );
                modalBuilderRef.current?.setFieldValue(
                  'mobile_image_width',
                  width
                );
                modalBuilderRef.current?.setFieldValue(
                  'mobile_image_height',
                  height
                );
              })
              .catch((error) => {
                console.log(error);
                alert.error('Erro ao carregar imagem mobile');
              });
          }}
        />

        <Field
          label="Link"
          name="url"
          description="Link para onde o banner leva o usuário se for clicado"
          field={{
            type: 'text',
            componentProps: {
              InputProps: {
                inputProps: {
                  maxLength: 500,
                },
                startAdornment: (
                  <div
                    className={classes.adornamentHttp}
                    children="HTTP/HTTPS"
                  />
                ),
              },
            },
          }}
        />
        <Field
          label="Nova guia?"
          name="open_url_type"
          field={{
            type: 'list',
            data: [
              { value: '0', label: 'Abrir em uma nova janela' },
              { value: '1', label: 'Abrir na mesma janela' },
            ],
          }}
        />
        <Field
          label="Ativo?"
          name="is_active"
          field={{
            type: 'boolean',
            isSwitch: true,
          }}
          positionLabel="right"
        />
      </ModalBuilder>
    </ContainerCustom>
  );
}
