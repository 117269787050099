import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import disableFields from 'core/lib/disableFields';
import { InputAdornment, IconButton, makeStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  password: {
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
}));

export default function Details(props: any) {
  const {
    isNew,
    id,
    page: { label, route, routes, permissions },
    instance,
  } = props;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordQuality, setPasswordQuality] = useState(-1);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();

  const user: any = JSON.parse(localStorage.getItem('@Auth:user') as string);

  async function onSubmit(data: Record<string, any>) {
    try {
      let { create: routeCreate, update: routeUpdate } = routes || {};

      routeUpdate = routeUpdate(id);

      if (isNew) {
        data = (await api.post(routeCreate, data)).data;
        alert.success('Informações salvas com sucesso', 10000);
      } else {
        await api.put(routeUpdate, { ...data, id }).then(({ config }) => {
          const currentUser = JSON.parse(config.data);

          if (currentUser.name === user.name && currentUser.password !== '') {
            localStorage.clear();
            window.location.reload();
          }

          alert.success('Alterações salvas com sucesso', 10000);
        });
      }

      history.push(`/${route}`);
    } catch (e: any) {
      const {
        request: { status },
      } = e;
      let message;

      switch (status) {
        case 401:
          message = 'Email já está em uso';
          break;

        default:
          message = 'Erro no servidor';
          break;
      }

      alert.error(message);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions?.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={isNew === true ? VALIDATIONS() : VALIDATEEDITUSER()}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            }}
            required
            slot="6"
          />
          <Field
            label="E-mail"
            name="email"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 150,
                    form: {
                      autocomplete: 'new-email',
                    },
                  },
                  disabled: !isNew,
                },
              },
            }}
            required
            slot="6"
          />
          <Field
            label="Senha"
            name="password"
            description="A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número"
            onChange={(value) => {
              const regex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#@$!%*?&]{8,}$/;

              if (regex.test(value)) {
                setPasswordQuality(1);
              } else {
                setPasswordQuality(0);
              }
            }}
            field={{
              type: 'text',
              componentProps: {
                helperText:
                  passwordQuality === 0 &&
                  'A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número',
                className: classes.password,
                InputProps: {
                  type: showPassword ? 'text' : 'password',

                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    maxLength: 150,
                    autocomplete: 'new-password',
                  },
                },
              },
            }}
            required
            slot="6"
          />
          <Field
            label="Grupo do usuário"
            name="userprofile"
            field={{
              type: 'autocomplete',
              relation: 'userprofile',
              field: 'name',
              route: 'userprofiles/custom-autocomplete',
            }}
            required
            slot="6"
          />
        </Container>
      </Form>
    </>
  );
}

const VALIDATIONS = () => {
  const validations = {
    name: Yup.string().required('O campo nome está vazio'),
    email: Yup.string()
      .email('Preencha um email válido')
      .required('O campo e-mail está vazio'),
    password: undefined as any,
    userprofile: Yup.object()
      .required('O campo grupo do usuário está vazio')
      .nullable(),
  };

  validations.password = Yup.string()
    .required('O campo senha está vazio')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#@$!%*?&]{8,}$/, {
      message:
        'Senha fraca, a senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número',
    });

  return validations;
};

const VALIDATEEDITUSER = () => {
  const editUservalidations = {
    name: Yup.string().required('O campo nome está vazio'),
    email: Yup.string().required('O campo e-mail está vazio'),
    password: undefined as any,
    userprofile: Yup.object()
      .required('O campo grupo do usuário está vazio')
      .nullable(),
  };

  editUservalidations.password =
    editUservalidations.password !== undefined
      ? Yup.string().matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#@$!%*?&]{8,}$/,
          {
            message:
              'Senha fraca, a senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número',
          }
        )
      : false;

  return editUservalidations;
};
