import {
    useEffect,
    useState,
    useContext,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { makeStyles } from '@material-ui/core';

import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import Modal from 'core/components/Modal';
import Autocomplete from 'core/components/inputs/Autocomplete';
import Table, { ColDef } from 'core/components/Table';
import InputContainer from 'core/components/InputContainer';
import Checkbox from 'core/components/inputs/Checkbox';
import Button from 'core/components/Button';

export interface CompanyManagerProps {
    onClose(company?: Record<string, any>, extra?: any): void;
}

export interface CompanyManagerRef {
    open(extra?: any): void;
}

const useStyles = makeStyles((theme) => ({
    labelBottom: {
        alignItems: 'unset',
        flexDirection: 'column',
        marginBottom: 10,
        '& p': {
            marginBottom: theme.spacing(1),
        },
    },
    paper: {
        width: 'auto',
    },
    table: {
        height: 300,
    },
    integrate: {
        margin: `${theme.spacing(2)}px auto 0`,
    },
}));

export default forwardRef<CompanyManagerRef, CompanyManagerProps>(
    function CompanyManager(props, ref) {
        const alert = useContext(AlertContext);

        const [{ states, cities, companies }, setState] = useState({
            states: [] as any[],
            cities: [] as any[],
            companies: [] as any[],
        });
        const [selected, setSelected] = useState({
            state: '' as string | Record<string, any>,
            city: '' as string | Record<string, any>,
            company: null as null | Record<string, any>,
        });
        const colDef: ColDef[] = [
            {
                field: 'companyName',
                headerName: 'Companhia',
                width: 400,
            },
            {
                field: 'address',
                headerName: 'Endereço',
            },
            {
                field: 'integrate',
                headerName: ' ',
                width: 50,
                renderCell({ row }) {
                    return (
                        <Checkbox
                            checked={row.id === selected.company?.id}
                            onChange={() => [
                                setSelected({ ...selected, company: row }),
                            ]}
                        />
                    );
                },
            },
        ];
        const classes = useStyles();
        const _cities = cities.filter(
            (city) =>
                typeof selected.state === 'object' &&
                city.stateId === selected.state?.id
        );
        const _companies = companies.filter(
            (company) =>
                typeof selected.city === 'object' &&
                company.cityId === selected.city?.id
        );
        const [handler, setHandler] = useState({
            open: false,
            extra: null as any,
        });

        function onClose(company?: Record<string, any>) {
            props.onClose(company, handler.extra);

            setHandler({ open: false, extra: null });

            setSelected({ state: '', city: '', company: null });
        }

        useImperativeHandle(ref, () => ({
            open: (extra: any) => setHandler({ open: true, extra }),
        }));

        useEffect(() => {
            api.get('marketplacemelhornvioconfigs/companies')
                .then(({ data }) => {
                    setState(data);
                })
                .catch(() => {
                    alert.error('Não foi possivel listar as companhias');
                });
        }, []);

        return (
            <Modal
                open={handler.open}
                classes={{ paper: classes.paper }}
                onClose={() => onClose()}
                animation="Fade"
            >
                <div
                    style={{
                        width: 900,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <InputContainer
                            label="Estado"
                            classes={{ content: classes.labelBottom }}
                            style={{ content: { marginRight: 20 } }}
                        >
                            <Autocomplete
                                options={states}
                                getOptionLabel={(option) =>
                                    option ? option.name : ''
                                }
                                value={selected.state || ''}
                                onChange={(_, value) => {
                                    setSelected({
                                        state: value,
                                        city: '',
                                        company: null,
                                    });
                                }}
                            />
                        </InputContainer>
                        <InputContainer
                            label="Cidade"
                            classes={{ content: classes.labelBottom }}
                        >
                            <Autocomplete
                                options={_cities}
                                getOptionLabel={(option) =>
                                    option ? option.name : ''
                                }
                                disabled={!selected.state}
                                value={selected.city || ''}
                                onChange={(_, value) => {
                                    setSelected({
                                        state: selected.state,
                                        city: value,
                                        company: null,
                                    });
                                }}
                            />
                        </InputContainer>
                    </div>
                    <Table
                        columns={colDef}
                        rows={_companies}
                        paginationMode="client"
                        className={classes.table}
                    />
                    <Button
                        color="#456AEF"
                        className={classes.integrate}
                        onClick={() => onClose(selected.company || undefined)}
                    >
                        Integrar
                    </Button>
                </div>
            </Modal>
        );
    }
);
