import { withStyles } from '@material-ui/core/styles';
import { makeStyles, Tooltip, Theme } from '@material-ui/core';

const useStyles = makeStyles({
  tooltipImage: {
    width: 200,
    display: 'flex',
    borderRadius: 'inherit',
  },
});

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: 0,
    boxShadow: theme.shadows[1],
  },
  arrow: {
    color: '#333333',
  },
}))(Tooltip);

export default function PreviewImage(props: { image: string; children: any }) {
  const classes = useStyles();
  const { image, children } = props;

  return (
    <HtmlTooltip
      title={<img src={image} className={classes.tooltipImage} alt="Preview" />}
      disableHoverListener={!image}
    >
      {children}
    </HtmlTooltip>
  );
}
