import { Badge, Box, Button, makeStyles } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { useRef, useState } from 'react';
import useSWR from 'swr';
import QuestionsNotificationsPopover from './QuestionsNotificationsPopover';

const useStyles = makeStyles((theme) => ({
  badge: {
    top: 0,
    right: 0,
    color: '#fff',
    padding: '0 4px',
    borderRadius: '50%',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '14px',
    height: '14px',
    minWidth: '14px',
    marginRight: '14px',
  },
  icon: {
    color: '#456AEF',
    fontSize: '24px',
  },
}));

interface CountNotifications {
  count: string;
}

const QuestionsNotifications: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const { data: countNotifications } = useSWR<CountNotifications[]>(
    '/productQuestions/countNotifications'
  );

  const notificationsCount = Number((countNotifications && countNotifications[0].count) || 0);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button
        aria-describedby={'questions-notifications'}
        className={classes.badge}
        onClick={(e) => setOpen((o) => !o)}
        ref={anchorEl}
      >
        <Badge
          badgeContent={
            notificationsCount
          }
          color="error"
          invisible={notificationsCount === 0}
        >
          <ChatBubbleOutlineIcon className={classes.icon} />
        </Badge>
      </Button>
      <QuestionsNotificationsPopover
        anchorEl={anchorEl.current}
        id={'questions-notifications'}
        open={open}
        onClose={onClose}
      />
    </Box>
  );
};

export default QuestionsNotifications;
