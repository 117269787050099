import { api } from 'core/lib/api';

const exportOrders = async (id: number) => {
  const { data } = await api.get('affiliates/export', {
    responseType: 'blob',
    params: {
      id,
    }
  });

  const blob = new Blob([data]);
  const url = URL.createObjectURL(blob);
  const linkDownload = document.createElement('a');

  linkDownload.href = url;
  linkDownload.download = 'export-orders.xlsx';
  linkDownload.click();
};

export default exportOrders;