import {
    TimePicker as TimePickerCore,
    TimePickerProps,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    time: {
        background: '#FFF',
        '& input': {
            paddingTop: 10.5,
            paddingBottom: 10.5,
            fontSize: theme.typography.pxToRem(15),
            height: 19,
        },
    },
}));

export default function TimePicker({ className, ...props }: TimePickerProps) {
    const classes = useStyles();

    return (
        <TimePickerCore
            className={clsx(classes.time, className)}
            inputVariant="outlined"
            {...props}
        />
    );
}
