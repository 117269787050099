import { Page } from 'core/interfaces/page';
import moment from 'moment';
import List from './List';

const Customer: Page = {
  label: 'Clientes',
  route: 'customers',
  routes: {
    findOne: (id) => `customers/${id}`,
    find: 'customers',
  },
  // Details,
  List,
  listProps: {
    fields: {
      first_name: {
        headerName: 'Nome',
      },
      last_name: {
        headerName: 'Último nome',
      },
      email: {
        headerName: 'E-mail',
      },
      phone: {
        headerName: 'Telefone',
      },
      cpf: {
        headerName: 'CPF',
      },
      cnpj: {
        headerName: 'CNPJ',
      },
      birth_date: {
        headerName: 'Data de nascimento',
        renderCell: ({ row: { birth_date } }) => {
          return birth_date && moment(birth_date).format('DD/MM/YYYY');
        },
      },
      customer_address: {
        headerName: 'Endereço',
        renderCell: ({ row: { customer_address } }) => {
          return customer_address.length > 0
            ? `${customer_address[0].street}, ${customer_address[0].city} - ${customer_address[0].state}`
            : '';
        },
      },
    },
  },
};

export default Customer;
