import { useContext, useEffect, useRef, useState } from 'react';
import { ValidationError } from 'yup';

import BlingBreadCrumb, { BreadCrumbRef } from 'core/toolbox/BlingBreadCrumb';
import Tabs, { TabsRef } from 'core/components/Tabs';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';

// Tabs
import Authentication from './tabs/Authentication';
import Categories from './tabs/Categories';
import Synchronization from './tabs/Synchronization';
import ActiveRegister from './tabs/ActiveRegister';

interface FormRef {
    getData(): Record<string, any>;
}

export default function Details(props: any) {
    const {
        page: { route, routes, permissions },
    } = props;
    const formRef = useRef<FormRef>(null);
    const tabsRef = useRef<TabsRef>(null);
    const breadCrumbRef = useRef<BreadCrumbRef>(null);
    const { current: instance } = useRef(props.instance);
    const alert = useContext(AlertContext);

    const [isActive, setIsActive] = useState(!!instance?.is_active);
    const [isNew, setIsNew] = useState(true);

    async function onSubmit(data: Record<string, any>) {
        try {
            breadCrumbRef.current?.setDisabled(true);

            data = (await api.post(routes.create, data)).data;

            Object.assign(instance, data);

            setIsNew(false);
            setIsActive(true);

            alert.success('Integração realizada com sucesso', 10000);
        } catch (error: any) {

            const errorMessage = error.response.data.error.message;

            if (errorMessage.includes("Bling: chave de API não autorizada, por favor verifique seu token e tente novamente.")) {
                alert.warning(errorMessage, 10000);
            } else {
                alert.error('Erro ao realizar integração', 10000);
            }

        } finally {
            breadCrumbRef.current?.setDisabled(false);
        }
    }

    function onSave() {
        try {
            const data = formRef.current!.getData();

            switch (tabsRef.current?.controller) {
                case 0: {
                    Object.assign(instance, data);

                    break;
                }
            }

            onSubmit(instance);
        } catch (error: any) {
            if (error instanceof ValidationError) {
                alert.error(error.message);
            }
        }
    }

    useEffect(() => {
        if (instance?.is_active) {
            setIsNew(false);
        }
    }, []);

    return (
        <>
            <BlingBreadCrumb
                ref={breadCrumbRef}
                route={route}
                label="Integrações"
                integrated={{ isActive, setIsActive }}
                onSave={onSave}
                instance={instance}
                alert={alert}
                api={api}
                isNew={{isNew, setIsNew}}
                disabled={!permissions.update}
            />
            <Tabs
                ref={tabsRef}
                tabs={[
                    { label: 'Autenticação' },
                    { label: 'Vincular Categorias' },
                    { label: 'Sincronização' },
                    { label: 'Registro de Atividades' },
                ]}
                disableClick={isNew ? 'right' : false}
            >
                {(controller) => {
                    switch (controller) {
                        case 0: {
                            return (
                                <Authentication
                                    ref={formRef as any}
                                    instance={instance}
                                    integrated={{ isActive, setIsActive }}
                                    isNew={isNew}
                                    permissions={permissions}
                                />
                            );
                        }

                        case 1: {
                            return (
                                <Categories
                                    instance={instance}
                                    integrated={{ isActive, setIsActive }}
                                    isNew={isNew}
                                    permissions={permissions}
                                />
                            );
                        }

                        case 2: {
                            return (
                                <Synchronization
                                    instance={instance}
                                    integrated={{ isActive, setIsActive }}
                                    isNew={isNew}
                                    permissions={permissions}
                                />
                            );
                        }

                        case 3: {
                            return (
                                <ActiveRegister
                                    instance={instance}
                                    integrated={{ isActive, setIsActive }}
                                    isNew={isNew}
                                    permissions={permissions}
                                />
                            );
                        }
                    }
                }}
            </Tabs>
        </>
    );
}
