import moment from 'moment';
import CurveLinesChart from './CurveLinesChart';

interface Sell {
  x: Date;
  y: number;
}

interface Props {
  allSells: Sell[];
  period: 'year' | 'month' | 'week' | 'last7days';
}

const SellsInPeriod: React.FC<Props> = ({ allSells, period }) => {
  const currentDate = moment();

  let allData = allSells.map((sell) => ({
    x: moment(sell.x),
    y: sell.y,
  }));

  // Fill all 365 days in current year, if not exists pull 0
  for (let i = 0; i < 365; i++) {
    const date = moment(new Date(`${currentDate.year()}-01-01`)).add(i, 'days');
    const sell = allData.find((data) => data.x.isSame(date, 'day'));
    if (!sell) {
      allData.push({
        x: date,
        y: 0,
      });
    }
  }

  allData = allData.sort((a, b) => a.x.unix() - b.x.unix());

  const dataByYear = allData
    .filter((d) => d.x.year() === currentDate.year())
    .reduce<{ x: string; y: number }[]>((acc, d) => {
      const MONTHS = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ];

      const monthName = MONTHS[d.x.month()];

      const found = acc.find((dd) => dd.x === monthName);
      if (!found) {
        acc.push({ ...d, x: monthName });
      } else {
        found.y += d.y;
      }

      return acc;
    }, []);

  const getWeekDay = (day: number) => {
    if (day < 8) return 'Sem 1';
    else if (day < 15) return 'Sem 2';
    else if (day < 22) return 'Sem 3';
    else return 'Sem 4';
  };

  const dataByMonth = allData
    .filter(
      (d) =>
        d.x.year() === currentDate.year() && d.x.month() === currentDate.month()
    )
    .reduce<{ x: string; y: number }[]>((acc, d) => {
      const day = d.x.date();

      const dayWeek = getWeekDay(day);

      const found = acc.find((dd) => dd.x === dayWeek);
      if (!found) {
        acc.push({ ...d, x: dayWeek });
      } else {
        found.y += d.y;
      }

      return acc;
    }, []);

  const dataByWeek = allData
    .filter(
      (d) =>
        d.x.year() === currentDate.year() &&
        d.x.month() === currentDate.month() &&
        getWeekDay(d.x.date()) === getWeekDay(currentDate.date())
    )
    .reduce<{ x: string; y: number }[]>((acc, d) => {
      const formattedDay = d.x.format('DD/MM');

      const found = acc.find((dd) => dd.x === formattedDay);
      if (!found) {
        acc.push({ ...d, x: formattedDay });
      } else {
        found.y += d.y;
      }

      return acc;
    }, []);

  const dataBylast7Days = allData
    .filter((d) =>
      d.x.isBetween(currentDate.clone().subtract(7, 'day'), currentDate)
    )
    .reduce<{ x: string; y: number }[]>((acc, d) => {
      const formattedDay = d.x.format('DD/MM');

      const found = acc.find((dd) => dd.x === formattedDay);
      if (!found) {
        acc.push({ ...d, x: formattedDay });
      } else {
        found.y += d.y;
      }

      return acc;
    }, []);

  const NAME_FILTER = {
    year: dataByYear,
    month: dataByMonth,
    week: dataByWeek,
    last7days: dataBylast7Days,
  };

  const data = NAME_FILTER[period];

  return (
    <CurveLinesChart
      name="R$"
      categories={data.map((d) => d.x)}
      series={data.map((d) => d.y)}
    />
  );
};

export default SellsInPeriod;
