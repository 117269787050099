import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field, { FieldRef } from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import RangeInput from './RangeInput';
import ShippingTypeItems from './ShippingTypeItems';
import useIsSeller from 'core/lib/useIsSeller';
import Loading from 'core/components/Loading';
import disableFields from 'core/lib/disableFields';

function VALIDATIONS(isSeller: boolean) {
  const validations = {
    shipping_service: Yup.object()
      .when('shipping_source', (shipping_source, schema) =>
        !isSeller && shipping_source.id === 3
          ? schema.required('Preencha o campo tipo de serviço')
          : schema.optional()
      )
      .nullable(),
    marketplace_correios_service: Yup.object()
      .when('shipping_source', (shipping_source, schema) =>
        !isSeller && shipping_source.id === 1
          ? schema.required('Preencha o campo tipo de serviço')
          : schema.optional()
      )
      .nullable(),
    name: Yup.string().required('Preencha o campo nome da opção'),
    range_type: Yup.string().when(
      'shipping_source',
      (shipping_source, schema) =>
        !isSeller && shipping_source.id === 2
          ? schema.required('Preencha o campo calcular prazo com')
          : schema.optional()
    ),
    custom_term_min: Yup.number()
      .when('custom_term_max', (custom_term_max, schema) =>
        schema.lessThan(
          custom_term_max,
          'O primeiro campo do prazo personalizado deve ser menor que o segundo campo'
        )
      )
      .when('range_type', (range_type, schema) =>
        Number(range_type) === 1
          ? schema.required(
              'Preencha corretamente o primeiro campo do prazo personalizado'
            )
          : schema.optional()
      )
      .nullable(),
    custom_term_max: Yup.number()
      .when('range_type', (range_type, schema) =>
        Number(range_type) === 1
          ? schema.required(
              'Preencha corretamente o segundo campo do prazo personalizado'
            )
          : schema.optional()
      )
      .nullable(),
  };

  if (!isSeller) {
    // @ts-ignore
    validations.shipping_source = Yup.object()
      .required('Preencha o campo operador logístico')
      .nullable();
  }

  return validations;
}

export default function Details(props: any) {
  const {
    isNew,
    id,
    page: { label, route, routes, permissions },
    instance,
  } = props;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const shippingServiceRef = useRef<FieldRef>(null);
  const marketplaceCorreiosServiceRef = useRef<FieldRef>(null);
  const rangeTypeRef = useRef<FieldRef>(null);
  const customTermRef = useRef<FieldRef>(null);
  const shippingTypeItemsRef = useRef<FieldRef>(null);
  const [rangeType, setRangeType] = useState<any>();

  const [isSeller, isLoading] = useIsSeller();

  async function onSubmit(data: Record<string, any>) {
    const {
      create: routeCreate = `${route}/custom`,
      update: routeUpdate = `${route}/custom/${id}`,
    } = routes || {};

    const marketplace_correios_service_id =
      data?.marketplace_correios_service?.id;

    try {
      const sellerShippingTypeResponse = await api.get(
        '/shippingtypes/seller-shipping-type'
      );

      const { shippingTypes } = sellerShippingTypeResponse.data;

      const alreadyHasShippingType = shippingTypes.find(
        (s: any) =>
          s.marketplace_correios_service_id ===
            marketplace_correios_service_id && s.id !== id
      );

      if (alreadyHasShippingType) {
        alert.error(
          'Já existe esse tipo de serviço cadastrado, na área do vendedor.'
        );
        return;
      }

      if (isNew) {
        data = (await api.post(routeCreate, data)).data;

        alert.success('Informações salvas com sucesso', 10000);
      } else {
        await api.put(routeUpdate, { ...data, id });

        alert.success('Alterações salvas com sucesso', 10000);
      }

      history.push(`/${route}`);
    } catch (error: any) {
      const status = error?.request?.status;
      let message;

      switch (status) {
        case 409:
          message = 'URL amigável já está em uso!';
          break;

        default:
          message = 'Erro no servidor';
          break;
      }

      alert.error(message);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  if (isLoading) return <Loading />;

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions?.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS(isSeller)}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Operador logístico"
            name="shipping_source"
            field={{
              type: 'autocomplete',
              relation: 'shipping_source',
              field: 'name',
              // @ts-ignore
              route: 'shippingsources/autocomplete-custom',
              // @ts-ignore
              componentProps: {
                disabled: !isNew,
              },
            }}
            required
            slot="6"
            editable={!isSeller}
            onChange={(value) => {
              shippingServiceRef.current?.setEditable(Number(value?.id) === 3);

              marketplaceCorreiosServiceRef.current?.setEditable(
                Number(value?.id) === 1
              );

              rangeTypeRef.current?.setEditable(Number(value?.id) === 2);

              customTermRef.current?.setEditable(false);

              shippingTypeItemsRef.current?.setEditable(false);
            }}
          />
          <Field
            ref={shippingServiceRef}
            label="Tipo de serviço"
            name="shipping_service"
            field={{
              type: 'autocomplete',
              relation: 'shipping_service',
              field: 'name',
              route: 'shippingservices/autocomplete-custom',
            }}
            required
            slot="6"
            editable={Number(instance.shipping_source_id) === 3}
          />
          <Field
            ref={marketplaceCorreiosServiceRef}
            label="Tipo de serviço"
            name="marketplace_correios_service"
            field={{
              type: 'autocomplete',
              relation: 'marketplace_correios_service',
              field: 'name',
              route:
                'shippingtypes/custom/autocomplete/marketplace_correios_service',
            }}
            required
            slot="6"
            editable={Number(instance.shipping_source_id) === 1}
          />
          <Field
            label="Nome da opção"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            }}
            required
            slot="6"
          />
          <Field
            label="Sequência"
            name="order"
            field={{
              type: 'number',
              numberFormat: {
                limitOfBytes: '2',
                allowNegative: false,
              },
            }}
            slot="6"
          />
          <Field
            label="Valor mínimo do carrinho"
            name="min_value_activate"
            description="Este campo será utilizado para estipular um valor mínimo de compra"
            field={{
              type: 'number',
              numberFormat: {
                allowNegative: false,
                decimalSeparator: ',',
                decimalScale: 2,
                fixedDecimalScale: true,
                thousandSeparator: '.',
                isNumericString: true,
                precision: 10,
                prefix: 'R$ ',
              },
            }}
            slot="6"
          />
          <Field
            ref={rangeTypeRef}
            label="Calcular prazo com"
            name="range_type"
            field={{
              type: 'list',
              data: [
                { value: '0', label: 'Faixas de CEP' },
                { value: '1', label: 'Prazo Personalizado' },
              ],
              componentProps: {
                disabled: !isNew,
              },
            }}
            slot="6"
            editable={isSeller || Number(instance.shipping_source_id) === 2}
            onChange={(value) => {
              customTermRef.current?.setEditable(Number(value) === 1);

              shippingTypeItemsRef.current?.setEditable(true);

              setRangeType(Number(value));
            }}
          />
          <RangeInput
            ref={customTermRef}
            editable={Number(instance.range_type) === 1}
          />
          <Field
            label="Ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
          <Field
            ref={shippingTypeItemsRef}
            label=""
            name=""
            field={{
              type: 'custom',
              component: (
                <ShippingTypeItems
                  isNew={isNew}
                  rangeType={rangeType}
                  onSave={() => formRef.current?.submitForm()}
                />
              ),
            }}
            editable={
              (isSeller && !isNew) || Number(instance.shipping_source_id) === 2
            }
          />
        </Container>
      </Form>
    </>
  );
}
