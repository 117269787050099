import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import BreadCrumbCore from '../components/BreadCrumb';

export interface BreadCrumbProps {
    id?: any;
    returnRoute: string;
    label: string;
    subtitle: string;
}

const useStyles = makeStyles({
    informations: {
        display: 'contents',
    },
    informationsContent: {
        display: 'flex',
        marginLeft: 'auto',
    },
    created: {
        marginRight: 20,
        '& p': {
            fontSize: '0.875rem',
            color: '#444444',
        },
    },
    productRef: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'middle',
        padding: '4px 7px 4px 7px',
        borderRadius: '10px',
        marginLeft: '15px',
        marginTop: '4px',
        fontSize: 'small',
        color: '#898989',
        background: '#efefef',
    },
});

const BreadCrumbCatalogWithSubTitle: React.FC<BreadCrumbProps> = (props) => {
    const { returnRoute, label, subtitle } = props;
    const classes = useStyles();
    const history = useHistory();

    return (
        <BreadCrumbCore
            title={
                <span
                    onClick={() => history.push(returnRoute)}
                    children={label}
                    style={{ color: '#456AEF' }}
                />
            }
            dictionary={{ action: subtitle }}
            slot={{
                end: (
                    <div className={classes.informations}>
                        <div className={classes.informationsContent}></div>
                    </div>
                ),
            }}
        />
    );
};

export default BreadCrumbCatalogWithSubTitle;
