import { Component } from 'react';
import MaskedInput from 'react-text-mask';
import TextFieldCore, {
    OutlinedTextFieldProps as TextFieldPropsCore,
} from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

export interface MaskedInputProps {
    mask?:
        | Array<string | RegExp>
        | boolean
        | ((value: string) => Array<string | RegExp> | boolean);
    guide?: boolean;
    placeholderChar?: string;
    keepCharPositions?: boolean;
    pipe?: (
        conformedValue: string,
        config: any
    ) => false | string | { value: string; indexesOfPipedChars: number[] };
    showMask?: boolean;
    render?: (ref: (inputElement: HTMLElement) => void, props: any) => any;
}

export interface TextFieldProps
    extends Omit<TextFieldPropsCore, 'variant' | 'size' | 'type'> {
    type?:
        | 'button'
        | 'checkbox'
        | 'color'
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'file'
        | 'hidden'
        | 'image'
        | 'month'
        | 'number'
        | 'password'
        | 'radio'
        | 'range'
        | 'reset'
        | 'search'
        | 'submit'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week';
    maskFormat?: MaskedInputProps;
}

interface TextMaskCustomProps {
    inputRef(ref: HTMLInputElement | null): void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        '& input, & textarea': {
            fontSize: theme.typography.pxToRem(15),
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 0,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& textarea': {
            resize: 'vertical',
        }
    },
}));

class MaskFormat extends Component<
    TextMaskCustomProps & { maskFormat: MaskedInputProps }
> {
    render() {
        const { inputRef, maskFormat, ...other } = this.props;

        return (
            // @ts-ignore
            <MaskedInput
                {...other}
                guide={false}
                ref={(ref: any) => inputRef(ref ? ref.inputElement : null)}
                {...maskFormat}
            />
        );
    }
}

export default function TextField(props: TextFieldProps) {
    const { maskFormat, ...restProps } = props;
    const classes = useStyles();

    if (maskFormat) {
        restProps.InputProps = {
            ...restProps.InputProps,
            inputComponent: MaskFormat as any,
            inputProps: { maskFormat },
        };
    }

    return (
        <TextFieldCore
            {...restProps}
            classes={{
                root: classes.root,
            }}
            variant="outlined"
            size="small"
        />
    );
}
