import Chart from 'react-apexcharts';
interface Props {
  series: number[];
  categories: string[];
  name: string;
}


const CurveLinesChart: React.FC<Props> = ({ series, name, categories }) => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Chart
        options={{
          stroke: {
            curve: 'straight',
          },
          dataLabels: {
            enabled: false,
          },

          xaxis: {
            categories,
          },
        }}
        series={[
          {
            name,
            data: [...series],
          },
        ]}
        type="area"
        width="100%"
        height={400}
      />
    </div>
  );
};

export default CurveLinesChart;
