import { Page } from 'core/interfaces/page';
import Details from './Details';

const AboutCompany: Page = {
    label: 'Sobre a Empresa',
    route: 'aboutcompany',
    onlyDetails: true,
    routes: {
        update: 'marketplaces/aboutcompany',
        findOne: 'marketplaces/aboutcompany',
    },
    Details,
};

export default AboutCompany;
