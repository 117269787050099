import {
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';
import { api } from 'core/lib/api';
import InputResponsive from '../core/components/InputResponsive';
import InputContainer from '../core/components/InputContainer';
import AutocompleteAsync from '../core/components/inputs/AutocompleteAsync';

export interface CategoriesProps {
    label: string;
    relation: string;
    instance?: any;
    onChangeValue?(value: any): void;
    editable?: boolean;
    required?: boolean;
}

export interface CategoriesRef {
    setEditable(value: boolean): void;
    setLabel(value: string): void;
}

const useStyles = makeStyles((theme) => ({
    labelBottom: {
        alignItems: 'unset',
        flexDirection: 'column',
        marginBottom: 10,
        '& p': {
            marginBottom: theme.spacing(1),
        },
    },
}));

export default forwardRef<CategoriesRef, CategoriesProps>(function Categories(
    props,
    ref
) {
    const {
        relation,
        instance: { [relation]: categories } = {},
        onChangeValue,
        required,
    } = props;
    const classes = useStyles();
    const { fieldName, defaultValue, registerField } = useField(relation);
    const [value, setValue] = useState<any>([]);
    const [editable, setEditable] = useState(
        props.editable || props.editable === undefined
    );
    const [label, setLabel] = useState(props.label);
    const inputRef = useRef({ value });

    useImperativeHandle(ref, () => ({
        setEditable,
        setLabel,
    }));

    useEffect(() => {
        (async () => {
            const options = (
                await api.get('categorys/autocomplete-path-custom')
            ).data;

            if (categories && Array.isArray(categories)) {
                for (const option of options) {
                    for (const category of categories) {
                        if (option.id === category.id) {
                            category.name = option.name;
                            break;
                        }
                    }
                }

                setValue(categories);
            }
        })();
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                if (typeof value !== 'string')
                    return value
                        ? value.map((value: any) => ({ id: value.id }))
                        : [];
            },
            setValue: (_, value) => {
                setValue(value ?? []);
            },
        });
    }, [fieldName, registerField, value, defaultValue]);

    if (!editable) {
        return null;
    }

    return (
        <InputResponsive slot="12">
            <InputContainer
                label={label}
                required={required}
                classes={{ content: classes.labelBottom }}
            >
                <AutocompleteAsync
                    defaultValue={defaultValue || categories}
                    options={async () => {
                        return (
                            await api.get('categorys/autocomplete-path-custom')
                        ).data;
                    }}
                    getOptionSelected={(option, value) =>
                        option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(_: unknown, value) => {
                        onChangeValue?.(value);

                        inputRef.current.value = value;

                        setValue(value);
                    }}
                    multiple
                    delayLoad={500}
                    loadingText="Carregando..."
                    noOptionsText="Sem resultados"
                />
            </InputContainer>
        </InputResponsive>
    );
});
