import { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Modal from 'core/components/Modal';
import Button from '../components/Button';
import ButtonAction, { ButtonActionRef } from '../components/ButtonAction';
import BreadCrumbCore from '../components/BreadCrumb';

export interface BreadCrumbProps {
    route: string;
    label: string;
    creator?: {
        name: string;
        when: string | Date;
    };
    onSave?(): Promise<void> | void;
    onCancel?(): Promise<void> | void;
    integrated?: any;
    disableCancel?: boolean;
    localeText?: {
        buttonSaveLabel?: string;
        buttonCancelLabel?: string;
    };
    instance?: any;
    alert?: any;
    api?: any;
    isNew?: any;
    disabled?: boolean;
}

export type BreadCrumbRef = ButtonActionRef;

export default forwardRef<BreadCrumbRef, BreadCrumbProps>(
    function BlingBreadCrumb(props, ref) {
        const {
            label,
            integrated,
            disableCancel = false,
            alert,
            api,
            isNew,
            disabled
        } = props;
        const classes = useStyles();
        const history = useHistory();
        const buttonActionRef = useRef<ButtonActionRef>(null);

        const [modalRemoveIntegration, setModalRemoveIntegration] =
            useState(false);
        const [
            modalSuccessRemoveIntegration,
            setModalSuccessRemoveIntegration,
        ] = useState(false);

        async function onSave() {
            buttonActionRef.current?.setDisabled(true);

            await props.onSave?.();

            buttonActionRef.current?.setDisabled(false);
        }

        async function onCancel() {
            buttonActionRef.current?.setDisabled(true);

            history.push(`/`);

            await props.onCancel?.();

            buttonActionRef.current?.setDisabled(false);
        }

        async function removeIntegration() {
            setModalRemoveIntegration(false);

            try {
                const { data } = await api.delete(
                    'sellerblingconfigs/remove-integration'
                );

                if (data.deleted) {
                    integrated.setIsActive(false);
                    isNew.setIsNew(true);
                    setModalSuccessRemoveIntegration(true);

                    setTimeout(() => {
                        setModalSuccessRemoveIntegration(false);
                    }, 5000);
                }
            } catch (error: any) {
                alert.error(
                    'Não foi possível remover a integração com o Bling'
                );
            }
        }

        useImperativeHandle(ref, () => {
            if (buttonActionRef.current) {
                return buttonActionRef.current;
            }

            return {} as any;
        });

        useEffect(() => {
            buttonActionRef.current?.setDisabled(disabled || false);
        }, [ ])

        return (
            <>
                <BreadCrumbCore
                    title={<span children={label} />}
                    dictionary={{ action: 'Bling' }}
                    slot={{
                        end: (
                            <div className={classes.informations}>
                                <div className={classes.informationsContent}>
                                    {!disableCancel && (
                                        <Button
                                            children="Voltar"
                                            color="#999999"
                                            outline
                                            onClick={onCancel}
                                            style={{ marginRight: 20 }}
                                        />
                                    )}
                                    {isNew.isNew && integrated.isActive && (
                                        <ButtonAction
                                            ref={buttonActionRef as any}
                                            title="Integrar"
                                            onClick={onSave}
                                            className={classes.buttonIntegrate}
                                        />
                                    )}
                                    {!isNew.isNew && integrated.isActive && (
                                        <ButtonAction
                                            ref={buttonActionRef as any}
                                            title="Remover Integração"
                                            onClick={() =>
                                                setModalRemoveIntegration(true)
                                            }
                                            className={
                                                classes.buttonRemoveIntegrate
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        ),
                    }}
                />
                <Modal
                    open={modalRemoveIntegration}
                    onClose={() => setModalRemoveIntegration(false)}
                    classes={{ paper: classes.paper }}
                >
                    <>
                        <Typography
                            variant="h3"
                            children="Remover integração com o Bling?"
                            className={classes.modalTitle}
                        />
                        <div style={{ width: '100%' }}>
                            <Typography
                                variant="h5"
                                children="Atenção, ao remover sua aplicação seus produtos ficarão indisponíveis no marketplace até que seja realizada uma nova integração."
                                className={classes.modalSubTitle}
                            />

                            <div className={classes.modalFooter}>
                                <Button
                                    color="#456AEF"
                                    outline
                                    style={{ width: 160, border: 'none' }}
                                    onClick={() =>
                                        setModalRemoveIntegration(false)
                                    }
                                    disabled={disabled}
                                >
                                    <p
                                        children="Cancelar"
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    />
                                </Button>

                                <Button
                                    className={classes.buttonRemoveIntegrate}
                                    onClick={removeIntegration}
                                    disabled={disabled}
                                >
                                    <p
                                        children="Remover integração"
                                        style={{
                                            color: 'inherit',
                                            textDecoration: 'none',
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>
                    </>
                </Modal>
                <Modal
                    open={modalSuccessRemoveIntegration}
                    onClose={() => setModalSuccessRemoveIntegration(false)}
                    classes={{ paper: classes.paper }}
                >
                    <>
                        <div className={classes.modalSuccessHeaderContent}>
                            <img
                                src="/icons/success.svg"
                                alt="A integração foi removida com sucesso"
                                style={{ marginBottom: '15px' }}
                            />
                            <Typography
                                variant="h3"
                                children="Integração removida"
                                className={classes.modalTitleSuccess}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <Typography
                                variant="h5"
                                children="A integração foi removida com sucesso. A qualquer momento você pode configurar novamente esta integração."
                                className={classes.modalSubTitleSuccess}
                            />
                        </div>
                    </>
                </Modal>
            </>
        );
    }
);

const useStyles = makeStyles((theme) => ({
    informations: {
        display: 'contents',
    },
    informationsContent: {
        display: 'flex',
        marginLeft: 'auto',
    },
    created: {
        marginRight: 20,
        '& p': {
            fontSize: '0.875rem',
            color: '#444444',
        },
    },
    productRef: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'middle',
        padding: '4px 7px 4px 7px',
        borderRadius: '10px',
        marginLeft: '15px',
        marginTop: '4px',
        fontSize: 'small',
        color: '#898989',
        background: '#efefef',
    },
    buttonIntegrate: {
        background: 'rgb(69 106 239)',
        border: '1px solid rgb(69 106 239)',
        '&:hover': {
            background: 'rgb(69 106 239)',
            border: '1px solid rgb(69 106 239)',
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#f1f1f1',
            border: '1px solid rgb(153, 153, 153)',
          },
    },
    buttonRemoveIntegrate: {
        background: '#d50000',
        width: 'auto',
        border: '1px solid #d50000',
        '&:hover': {
            background: '#b30000',
            border: '1px solid #b30000',
            color: '#fff',
        },
        '&:disabled': {
            backgroundColor: '#f1f1f1',
            border: '1px solid rgb(153, 153, 153)',
          },
    },
    modalTitle: {
        fontSize: theme.typography.pxToRem(20.5),
        color: '#333333',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(1.25),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    modalSubTitle: {
        fontSize: theme.typography.pxToRem(16.5),
        color: '#666666',
        paddingBottom: theme.spacing(1.25),
        marginBottom: theme.spacing(4),
        width: '100%',
    },
    modalTitleSuccess: {
        fontSize: theme.typography.pxToRem(20.5),
        color: '#333333',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: theme.spacing(1.25),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    modalSubTitleSuccess: {
        fontSize: theme.typography.pxToRem(16.5),
        color: '#666666',
        textAlign: 'center',
        width: '100%',
    },
    modalFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    modalSuccessHeaderContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    paper: {
        width: 475,
    },
    title: {},
}));
