import {
  Button,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  makeStyles,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import BreadCrumbAdd from 'core/components/BreadCrumbAdd';
import Loading from 'core/components/Loading';
import { api } from 'core/lib/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

const CustomerList = () => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [customers, setCustomers] = useState<any[]>([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // get /customers
    if (customers.length === 0) setIsLoading(true);
    api
      .get('customers', {
        params: {
          skip: page,
          limit: rowsPerPage,
          search,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setCustomers(response.data.data);
        setCustomersCount(response.data.count);
      });
  }, [search, page, rowsPerPage]);

  const exportCustomers = () => {
    api.get('customers/all').then((response) => {
      const data = response.data.data.map((c: any) => ({
        Nome: c.first_name,
        'Último nome': c.last_name,
        'E-mail': c.email,
        Telefone: c.phone,
        CPF: c.cpf,
        CNPJ: c.cnpj,
        'Data de nascimento':
          c.birth_date && moment(c.birth_date).format('DD/MM/YYYY'),
        Endereço:
          c.customer_address.length > 0
            ? `${c.customer_address[0].street}, ${c.customer_address[0].city} - ${c.customer_address[0].state}`
            : '',
      }));

      // export xlxs
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Clientes');
      XLSX.writeFile(wb, 'clientes.xlsx');
    });
  };

  const handleChangeText = (text: string) => {
    setSearch(text);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbAdd
        route=""
        title="Clientes"
        onTextChange={handleChangeText}
        disableButtonAdd
      />

      <Box className={classes.actions}>
        <Button
          className={classes.exportButton}
          children="Exportar"
          onClick={exportCustomers}
        />
      </Box>

      <Box className={classes.content}>
        <Table className={classes.customers}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Último nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>CNPJ</TableCell>
              <TableCell>Data de nascimento</TableCell>
              <TableCell>Endereço</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.first_name}</TableCell>
                <TableCell>{customer.last_name}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.phone}</TableCell>
                <TableCell>{customer.cpf}</TableCell>
                <TableCell>{customer.cnpj}</TableCell>
                <TableCell>
                  {customer.birth_date &&
                    moment(customer.birth_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>
                  {customer.customer_address.length > 0
                    ? `${customer.customer_address[0].street}, ${customer.customer_address[0].city} - ${customer.customer_address[0].state}`
                    : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <tr>
              <TablePagination
                count={customersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `Mostrando ${from}-${to} de ${count} registros`
                }
              />
            </tr>
          </TableFooter>
        </Table>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    background: '#F2F2F2',
    width: '100%',
    height: 60,
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > p': {
      marginRight: 20,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  exportButton: {
    backgroundColor: '#456AEF',
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    padding: '6px 16px',
    marginLeft: '15px',
    height: 40,
    width: 80,
    '&:hover': {
      backgroundColor: '#456aef84',
      transition: 'background-color 500ms linear',
    },
  },

  content: {
    padding: '25px 30px',
  },
  customers: {
    width: '100%',
    padding: '0 30px',
    '& th': {
      fontWeight: 'bold',
      fontSize: '15px',
    },
    '& thead': {
      '& tr': {
        '& th': {
          fontWeight: 600,
          fontSize: theme.typography.pxToRem(15),
          color: '#444444',
          border: 'none',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          '&:first-of-type': {
            paddingLeft: 0,
          },
          '&:last-of-type': {
            paddingRight: 0,
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:last-of-type td': {
          border: 'none',
        },
        '&:not(:last-of-type)': {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        '& td': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          fontSize: theme.typography.pxToRem(14),
          color: '#444444',
          height: 50,
          '&:first-of-type': {
            paddingLeft: 0,
          },
          '&:last-of-type': {
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  },
}));

export default CustomerList;
