import { Page } from 'core/interfaces/page';
import Details from './Details';

const IntegrationCatalogFacebook: Page = {
    label: 'Catálogo Facebook',
    route: 'catalog_facebook',
    onlyDetails: true,
    passInstance: true,
    Details
};

export default IntegrationCatalogFacebook;
