import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import Alert from '@material-ui/lab/Alert';
import { environment } from 'environment';

import BreadCrumbCore from 'core/components/BreadCrumb';
import InputResponsive from 'core/components/InputResponsive';
import Button from 'core/components/Button';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import { useHistory } from 'react-router-dom';
import disableFields from 'core/lib/disableFields';

const useStyles = makeStyles({
  content: {
    border: 'none',
  },
  wrap: {
    width: 'calc(100% + 20px)',
  },
  informations: {
    display: 'contents',
  },
  informationsContent: {
    display: 'flex',
    marginLeft: 'auto',
  },
  integrationButton: {
    '&:disabled': {
      backgroundColor: '#f1f1f1',
      border: '1px solid rgb(153, 153, 153)',
    },
  },
});

const VALIDATIONS = {
  email: Yup.string().email('E-mail para contato do Melhor Envio inválido'),
};

const Details = (props: any) => {
  const {
    page: { routes, label, permissions },
    instance,
  } = props;

  const alert = useContext(AlertContext);
  const formRef = useRef<FormHandles>(null);
  const { search } = useLocation();
  const history = useHistory();

  const classes = useStyles();

  const [melhorEnvioIntegrate, setMelhorEnvioIntegrate] = useState<boolean>(
    instance.integrate
  );

  const onSubmit = async (data: Record<string, any>) => {
    try {
      await api.put(routes.update, data);

      alert.success('Configurações salvas com sucesso');
    } catch (err) {
      alert.error('Não foi possível salvar as configurações do marketplace');
    }
  };

  const onError = (error: Yup.ValidationError) => {
    alert.error(error.message);
  };

  const onIntegrate = async () => {
    try {
      const data = formRef.current!.getData();

      await api.put(routes.update, data);

      const url = environment.meLink;
      const clientId = data.client_id;
      const redirectUri = window.location.href.split('?')[0];
      const responseType = 'code';
      const scope =
        'cart-read cart-write companies-read companies-write coupons-read ' +
        'coupons-write notifications-read orders-read products-read products-write ' +
        'purchases-read shipping-calculate shipping-cancel shipping-checkout shipping-companies ' +
        'shipping-generate shipping-preview shipping-print shipping-share shipping-tracking ' +
        'ecommerce-shipping transactions-read users-read users-write';

      // ! Change this in prod
      window.open(
        `${url}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`,
        '_self'
      );
    } catch (error: any) {
      console.log(error);
      alert.error('Não foi possível fazer a integração com o melhor envio');
    }
  };

  const removeIntegration = async () => {
    try {
      const { data } = await api.delete(
        'marketplaces/integrations/melhor-envio'
      );

      if (data.deleted) {
        setMelhorEnvioIntegrate(false);

        alert.success('Integração removida com sucesso');
      }
    } catch (error: any) {
      alert.error('Não foi possível remover a integração com o melhor envio');
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const code = query.get('code');

    if (code) {
      query.delete('code');
      (async () => {
        try {
          const { data } = await api.get(
            `marketplaces/integrations/melhor-envio`,
            { params: { code } }
          );

          if (data.integrated) {
            setMelhorEnvioIntegrate(true);

            alert.success('Integração realizada com sucesso');
          }
        } catch (error: any) {
          alert.error('Erro na integração');
        }
      })();
    }
  }, []);

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumbCore
        title={<span children={'Integrações'} style={{ color: '#456AEF' }} />}
        dictionary={{ action: label }}
        slot={{
          end: (
            <div className={classes.informations}>
              <div className={classes.informationsContent}>
                <Button
                  children="Voltar"
                  color="#999999"
                  outline
                  onClick={() => history.goBack()}
                  style={{ marginRight: 20 }}
                />
                <Button
                  children="Integrar"
                  color="#456AEF"
                  onClick={() => formRef.current?.submitForm()}
                  style={{
                    marginRight: 20,
                    display: !permissions.update ? 'none' : '',
                  }}
                  className={classes.integrationButton}
                />
              </div>
            </div>
          ),
        }}
      />

      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <ContainerCustom
          title="Integração com o Melhor Envio"
          subtitle="Configure uma conta do Melhor Envio"
          classes={{ wrap: classes.wrap }}
        >
          <InputResponsive slot="12">
            <Alert style={{ width: '100%' }} severity="info">
              Sua callback url:{' '}
              <strong>{window.location.href.split('?')[0]}</strong>
              <span
                style={{
                  marginLeft: '15px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    window.location.href.split('?')[0]
                  );
                  alert.success('Link copiado para a área de transferência');
                }}
              >
                Copiar
              </span>
            </Alert>
          </InputResponsive>
          <Field
            label="ClientID"
            name="client_id"
            field={{
              type: 'number',
              componentProps: {
                disabled: melhorEnvioIntegrate,
              },
              numberFormat: {
                limitOfBytes: '4',
                allowNegative: false,
              },
            }}
            slot="6"
          />
          <Field
            label="Secret"
            name="client_secret"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 100,
                  },
                },
              },
              disabled: melhorEnvioIntegrate,
            }}
            slot="6"
          />
          <Field
            label="E-mail para contato"
            name="email"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 100,
                  },
                },
              },
              disabled: melhorEnvioIntegrate,
            }}
            slot="6"
          />
          <InputResponsive slot="12">
            {melhorEnvioIntegrate && (
              <Button
                color="#E74A4A"
                onClick={removeIntegration}
                children="Remover integração"
                outline
                style={{ width: 'auto' }}
                className={classes.integrationButton}
              />
            )}
            {!melhorEnvioIntegrate && (
              <Button
                color="#60BA57"
                onClick={onIntegrate}
                children="Integrar"
                className={classes.integrationButton}
              />
            )}
          </InputResponsive>
        </ContainerCustom>
      </Form>
    </>
  );
};

export default Details;
