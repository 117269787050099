import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { DateTimePickerProps as DateTimePickerBaseProps } from '@material-ui/pickers';

import DateTimePickerCore from '../components/inputs/DateTimePicker';

export interface DateTimePickerProps
    extends Omit<DateTimePickerBaseProps, 'name' | 'value' | 'onChange'> {
    name: string;
    onChange?: DateTimePickerBaseProps['onChange'];
}

export default function DateTimePicker(props: DateTimePickerProps) {
    const { name, defaultValue = null, ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);
    const [defaultInputValue, setDefaultInputValue] = useState(value);

    const onChange = (datetime: any) => {
        setValue(datetime ? datetime.toISOString() : null);

        setDefaultInputValue(datetime);

        restProps.onChange?.(datetime);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value ?? null;
                },
                setValue(_: unknown, value: any) {
                    onChange(value);
                },
            });
        }
    }, [fieldName, registerField, defaultInputValue]);

    return (
        <DateTimePickerCore
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
        />
    );
}
