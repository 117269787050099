import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ISearchTable from 'core/interfaces/ISearchTable';
import { useContext, useState } from 'react';
import palette from '../utils/palette';
import Button from './Button';
import AlertContext from 'core/contexts/Alert';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Refresh, FileCopyOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 2,
  },
  description: {
    fontSize: 14,
    lineHeight: 2,
  },
  column: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
  },
  pointer: {
    cursor: 'pointer',
  },
  Synchronization: {
    margin: '0 !important',
  },
  button: {
    backgroundColor: '#456AEF',
    color: 'white',
    fontSize: 14,
    width: '240px',
    marginLeft: 8,
    textTransform: 'unset',
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      backgroundColor: '#3F5EFB',
    },
  },
  fileCopyOutlined: {
    marginLeft: '10px',
    cursor: 'pointer',
    color: '#456AEF',
  },
}));

const SearchTable = (props: ISearchTable) => {
  const classes = useStyles();
  const history = useHistory();
  const Syncronization = props.className;

  const alert = useContext(AlertContext);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:last-child td, &:last-child th': {
        border: `${props?.pagination ? '' : 0}`,
      },
    },
  }))(TableRow);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props?.rowsPerPage || 10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const handleData = (props: ISearchTable) => {
    if (props.columns.length === 0) return [];

    if (props?.pagination)
      return props.rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    return props.rows;
  };

  const copyValue = (value: any) => {
    navigator.clipboard.writeText(value);

    alert.success('Copiado com sucesso!');
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          <Typography className={classes.description}>
            {props.description}
          </Typography>
        </div>

        <Button className={classes.button} onClick={() => props.handleUpdate?.()}>
          {props?.isLoading ? (
            <>
              <CircularProgress style={{ color: 'white' }} size="1.5rem" />{' '}
              Atualizando registros...
            </>
          ) : (
            <>
              <Refresh /> Atualizar registros
            </>
          )}
        </Button>
      </div>

      <Paper
        className={`${classes.paper} ${
          Syncronization ? classes.Synchronization : ''
        }`}
      >
        <Table>
          <TableHead>
            <TableRow>
              {props.columns.map((column: string) => (
                <TableCell key={column} className={classes.column}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {handleData(props).map((rows, index) => (
              <StyledTableRow key={index}>
                {rows.map((cell, index) => (
                  <TableCell
                    key={index}
                    className={`${cell?.className} ${
                      cell?.clickable ? classes.pointer : null
                    }`}
                    style={{
                      color: cell?.color
                        ? palette[cell?.color] || cell?.color
                        : '',
                      fontWeight: cell?.fontWeight,
                      cursor: cell?.clickable ? 'pointer' : 'default',
                      ...cell?.style,
                    }}
                    onClick={() =>
                      cell?.clickable && history.push(cell?.route || '')
                    }
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {cell.icon} {cell.value}{' '}
                      {cell.copyable && (
                        <FileCopyOutlined
                          className={classes.fileCopyOutlined}
                          onClick={() => copyValue(cell.value)}
                        />
                      )}
                    </div>
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
          {props?.pagination && (
            <TableFooter>
              <tr>
                <TablePagination
                  count={props.rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={props.rowsPerPageOptions || []}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from}-${to} de ${count} registros`
                  }
                />
              </tr>
            </TableFooter>
          )}
        </Table>
      </Paper>
    </div>
  );
};

export default SearchTable;
