import { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb, { BreadCrumbRef } from 'core/toolbox/BreadCrumb';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import Tabs, { TabsRef } from 'core/components/Tabs';
import AlertContext from 'core/contexts/Alert';
import Segmentation from 'core/toolbox/Segmentation';
import Switchable from 'components/Switchable';
import { api } from 'core/lib/api';
import QuickAddGroupUser from './QuickAddGroupUser';
import disableFields from 'core/lib/disableFields';

const VALIDATIONS = {
    name: Yup.string().required('O campo nome está vazio'),
};

export default function Details(props: any) {
    const {
        id,
        page: { label, route, routes, permissions },
    } = props;
    const [[isNew, instance], setState] = useState([
        props.isNew,
        props.instance,
    ]);
    const history = useHistory();
    const formRef = useRef<FormHandles>(null);
    const tabsRef = useRef<TabsRef>(null);
    const breadCrumbRef = useRef<BreadCrumbRef>(null);
    const alert = useContext(AlertContext);

    useEffect(() => {
        disableFields(permissions);
    }, [instance]);

    async function onSubmit(data: Record<string, any>) {
        if (isNew) {
            data = (await api.post(routes.create, data)).data;

            setState([false, data]);

            history.replace(`/${route}/${data.id}`);

            tabsRef.current?.setController(1);

            breadCrumbRef.current?.setTitle('Salvar');
        } else {
            await api.put(routes.update(id), { ...data, id });

            alert.success('Alterações salvas com sucesso', 10000);
        }
    }

    function onError(error: Yup.ValidationError) {
        alert.error(error.message);
    }

    function Geral() {
        return (
            <Form
                ref={formRef}
                style={{ display: 'contents' }}
                initialData={instance}
                validations={VALIDATIONS}
                onSubmit={onSubmit}
                onError={onError}
                abortEarly
            >
                <Container>
                    <Field
                        label="Nome"
                        name="name"
                        field={{
                            type: 'text',
                            componentProps: {
                                InputProps: {
                                    inputProps: {
                                        maxLength: 150,
                                    },
                                },
                            },
                        }}
                        required
                        slot="4"
                    />
                </Container>
            </Form>
        );
    }

    function ControlAccess() {
        return (
            <div style={{ padding: 30 }}>
                <Segmentation
                    parent={{
                        id,
                        route,
                    }}
                    page={{
                        label: 'Controle de acessos',
                        route: 'permissions',
                        permissions: {
                            create: false,
                            read: true,
                            update: false,
                            delete: true,
                        },
                        listProps: {
                            fields: {
                                menu: {
                                    headerName: 'Menu',
                                    width: 250,
                                    renderCell({ row }: any) {
                                        return row.menu.name;
                                    },
                                },
                                can_read: {
                                    headerName: 'Visualizar',
                                    renderCell({
                                        row: { id: selfId, can_read },
                                    }: any) {
                                        return (
                                            <Switchable
                                                id={id}
                                                value={can_read}
                                                column="can_read"
                                                route={`userprofiles/custom/${id}/permissions/${selfId}`}
                                                routeIsRoot
                                                isCheckbox
                                            />
                                        );
                                    },
                                },
                                can_create: {
                                    headerName: 'Incluir',
                                    renderCell({
                                        row: { id: selfId, can_create },
                                    }: any) {
                                        return (
                                            <Switchable
                                                id={id}
                                                value={can_create}
                                                column="can_create"
                                                route={`userprofiles/custom/${id}/permissions/${selfId}`}
                                                routeIsRoot
                                                isCheckbox
                                            />
                                        );
                                    },
                                },
                                can_update: {
                                    headerName: 'Editar',
                                    renderCell({
                                        row: { id: selfId, can_update },
                                    }: any) {
                                        return (
                                            <Switchable
                                                id={id}
                                                value={can_update}
                                                column="can_update"
                                                route={`userprofiles/custom/${id}/permissions/${selfId}`}
                                                routeIsRoot
                                                isCheckbox
                                            />
                                        );
                                    },
                                },
                                can_delete: {
                                    headerName: 'Excluir',
                                    renderCell({
                                        row: { id: selfId, can_delete },
                                    }: any) {
                                        return (
                                            <Switchable
                                                id={id}
                                                value={can_delete}
                                                column="can_delete"
                                                route={`userprofiles/custom/${id}/permissions/${selfId}`}
                                                routeIsRoot
                                                isCheckbox
                                            />
                                        );
                                    },
                                },
                            },
                        },
                    }}
                    QuickAdd={QuickAddGroupUser}
                    quickAddExtra={{
                        routeMenu: `permissions/autocomplete-custom?user_profile_id=${id}`,
                    }}
                    disabledEdit
                />
            </div>
        );
    }

    function Users() {
        return (
            <div style={{ padding: 30 }}>
                <Segmentation
                    parent={{
                        id,
                        route,
                    }}
                    page={{
                        label: 'Controle de acessos',
                        route: 'users',
                        permissions: {
                            create: false,
                            read: true,
                            update: false,
                            delete: true,
                        },
                        listProps: {
                            fields: {
                                name: {
                                    headerName: 'Nome',
                                },
                                email: {
                                    headerName: 'E-mail',
                                },
                            },
                        },
                    }}
                    disabledEdit
                    disabledRemove
                />
            </div>
        );
    }

    return (
        <>
            <BreadCrumb
                ref={breadCrumbRef}
                route={route}
                label={label}
                isNew={isNew}
                creator={{
                    name: instance.createby?.name,
                    when: instance.created_at,
                }}
                onSave={() => {
                    if (tabsRef.current!.controller > 0) {
                        alert.success('Informações salvas com sucesso', 10000);
                    } else {
                        formRef.current?.submitForm();
                    }
                }}
                localeText={{
                    buttonSaveLabel: isNew ? 'Continuar' : 'Salvar',
                }}
                disableSave={!permissions?.update}
            />
            <Tabs
                ref={tabsRef}
                tabs={[
                    { label: 'Geral' },
                    { label: 'Controle de acessos' },
                    { label: 'Usuários' },
                ]}
                disableClick={isNew ? 'right' : false}
            >
                {(controller) => {
                    switch (controller) {
                        case 0: {
                            return <Geral />;
                        }

                        case 1: {
                            return <ControlAccess />;
                        }

                        case 2: {
                            return <Users />;
                        }

                        default: {
                            return <></>;
                        }
                    }
                }}
            </Tabs>
        </>
    );
}
