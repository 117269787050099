import { Page } from 'core/interfaces/page';
import Details from './Details';

const External: Page = {
    label: 'Códigos externos',
    route: 'external',
    onlyDetails: true,
    routes:{
        findOne: "marketplaces/pixel",
        update: "marketplaces/pixel",
    },
    Details,
};

export default External;