import { useHistory } from 'react-router-dom';
import { IconButton, InputAdornment, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchRounded } from '@material-ui/icons';

import TextField from './inputs/TextField';

export interface BreadCrumbAddProps {
  title: string;
  description?: string;
  route: string;
  debounce?: number;
  onTextChange(text: string): void;
  disableButtonAdd?: boolean;
  disableInputSearch?: boolean;
}

const useStyles = makeStyles({
  breadCrumb: {
    borderTop: '1px solid #DDDDDD',
    borderBottom: '1px solid #DDDDDD',
    padding: '15px 30px',
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    backgroundColor: 'white',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 22,
    margin: '12px 0',
    lineHeight: 1.3,
  },
  description: {
    color: '#999999',
    fontSize: 14,
    margin: '12px 0',
    lineHeight: 1.3,
  },
  textField: {
    marginLeft: 'auto',
    '& .MuiInputAdornment-positionStart': {
      color: '#999999',
      paddingLeft: 12,
      '& .MuiIcon-root': {
        fontSize: '1.25rem',
        color: '#999999',
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 12px 10px 0',
        '&::-webkit-input-placeholder, &::placeholder': {
          opacity: 1,
          color: '#444444',
        },
      },
    },
  },
  includeButton: {
    backgroundColor: '#456aef',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#456aef',
    },
    borderRadius: 16,
    padding: '8px 16px',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 8,
    marginTop: 2,
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

let interval: any = null;

export default function BreadCrumbAdd(props: BreadCrumbAddProps) {
  const classes = useStyles();
  const history = useHistory();
  const {
    title,
    description,
    route,
    debounce = 500,
    onTextChange,
    disableButtonAdd = false,
    disableInputSearch = false,
  } = props;

  function setSearch(event: any) {
    clearInterval(interval);

    interval = setTimeout(() => {
      const { value: search } = event.nativeEvent.target;

      onTextChange(search);
    }, debounce);
  }

  return (
    <div className={classes.breadCrumb}>
      <Box>
        <Box className={classes.topContainer}>
          <Typography
            variant="h1"
            noWrap
            className={classes.title}
            children={title}
          />

          {!disableButtonAdd && (
            <IconButton
              onClick={() => history.push(route)}
              className={classes.includeButton}
            >
              Incluir
            </IconButton>
          )}
        </Box>
        {description && (
          <Typography
            className={classes.description}
            variant="body1"
            noWrap
            children={description}
          />
        )}
      </Box>

      {!disableInputSearch && (
        <TextField
          placeholder="Pesquisa"
          onChange={setSearch}
          className={classes.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
}
