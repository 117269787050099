import { useState } from 'react';
import { makeStyles, Button, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import Table from 'core/components/Table';
import Select from 'core/components/inputs/Select';
import BreadCrumb from 'core/components/BreadCrumb';

const useStyles = makeStyles((theme) => ({
    buttonBack: {
        border: '1px solid #999999',
        color: '#999999',
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(15),
        marginLeft: 20,
        width: 90,
        textTransform: 'unset',
    },
}));

export default function Details(props: any) {
    const { id, instance } = props;
    const classes = useStyles();
    const history = useHistory();
    const [filter, setFilter] = useState<string | number>('');

    return (
        <>
            <BreadCrumb
                title="Importações"
                dictionary={{
                    action: (
                        <span>
                            #{id} (
                            {moment(instance.created_at).format('DD/MM/YYYY')})
                        </span>
                    ),
                }}
                slot={{
                    end: (
                        <>
                            {instance.import_products_items?.length && (
                                <Select
                                    defaultValue=""
                                    displayEmpty
                                    style={{ width: 120, marginLeft: 'auto' }}
                                    onChange={(event) =>
                                        setFilter(event.target.value as any)
                                    }
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    <MenuItem value={0}>Alerta</MenuItem>
                                    <MenuItem value={1}>Erro</MenuItem>
                                </Select>
                            )}
                            <Button
                                onClick={() => history.push('/importproducts')}
                                className={classes.buttonBack}
                                children="Voltar"
                            />
                        </>
                    ),
                }}
                symbol="-"
            />
            <div style={{ padding: 30 }}>
                {instance.import_products_items?.length ? (
                    <Table
                        columns={[
                            {
                                field: 'line',
                                headerName: 'Linha',
                                width: 100,
                            },
                            {
                                field: 'type',
                                headerName: 'Tipo de mensagem',
                                width: 1,
                                renderCell({ row: { type } }) {
                                    switch (type) {
                                        case 0:
                                            return (
                                                <span
                                                    style={{
                                                        color: '#FF8A00',
                                                        width: 'inherit',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                    children="Alerta"
                                                />
                                            );

                                        case 1:
                                            return (
                                                <span
                                                    style={{
                                                        color: '#E74A4A',
                                                        width: 'inherit',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                    children="Erro"
                                                />
                                            );
                                    }
                                },
                            },
                            {
                                field: 'message',
                                headerName: 'Mensagem',
                            },
                        ]}
                        rows={instance.import_products_items.filter(
                            (importProductItem: any) =>
                                filter === ''
                                    ? true
                                    : filter === importProductItem.type
                        )}
                    />
                ) : (
                    <Alert severity="success">
                        Importação realizada com sucesso
                    </Alert>
                )}
            </div>
        </>
    );
}
