import { TreeItem as TreeItemCore } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

import TreeItemCategoryLabel from './TreeItemCategoryLabel';

interface Props {
    index: number;
    data: any;
    childs: any[];
    group: any[];
    userCanUpdate: boolean;
    userCanDelete: boolean;
    userCanCreate: boolean;
    updateColumn(index: number, data: any, checked: boolean): void;
    onEdit(e: any, id: number): void;
    setDialog(p: { id: number; key: number; group: any[] }): void;
}

const useStyles = makeStyles((theme) => ({
    treeItem: {
        '& .MuiTreeItem-content': {
            borderBottom: '1px solid rgb(221, 221, 221)',
            '& .MuiTreeItem-label': {
                backgroundColor: 'transparent !important',
                paddingLeft: 6,
            },
        },
    },
}));

const TreeItemCategory: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <TreeItemCore
            style={{ cursor: 'none' }}
            nodeId={props.data.id.toString()}
            className={classes.treeItem}
            label={<TreeItemCategoryLabel {...props} />}
        >
            {props.childs.map((data, index) => (
                <TreeItemCategory
                    {...props}
                    data={data}
                    index={index}
                    key={index}
                    childs={data.childs}
                    group={props.childs}
                />
            ))}
        </TreeItemCore>
    );
};

export default TreeItemCategory;
