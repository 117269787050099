import { Page } from 'core/interfaces/page';
import Switchable from 'components/Switchable';
import Details from './Details';

const SectionContent: Page = {
    label: 'Conteúdos',
    route: 'sectioncontents',
    Details,
    listProps: {
        fields: {
            title: {
                headerName: 'Título da página',
            },
            section: {
                headerName: 'Seção',
                renderCell({ row }) {
                    return row.section.name;
                },
            },
            is_active: {
                headerName: 'Ativo?',
                width: 100,
                renderCell: ({ row: { id, is_active } }) => (
                    <Switchable
                        id={id}
                        value={is_active}
                        column="is_active"
                        route="sectioncontents"
                        permissions={!SectionContent.permissions?.update}
                    />
                ),
            },
        },
        limitControlled: [100, 150, 200, 250],
    },
};

export default SectionContent;
