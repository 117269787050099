import {
  useContext,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import Categories from 'components/Categories';
import { api } from 'core/lib/api';
import AuthContext from 'core/contexts/Auth';
import NewBrand from '../NewBrand';
import useIsSeller from 'core/lib/useIsSeller';
import Loading from 'core/components/Loading';
import AlertContext from 'core/contexts/Alert';
import { getUserData } from 'core/lib/getUserData';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { Permissions } from 'core/interfaces/page';
import disableFields from 'core/lib/disableFields';

export interface GeralProps {
  isNew: boolean;
  instance: Record<string, any>;
  permissions: Permissions;
}

export interface GeralRef {
  getData(): Record<string, any>;
}

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '100%',
    margin: '20px 20px 0px 0px',
    '& .MuiCardHeader-root': {
      padding: '6px',
    },
    '& .MuiCardHeader-content > span': {
      fontWeight: 700,
      paddingLeft: '12px',
    },
    '& .MuiCardHeader-action': {
      margin: '0px',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  headerTypography: {
    fontWeight: 100,
  },
  boxText: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '6px',
    marginBottom: '12px',
  },
  titleInput: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 1.5,
    color: '#475569',
  },
}));

export default forwardRef<GeralRef, GeralProps>(function Geral(props, ref) {
  const { isNew, instance, permissions } = props;
  const formRef = useRef<FormHandles>(null);

  const { extra } = useContext(AuthContext);
  const [hasWholesale, setHasWholesale] = useState(false);
  const alert = useContext(AlertContext);

  const [isSeller, isLoading] = useIsSeller();
  const [expanded, setExpanded] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    const { seller_id } = getUserData();
    if (!isNew) {
      api
        .get(`products/${instance.id}`, {
          params: { seller: seller_id },
        })
        .then(({ data }) => {
          formRef.current?.setData(data);
        })
        .catch((error: any) => {
          console.log('error', error);

          alert.error('Não foi possível carregar os dados do produto');
        });
    }

    if (isNew) {
      api
        .get(`users/has-wholesale?seller_id=${seller_id}`)
        .then(({ data }) => {
          setHasWholesale(data.hasWholesale);
        })
        .catch((error: any) => {
          console.log('error', error);
          alert.error('Não foi possível carregar os dados do produto');
        });
    }
  }, []);

  const isNewWholesale = isNew ? hasWholesale : instance?.hasWholesale;

  useImperativeHandle(
    ref,
    () => ({
      getData() {
        try {
          const data = formRef.current!.getData();

          VALIDATIONS.validateSync(data);

          return data;
        } catch (error: any) {
          throw error;
        }
      },
    }),
    []
  );

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  if (isLoading) return <Loading />;

  return (
    <Form
      ref={formRef}
      initialData={instance}
      style={{ display: 'contents' }}
      abortEarly
    >
      <Container>
        <Field
          label="Nome"
          name="name"
          field={{
            type: 'text',
            componentProps: {
              InputProps: {
                inputProps: {
                  maxLength: 200,
                },
              },
            },
          }}
          required
          slot="8"
        />
        <NewBrand />
        <Field
          label="Ativo?"
          name="is_active"
          field={{
            type: 'boolean',
            isSwitch: true,
          }}
          positionLabel="right"
        />
        <Field
          label="Preço"
          name="price"
          description={
            isNew ? '' : "Altere o preço do produto na aba 'Estoque'"
          }
          field={{
            type: 'number',
            numberFormat: {
              allowNegative: false,
              decimalSeparator: ',',
              decimalScale: 2,
              fixedDecimalScale: true,
              thousandSeparator: '.',
              isNumericString: true,
              prefix: 'R$ ',
            },
            disabled: !isNew,
          }}
          required
          slot="4"
        />
        <Field
          label="Preço promocional"
          name="promotional_price"
          description={
            isNew
              ? 'O preço promocional deve ser menor que o preço'
              : "Altere o preço promocional na aba 'Estoque'"
          }
          field={{
            type: 'number',
            numberFormat: {
              allowNegative: false,
              decimalSeparator: ',',
              decimalScale: 2,
              fixedDecimalScale: true,
              thousandSeparator: '.',
              isNumericString: true,
              prefix: 'R$ ',
            },
            disabled: !isNew,
          }}
          slot="4"
        />
        <Field
          label="Dias adicionais no prazo de entrega"
          name="delivery_additional_days"
          field={{
            type: 'number',
            numberFormat: {
              allowNegative: false,
              limitOfBytes: '2',
            },
          }}
          slot="4"
        />
        {(!isSeller ? isNewWholesale : extra?.hasWholesale) && (
          <>
            <Field
              label="Preço de atacado"
              name="wholesale_price"
              description={
                isNew ? '' : "Altere o preço de atacado na aba 'Estoque'"
              }
              field={{
                type: 'number',
                numberFormat: {
                  allowNegative: false,
                  decimalSeparator: ',',
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  thousandSeparator: '.',
                  isNumericString: true,
                  prefix: 'R$ ',
                },
                disabled: !isNew,
              }}
              slot="4"
            />
            <Field
              label="Preço promocional de atacado"
              name="wholesale_promotional_price"
              description={
                isNew
                  ? 'O preço promocional de atacado deve ser menor que o preço de atacado'
                  : "Altere o preço promocional de atacado na aba 'Estoque'"
              }
              field={{
                type: 'number',
                numberFormat: {
                  allowNegative: false,
                  decimalSeparator: ',',
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  thousandSeparator: '.',
                  isNumericString: true,
                  prefix: 'R$ ',
                },
                disabled: !isNew,
              }}
              slot="4"
            />
            <Field
              label="Quantidade mínima de itens para atacado"
              name="wholesale_min_amount"
              description="Quantidade mínima de itens para ativar o preço de atacado"
              field={{
                type: 'number',
                numberFormat: {
                  allowNegative: false,
                  limitOfBytes: '4',
                },
              }}
              slot="4"
            />
          </>
        )}

        <Field
          label="Quantidade mínima de itens para venda"
          name="min_amount_for_sale"
          description="Quantidade mínima de itens para ativar a venda"
          field={{
            type: 'number',
            numberFormat: {
              allowNegative: false,
              limitOfBytes: '4',
            },
          }}
          slot="4"
        />

        <Field
          label="Link do vídeo destaque (Opcional)"
          name="productMainVideo.url"
          description="Inclua um vídeo do Youtube que será exibido como destaque após a descrição"
          field={{
            type: 'text',
            componentProps: {
              InputProps: {
                inputProps: {
                  maxLength: 200,
                },
              },
            },
          }}
          slot="8"
        />

        <Categories
          label="Categorias"
          relation="categories"
          instance={instance}
        />
        <Field
          label="Descrição"
          name="description"
          field={{
            type: 'ckeditor',
            componentProps: {
              onReady({ editing: { view } }) {
                if (view) {
                  view.change(({ setStyle, document }) => {
                    setStyle('height', '360px', document.getRoot());
                  });
                }
              },
            },
          }}
        />

        <Card className={classes.cardRoot}>
          <CardHeader
            title="SEO"
            subtitle="Configurações de SEO"
            action={
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreOutlined />
              </IconButton>
            }
            style={{
              backgroundColor: '#E5E7EB',
            }}
            titleTypographyProps={{
              variant: 'inherit',
            }}
          />

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box className={classes.boxText}>
                <Typography className={classes.titleInput}>
                  Título para SEO
                </Typography>
                <Typography className={classes.subTitle}>
                  O título para SEO deve ter no máximo 70 caracteres.
                </Typography>
                <Field
                  name="seo_title"
                  field={{
                    type: 'text',
                    componentProps: {
                      InputProps: {
                        inputProps: {
                          maxLength: 70,
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Box className={classes.boxText}>
                <Typography className={classes.titleInput}>
                  Descrição para SEO
                </Typography>
                <Typography className={classes.subTitle}>
                  A descrição para SEO deve ter no máximo 170 caracteres.
                </Typography>
                <Field
                  name="seo_description"
                  field={{
                    type: 'text',
                    componentProps: {
                      multiline: true,
                      rows: 4,
                      InputProps: {
                        inputProps: {
                          maxLength: 170,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      </Container>
    </Form>
  );
});

const VALIDATIONS = Yup.object().shape({
  name: Yup.string().required('O campo nome está vazio'),
  price: Yup.number()
    .required('O campo preço está vazio')
    .moreThan(0, 'O preço deve ser maior que zero')
    .nullable(),
  promotional_price: Yup.number()
    .when('price', (price, schema) =>
      schema.lessThan(
        price,
        'O preço promocional não pode ser maior que o preço'
      )
    )
    .moreThan(0, 'O preço promocional deve ser maior que zero')
    .nullable(),
  wholesale_promotional_price: Yup.number()
    .when('wholesale_price', (wholesale_price, schema) =>
      schema.lessThan(
        wholesale_price,
        'O preço promocional de atacado não pode ser maior que o preço'
      )
    )
    .moreThan(0, 'O preço promocional deve ser maior que zero')
    .nullable(),
  'productMainVideo.url': Yup.string().matches(
    /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:\S+)?/,
    {
      message: 'O link do vídeo deve ser do Youtube',
      excludeEmptyString: true,
    }
  ),
});
