import { useContext, ChangeEvent, useState } from 'react';

import { api } from 'core/lib/api';
import Switch from '../core/components/inputs/Switch';
import Checkbox from '../core/components/inputs/Checkbox';
import AlertContext from '../core/contexts/Alert';

export default function Switchable(props: {
    id: number;
    value: boolean;
    column: string;
    route: string;
    routeIsRoot?: true;
    isCheckbox?: true;
    permissions?: boolean;
}) {
    const {
        id,
        column,
        route,
        permissions,
        routeIsRoot = false,
        isCheckbox = false,
    } = props;

    const [value, setValue] = useState(props.value);

    const alert = useContext(AlertContext);

    async function onChange(event: ChangeEvent<HTMLInputElement>) {
        try {
            setValue(event.target.checked);

            await api.put(
                routeIsRoot ? route : `${route}/custom/${id}/${column}`,
                { [column]: event.target.checked }
            );
        } catch (error: any) {
            setTimeout(() => setValue(value), 500);

            alert.error('Não foi possivel alterar o status de ativo');
        }
    }

    if (isCheckbox) {
        return <Checkbox checked={value} onChange={onChange} />;
    }

    return (
        <Switch checked={value} onChange={onChange} disabled={permissions} />
    );
}
