import { ReactNode, forwardRef, useImperativeHandle, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface MainProps {
    children: ReactNode;
}

export interface MainRef {
    open: boolean;
    toggle(): void;
}

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
        overflowX: 'hidden',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -DRAWER_WIDTH,
    },
    contentShift: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        width: '100%',
    },
}));

export default forwardRef<MainRef, MainProps>(function (props, ref) {
    const { children } = props;
    const classes = useStyles();
    const open = useRef(true);
    const mainRef = useRef<HTMLElement>();

    useImperativeHandle(ref, () => ({
        open: open.current,
        toggle() {
            mainRef.current!.classList.toggle(classes.contentShift);
        },
    }));

    return (
        <main
            ref={mainRef as any}
            className={clsx(classes.content, classes.contentShift)}
        >
            <div className={classes.drawerHeader} />
            {children}
        </main>
    );
});
