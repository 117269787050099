import { ReactNode } from 'react';

export interface ContainerProps {
    noPadding?: boolean;
    children: ReactNode;
}

export default function Container(props: ContainerProps) {
    const { noPadding = false, children } = props;

    return (
        <div style={{ padding: noPadding ? 0 : 30 }}>
            <div
                style={{
                    width: 'calc(100% + 20px)',
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginBottom: -10,
                }}
            >
                {children}
            </div>
        </div>
    );
}
