/**
 * Get dimensions of any image by url
 * @param {string} url
 * @param {number} [rejectTimeout] timeout for reject
 * @return {Promise<{ width: number, height: number }>}
 */

const imageSize = (url, rejectTimeout = 5000) => new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve({
        width: img.width,
        height: img.height
    });
    img.onerror = () => reject(new Error('Image load error'));
    setTimeout(() => reject(new Error('Image load timeout')), rejectTimeout);
})

export default async function getImageSize(url) {
    try {
        const {
            width,
            height
        } = await imageSize(url);
        return {
            width,
            height
        };
    } catch (error) {
        console.error(error);
        return {
            width: 0,
            height: 0
        };
    }
}