import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import IDashTable from 'core/interfaces/IDashTable';
import { useState } from 'react';
import palette from '../utils/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  headerTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
  },
  headerViewMore: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#456AEF',
    cursor: 'pointer',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      '& table': {
        display: 'inline-block',
      },
    },

    overflowX: 'auto',
  },
  column: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
  },
  pointer: {
    cursor: 'pointer',
  },
  Synchronization: {
    margin: '0 !important',
  },
}));

function DashTable(props: IDashTable) {
  const classes = useStyles();
  const history = useHistory();
  const Syncronization = props.className;

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:last-child td, &:last-child th': {
        border: `${props?.pagination ? '' : 0}`,
      },
    },
  }))(TableRow);

  const [page, setPage] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rowsPerPage, setRowsPerPage] = useState(props?.rowsPerPage || 10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const handleData = (props: IDashTable) => {
    if (props.columns.length === 0) return [];

    if (props?.pagination)
      return props.rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    return props.rows;
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerTitle}>{props.title}</Typography>
        <Typography
          className={classes.headerViewMore}
          onClick={() => history.push(props.viewMore?.route || '')}
        >
          {props.viewMore?.label}
        </Typography>
      </div>

      <Paper
        className={`${classes.paper} ${
          Syncronization ? classes.Synchronization : ''
        }`}
      >
        <Table>
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell key={column} className={classes.column}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {handleData(props).map((rows, index) => (
              <StyledTableRow key={index}>
                {rows.map((cell, index) => (
                  <TableCell
                    key={index}
                    className={`${cell?.className} ${
                      cell?.clickable ? classes.pointer : null
                    }`}
                    style={{
                      color: cell?.color
                        ? palette[cell?.color] || cell?.color
                        : '',
                      fontWeight: cell?.fontWeight,
                      cursor: cell?.clickable ? 'pointer' : 'default',
                      ...cell?.style,
                    }}
                    onClick={() =>
                      cell?.clickable && history.push(cell?.route || '')
                    }
                  >
                    {cell?.icon} {cell?.value}
                  </TableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
          {props?.pagination && (
            <TableFooter>
              <tr>
                <TablePagination
                  count={props.rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={props.rowsPerPageOptions || []}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Mostrando ${from}-${to} de ${count} registros`
                  }
                />
              </tr>
            </TableFooter>
          )}
        </Table>
      </Paper>
    </div>
  );
}

export default DashTable;
