import {
    forwardRef,
    useRef,
    useState,
    useImperativeHandle,
    useContext,
} from 'react';
import { makeStyles } from '@material-ui/core';

import Button from 'core/components/Button';
import TextField from 'core/components/inputs/TextField';
import AlertContext from 'core/contexts/Alert';

export interface QuickAddProps {
    onInsert(data: Record<string, any>): void;
}

export interface QuickAddRef {
    setDisabled(disabled: boolean): void;
}

const useStyles = makeStyles((theme) => ({
    wrap: {
        backgroundColor: '#F2F2F2',
        padding: `${theme.spacing(1.5)}px 30px`,
        margin: '-10px 0 6px -30px',
        width: 'calc(100% + 60px)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    wrapCheckbox: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 30,
        '& span': {
            marginLeft: 10,
            color: '#444444',
            fontSize: theme.typography.pxToRem(15),
        },
    },
}));

export default forwardRef<QuickAddRef, QuickAddProps>(
    function QuickAddAttribute(props, ref) {
        const classes = useStyles();
        const [disabled, setDisabled] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const alert = useContext(AlertContext);

        async function onInsert() {
            if (inputRef.current) {
                if (inputRef.current.value.trim()) {
                    try {
                        await props.onInsert({ name: inputRef.current.value });

                        inputRef.current.value = '';

                        inputRef.current.focus();
                    } catch ({ response: { status } }) {
                        if (status === 409) {
                            alert.error('Este valor já foi adicionado');
                        } else {
                            alert.error('Não foi possível adicionar o valor');
                        }
                    }
                } else {
                    inputRef.current.focus();

                    alert.error('Preencha o campo informe valor');
                }
            }
        }

        useImperativeHandle(ref, () => ({
            setDisabled(disabled) {
                setDisabled(disabled);
            },
        }));

        return (
            <div className={classes.wrap}>
                <TextField
                    inputRef={inputRef}
                    placeholder="Informe o valor"
                    style={{ width: 250 }}
                    onKeyPress={(e) => e.key === 'Enter' && onInsert()}
                />
                <Button
                    children="Inserir"
                    color="#456AEF"
                    style={{ marginLeft: 20 }}
                    disabled={disabled}
                    onClick={onInsert}
                />
            </div>
        );
    }
);
