import { Page } from 'core/interfaces/page';
import ColorsFontsDetails from './ColorsFontsDetails';

const ColorsFonts: Page = {
  label: 'Cores e fonts',
  route: 'colors-fonts',
  onlyDetails: true,
  passInstance: true,
  Details: ColorsFontsDetails as any,
};

export default ColorsFonts;
