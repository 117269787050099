import { Page } from 'core/interfaces/page';
import Switchable from 'components/Switchable';
import Details from './Details';

const GroupBanner: Page = {
  label: 'Grupo de banners',
  route: 'bannergroups',
  Details,
  listProps: {
    fields: {
      name: {
        headerName: 'Nome',
      },
      is_active: {
        headerName: 'Ativo?',
        width: 100,
        renderCell: ({ row: { id, is_active } }) => (
          <Switchable
            id={id}
            value={is_active}
            column="is_active"
            route="bannergroups"
            permissions={!GroupBanner.permissions?.update}
          />
        ),
      },
    },
    limitControlled: [100, 150, 200, 250],
    dialogOnDelete:
      'Deletando este grupo de banners você ' +
      'estará deletando todos banners criados, ' +
      'deseja continuar?',
  },
};

export default GroupBanner;
