import { useState, ChangeEvent, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  InputAdornment,
  Typography,
  makeStyles,
  ButtonBase,
} from '@material-ui/core';
import { AddCircle, SearchRounded } from '@material-ui/icons';
import * as Yup from 'yup';
import expr from 'property-expr';

import TextField from 'core/components/inputs/TextField';
import Modal from 'core/components/Modal';
import Button from 'core/components/Button';
import ModalImportError from 'components/ModalImportError';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import { ExcelReader, ImportProducts } from 'classes';
import { environment } from 'environment';
import useIsSeller from 'core/lib/useIsSeller';

export interface BreadCrumbAddProps {
  onTextChange(text: string): void;
}

let interval: any = null;

export default function BreadCrumbAdd(props: BreadCrumbAddProps) {
  const { onTextChange } = props;
  const classes = useStyles();
  const history = useHistory();
  const [modalImport, setModalImport] = useState(false);
  const [modalErrorsImport, setModalErrorsImport] = useState({
    open: false,
    errors: [] as any[],
  });
  const alert = useContext(AlertContext);

  const [ isSeller ] = useIsSeller();

  const getLocalStorageSeller = localStorage.getItem('@Auth:extra') as string;
  const updateLocalStorage = JSON.parse(getLocalStorageSeller);

  const params: Record<string, any> = {};

  if (!isSeller && updateLocalStorage.seller_id !== null) {
    params.seller = updateLocalStorage.seller_id;
  }

  function setSearch(event: any) {
    clearInterval(interval);

    interval = setTimeout(() => {
      const { value: search } = event.nativeEvent.target;

      onTextChange(search);
    }, 500);
  }

  async function uploadProducts(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new ExcelReader(file);
      const rows = await reader.read();

      if (rows.length) {
        try {
          // * Remove header
          rows.shift();

          const importProducts = new ImportProducts(
            rows.map((row) => ({
              values: row.values as any[],
              number: row.number,
            }))
          );

          await api.post(
            'products/import',
            importProducts.validate().map((product) => ({
              ...product,
              product: undefined,
            })),
            { params }
          );

          alert.success(
            'Nas próximas horas estaremos processando seu pedido de importação',
            5000
          );
        } catch (error: any) {
          const errors: any[] = [];

          if (error instanceof Yup.ValidationError) {
            error.inner.forEach((err) => {
              const path = err.path!.split('.').slice(0, -1).join('.');

              errors.push({
                row: expr.getter(path)(error.value).row_excel,
                message: err.message,
              });
            });

            setModalImport(false);

            setModalErrorsImport({ open: true, errors });
          }
        }
      } else {
        alert.error('O arquivo está vazio');
      }
    }
  }

  async function downloadProducts() {
    try {
      const { data } = await api.get('products/export', {
        responseType: 'blob',
        params,
      });
      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = 'export-product.xlsx';
      a.click();
    } catch (error: any) {
      alert.error('Não foi possivel fazer a exportação dos produtos');
    }
  }

  function setSellerName() {
    if (!isSeller && updateLocalStorage.seller_id !== null) {
      const { sellerName } = updateLocalStorage;

      return `${sellerName === undefined ? '' : `${sellerName} /`} Produtos`;
    }

    return 'Produtos';
  }

  return (
    <div className={classes.breadCrumb}>
      <Typography
        variant="h1"
        noWrap
        className={classes.title}
        children={setSellerName()}
      />
      <IconButton
        onClick={() => history.push('/products/new')}
        style={{ marginLeft: 8, marginTop: 2 }}
      >
        <AddCircle style={{ color: '#456AEF' }} />
      </IconButton>
      <ButtonBase
        className={classes.importButton}
        children="Importar ou exportar tabela de produtos"
        onClick={() => setModalImport(true)}
      />
      <TextField
        placeholder="Pesquisa"
        onChange={setSearch}
        className={classes.textField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
      <Modal
        open={modalImport}
        onClose={() => setModalImport(false)}
        classes={{ paper: classes.paper }}
      >
        <>
          <Typography
            variant="h3"
            children="Tabela de produtos"
            className={classes.modalTitle}
          />
          <div style={{ width: '100%' }}>
            <input
              id="upload-template"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: 'none' }}
              onClick={(event: any) => {
                event.target.value = null;
              }}
              onChange={uploadProducts}
            />
            <label htmlFor="upload-template" style={{ display: 'contents' }}>
              <Button
                color="#456AEF"
                // @ts-ignore
                component="span"
                children="Importar"
                style={{ marginRight: 20 }}
              />
            </label>
            <Button
              color="#456AEF"
              style={{ marginRight: 20 }}
              onClick={downloadProducts}
            >
              Exportar
            </Button>
            <Button color="#456AEF" outline style={{ width: 160 }}>
              <a
                href={environment.templateLink}
                download="Template"
                children="Baixar template"
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              />
            </Button>
          </div>
        </>
      </Modal>
      <ModalImportError
        open={modalErrorsImport.open}
        title="Importar tabela de produtos"
        subtitle="Não foi possível importar a tabela devido aos problemas abaixo. Corrija os erros antes de tentar novamente."
        fileName="import-products-logs"
        errors={modalErrorsImport.errors}
        onClose={() => setModalErrorsImport({ open: false, errors: [] })}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  breadCrumb: {
    borderTop: '1px solid #DDDDDD',
    borderBottom: '1px solid #DDDDDD',
    padding: '15px 30px',
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    backgroundColor: 'white',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 22,
    margin: '12px 0',
    lineHeight: 1.3,
  },
  textField: {
    '& .MuiInputAdornment-positionStart': {
      color: '#999999',
      paddingLeft: 12,
      '& .MuiIcon-root': {
        fontSize: '1.25rem',
        color: '#999999',
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 12px 10px 0',
        '&::-webkit-input-placeholder, &::placeholder': {
          opacity: 1,
          color: '#444444',
        },
      },
    },
  },
  importButton: {
    color: '#456AEF',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    height: 35,
    padding: theme.spacing(0, 1),
    margin: `0 ${theme.spacing(1)}px 0 auto`,
    borderRadius: 4,
  },
  modalTitle: {
    fontSize: theme.typography.pxToRem(17.5),
    color: '#333333',
    borderBottom: '1px solid #DDDDDD',
    paddingBottom: theme.spacing(1.25),
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  paper: {
    width: 475,
  },
}));
