import { Page } from "core/interfaces/page";
import Switchable from 'components/Switchable';
import Details from './Details';


const Coupon: Page = {
    label: "Cupons",
    route: "coupons",
    routes: {
        find: "coupons",
        create: "coupons/custom",
        update: (id) => `coupons/custom/${id}`,
    },
    Details,
    listProps: {
        fields: {
            internal_title: {
                headerName: "Nome",
            },
            code: {
                headerName: "Código",
            },
            discount: {
                headerName: "Desconto",
            },
            start_at: {
                headerName: 'Data inicial',
                
            },
            end_at: {
                headerName: 'Data final'
            },
            is_active: {
                headerName: 'Ativo?',
                width: 100,
                renderCell: ({ row: { id, is_active } }) => (
                    <Switchable
                        id={id}
                        value={is_active}
                        column="is_active"
                        route="coupons"
                        permissions={
                            !Coupon.permissions?.update
                        }
                    />
                ),
            },
        },
        dialogOnDelete: 'Tem certeza que deseja excluir?'
    },
}


export default Coupon;