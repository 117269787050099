import { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field, { FieldRef } from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import AuthContext from 'core/contexts/Auth';
import { api } from 'core/lib/api';
import MarketplaceUrl from 'components/MarketplaceUrl';
import { slugify } from 'utils/slugify';
import { FileCopy } from '@material-ui/icons';
import disableFields from 'core/lib/disableFields';

const VALIDATIONS = {
  title: Yup.string().when('type', (type, schema) =>
    type === '0' || type === '1'
      ? schema.required('O campo título estpa vazio')
      : schema.optional()
  ),
  link: Yup.string()
    .url('URL inválida')
    .when('type', (type, schema) =>
      type === '1'
        ? schema.required('O campo URL está vazio')
        : schema.optional()
    ),
  slug_name: Yup.string().when('type', (type, schema) =>
    type === '0'
      ? schema.required('O campo URL amigável está vazio')
      : schema.optional()
  ),
  section: Yup.object().required('O campo seção vazio').nullable(),
};

export default function Details(props: any) {
  const {
    isNew,
    id,
    page: { label, route, routes, permissions },
    instance,
  } = props;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const auth = useContext(AuthContext);

  const linkRef = useRef<FieldRef>(null);
  const slugNameRef = useRef<FieldRef>(null);
  const descriptionRef = useRef<FieldRef>(null);

  const [section, setSection] = useState<any>(instance.section);

  const slugUrl = `institucional/${slugify(section?.name || '')}`

  async function onSubmit(data: Record<string, any>) {
    const {
      create: routeCreate = `${route}/custom`,
      update: routeUpdate = `${route}/custom/${id}`,
    } = routes || {};

    try {
      if (isNew) {
        data = (await api.post(routeCreate, data)).data;

        alert.success('Informações salvas com sucesso', 10000);
      } else {
        await api.put(routeUpdate, { ...data, id });

        alert.success('Alterações salvas com sucesso', 10000);
      }

      history.push(`/${route}`);
    } catch ({ request: { status } }: any) {
      let message;

      switch (status) {
        case 409:
          message = 'URL amigável já está em uso!';
          break;

        default:
          message = 'Erro no servidor';
          break;
      }

      alert.error(message);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  const copyUrl = () => {
    const marketplaceUrl = auth.extra!.marketplace?.domain || '';
    const slugName = formRef.current?.getFieldValue('slug_name');
    const url = `${marketplaceUrl}/${slugUrl}/${slugName}`.replace(/^(?:http(s)?:\/\/)?(?:www\.)?(?:\\)?/, 'https://')
  
    navigator.clipboard.writeText(url);
    alert.success('URL copiada para a área de transferência');
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions?.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Tipo de conteúdo"
            name="type"
            field={{
              type: 'radio-button',
              data: [
                { label: 'Página de texto', value: '0' },
                { label: 'Link externo', value: '1' },
              ],
              componentProps: {
                disabled: !isNew,
              },
            }}
            defaultValue="0"
            onChange={(value) => {
              linkRef.current?.setEditable(value !== '0');

              slugNameRef.current?.setEditable(value === '0');

              descriptionRef.current?.setEditable(value === '0');
            }}
          />
          <Field
            label="Título da página"
            name="title"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            onChange={(value) => {
              if (formRef.current?.getFieldValue('type') === '0') {
                formRef.current?.setFieldValue('slug_name', slugify(value));
              }
            }}
            required
            slot="6"
          />
          <Field
            label="Sequência"
            name="order"
            field={{
              type: 'number',
              numberFormat: {
                limitOfBytes: '2',
                allowNegative: false,
              },
            }}
            slot="6"
          />
          <Field
            ref={linkRef}
            label="URL"
            name="link"
            description="É necessário incluir o http:// ou https:// na url informada"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 500,
                  },
                },
              },
            }}
            editable={!isNew && instance.type === 1}
            slot="6"
            required
          />
          <Field
            ref={slugNameRef}
            label="URL amigável"
            name="slug_name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  startAdornment: (
                    <MarketplaceUrl
                      urlPath={
                        section?.name &&
                        slugUrl
                      }
                    />
                  ),
                  endAdornment: (
                    <FileCopy
                      style={{
                        cursor: 'pointer',
                        color: '#456AEF',
                        marginRight: '10px',
                        fontSize: '1.2rem',
                      }}
                      onClick={() => {
                        copyUrl();
                      }}
                    />
                  ),
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            editable={!(!isNew && instance.type === 1)}
            slot="6"
            onChange={(value) => {
              formRef.current?.setFieldValue('slug_name', slugify(value));
            }}
            required
          />
          <Field
            label="Seção"
            name="section"
            field={{
              type: 'autocomplete',
              relation: 'section',
              field: 'name',
              route: 'sectioncontents/custom/autocomplete/section',
            }}
            slot="6"
            onChange={(value) => {
              setSection(value);
            }}
            required
          />
          <Field
            label="Faça o upload do ícone (Opcional)"
            name="icon_path"
            field={{
              type: 'upload',
              accept: 'image/*',
              route: `sellers/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue('icon_path', value.url);
            }}
            slot="12"
          />
          <Field
            label="Ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
            slot={{
              sm: '6',
              xs: '12',
              md: '2',
              lg: '1',
            }}
          />
          <Field
            label="Exibir no rodapé?"
            name="show"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
            slot={{
              sm: '6',
              xs: '12',
              md: '3',
              lg: '2',
            }}
          />
          <Field
            ref={descriptionRef}
            label="Conteúdo"
            name="description"
            field={{
              type: 'ckeditor',
              componentProps: {
                onReady({ editing: { view } }) {
                  view.change(({ setStyle, document }) => {
                    setStyle('height', '200px', document.getRoot());
                  });
                },
              },
            }}
            editable={!(!isNew && instance.type === 1)}
          />
        </Container>
      </Form>
    </>
  );
}
