import { forwardRef, useContext, useEffect, useState } from 'react';

import { api } from 'core/lib/api';
import Form from 'core/components/Form';

import InputContainer from 'core/components/InputContainer';
import InputResponsive from 'core/components/InputResponsive';
import AutocompleteAsync from 'core/components/inputs/AutocompleteAsync';
import Button from 'core/components/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import { makeStyles } from '@material-ui/core/styles';
import AlertContext from 'core/contexts/Alert';

import DashTable from 'core/components/DashTable';
import IDashTable, { exampleTable } from 'core/interfaces/IDashTable';
import moment from 'moment';

export interface SynchronizationBlingrops {
    instance: Record<string, any>;
    integrated?: any;
    isNew?: boolean;
    permissions?: any;
}

export interface SynchronizationBlingRef {
    getData(): Record<string, any>;
}

export default forwardRef<SynchronizationBlingRef, SynchronizationBlingrops>(
    function Synchronization(props, ref) {
        const { instance, permissions } = props;
        const classes = useStyles();
        const alert = useContext(AlertContext);

        const [syncOptions, setSyncOptions] = useState<any>([]);
        const [selectedOption, setSelectedOption] = useState(null);
        const [dashTableProps, setDashTablesProps] =
            useState<IDashTable>(exampleTable);
        const [isLoading, setIsLoading] = useState(true);

        async function getData() {
            setIsLoading(true);

            await api.get('sellerblingsyncs/syncs').then((res) => {
                setDashTablesProps({
                    columns: [
                        'Código de sincronização',
                        'Data da criação',
                        'Tipo de sincronização',
                        'Status',
                    ],
                    rows: res.data.map((data: any) => {
                        const status = {
                            0: 'Aguardando processamento',
                            1: 'Em processamento',
                            2: 'Concluído',
                            3: 'Falha na sincronização',
                        };

                        return [
                            { value: data.id },
                            {
                                value: moment(data.created_at).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                ),
                            },
                            { value: data.auto_sync ? 'Automática' : 'Manual' },
                            {
                                value:
                                    data.status === 0
                                        ? status[0]
                                        : data.status === 1
                                        ? status[1]
                                        : data.status === 2
                                        ? status[2]
                                        : status[3],
                                color:
                                    data.status === 0
                                        ? 'primary'
                                        : data.status === 1
                                        ? 'warning'
                                        : data.status === 2
                                        ? 'success'
                                        : 'danger',
                                fontWeight: 'bold',
                            },
                        ];
                    }),
                    pagination: true,
                    rowsPerPage: 10,
                    rowsPerPageOptions: [10, 20, 30, 40, 50],
                    className: 'Synchronization',
                });

                setIsLoading(false);
            });
        }

        useEffect(() => {
            const synOptions = [
                {
                    sync_type: 0,
                    auto_sync: false,
                    name: 'Atualizar alterações recentes',
                },
                {
                    sync_type: 1,
                    auto_sync: false,
                    name: 'Forçar sincronização de todos os produtos',
                },
            ];

            setSyncOptions(synOptions);
        }, []);

        useEffect(() => {
            getData();
        }, []);

        async function onSubmit(option: any) {
            try {
                if (option !== null) {
                    const { data } = await api.post(
                        'sellerblingsyncs/create-sync-bling',
                        option
                    );

                    if (data?.status === 400) {
                        alert.warning(data.message, 10000);
                    } else {
                        alert.success(
                            'Sincronização agendada com sucesso',
                            10000
                        );
                        getData();
                    }
                }
            } catch (error) {
                alert.error(
                    'Erro ao realizar solicitação de sincronização',
                    10000
                );
            }
        }

        return (
            <>
                <Form
                    style={{ display: 'contents' }}
                    initialData={instance}
                    abortEarly
                >
                    <ContainerCustom
                        title="Sincronizar manualmente com Bling"
                        subtitle="Selecione a opção desejada e clique em sincronizar. Após a sincronização, verifique os produtos se foram sincronizados com o Bling."
                        classes={{
                            content: classes.content,
                            wrap: classes.wrap,
                        }}
                    >
                        <InputResponsive slot="5">
                            <InputContainer
                                label="Sincronização manual com o Bling"
                                required
                                classes={{ content: classes.labelBottom }}
                            >
                                <AutocompleteAsync
                                    key={syncOptions.length}
                                    options={async () => {
                                        return await syncOptions;
                                    }}
                                    getOptionSelected={(option, value) =>
                                        option.id === value.id
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_: unknown, value) => {
                                        if (value) {
                                            setSelectedOption(value);
                                        }
                                    }}
                                    delayLoad={500}
                                    loadingText="Carregando..."
                                    noOptionsText="Sem resultados"
                                    disabled={!permissions.create}
                                />
                            </InputContainer>
                        </InputResponsive>

                        <InputResponsive slot="2">
                            <Button
                                color="#456AEF"
                                // @ts-ignore
                                component="span"
                                children="Sincronizar agora"
                                style={{ marginTop: 10 }}
                                className={classes.syncButton}
                                onClick={() => {
                                    onSubmit(selectedOption);
                                }}
                            />
                        </InputResponsive>
                    </ContainerCustom>
                </Form>

                <ContainerCustom
                    title="Sincronizar manualmente com Bling"
                    subtitle="Selecione a opção desejada e clique em sincronizar. Após a sincronização, verifique os produtos se foram sincronizados com o Bling."
                    classes={{ content: classes.content, wrap: classes.wrap }}
                >
                    <div>
                        <Button
                            color="#456AEF"
                            children={
                                isLoading
                                    ? 'Atualizando dados...'
                                    : 'Atualizar dados'
                            }
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                display: 'inline-block',
                            }}
                            className={classes.syncButton}
                            onClick={() => !isLoading && getData()}
                        />
                    </div>
                    {!isLoading ? (
                        dashTableProps.rows.length > 0 ? (
                            <DashTable {...dashTableProps} />
                        ) : (
                            <div className={classes.empty}>
                                <h3>Nenhuma sincronização encontrada</h3>
                            </div>
                        )
                    ) : (
                        <div className={classes.empty}>
                            <CircularProgress />
                        </div>
                    )}
                </ContainerCustom>
            </>
        );
    }
);

const useStyles = makeStyles((theme) => ({
    content: {
        border: 'none',
    },
    wrap: {
        width: 'calc(100% + 20px)',
    },
    title: {
        marginLeft: '10px',
    },
    labelBottom: {
        alignItems: 'unset',
        flexDirection: 'column',
        marginBottom: 10,
        '& p': {
            marginBottom: '0px',
        },
    },
    empty: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    syncButton: {
        color: '#fff',
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        height: 38.1,
        padding: theme.spacing(0, 1),
        borderRadius: 4,
        width: 'auto',
    },
}));
