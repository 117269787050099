import FormControlLabelBase, {
    FormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import { createStyles, Theme, withStyles } from '@material-ui/core';

export default withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'transparent',
        },
        label: {
            color: '#444444',
            fontSize: theme.typography.pxToRem(15),
        },
    })
)((props: FormControlLabelProps) => <FormControlLabelBase {...props} />);
