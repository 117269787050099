import { useEffect, useState, useContext, useRef, ChangeEvent } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import {
  DeleteOutlineOutlined,
  AddCircleOutlineOutlined,
} from '@material-ui/icons';
import { conformToMask } from 'react-text-mask';
import { useField } from '@unform/core';
import * as Yup from 'yup';
import Excel from 'exceljs';

import Button from 'core/components/Button';
import Table, { ColDef } from 'core/components/Table';
import TextField from 'core/unform/TextField';
import NumberField from 'core/unform/NumberField';
import Form from 'core/components/Form';
import AlertContext from 'core/contexts/Alert';
import { currency } from 'utils/currency';
import ModalImportError from 'components/ModalImportError';
import NewDialog from 'core/components/NewDialog';

export interface ShippingTypeItemsProps {
  isNew: boolean;
  editable?: boolean;
  rangeType?: number;
  onSave: () => void;
}

const MASK_CEP = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
const TEST_CEP = /^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}[\d]{3}-[\d]{3}/;

const useStyles = makeStyles({
  wrapper: {
    width: 'calc(100% + 40px)',
    margin: '20px -30px 0',
    padding: `30px`,
    borderTop: '1px solid rgb(221, 221, 221)',
  },
});

function transformStringToCEP(text: string) {
  return conformToMask(text, MASK_CEP).conformedValue;
}

const VALIDATIONS = {
  UPLOAD(rangeType: number) {
    const validations = {
      start_zip_code: Yup.string()
        .required('A faixa de CEP precisa ter um <strong>CEP de</strong>')
        .matches(/^[0-9]{8}$/, {
          message: 'O campo <strong>CEP de</strong> está inválido',
        }),
      end_zip_code: Yup.string()
        .required('A faixa de CEP precisa ter um <strong>CEP até</strong>')
        .matches(/^[0-9]{8}$/, {
          message: 'O campo <strong>CEP até</strong> está inválido',
        })
        .test(
          'lessThanCep',
          'O campo <strong>CEP de</strong>, não pode ser maior que o campo <strong>CEP até</strong>',
          (end_zip_code, { parent: { start_zip_code } }) =>
            Number(start_zip_code) <= Number(end_zip_code)
        ),
      start_weight: Yup.number()
        .typeError('Apenas numeros para <strong>peso de</strong>')
        .when('end_weight', (end_weight, schema) =>
          schema.lessThan(
            end_weight,
            'O peso de não pode ser maior ou igual que o peso até'
          )
        )
        .required('O campo <strong>peso de</strong> está vazio')
        .moreThan(
          0,
          'O campo <strong>peso de</strong> deve ser maior que zero'
        ),
      end_weight: Yup.number()
        .typeError('Apenas numeros para <strong>peso até</strong>')
        .required('O campo <strong>peso até</strong> está vazio')
        .moreThan(
          0,
          'O campo <strong>peso até</strong> deve ser maior que zero'
        ),
      price: Yup.number()
        .typeError('Apenas numeros para <strong>preço</strong>')
        .required('O campo <strong>preço</strong> está vazio')
        .moreThan(0, 'O campo <strong>preço</strong> deve ser maior que zero'),
      days: undefined as any,
    };

    if (rangeType === 0) {
      validations.days = Yup.number()
        .typeError('Apenas numeros para <strong>dias</strong>')
        .required('O campo <strong>dias</strong> está vazio')
        .moreThan(0, 'O campo <strong>dias</strong> deve ser maior que zero');
    }

    return Yup.object(validations);
  },
  FORM(rangeType: number) {
    const validations = {
      start_zip_code: Yup.string()
        .required('Preencha o campo CEP de')
        .matches(TEST_CEP, { message: 'O campo CEP de está inválido' }),
      end_zip_code: Yup.string()
        .required('Preencha o campo CEP até')
        .matches(TEST_CEP, { message: 'O campo CEP até está inválido' })
        .test(
          'lessThanCep',
          'O campo CEP de, não pode ser maior que o campo CEP até',
          (end_zip_code, { parent: { start_zip_code } }) =>
            Number(start_zip_code!.replace('-', '')) <=
            Number(end_zip_code!.replace('-', ''))
        ),
      start_weight: Yup.number()
        .required('Preencha o campo peso de')
        .when('end_weight', (end_weight, schema) =>
          schema.lessThan(
            end_weight,
            'O peso de não pode ser maior ou igual que o peso até'
          )
        )
        .nullable(),
      end_weight: Yup.number().required('Preencha o campo peso até').nullable(),
      price: Yup.number().required('Preencha o campo preço').nullable(),
      days: undefined as any,
    };

    if (rangeType === 0) {
      validations.days = Yup.number()
        .required('Preencha o campo dias')
        .nullable();
    }

    return validations;
  },
};

export default function ShippingTypeItems(props: ShippingTypeItemsProps) {
  const { isNew, rangeType } = props;
  const {
    fieldName,
    defaultValue: defaultFieldValue = [],
    registerField,
  } = useField('shipping_type_items');
  const classes = useStyles();
  const formRef = useRef<any>(null);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const alert = useContext(AlertContext);
  const [data, setData] = useState<any[]>([
    {
      start_zip_code: '',
      end_zip_code: '',
      start_weight: '',
      end_weight: '',
      price: '',
      days: '',
    },
    ...defaultFieldValue,
  ]);

  const [pendingData, setPendingData] = useState<any[]>([]);
  const [modalErrorsImport, setModalErrorsImport] = useState({
    open: false,
    errors: [] as any[],
  });

  const [modalWaringDeleteAllData, setModalWaringDeleteAllData] =
    useState<boolean>(false);

  const linkTemplate =
    'https://s3.sellerfaces.com.br/prod-sf-image-assets/' +
    `${rangeType === 0 ? 'ImportFaixasCEP' : 'ImportPrazoPersonalizado'}.xlsx`;

  function onSubmit(newData: Record<string, any>) {
    newData.$created = true;

    setData((data) => [...data, newData]);
  }

  function deleteItem(id: number, index: number) {
    if (isNew) {
      data.splice(index, 1);
    } else {
      const row = data.filter((row) => row.$deleted !== true)[index];
      const trueIndex = data.indexOf(row);
      if (row.id) {
        data[trueIndex] = { id, $deleted: true };
      } else {
        data.splice(trueIndex, 1);
      }
    }
    setData((data) => [...data]);
  }

  function uploadTemplate(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];

    if (file) {
      const wb = new Excel.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);

      reader.onload = async () => {
        const buffer = reader.result;

        if (buffer) {
          const workbook = await wb.xlsx.load(buffer as any);

          try {
            const ceps: any[] = [];

            workbook.eachSheet((sheet) => {
              sheet.eachRow((row, rowIndex) => {
                if (rowIndex > 1 && row.values && Array.isArray(row.values)) {
                  const item = {
                    start_zip_code: String(row.values[1])
                      .replace(/[^0-9]/g, '')
                      .padStart(8, '0'),
                    end_zip_code: String(row.values[2])
                      .replace(/[^0-9]/g, '')
                      .padStart(8, '0'),
                    start_weight: row.values[3] as number,
                    end_weight: row.values[4] as number,
                    price: row.values[5] as number,
                    days: row.values[6] as number,
                    $$row: rowIndex,
                  };

                  ceps.push(item);
                }
              });
            });

            await Yup.array()
              .of(VALIDATIONS.UPLOAD(Number(rangeType)))
              .validate(ceps, { abortEarly: false });

            setModalWaringDeleteAllData(true);

            // setData((data) => [data[0], ...ceps]);
            setPendingData(ceps);
          } catch (error: any) {
            const errors: any[] = [];

            if (error instanceof Yup.ValidationError) {
              error.inner.forEach((err) => {
                const { path = '' } = err;
                const paths = path.split('.').slice(0, -1);

                let obj = error.value;

                for (const path of paths) {
                  if (/^\[[0-9]+\]$/.test(path)) {
                    obj =
                      obj[
                        // @ts-ignore
                        Number(path.match(/[0-9]+/)[0])
                      ];
                  } else {
                    obj = obj[path];
                  }
                }

                errors.push({
                  row: obj.$$row,
                  message: err.message,
                });
              });

              setModalErrorsImport({ open: true, errors });
            }
          }
        }
      };
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  const columns: ColDef[] = [
    {
      field: 'start_zip_code',
      headerName: 'CEP de',
      renderCell({ index, column, row }) {
        if (index === 0) {
          return (
            <TextField
              inputRef={firstInputRef}
              name="start_zip_code"
              maskFormat={{ mask: MASK_CEP }}
            />
          );
        }

        return transformStringToCEP(row[column.field]);
      },
    },
    {
      field: 'end_zip_code',
      headerName: 'CEP até',
      renderCell({ index, column, row }) {
        if (index === 0) {
          return (
            <TextField name="end_zip_code" maskFormat={{ mask: MASK_CEP }} />
          );
        }

        return transformStringToCEP(row[column.field]);
      },
    },
    {
      field: 'start_weight',
      headerName: 'Peso de (Kg)',
      renderCell({ index, column, row }) {
        if (index === 0) {
          return (
            <NumberField
              name="start_weight"
              numberFormat={{
                allowNegative: false,
                decimalSeparator: ',',
                decimalScale: 3,
                precision: 10,
                fixedDecimalScale: true,
                thousandSeparator: '.',
                isNumericString: true,
              }}
            />
          );
        }

        return currency(row[column.field], false, '000');
      },
    },
    {
      field: 'end_weight',
      headerName: 'Peso até (Kg)',
      renderCell({ index, column, row }) {
        if (index === 0) {
          return (
            <NumberField
              name="end_weight"
              numberFormat={{
                allowNegative: false,
                decimalSeparator: ',',
                decimalScale: 3,
                precision: 10,
                fixedDecimalScale: true,
                thousandSeparator: '.',
                isNumericString: true,
              }}
            />
          );
        }

        return currency(row[column.field], false, '000');
      },
    },
    {
      field: 'price',
      headerName: 'Preço (R$)',
      renderCell({ index, column, row }) {
        if (index === 0) {
          return (
            <NumberField
              name="price"
              numberFormat={{
                allowNegative: false,
                decimalSeparator: ',',
                decimalScale: 2,
                precision: 10,
                fixedDecimalScale: true,
                thousandSeparator: '.',
                isNumericString: true,
                prefix: 'R$ ',
              }}
            />
          );
        }

        return currency(row[column.field]);
      },
    },
  ];

  columns.push({
    field: 'days',
    headerName: 'Dias',
    renderCell({ index, column, row }) {
      if (index === 0) {
        return (
          <NumberField
            name="days"
            numberFormat={{
              limitOfBytes: '2',
              allowNegative: false,
            }}
          />
        );
      }

      return row[column.field];
    },
  });

  columns.push({
    field: 'edit',
    width: 50,
    renderHeader: () => <span />,
    renderCell({ index, row }) {
      if (index === 0) {
        return (
          <IconButton
            onClick={() => formRef.current!.submitForm()}
            children={<AddCircleOutlineOutlined />}
            title="Adicionar"
          />
        );
      }

      return (
        <IconButton
          onClick={() => deleteItem(row.id, index)}
          children={<DeleteOutlineOutlined />}
          title="Remover"
        />
      );
    },
  });

  useEffect(() => {
    firstInputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        getValue: () => {
          return data.slice(1);
        },
      });
    }
  }, [fieldName, registerField, data]);

  return (
    <div className={classes.wrapper}>
      <Button
        color="#456AEF"
        children={
          <a
            href={linkTemplate}
            download="Template"
            children="Baixar template"
            style={{ color: 'white', textDecoration: 'none' }}
          />
        }
        style={{ width: 'auto', margin: '0 20px 20px 0' }}
      />
      <input
        id="upload-template"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        style={{ display: 'none' }}
        onClick={(event: any) => {
          event.target.value = null;
        }}
        onChange={uploadTemplate}
      />
      <label htmlFor="upload-template" style={{ display: 'contents' }}>
        <Button
          color="#456AEF"
          children="Importar tabela"
          // @ts-ignore
          component="span"
          style={{ width: 'auto', margin: '0 0 20px 0' }}
        />
      </label>
      <Form
        abortEarly
        ref={formRef}
        onSubmit={onSubmit}
        onError={onError}
        validations={VALIDATIONS.FORM(Number(rangeType))}
      >
        <Table
          columns={columns}
          rows={data}
          paginationMode="client"
          pagination={data.length > 10 || undefined}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250, 500, 1000]}
          rowsPerPage={10}
          rowCount={data.length}
        />
      </Form>
      <ModalImportError
        open={modalErrorsImport.open}
        title="Importar tabela de ceps"
        subtitle="Não foi possível importar a tabela devido aos problemas abaixo. Corrija os erros antes de tentar novamente."
        fileName="import-ceps-logs"
        errors={modalErrorsImport.errors}
        onClose={() => setModalErrorsImport({ open: false, errors: [] })}
      />

      <NewDialog
        open={modalWaringDeleteAllData}
        title="Atenção"
        description="Essa importação erá excluir todas as faixas de cep previamente cadastradas nessa opção de frete. Tem certeza que deseja continuar?"
        onClose={() => setModalWaringDeleteAllData(false)}
        cancelButton={{
          onClick: () => setModalWaringDeleteAllData(false),
          text: 'Cancelar',
          color: '#456AEF',
          backgroundColor: '#ddd',
        }}
        confirmButton={{
          onClick: () => {
            setModalWaringDeleteAllData(false);

            pendingData[0].importedTable = true;
            setData((data) => [data[0], ...pendingData]);

            props.onSave();
          },
          text: 'Confirmar',
        }}
      />
    </div>
  );
}
