import { Page } from 'core/interfaces/page';
import Details from './Details';

const HomeBuilder: Page = {
    label: 'Editor da homepage',
    route: 'home-builder',
    routes: {
        findOne: 'marketplacearchitectureconfigs/home-builder',
    },
    onlyDetails: true,
    Details,
};

export default HomeBuilder;
