import { makeStyles, Typography, ButtonBase } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import Modal, { ModalProps } from 'core/components/Modal';

export interface ModalImportErrorProps {
    open: boolean;
    title: string;
    subtitle: string;
    fileName: string;
    errors?: any[];
    onClose: ModalProps['onClose'];
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(17.5),
        color: '#333333',
        borderBottom: '1px solid #DDDDDD',
        paddingBottom: theme.spacing(1.25),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        fontSize: theme.typography.pxToRem(14),
        color: '#333333',
        marginBottom: theme.spacing(3),
    },
    listErrors: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxHeight: 350,
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#456AEF transparent',
        '&::-webkit-scrollbar': {
            width: 10,
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#456AEF',
            borderRadius: 4,
        },
    },
    error: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '&:not(:last-of-type)': {
            marginBottom: theme.spacing(2),
        },
        '& .information': {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: theme.spacing(1.5),
            '& .row': {
                color: '#E74A4A',
                fontWeight: 'bold',
                fontSize: theme.typography.pxToRem(14),
            },
            '& .message': {
                color: '#333333',
                fontSize: theme.typography.pxToRem(13),
            },
        },
    },
    footer: {
        borderTop: '1px solid #DDDDDD',
        paddingTop: theme.spacing(4),
        marginTop: theme.spacing(3.5),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& .close': {
            width: 80,
            height: 40,
            border: '1px solid #999999',
            borderRadius: 4,
            color: '#999999',
            marginRight: theme.spacing(2.5),
            fontWeight: 'bold',
            fontSize: theme.typography.pxToRem(15),
        },
        '& .download': {
            width: 110,
            height: 40,
            border: '1px solid #456AEF',
            borderRadius: 4,
            background: '#456AEF',
            color: 'white',
            fontWeight: 'bold',
            fontSize: theme.typography.pxToRem(15),
        },
    },
}));

export default function ModalImportError(props: ModalImportErrorProps) {
    const { open, errors = [], onClose } = props;
    const classes = useStyles();

    function downloadLogs() {
        let _errors = `Erros encontrados: ${errors.length}\n\n`;

        for (let i = 0; i < errors.length; i++) {
            const error = errors[i];

            _errors += `Linha: ${error.row}\nMensagem: ${error.message.replace(
                /(<([^>]+)>)/gi,
                ''
            )}`;

            if (errors.length < i) {
                _errors += '\n\n';
            }
        }

        const blob = new Blob([_errors], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = `${props.fileName}-${Date.now()}.txt`;
        a.click();
    }

    return (
        <Modal open={open} onClose={onClose}>
            <>
                <Typography className={classes.title}>{props.title}</Typography>
                <Typography className={classes.subtitle}>
                    {props.subtitle}
                </Typography>
                <div className={classes.listErrors}>
                    {errors.map((error, index) => (
                        <div key={index} className={classes.error}>
                            <NotInterestedIcon htmlColor="#E74A4A" />
                            <div className="information">
                                <span className="row">Linha - {error.row}</span>
                                <span
                                    className="message"
                                    dangerouslySetInnerHTML={{
                                        __html: error.message,
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classes.footer}>
                    <ButtonBase
                        className="close"
                        children="Fechar"
                        onClick={(event) =>
                            onClose?.(event, 'cancelButton' as any)
                        }
                    />
                    <ButtonBase
                        className="download"
                        children="Baixar logs"
                        onClick={downloadLogs}
                    />
                </div>
            </>
        </Modal>
    );
}
