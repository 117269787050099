import { Page } from 'core/interfaces/page';
import Details from './Details';

const Home: Page = {
  label: 'Dashboard',
  route: 'dashboard',
  onlyDetails: true,
  passInstance: true,
  Details,
};

export default Home;
