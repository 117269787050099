import {
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';
import { api } from 'core/lib/api';
import InputResponsive from '../core/components/InputResponsive';
import InputContainer from '../core/components/InputContainer';
import AutocompleteAsync from '../core/components/inputs/AutocompleteAsync';

export interface SellersProps {
    label: string;
    editable?: boolean;
    required?: boolean;
}

export interface SellersRef {
    setEditable(value: boolean): void;
    getData(): Record<string, any>
    setValue(value: Record<string, any>): void;
}

const useStyles = makeStyles((theme) => ({
    labelBottom: {
        alignItems: 'unset',
        flexDirection: 'column',
        marginBottom: 10,
        '& p': {
            marginBottom: theme.spacing(1),
        },
    },
}));

export default forwardRef<SellersRef, SellersProps>(function Sellers(
    props,
    ref
) {
    const { label, required } = props;
    const classes = useStyles();
    const { fieldName, defaultValue, registerField } = useField('sellers');
    const [value, setValue] = useState<any>([]);
    const [editable, setEditable] = useState(
        props.editable || props.editable === undefined
    );
    const inputRef = useRef({ value });

    useImperativeHandle(ref, () => ({
        setEditable,
        getData: () => inputRef.current.value,
        setValue,
    }));

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                return value
                    ? value.map((value: any) => ({ id: value.id }))
                    : [];
            },
            setValue: (_, value) => {
                setValue(value ?? []);
            },
        });
    }, [fieldName, registerField, value, defaultValue]);

    if (!editable) {
        return null;
    }

    return (
        <InputResponsive slot="12">
            <InputContainer
                label={label}
                required={required}
                classes={{ content: classes.labelBottom }}
            >
                <AutocompleteAsync
                    defaultValue={defaultValue}
                    options={async () => {
                        const getSellerList = await api.get(
                            'sellers/autocomplete-custom'
                        );

                        function filterActiveSeller(data: {
                            is_active: boolean;
                        }) {
                            return data.is_active === true;
                        }

                        // Realiza o filtro nos sellers com status ativo e ordena por ordem alfabética
                        const filterSellers = getSellerList.data
                            .filter(filterActiveSeller)
                            .sort((a: { name: string }, b: { name: string }) =>
                                a.name.toLowerCase() > b.name.toLowerCase()
                                    ? 1
                                    : -1
                            );

                        return filterSellers;
                    }}
                    getOptionSelected={(option, value) =>
                        option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(_: unknown, value) => {
                        inputRef.current.value = value;

                        setValue(value);
                    }}
                    multiple
                    delayLoad={500}
                    loadingText="Carregando..."
                    noOptionsText="Sem resultados"
                />
            </InputContainer>
        </InputResponsive>
    );
});
