import { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import DeleteIcon from '@material-ui/icons/Delete';

interface InputFileProps {
  onUpload(url: string, name: string): void;
  uploadUrl: string;
  deleteUrl: string;
  value?: string;
  label?: string;
  name: string;
  required?: boolean;
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: 'absolute',
    marginLeft: 'auto',
    top: 10,
    right: 10,
    color: 'white',
    backgroundColor: '#456AEF',
    '&:hover': {
      backgroundColor: 'rgba(69, 106, 239, 0.5)',
    },
  },
}));

const UploadComponent: React.FC<InputFileProps> = ({
  label,
  value,
  name,
  onUpload,
  uploadUrl,
  deleteUrl,
  required,
}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(value || '');
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const alert = useContext(AlertContext);

  const classes = useStyles();

  useEffect(() => {
    if (selectedImage) {
      const bodyFormData = new FormData();
      bodyFormData.append('file', selectedImage);
      setUploadPercent(1);
      api
        .post(uploadUrl, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (event) => {
            if (event.total) {  // Verifique se 'event.total' não é 'undefined'
              let progress: number = Math.round((event.loaded * 100) / event.total);
              setUploadPercent(progress);
            } else {
              console.warn("event.total is undefined");
              setUploadPercent(0);  // Define um valor padrão em caso de erro
            }
            /* let progress: number = Math.round(
              (event.loaded * 100) / event.total
            ); 
            setUploadPercent(progress); */
          },
        })
        .then(function (response) {
          const { data } = response;
          alert.success('Upload realizado com sucesso.');

          setImageUrl(data.url);
          onUpload(data.url, data.name);
          setUploadPercent(0);
        })
        .catch(function (response) {
          alert.error('Erro ao fazer upload.');
          setUploadPercent(0);
        });
    }
  }, [selectedImage]);

  const deleteSelectedImage = () => {
    api
      .delete(deleteUrl)
      .then((r) => {
        setSelectedImage(null);
        setImageUrl('');
        onUpload('', '');
      })
      .catch((e) => {
        alert.error('Erro ao excluir.');
      });
  };

  return (
    <div
      style={{
        marginBottom: '0.4rem',
      }}
    >
      <input
        accept="image/*"
        type="file"
        name={name}
        id={name}
        style={{ display: 'none' }}
        onChange={(e: any) => setSelectedImage(e.target.files[0] ?? null)}
      />

      {label && (
        <Typography
          variant="h6"
          style={{
            fontWeight: 400,
            fontSize: 15,
          }}
        >
          {label}
          {required && (
            <span
              style={{
                color: 'red',
              }}
            >
              {' '}
              *
            </span>
          )}
        </Typography>
      )}

      <Box
        mt={2}
        textAlign="center"
        width="426px"
        style={{
          overflow: 'hidden',
          backgroundColor: '#f4f4f4',
          position: 'relative',
        }}
      >
        {uploadPercent === 0 ? (
          <>
            <img
              src={imageUrl || '/no-image.jpg'}
              alt={selectedImage?.name || 'default'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/no-image.jpg';
              }}
              height="100px"
            />
            {imageUrl && (
              <IconButton
                onClick={() => deleteSelectedImage()}
                size="medium"
                className={classes.iconButton}
                title="remover"
              >
                <DeleteIcon style={{ fontSize: 20 }} />
              </IconButton>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                height: '100px',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <span
                style={{
                  backgroundColor: '#eaeef4',
                  height: '8px',
                  width: '300px',
                  borderRadius: '5px',
                  display: 'flex',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#456AEF',
                    borderRadius: '5px',
                    width: `${uploadPercent}%`,
                  }}
                />
              </span>
              <span>{uploadPercent}%</span>
            </div>
          </>
        )}
      </Box>

      <Box
        textAlign="center"
        width="426px"
        style={{
          marginTop: -4,
        }}
      >
        <label htmlFor={name} style={{ width: '100%', display: 'block' }}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{
              width: '100%',
              color: '#4166ee',
              borderRadius: '0',
              backgroundColor: '#ecf0ff',
              textTransform: 'none',
              fontWeight: 600,
              boxShadow: 'none',
            }}
          >
            <CloudUploadOutlinedIcon
              style={{
                marginRight: '5px',
              }}
            />
            Selecionar Imagem
          </Button>
        </label>
      </Box>
    </div>
  );
};

export default UploadComponent;
