import {
    createContext,
    createRef,
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import AlertCore from '@material-ui/lab/Alert';

type type = 'error' | 'success' | 'info' | 'warning' | undefined;

interface Options {
    open: boolean;
    message: string;
    duration: number | null;
    type: type;
}

interface Ref {
    open(message: string, duration: number | null, type: type): void;
}

export interface AlertContextData {
    error(message: string, duration?: number | null): void;
    success(message: string, duration?: number | null): void;
    info(message: string, duration?: number | null): void;
    warning(message: string, duration?: number | null): void;
}

const AlertContext = createContext({} as AlertContextData);

const Alert = forwardRef<Ref, {}>(function (_, ref) {
    const [options, setOptions] = useState<Options>({
        open: false,
        message: '',
        duration: 3000,
        type: undefined,
    });

    function open(message: string, duration: number | null, type: type) {
        setOptions({ open: true, message, duration, type });
    }

    function close() {
        setOptions({
            open: false,
            message: '',
            duration: options.duration,
            type: options.type,
        });
    }

    useImperativeHandle(ref, () => ({
        open,
    }));

    return (
        <Snackbar
            open={options.open}
            autoHideDuration={options.duration}
            onClose={close}
        >
            <AlertCore severity={options.type} elevation={6} variant="filled">
                {options.message}
            </AlertCore>
        </Snackbar>
    );
});

export function AlertProvider({ children }: { children?: React.ReactNode }) {
    const alertRef = createRef<Ref>();

    function error(message = '', duration: number | null) {
        alertRef.current?.open(
            message,
            duration === undefined ? 3000 : duration,
            'error'
        );
    }

    function success(message = '', duration: number | null) {
        alertRef.current?.open(
            message,
            duration === undefined ? 3000 : duration,
            'success'
        );
    }

    function info(message = '', duration: number | null) {
        alertRef.current?.open(
            message,
            duration === undefined ? 3000 : duration,
            'info'
        );
    }

    function warning(message = '', duration: number | null) {
        alertRef.current?.open(
            message,
            duration === undefined ? 3000 : duration,
            'warning'
        );
    }

    return (
        <AlertContext.Provider value={{ error, success, info, warning }}>
            {children}
            <Alert ref={alertRef} />
        </AlertContext.Provider>
    );
}

export default AlertContext;
