import {
  makeStyles,
  Dialog as DialogBase,
  DialogTitle,
  DialogContent,
  Button as ButtonBase,
  DialogActions,
} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  'MuiPaper-root': {
    width: 420,
    minHeight: 241,
  },
  'MuiDialogActions-root': {
    justifyContent: 'center',
    padding: theme.spacing(2, 1),
  },
  'MuiButtonBase-root': {
    width: 100,
    border: '1px solid',
    fontWeight: 600,
    textTransform: 'unset',
  },
  'MuiDialogTitle-root': {
    fontWeight: 800,
  },
  header: {},
  icon: {},
  description: {},
  buttonCancel: {
    fontSize: 14,
    width: '240px',
    margin: '8px, 16px, 8px, 16px',
    textTransform: 'unset',
  },
  buttonConfirm: {
    fontSize: 14,
    width: '240px',
    margin: '8px, 16px, 8px, 16px',
    textTransform: 'unset',
  },
}));

interface IDialog {
  title: string;
  description: string;
  icon?: {
    name: string;
    color: string;
    backgroundColor: string;
  };
  cancelButton: {
    text: string;
    color?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    onClick(): void;
  };
  confirmButton: {
    text: string;
    color?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    onClick(): void;
  };
  open: boolean;
  onClose(): void;
}

const Dialog = (props: IDialog) => {
  const classes = useStyle();

  return (
    <>
      <DialogBase
        open={props.open}
        onClose={props.onClose}
        classes={{ paper: classes['MuiPaper-root'] }}
      >
        <DialogTitle
          classes={{
            root: classes['MuiDialogTitle-root'],
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent>
          <div className={classes?.header}>
            <div className={classes?.icon}></div>
            <div className={classes?.description}>{props.description}</div>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes['MuiDialogActions-root'] }}>
          <ButtonBase
            className={classes?.buttonCancel}
            onClick={() => props.cancelButton.onClick()}
            style={{
              color: props.cancelButton.color || 'black',
              backgroundColor: props.cancelButton.backgroundColor || '',
            }}
          >
            {props.cancelButton.text}
          </ButtonBase>
          <ButtonBase
            className={classes?.buttonConfirm}
            onClick={() => props.confirmButton.onClick()}
            style={{
              color: props.confirmButton.color || 'white',
              backgroundColor: props.confirmButton.backgroundColor || '#456AEF',
            }}
          >
            {props.confirmButton.text}
          </ButtonBase>
        </DialogActions>
      </DialogBase>
    </>
  );
};

export default Dialog;
