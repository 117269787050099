import { useContext } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ContainerCustom from 'core/toolbox/ContainerCustom';
import Field from 'core/toolbox/Field';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';

export interface SellerAccountProps {
    isNew: boolean;
    instance?: Record<string, any>;
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        alignItems: 'unset',
        flexDirection: 'column',
        '& p': {
            marginBottom: theme.spacing(1),
        },
    },
    buttonResend: {
        backgroundColor: '#456aef',
        color: 'white',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: '0.95rem',
        padding: '6px 16px',
        height: 40,
        marginTop: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#456aef80',
            transition: 'background-color 500ms linear',
        },
    },
    normalize: {
        width: 'calc(100% + 20px)',
    },
}));

export default function SellerAccount(props: SellerAccountProps) {
    const { isNew, instance: { id } = {} } = props;
    const classes = useStyles();
    const { success, error } = useContext(AlertContext);

    async function resendEmail() {
        try {
            await api.get(`sellers/${id}/resend-email`);

            success('Email enviado com sucesso');
        } catch (err) {
            error('Erro ao enviar email');
        }
    }

    return (
        <ContainerCustom
            title="Usuário"
            subtitle="Este usuário será usado para acessar esta loja."
            classes={{ wrap: classes.normalize }}
        >
            {isNew && (
                <Alert
                    severity="info"
                    style={{ width: 'calc(100% - 20px)', marginBottom: 20 }}
                >
                    Um email será enviado para criar um senha para a conta
                </Alert>
            )}
            <Field
                label="Email"
                name="user.email"
                field={{
                    type: 'text',
                    componentProps: {
                        disabled: !isNew,
                    },
                }}
                slot="6"
                required
            />
            <Field
                label="Nome"
                name="user.name"
                field={{
                    type: 'text',
                }}
                slot="6"
                required
            />
            {!isNew && (
                <Button
                    onClick={resendEmail}
                    className={classes.buttonResend}
                    children="Reenviar email"
                />
            )}
        </ContainerCustom>
    );
}
