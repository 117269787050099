import clsx from 'clsx';

import { IconButton, Typography, Icon, makeStyles } from '@material-ui/core';

import Switch from 'core/components/inputs/Switch';
import DisplayOrderCategory from './DisplayOrderCategory';

import AlertContext from 'core/contexts/Alert';
import { useContext } from 'react';

interface Props {
    index: number;
    data: any;
    childs: any[];
    group: any[];
    userCanUpdate: boolean;
    userCanDelete: boolean;
    userCanCreate: boolean;
    updateColumn(index: number, data: any, checked: boolean): void;
    onEdit(e: any, id: number): void;
    setDialog(p: { id: number; key: number; group: any[] }): void;
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        '& .MuiPaper-root': {
            padding: 20,
            '& p': {
                color: 'rgb(68, 68, 68)',
                fontWeight: 700,
                fontSize: '1.2em',
                textAlign: 'center',
                marginTop: 0,
            },
            '& button': {
                backgroundColor: '#456AEF',
                color: 'white',
                fontWeight: 700,
                width: 100,
                '&.cancel': {
                    border: '1px solid #456AEF',
                    background: 'white',
                    color: '#456AEF',
                },
                '& + button': {
                    marginLeft: '20px',
                },
            },
            '& .buttonsFooter': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
        },
    },
    closeIcon: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: '#333',
        cursor: 'pointer',
        '&:hover': {
            color: '#888',
        },
    },
    columnLabel: {
        color: '#444444',
        fontSize: '0.925rem',
        fontWeight: 600,
        marginBottom: 15,
        '& + p': {
            marginLeft: theme.spacing(1),
        },
    },
    icon: {
        color: '#999999',
        fontSize: '1rem',
        width: 'auto',
    },
}));

const TreeItemCategoryLabel: React.FC<Props> = (props) => {
    const {
        updateColumn,
        onEdit,
        setDialog,
        userCanUpdate,
        userCanDelete,
        userCanCreate,
    } = props;

    const classes = useStyles();

    const alert = useContext(AlertContext);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography
                variant="body2"
                onClick={(e) => {
                    if (userCanUpdate) {
                        onEdit(e, props.data.id);
                    } else if (userCanCreate) {
                        alert.info(
                            'Por favor clique em (+) para adicionar uma nova categoria'
                        );
                    } else {
                        alert.error(
                            'Você não tem permissão para editar esta categoria'
                        );
                    }
                }}
                style={{
                    width: '150px',
                    whiteSpace: 'nowrap',
                    color: '#456AEF',
                    fontSize: '0.925rem',
                    lineHeight: '2.75',
                }}
            >
                {props.data.name}
            </Typography>
            {!props.data.parent_id && (
                <Switch
                    defaultChecked={props.data.show_menu}
                    onChange={(_, checked) =>
                        updateColumn(
                            props.data.id as number,
                            'show_menu',
                            checked
                        )
                    }
                    disabled={!userCanUpdate}
                />
            )}

            <div>
                <DisplayOrderCategory
                    defaultValue={props.data.order}
                    id={props.data.id}
                />
                <Switch
                    defaultChecked={props.data.is_active}
                    onChange={(_, checked) =>
                        updateColumn(
                            props.data.id as number,
                            'is_active',
                            checked
                        )
                    }
                    disabled={!userCanUpdate}
                />
                <IconButton
                    onClick={(e) => onEdit(e, props.data.id)}
                    style={{ marginLeft: 22, marginRight: 12 }}
                    title="Editar"
                    disabled={!userCanUpdate}
                >
                    <Icon className={clsx('fas fa-edit', classes.icon)} />
                </IconButton>
                {
                    // @ts-ignore
                    <IconButton
                        onClick={(e) => {
                            setDialog({
                                id: props.data.id,
                                key: props.index,
                                group: props.group,
                            });

                            e.stopPropagation();
                        }}
                        title="Remover"
                        disabled={!userCanDelete}
                    >
                        <Icon className={clsx('fas fa-trash', classes.icon)} />
                    </IconButton>
                }
            </div>
        </div>
    );
};

export default TreeItemCategoryLabel;
