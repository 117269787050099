import { ReactNode } from 'react';
import { Typography, Button, makeStyles } from '@material-ui/core';

import Modal from 'core/components/Modal';
import Container from 'core/toolbox/Container';

export interface ModalBuilderProps {
    title: string;
    open: boolean;
    onClose(
        event: {},
        reason:
            | 'backdropClick'
            | 'escapeKeyDown'
            | 'confirmClick'
            | 'cancelClick'
    ): void;
    children: ReactNode;
}

export default function ModalBuilder(props: ModalBuilderProps) {
    const { title, open, onClose, children } = props;
    const classes = useStyles();

    return (
        <Modal animation="Fade" open={open} onClose={onClose}>
            <>
                <Typography
                    variant="h3"
                    children={title}
                    className={classes.title}
                />
                <Container noPadding>{children}</Container>
                <div className={classes.content}>
                    <Button
                        className={`${classes.button} cancel`}
                        onClick={() => onClose({}, 'cancelClick')}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className={classes.button}
                        onClick={() => onClose({}, 'confirmClick')}
                    >
                        Salvar
                    </Button>
                </div>
            </>
        </Modal>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(17.5),
        color: '#333333',
        borderBottom: '1px solid #DDDDDD',
        paddingBottom: theme.spacing(1.25),
        marginBottom: theme.spacing(4),
        width: '100%',
    },
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        borderTop: '1px solid #DDDDDD',
        paddingTop: theme.spacing(4),
        marginTop: theme.spacing(1.5),
    },
    button: {
        backgroundColor: '#456AEF',
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        height: 40,
        padding: '6px 16px',
        width: 100,
        '&:hover': {
            transition: 'background-color 500ms linear',
            backgroundColor: 'rgba(69, 106, 239, 0.5)',
        },
        '&.cancel': {
            border: '1px solid #456AEF',
            background: 'white',
            color: '#456AEF',
        },
        '& + button': {
            marginLeft: '20px',
        },
    },
}));
