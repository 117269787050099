import { ChangeEvent, useEffect, useState } from 'react';
import { useField } from '@unform/core';

import UploadBase, {
    UploadProps as UploadBaseProps,
} from '../components/inputs/Upload';

export interface UploadProps extends Omit<UploadBaseProps, 'name' | 'value'> {
    name: string;
    defaultValue?: string;
}

export default function Upload(props: UploadProps) {
    const { name, defaultValue = '', ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);

    function onChange(event: ChangeEvent<HTMLInputElement>, file?: File) {
        setValue(file?.name);

        restProps.onChange?.(event, file);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value ?? '';
                },
                setValue(_: unknown, value: string) {
                    setValue(value ?? '');
                },
            });
        }
    }, [fieldName, registerField, value]);

    return (
        <UploadBase
            id={name}
            name={name}
            {...restProps}
            value={value}
            onChange={onChange}
        />
    );
}
