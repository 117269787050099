import { useState } from 'react';

import Field from 'core/toolbox/Field';

export interface InstallmentProps {
    actived: boolean;
}

export default function Installment(props: InstallmentProps) {
    const [actived, setActived] = useState(props.actived);

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 20,
                width: 'calc(100% + 20px)',
                marginRight: -20,
            }}
        >
            <Field
                label="Deseja utilizar o boleto parcelado como forma de pagamento?"
                name="payment_config.boleto_installment_actived"
                onChange={(value) => {
                    setActived(value);
                }}
                field={{
                    type: 'boolean',
                    isSwitch: true,
                }}
                slot="12"
            />
            {actived && (
                <Field
                    label="Valor máximo de parcelas"
                    name="payment_config.boleto_installment_max_installment"
                    field={{
                        type: 'list',
                        data: [
                            { value: '2', label: '2 parcelas' },
                            { value: '3', label: '3 parcelas' },
                            { value: '4', label: '4 parcelas' },
                            { value: '5', label: '5 parcelas' },
                            { value: '6', label: '6 parcelas' },
                            { value: '7', label: '7 parcelas' },
                            { value: '8', label: '8 parcelas' },
                            { value: '9', label: '9 parcelas' },
                            { value: '10', label: '10 parcelas' },
                            { value: '11', label: '11 parcelas' },
                            { value: '12', label: '12 parcelas' },
                        ],
                    }}
                    slot="6"
                />
            )}
            {actived && (
                <Field
                    label="Valor mínimo do boleto"
                    name="payment_config.boleto_installment_min_value"
                    field={{
                        type: 'number',
                        numberFormat: {
                            limitOfBytes: '4',
                            allowNegative: false,
                            decimalSeparator: ',',
                            decimalScale: 0,
                            fixedDecimalScale: true,
                            thousandSeparator: '.',
                            isNumericString: true,
                            prefix: 'R$ ',
                        },
                    }}
                    slot="6"
                />
            )}
        </div>
    );
}
