import { Box, Collapse, ListItem, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

interface IProps {
  onClickHeader: () => void;
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
}

const SideBarListItem: React.FC<IProps> = ({
  onClickHeader,
  title,
  isOpen,
  children,
}) => {
  return (
    <Box>
      <ListItem
        button
        onClick={() => onClickHeader()}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #E5E7EB',
          borderTop: '1px solid #E5E7EB',
          padding: '10px 10px',
        }}
      >
        <Typography>{title}</Typography>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen}>
        <Box px="20px" mt="24px" style={{ fontSize: '14px' }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default SideBarListItem;
