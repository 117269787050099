import { useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { makeStyles, MenuItem } from '@material-ui/core';

import { api } from 'core/lib/api';
import InputContainer from '../components/InputContainer';
import InputResponsive, {
  InputResponsiveProps,
} from '../components/InputResponsive';
import { MaskedInputProps } from '../components/inputs/TextField';
import TextField, { TextFieldProps } from '../unform/TextField';
import Autocomplete, {
  AutocompleteProps as AutocompleteBaseProps,
} from '../unform/Autocomplete';
import Checkbox, { CheckboxProps } from '../unform/Checkbox';
import Switch, { SwitchProps } from '../unform/Switch';
import Upload, { UploadProps } from '../unform/Upload';
import Crop from '../unform/Crop';
import ColorPicker, { ColorPickerProps } from '../unform/ColorPicker';
import NumberField, { NumberFieldProps } from '../unform/NumberField';
import { NumberFormatProps } from '../components/inputs/NumberField';
import Select, { SelectProps } from '../unform/Select';
import Ckeditor, { CKEditorProps } from '../unform/CKEditor';
import RadioButton, { RadioGroupProps } from '../unform/RadioGroup';
import Radio from '../components/Radio';
import FormControlLabel from '../components/FormControlLabel';
import DateTimePicker, { DateTimePickerProps } from '../unform/DateTimePicker';
import DatePicker, { DatePickerProps } from '../unform/DatePicker';
import TimePicker, { TimePickerProps } from '../unform/TimePicker';
import AlertContext from 'core/contexts/Alert';

interface AutocompleteBasics {
  relation: string;
  field: string;
  foreignKey?: string;
  multiple?: true;
}

interface AutocompleteWithRoute extends AutocompleteBasics {
  type: 'autocomplete';
  route?: string | (() => string);
  params?: never;
  componentProps?: AutocompleteBaseProps;
}

interface AutocompleteWithParams extends AutocompleteBasics {
  type: 'autocomplete';
  route?: never;
  params?: { [key: string]: any };
  componentProps?: AutocompleteBaseProps;
}

type FieldBaseAutocomplete = AutocompleteWithRoute | AutocompleteWithParams;

interface FieldBaseColor {
  type: 'color';
  componentProps?: Omit<
    ColorPickerProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
}

interface FieldBaseText {
  type: 'text';
  componentProps?: Omit<
    TextFieldProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
  maskFormat?: MaskedInputProps;
  disabled?: boolean;
  style?: React.CSSProperties;
}

interface FieldBaseDateTime {
  type: 'datetime';
  componentProps?: Omit<
    DateTimePickerProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
  disabled?: boolean;
  format?: string;
}

interface FieldBaseDate {
  type: 'date';
  componentProps?: Omit<
    DatePickerProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
  disabled?: boolean;
  format?: string;
}

interface FieldBaseTime {
  type: 'time';
  componentProps?: Omit<
    TimePickerProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
  disabled?: boolean;
  format?: string;
}

interface FieldBaseBoolean {
  type: 'boolean';
  componentProps?: Omit<
    CheckboxProps | SwitchProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
  isSwitch?: true;
  disabled?: boolean;
}

interface FieldBaseUpload {
  type: 'upload';
  accept?: string;
  crop?: {
    size: {
      width: number;
      height: number;
    };
    showGrid?: boolean;
  };
  route?: string;
  maxSize?: {
    error: string;
    mb: number;
  };
  componentProps?: Omit<UploadProps, 'name' | 'defaultValue'>;
  loading?: boolean;
}

interface FieldBaseNumber {
  type: 'number';
  componentProps?: Omit<
    NumberFieldProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
  numberFormat?: NumberFormatProps;
  disabled?: boolean;
}

interface FieldBaseList {
  type: 'list';
  componentProps?: Omit<
    SelectProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
  data: Array<{ value: string; label: string; disabled?: true }>;
}

interface FieldBaseCkEditor {
  type: 'ckeditor';
  componentProps?: Omit<
    CKEditorProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  >;
}

interface FieldBaseRadioButton {
  type: 'radio-button';
  data: Array<{ value: string; label: string; disabled?: true }>;
  componentProps?: Omit<
    RadioGroupProps,
    'name' | 'defaultValue' | 'value' | 'onChange'
  > & { disabled?: boolean };
}

interface FieldBaseCustom {
  type: 'custom';
  component: any;
}

export interface FieldProps {
  label?: string;
  name: string;
  field:
    | FieldBaseColor
    | FieldBaseText
    | FieldBaseDateTime
    | FieldBaseDate
    | FieldBaseTime
    | FieldBaseAutocomplete
    | FieldBaseBoolean
    | FieldBaseUpload
    | FieldBaseNumber
    | FieldBaseList
    | FieldBaseCkEditor
    | FieldBaseRadioButton
    | FieldBaseCustom;
  editable?: boolean;
  defaultValue?: any;
  onChange?(value: any): void;
  required?: boolean;
  description?: string;
  slot?: InputResponsiveProps['slot'];
  positionLabel?: 'right' | 'left' | 'bottom';
}

export interface FieldRef {
  setEditable(value: boolean): void;
}

const useStyles = makeStyles((theme) => ({
  labelRight: {
    '& p': {
      marginRight: theme.spacing(1.5),
    },
  },
  labelLeft: {
    '& p': {
      marginLeft: theme.spacing(1.5),
    },
  },
  labelBottom: {
    alignItems: 'unset',
    flexDirection: 'column',
    '& p': {
      marginBottom: theme.spacing(1),
    },
    '& .MuiInputBase-input.Mui-disabled': {
      cursor: 'no-drop',
    },
  },
}));

function FieldType(props: {
  name: string;
  defaultValue: any;
  onChange?(value: any): void;
  field: FieldProps['field'];
}) {
  const { name, field, defaultValue, onChange } = props;
  const [loading, setLoading] = useState(false);

  const alert = useContext(AlertContext);

  async function onChangeFile(route: string, file?: File | Blob) {
    if (file) {
      const formData = new FormData();

      formData.append('file', file);

      setLoading(true);

      try {
        const { data } = await api.post(
          route || 'upload/uploads/upload',
          formData
        );

        onChange?.(data);
      } catch {
        alert.error('Erro ao enviar arquivo');
      }

      setLoading(false);
    }
  }

  switch (field.type) {
    case 'color': {
      return (
        <ColorPicker
          {...field.componentProps}
          defaultValue={defaultValue}
          name={name}
          onChange={({ target: { value } }) => {
            onChange?.(value);
          }}
        />
      );
    }

    case 'text': {
      if (name === '0.sku') {
        return (
          <TextField
            {...field.componentProps}
            type={field.type}
            maskFormat={field.maskFormat}
            defaultValue={defaultValue}
            name={name}
            onChange={({ target: { value } }) => {
              onChange?.(value);
            }}
            disabled
            style={{ background: '#F2F2F2' }}
          />
        );
      }

      return (
        <TextField
          {...field.componentProps}
          type={field.type}
          maskFormat={field.maskFormat}
          defaultValue={defaultValue}
          name={name}
          onChange={({ target: { value } }) => {
            onChange?.(value);
          }}
          disabled={field.disabled}
          style={field.style}
        />
      );
    }

    case 'datetime': {
      return (
        <DateTimePicker
          {...field.componentProps}
          defaultValue={defaultValue}
          name={name}
          onChange={(value) => {
            onChange?.(value);
          }}
          disabled={field.disabled}
          format={field.format}
        />
      );
    }

    case 'date': {
      return (
        <DatePicker
          {...field.componentProps}
          defaultValue={defaultValue}
          name={name}
          onChange={(value) => {
            onChange?.(value);
          }}
          disabled={field.disabled}
          format={field.format}
        />
      );
    }

    case 'time': {
      return (
        <TimePicker
          {...field.componentProps}
          defaultValue={defaultValue}
          name={name}
          onChange={(value) => {
            onChange?.(value);
          }}
          disabled={field.disabled}
          format={field.format}
        />
      );
    }

    case 'autocomplete': {
      const { field: label } = field;

      return (
        <Autocomplete
          options={async () => {
            const params = field.params || {};
            const route =
              typeof field.route === 'string'
                ? field.route
                : field.route?.() || '';

            return (await api.get(route, { params })).data.sort(
              (a: { name: string }, b: { name: string }) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            );
          }}
          getOptionDisabled={(option) => option.disabled === true}
          getOptionSelected={(option, value) => option[label] === value[label]}
          getOptionLabel={(option) =>
            typeof option === 'object' ? option[label] : option
          }
          {...field.componentProps}
          multiple={field.multiple}
          name={name}
          defaultValue={defaultValue}
          onChange={(_: unknown, value) => {
            onChange?.(value);
          }}
        />
      );
    }

    case 'boolean': {
      if (field.isSwitch) {
        return (
          <Switch
            {...field.componentProps}
            name={name}
            defaultChecked={defaultValue}
            onChange={({ target: { checked: value } }) => {
              onChange?.(value);
            }}
            disabled={field.disabled}
          />
        );
      }

      return (
        <Checkbox
          {...field.componentProps}
          name={name}
          defaultChecked={defaultValue}
          onChange={({ target: { checked: value } }) => {
            onChange?.(value);
          }}
        />
      );
    }

    case 'upload': {
      if (field.crop) {
        return (
          <Crop
            id={name}
            name={name}
            accept={field.accept}
            crop={field.crop}
            {...field.componentProps}
            defaultValue={defaultValue}
            onChange={async (_: unknown, file) =>
              onChangeFile(field.route || '', file)
            }
            loading={field?.loading || loading}
          />
        );
      }

      return (
        <Upload
          id={name}
          name={name}
          accept={field.accept}
          {...field.componentProps}
          defaultValue={defaultValue as any}
          onChange={async (_: unknown, file) =>
            onChangeFile(field.route || '', file)
          }
          loading={field?.loading || loading}
        />
      );
    }

    case 'number': {
      return (
        <NumberField
          numberFormat={field.numberFormat}
          disabled={field.disabled}
          {...field.componentProps}
          name={name}
          defaultValue={defaultValue}
          onChange={({ target: { value } }) => {
            onChange?.(value);
          }}
        />
      );
    }

    case 'list': {
      return (
        <Select
          children={field.data.map((option) => (
            <MenuItem
              key={String(option.value)}
              value={option.value}
              children={option.label}
              disabled={option.disabled}
            />
          ))}
          {...field.componentProps}
          name={name}
          defaultValue={defaultValue}
          onChange={({ target: { value } }) => {
            onChange?.(value);
          }}
        />
      );
    }

    case 'ckeditor': {
      return (
        <Ckeditor
          config={{
            toolbar: {
              items: [
                'bold',
                'italic',
                'strikethrough',
                'link',
                'bulletedList',
                'numberedList',
                'fontFamily',
                'fontColor',
                'fontSize',
                'underline',
                'blockQuote',
                'removeFormat',
                '|',
                'alignment',
                'outdent',
                'indent',
                '|',
                'insertTable',
                'insertImage',
                '|',
                'htmlEmbed',
                'sourceEditing',
                'pageBreak',
                'horizontalLine',
                '|',
                'findAndReplace',
                'undo',
                'redo',
              ],
            },
            image: {
              toolbar: [
                'imageTextAlternative',
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:side',
                'linkImage',
              ],
            },
            mediaEmbed: {
              toolbar: ['mediaEmbed'],
            },
            table: {
              contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties',
              ],
            },
            fontSize: {
              options: [9, 11, 13, 'Padrão', 17, 19, 21],
            },
            language: 'pt-br',
          }}
          {...field.componentProps}
          name={name}
          defaultValue={defaultValue}
          onChange={(_, editor) => {
            const value = editor.getData();

            onChange?.(value);
          }}
        />
      );
    }

    case 'radio-button': {
      const { disabled = false, ...componentProps } =
        field.componentProps || {};

      return (
        <RadioButton
          style={{ flexDirection: 'row' }}
          children={field.data.map((radio, index) => (
            <FormControlLabel
              key={index}
              value={radio.value}
              disabled={radio.disabled}
              label={radio.label}
              control={<Radio disabled={disabled} />}
            />
          ))}
          {...componentProps}
          name={name}
          defaultValue={defaultValue}
          onChange={(_, value) => {
            onChange?.(value);
          }}
        />
      );
    }
  }
}

export default forwardRef<FieldRef, FieldProps>(function Field(props, ref) {
  const {
    label,
    name,
    field,
    required = false,
    description,
    slot = '12',
    positionLabel = 'bottom',
  } = props;
  const classes = useStyles();
  const position =
    positionLabel === 'bottom'
      ? 'labelBottom'
      : positionLabel === 'left'
      ? 'labelLeft'
      : 'labelRight';
  const [editable, setEditable] = useState(
    props.editable || props.editable === undefined
  );

  useImperativeHandle(ref, () => ({
    setEditable,
  }));

  return (
    <>
      {editable &&
        (field.type === 'custom' ? (
          field.component
        ) : (
          <InputResponsive slot={slot}>
            <InputContainer
              label={label}
              required={required}
              description={description}
              classes={{ content: classes[position] }}
              positionLabel={positionLabel === 'left' ? 'after' : 'before'}
            >
              {/*
                                // @ts-ignore */}
              <FieldType
                name={name}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                field={field}
              />
            </InputContainer>
          </InputResponsive>
        ))}
    </>
  );
});
