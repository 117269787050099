import { ReactNode } from 'react';
import {
  Button,
  Dialog as DialogBase,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { DialogProps as DialogPropsBase } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

export interface DialogProps {
  open: DialogPropsBase['open'];
  onClose?(
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'confirmClick' | 'cancelClick'
  ): void;
  title: ReactNode;
  subtitle: ReactNode;
  cancelTitle?: string;
  confirmTitle?: string;
}

const useStyles = makeStyles((theme) => ({
  'MuiPaper-root': {
    width: 420,
  },
  'MuiDialogActions-root': {
    justifyContent: 'center',
    '& *': {
      margin: '0 30px',
    },
    padding: '18px 24px',
  },
  'MuiButtonBase-root': {
    border: '1px solid',
    fontWeight: 600,
    textTransform: 'unset',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',

    color: '#0F172A',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',

    color: '#0F172A',
  },
  buttonCancel: {
    color: '#456AEF',
    border: 'none',
  },
  buttonCopy: {
    borderColor: '#F22B24',
    background: '#F22B24',
    whiteSpace: 'nowrap',
    color: 'white',
    '&:hover': {
      background: '#F22B24',
      opacity: 0.8,
      color: 'white',
    },
  },
  deleteIcon: {
    color: '#F22B24',
    backgroundColor: '#fff1f1',
    borderRadius: '50%',
    padding: '4px',
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  closeIcon: {
    marginLeft: 'auto',
    color: '#0F172A',
    padding: '0px',
    margin: '0px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const DialogText = DialogContentText;

export default function ModalQuestion(props: DialogProps) {
  const {
    open,
    onClose,
    title,
    subtitle,
    cancelTitle = 'Cancelar',
    confirmTitle = 'Continuar',
  } = props;
  const classes = useStyles();

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      classes={{ paper: classes['MuiPaper-root'] }}
    >
      <DialogTitle>
        <Box display="flex">
          <DeleteIcon className={classes.deleteIcon} />
          <Typography className={classes.title}>{title}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={(e) => onClose?.(e, 'cancelClick')}
          />
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography className={classes.subtitle}>{subtitle}</Typography>

        <DialogText
          children="Esta ação não poderá ser desfeita."
          style={{ color: '#d60606', fontSize: 14 }}
        />
      </DialogContent>

      <DialogActions classes={{ root: classes['MuiDialogActions-root'] }}>
        <Button
          variant="text"
          className={classes.buttonCancel}
          classes={{ root: classes['MuiButtonBase-root'] }}
          onClick={(e) => onClose?.(e, 'cancelClick')}
          disableRipple
        >
          {cancelTitle}
        </Button>
        <Button
          className={classes.buttonCopy}
          classes={{ root: classes['MuiButtonBase-root'] }}
          onClick={(e) => onClose?.(e, 'confirmClick')}
        >
          {confirmTitle}
        </Button>
      </DialogActions>
    </DialogBase>
  );
}
