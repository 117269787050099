import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  useContext,
  ReactEventHandler,
} from 'react';
import { useField } from '@unform/core';
import {
  Button,
  Modal,
  Fade,
  makeStyles,
  Typography,
  Box,
} from '@material-ui/core';
import { Subject } from 'rxjs';

import Upload, {
  UploadProps as UploadBaseProps,
  UploadRef as UploadBaseRef,
} from '../components/inputs/Upload';
import { getCroppedImg2 } from '../utils/cropImage';

import ReactCrop, { Crop as ReactCropType } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import AlertContext from 'core/contexts/Alert';
import CloseIcon from '@material-ui/icons/Close';

export interface UploadProps extends Omit<UploadBaseProps, 'name' | 'value'> {
  name: string;
  defaultValue?: string;
  crop: {
    size: {
      width: number;
      height: number;
    };
    showGrid?: boolean;
  };
  onChange?(event: ChangeEvent<HTMLInputElement>, file?: File | Blob): void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '21px 36px 25px 36px',
    borderRadius: 8,
    outline: 'unset',
    maxWidth: '90%',
    maxHeight: '90%',
  },
  cropWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  cancelButton: {
    backgroundColor: 'transparent',
    border: '1px solid #456AEF',
    color: '#456AEF',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    marginRight: '24px',
    width: 200,
    height: 40,
  },
  cropButton: {
    backgroundColor: '#456AEF',
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),

    width: 254,
    height: 40,
    '&:hover': {
      backgroundColor: '#3f51b5',
    },
  },
}));

export default function Crop(props: UploadProps) {
  const { name, defaultValue = '', ...restProps } = props;
  const classes = useStyles();

  const {
    fieldName,
    defaultValue: defaultFieldValue,
    registerField,
  } = useField(name);

  const [value, setValue] = useState(defaultFieldValue ?? defaultValue);
  const [modalCrop, setModalCrop] = useState({ open: false, src: '' });

  const [crop, setCrop] = useState<ReactCropType>({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const awaitSlice = useRef<Subject<Blob>>();
  const cropRef = useRef<any>();
  const uploadRef = useRef<UploadBaseRef>();
  const alert = useContext(AlertContext);
  const imageRef = useRef<HTMLImageElement>(null);

  function onChange(event: ChangeEvent<HTMLInputElement>, file?: File) {
    if (file) {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = (e: any) => {
        const image = new Image();

        image.src = String(e.target.result);

        image.onload = async (ev) => {
          onImageLoad(ev as any);
          awaitSlice.current = new Subject();

          setModalCrop({ open: true, src: image.src });

          awaitSlice.current.subscribe((blob) => {
            awaitSlice.current!.complete();

            setValue(file?.name);

            setModalCrop({ open: false, src: '' });

            setCrop(crop);

            setCroppedAreaPixels(null);

            restProps.onChange?.(event, blob);
          });
        };
      };
    }
  }

  const onImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    const image = e.currentTarget;
    if (!image) return;

    const width = image.naturalWidth;
    const height = image.naturalHeight;

    setCrop({
      unit: 'px',
      width: width >= 200 ? 200 : width,
      height: height >= 208 ? 208 : height,

      x: 0,
      y: 0,
    });
  };

  function onClose() {
    uploadRef.current?.setFile(undefined);

    setModalCrop({ open: false, src: '' });

    setCrop(crop);

    setCroppedAreaPixels(null);
  }

  function onCropChange(crop: ReactCropType) {
    setCrop(crop);
  }

  function onCropComplete(crop: any, _: any) {
    setCroppedAreaPixels(crop);
  }

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        getValue() {
          return value ?? '';
        },
        setValue(_: unknown, value: string) {
          setValue(value ?? '');
        },
      });
    }

    return () => {
      awaitSlice.current?.unsubscribe();
    };
  }, [fieldName, registerField, value]);

  return (
    <>
      <Box>
        <Upload
          // @ts-ignore
          ref={uploadRef}
          id={name}
          name={name}
          {...restProps}
          value={value as any}
          onClick={(e) => {
            // @ts-ignore
            e.target.value = '';
          }}
          onChange={onChange}
        />
      </Box>
      <Modal open={modalCrop.open} className={classes.modal} onClose={onClose}>
        <Fade in={modalCrop.open}>
          <Box className={classes.modalWrapper}>
            <Box
              style={{
                fontSize: '16px',
                textAlign: 'left',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography style={{ fontWeight: 600, fontSize: '16px' }}>
                  Ajuste o enquadramento desejado.
                </Typography>
                <Button>
                  <CloseIcon style={{ color: '#456AEF' }} />
                </Button>
              </Box>
              <Typography style={{ fontSize: '14px', marginTop: '4px' }}>
                A imagem deve ser quadrada e tamanho de 200x208 pixels
              </Typography>
              <Typography
                style={{ fontSize: '14px', fontWeight: 600, margin: '16px 0' }}
              >
                Área selecionada: {crop.width.toFixed(2)} x{' '}
                {crop.height.toFixed(2)} pixels.
              </Typography>
            </Box>
            <Box className={classes.cropWrapper}>
              <ReactCrop
                ref={cropRef}
                crop={crop}
                onChange={onCropChange}
                onComplete={onCropComplete}
              >
                <Box justifyContent="center" display="flex">
                  <img
                    src={modalCrop.src}
                    ref={imageRef}
                    style={{
                      maxHeight: '50vh',
                    }}
                    alt="preview"
                  />
                </Box>
              </ReactCrop>
            </Box>

            <Box
              display="flex"
              width="full"
              justifyContent="center"
              paddingTop="24px"
            >
              <Button
                className={classes.cancelButton}
                children="Cancelar"
                onClick={onClose}
              />
              <Button
                className={classes.cropButton}
                children="Salvar Imagem"
                onClick={async () => {
                  if (croppedAreaPixels !== null && imageRef.current) {
                    const croppedImage = await getCroppedImg2(
                      imageRef.current,
                      croppedAreaPixels
                    );

                    if (!croppedImage) {
                      alert.error('Erro ao cortar imagem');
                      return;
                    }

                    awaitSlice.current!.next(croppedImage);
                  } else {
                    alert.warning('Selecione uma área para cortar.');
                  }
                }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
