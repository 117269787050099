import { useRef, useContext, FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
} from '@material-ui/core';
import { api } from 'core/lib/api';
import AlertContext from '../core/contexts/Alert';

import { Form } from '../utils/validator';
import { environment } from '../environment';
import useMarketplaceDetails from 'core/lib/useMarketplaceDetails';
import Loading from 'core/components/Loading';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        height: 'fit-content',
    },
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formLogin: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    buttonSend: {
        margin: theme.spacing(3, 0, 1.5),
    },
    backPage: {
        background: 'transparent',
        boxShadow: 'none',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.54)',
        textDecoration: 'none',
    }
}));

const validations = new Form(null, {
    email: {
        required: 'Preencha o campo de email',
        email: 'Preencha com um email válido',
    },
});

export default function () {
    const classes = useStyles();
    const history = useHistory();
    const alert = useContext(AlertContext);
    const refs = { email: useRef<HTMLInputElement>() };

    async function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        try {
            const instance = {
                email: refs.email.current?.value,
            };

            validations.resetData(instance);

            const userValidation = await validations.isValid();

            if (userValidation?.error) {
                throw userValidation?.message;
            }

            const { data } = await api.post('users/forgot-password', instance);

            if (data.sendEmail) {
                history.push('/');

                alert.success(
                    'Email com a requisição de troca de senha enviado'
                );
            } else {
                throw String('Erro ao enviar email');
            }
        } catch (error: any) {
            let message = '';

            if (error.response) {
                switch (error.response.status) {
                    case 401: {
                        const { message: code } = error.response.data.error;

                        switch (code) {
                            case 'MARKETPLACE_WRONG':
                                message =
                                    'Essa conta não pertence a esse marketplace';
                                break;

                            default:
                                message = 'Erro ao enviar email';
                                break;
                        }

                        break;
                    }

                    case 404:
                        message = 'O email digitado não foi encontrado';
                        break;

                    default:
                        message = 'Erro no servidor por favor tente mais tarde';
                        break;
                }
            } else {
                message = String(error);
            }

            alert.error(message);
        }
    }

    const {data: details, isLoading} = useMarketplaceDetails();

    if (isLoading) return <Loading />;

    return (
        <Container component="main" maxWidth="xs" className={classes.main}>
            <div className={classes.containerWrapper}>
                <img
                    alt={details.name}
                    title={details.name}
                    src={details.logo_path}
                    style={{ width: '180px', marginBottom: 24 }}
                />
                <form
                    className={classes.formLogin}
                    noValidate
                    onSubmit={onSubmit}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email"
                        id="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        inputRef={refs.email}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        children="Enviar"
                        className={classes.buttonSend}
                    />
                    <Link
                        to="/"
                        className={classes.backPage}
                        children="Voltar para o login"
                    />
                </form>
            </div>
            <Box mt={8}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                >
                    {`Todos os Direitos Reservados © ${new Date().getFullYear()}`}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ marginTop: 10, fontWeight: 600 }}
                >
                    Versão {environment.version}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ marginTop: 10, fontWeight: 600 }}
                >
                    Versão API {details.version}
                </Typography>
            </Box>
        </Container>
    );
}
