import axios from 'axios';

export default async function (cep: string) {
    const URL = `https://viacep.com.br/ws/${cep}/json/`;

    try {
        return (await axios.get(URL)).data;
    } catch (error: any) {
        return null;
    }
}
