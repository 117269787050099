import * as Yup from 'yup';

interface Product {
    values: any[];
    number: number;
}

export class ImportProducts {
    constructor(private readonly products: Product[]) {}

    private getImages(images: any[]) {
        return images
            .map((image) => (typeof image === 'object' ? image.text : image))
            .filter((image) => image)
            .join(';');
    }

    public validate() {
        let product: Record<string, any>;

        const products = this.products.map((_product) => {
            if (_product.values[2] === 'P') {
                product = _product.values;
            }

            return {
                product_code:
                    _product.values[1] === undefined
                        ? null
                        : String(_product.values[1]),
                identifier: String(_product.values[2]).toUpperCase(),
                sku_code: _product.values[3],
                product_name: _product.values[4],
                is_active: String(_product.values[5]).toLowerCase() === 'sim',
                brand: _product.values[6],
                variation1: _product.values[7],
                variation2: _product.values[8],
                variation3: _product.values[9],
                categories: _product.values[10],
                inventory: _product.values[11],
                price: _product.values[12],
                promotional_price: _product.values[13],
                wholesale_price: _product.values[14],
                wholesale_promotional_price: _product.values[15],
                wholesale_min_amount:
                    _product.values[16] === undefined
                        ? null
                        : Number(_product.values[16]),
                weight_kg: _product.values[17],
                height_cm: _product.values[18],
                width_cm: _product.values[19],
                length_cm: _product.values[20],
                delivery_additional_days: _product.values[21],
                description: _product.values[22],
                images_url: this.getImages([
                    _product.values[23],
                    _product.values[24],
                    _product.values[25],
                    _product.values[26],
                    _product.values[27],
                ]),
                product,
                row_excel: _product.number,
            };
        });

        SCHEMA.validateSync(products, { abortEarly: false });

        return products;
    }
}

const SCHEMA = Yup.array().of(
    Yup.object().shape({
        identifier: Yup.string()
            .required('O produto precisa ter um <strong>identificador</strong>')
            .matches(/^P|V$/, {
                message:
                    'O identificador precisa ser <strong>P</strong> para produto ou <strong>V</strong> para variação',
            }),
        sku_code: Yup.string().optional(),
        product_name: Yup.string().when('identifier', {
            is: 'P',
            then: Yup.string().required(
                'O produto precisa ter um <strong>nome</strong>'
            ),
        }),
        is_active: Yup.boolean().optional(),
        brand: Yup.string().optional(),
        variationValue1: Yup.string().test(
            'verifyVariation',
            'Valor de variação requer variação',
            (variationValue, { parent }) =>
                variationValue && parent.identifier === 'V'
                    ? parent.product.values[7]
                    : true
        ),
        variationValue2: Yup.string().test(
            'verifyVariation',
            'Valor de variação requer variação',
            (variationValue, { parent }) =>
                variationValue && parent.identifier === 'V'
                    ? parent.product.values[8]
                    : true
        ),
        variationValue3: Yup.string().test(
            'verifyVariation',
            'Valor de variação requer variação',
            (variationValue, { parent }) =>
                variationValue && parent.identifier === 'V'
                    ? parent.product.values[9]
                    : true
        ),
        inventory: Yup.number()
            .typeError('Apenas numeros para <strong>estoque</strong>')
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>preço</strong>'
            )
            .integer(
                'Apenas valores inteiros para <strong>comprimento</strong>'
            ),
        price: Yup.number()
            .typeError('Apenas numeros para <strong>preço</strong>')
            .when('identifier', {
                is: 'P',
                then: Yup.number().required(
                    'O produto precisa ter um <strong>preço</strong>'
                ),
            })
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>preço</strong>'
            ),
        promotional_price: Yup.number()
            .typeError('Apenas numeros para <strong>preço promocional</strong>')
            .when('price', (price, schema) =>
                schema.lessThan(
                    price,
                    'O preço promocional não pode ser maior que o preço'
                )
            ),
        wholesale_price: Yup.number()
            .typeError('Apenas numeros para <strong>preço de atacado</strong>')
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>preço de atacado</strong>'
            ),
        wholesale_promotional_price: Yup.number()
            .typeError(
                'Apenas numeros para <strong>preço promocional de atacado</strong>'
            )
            .when('wholesale_price', (wholesale_price, schema) =>
                schema.lessThan(
                    wholesale_price,
                    'O preço promocional de atacado não pode ser maior que o preço de atacado'
                )
            )
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>preço promocional de atacado</strong>'
            ),
        wholesale_min_amount: Yup.number()
            .typeError(
                'Apenas numeros para <strong>quantidade mínima de itens</strong>'
            )
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>quantidade mínima de itens</strong>'
            )
            .nullable(),
        weight_kg: Yup.number()
            .typeError('Apenas numeros para <strong>peso</strong>')
            .when('identifier', {
                is: 'P',
                then: Yup.number().required(
                    'O produto precisa ter um <strong>peso em kg</strong>'
                ),
            })
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>peso</strong>'
            ),
        height_cm: Yup.number()
            .typeError('Apenas numeros para <strong>altura</strong>')
            .when('identifier', {
                is: 'P',
                then: Yup.number().required(
                    'O produto precisa ter uma <strong>altura em cm</strong>'
                ),
            })
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>altura</strong>'
            )
            .integer('Apenas valores inteiros para <strong>altura</strong>'),
        width_cm: Yup.number()
            .typeError('Apenas numeros para <strong>largura</strong>')
            .when('identifier', {
                is: 'P',
                then: Yup.number().required(
                    'O produto precisa ter uma <strong>largura em cm</strong>'
                ),
            })
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>largura</strong>'
            )
            .integer('Apenas valores inteiros para <strong>largura</strong>'),
        length_cm: Yup.number()
            .typeError('Apenas numeros para <strong>comprimento</strong>')
            .when('identifier', {
                is: 'P',
                then: Yup.number().required(
                    'O produto precisa ter um <strong>comprimento em cm</strong>'
                ),
            })
            .moreThan(
                -1,
                'Apenas valores positivos para <strong>comprimento</strong>'
            )
            .integer(
                'Apenas valores inteiros para <strong>comprimento</strong>'
            ),
    })
);
