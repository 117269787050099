import ModalCore, {
    ModalProps as ModalBaseProps,
} from '@material-ui/core/Modal';
import { Fade, Grow, Slide, Zoom, makeStyles } from '@material-ui/core';

type AnimationsType = 'Fade' | 'Grow' | 'Slide' | 'Zoom';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        borderRadius: 4,
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        outline: 'unset',
        alignItems: 'center',
        position: 'relative',
        width: 450,
    },
}));

export interface ModalProps extends Omit<ModalBaseProps, 'classes'> {
    animation?: AnimationsType;
    classes?: {
        modal?: string;
        paper?: string;
    };
}

export default function Modal(props: ModalProps) {
    const { animation, classes: _classes = {}, children, ...rest } = props;
    const classes = useStyles();

    function Animation(props: {
        animation?: AnimationsType;
        children: React.ReactElement<any, any>;
    }) {
        const { animation, children } = props;

        switch (animation) {
            case 'Fade': {
                return <Fade in={rest.open} children={children} />;
            }

            case 'Grow': {
                return <Grow in={rest.open} children={children} />;
            }

            case 'Slide': {
                return <Slide in={rest.open} children={children} />;
            }

            case 'Zoom': {
                return <Zoom in={rest.open} children={children} />;
            }

            default: {
                return children;
            }
        }
    }

    return (
        <ModalCore
            {...rest}
            className={`${classes.modal} ${rest.className} ${_classes.modal}`}
        >
            {Animation({
                animation,
                children: (
                    <div className={`${classes.paper} ${_classes.paper}`}>
                        {children}
                    </div>
                ),
            })}
        </ModalCore>
    );
}
