import {
    forwardRef,
    useRef,
    useState,
    useImperativeHandle,
    useEffect,
    useContext,
} from 'react';
import { makeStyles } from '@material-ui/core';

import Button from 'core/components/Button';
import AutocompleteAsync from 'core/components/inputs/AutocompleteAsync';
import Checkbox from 'core/components/inputs/Checkbox';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';

export interface QuickAddProps {
    onInsert(data: Record<string, any>): void;
    extra?: Record<string, any>;
}

export interface QuickAddRef {
    setDisabled(disabled: boolean): void;
}

const useStyles = makeStyles((theme) => ({
    wrap: {
        backgroundColor: '#F2F2F2',
        padding: `${theme.spacing(1.5)}px 30px`,
        margin: '-10px 0 6px -30px',
        width: 'calc(100% + 60px)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    wrapCheckbox: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 30,
        '& span': {
            marginLeft: 10,
            color: '#444444',
            fontSize: theme.typography.pxToRem(15),
        },
    },
}));

export default forwardRef<QuickAddRef, QuickAddProps>(
    function QuickAddGroupUser(props, ref) {
        const { extra = {} } = props;
        const classes = useStyles();

        const [disabled, setDisabled] = useState(false);
        const [, setOptions] = useState<any[]>([]);
        const [permissions, setPermissions] = useState({
            menu: null,
            CanRead: false,
            CanCreate: false,
            CanUpdate: false,
            CanDelete: false,
        });

        const inputMenuRef = useRef<HTMLInputElement>(null);
        const buttonCanReadRef = useRef<HTMLInputElement>(null);
        const buttonCanCreateRef = useRef<HTMLInputElement>(null);
        const buttonCanUpdateRef = useRef<HTMLInputElement>(null);
        const buttonCanDeleteRef = useRef<HTMLInputElement>(null);

        const alert = useContext(AlertContext);

        async function onInsert() {
            if (permissions.menu) {
                try {
                    const data = {
                        // @ts-ignore
                        menu_id: permissions.menu.id,
                        can_read: buttonCanReadRef.current
                            ? buttonCanReadRef.current?.checked
                            : false,
                        can_create: buttonCanCreateRef.current
                            ? buttonCanCreateRef.current?.checked
                            : false,
                        can_update: buttonCanUpdateRef.current
                            ? buttonCanUpdateRef.current?.checked
                            : false,
                        can_delete: buttonCanDeleteRef.current
                            ? buttonCanDeleteRef.current?.checked
                            : false,
                    };

                    await props.onInsert(data);

                    inputMenuRef.current!.focus();

                    setPermissions({
                        menu: null,
                        CanRead: false,
                        CanCreate: false,
                        CanUpdate: false,
                        CanDelete: false,
                    });
                } catch (error: any) {
                    alert.error('Não foi possível adicionar o valor');
                }
            }
        }

        useImperativeHandle(ref, () => ({
            setDisabled(disabled) {
                setDisabled(disabled);
            },
        }));

        useEffect(() => {
            (async () => {
                try {
                    const { data } = await api.get(extra.routeMenu);

                    setOptions(data);
                } catch (error: any) {
                    alert.error('Não foi possível carregar os menus');
                }
            })();
        }, []);

        return (
            <div className={classes.wrap}>
                <AutocompleteAsync
                    inputRef={inputMenuRef}
                    options={async () => {
                        let data = [];

                        try {
                            data = (await api.get(extra.routeMenu)).data;
                        } catch (error: any) {
                            alert.error('Não foi possível carregar os menus');
                        }

                        return data;
                    }}
                    getOptionSelected={(option, value) =>
                        option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    value={permissions.menu}
                    placeholder="Informe o menu"
                    style={{ width: 250, marginRight: 'auto' }}
                    clearText="Limpar"
                    onChange={(event, value) => {
                        if (event.type === 'click') {
                            setPermissions({
                                menu: value || null,
                                CanRead: value
                                    ? value.permissions.can_read
                                    : false,
                                CanCreate: value
                                    ? value.permissions.can_create
                                    : false,
                                CanUpdate: value
                                    ? value.permissions.can_update
                                    : false,
                                CanDelete: value
                                    ? value.permissions.can_delete
                                    : false,
                            });
                        }
                    }}
                    delayLoad={500}
                    loadingText="Carregando..."
                    noOptionsText="Sem menus"
                />
                {permissions.CanRead && (
                    <div className={classes.wrapCheckbox}>
                        <Checkbox inputRef={buttonCanReadRef} />
                        <span>Visualizar</span>
                    </div>
                )}
                {permissions.CanCreate && (
                    <div className={classes.wrapCheckbox}>
                        <Checkbox inputRef={buttonCanCreateRef} />
                        <span>Incluir</span>
                    </div>
                )}
                {permissions.CanUpdate && (
                    <div className={classes.wrapCheckbox}>
                        <Checkbox inputRef={buttonCanUpdateRef} />
                        <span>Editar</span>
                    </div>
                )}
                {permissions.CanDelete && (
                    <div className={classes.wrapCheckbox}>
                        <Checkbox inputRef={buttonCanDeleteRef} />
                        <span>Excluir</span>
                    </div>
                )}
                <Button
                    children="Inserir"
                    color="#456AEF"
                    disabled={disabled}
                    onClick={onInsert}
                />
            </div>
        );
    }
);
