import { useState, useImperativeHandle, forwardRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import InputResponsive from 'core/components/InputResponsive';
import InputContainer from 'core/components/InputContainer';
import NumberField from 'core/unform/NumberField';

export interface RangeInputProps {
    editable?: boolean;
}

export interface RangeInputRef {
    setEditable(value: boolean): void;
}

const useStyles = makeStyles((theme) => ({
    labelBottom: {
        alignItems: 'unset',
        flexDirection: 'column',
        marginBottom: 10,
        '& p': {
            marginBottom: theme.spacing(1),
        },
    },
    wrapper: {
        display: 'flex',
        alignItems: 'baseline',
    },
    texts: {
        fontSize: theme.typography.pxToRem(15),
    },
    inputs: {
        width: '50px !important',
        '& input': {
            textAlign: 'center',
        },
    },
}));

export default forwardRef<RangeInputRef, RangeInputProps>(function RangeInput(
    props,
    ref
) {
    const classes = useStyles();
    const [editable, setEditable] = useState(
        props.editable || props.editable === undefined
    );

    useImperativeHandle(ref, () => ({
        setEditable,
    }));

    return (
        <>
            {editable && (
                <InputResponsive slot="6">
                    <InputContainer
                        label="Prazo personalizado (em dias)"
                        required
                        classes={{ content: classes.labelBottom }}
                    >
                        <div className={classes.wrapper}>
                            <Typography
                                className={classes.texts}
                                children="Entre"
                                style={{ marginRight: 16 }}
                            />
                            <NumberField
                                className={classes.inputs}
                                name="custom_term_min"
                                numberFormat={{
                                    limitOfBytes: '2',
                                    allowNegative: false,
                                }}
                            />
                            <Typography
                                className={classes.texts}
                                children="e"
                                style={{ margin: '0 16px 0' }}
                            />
                            <NumberField
                                className={classes.inputs}
                                name="custom_term_max"
                                numberFormat={{
                                    limitOfBytes: '2',
                                    allowNegative: false,
                                }}
                            />
                            <Typography
                                className={classes.texts}
                                children="dias"
                                style={{ marginLeft: 16 }}
                            />
                        </div>
                    </InputContainer>
                </InputResponsive>
            )}
        </>
    );
});
