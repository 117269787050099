import { Page } from 'core/interfaces/page';
import Details from './Details';

const User: Page = {
    label: 'Usuários',
    route: 'users',
    routes: {
        create: 'users/create-user',
        findOne: (id) => `users/users-from/${id}`,
        find: 'users/users-from',
        update: (id) => `users/update-user/${id}`,
    },
    Details,
    listProps: {
        fields: {
            name: {
                headerName: 'Nome',
            },
            email: {
                headerName: 'E-mail',
            },
            userprofile: {
                headerName: 'Grupo do usuário',
                renderCell({ row }) {
                    if (row.userprofile) {
                        return row.userprofile.name;
                    }

                    return 'Sem grupo vinculado';
                },
            },
        },
        dialogOnDelete: 'Tem certeza que deseja excluir?',
    },
};

export default User;
