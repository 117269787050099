import { useState } from "react";
import { DateRange } from '@material-ui/icons';
import DatePicker from 'core/unform/DatePicker';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dateField: {
        width: 185,
        marginRight: 20,
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: theme.spacing(1.5),
            cursor: 'pointer',
        },
        '& input': {
            paddingLeft: theme.spacing(1.5),
            '&::-webkit-input-placeholder, &::placeholder': {
                opacity: 1,
                color: '#444444',
            },
            cursor: 'pointer',
        },
    }
}));

const Pickers = () => {
    const classes = useStyles();
    const [minDate, setMinDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();

    return (
        <>
            <DatePicker
                name="date_start"
                disableToolbar
                className={classes.dateField}
                placeholder="Período inicial"
                format="DD/MM/YYYY"
                clearable
                InputProps={{
                    startAdornment: <DateRange style={{ color: '#999999' }} />,
                }}
                maxDate={maxDate || new Date()}
                clearLabel="Limpar"
                cancelLabel="Cancelar"
                onChange={(date: any) => {
                    setMinDate(date ? new Date(date.toISOString()) : undefined);
                }}
            />
            <DatePicker
                name="date_end"
                disableToolbar
                className={classes.dateField}
                placeholder="Período final"
                format="DD/MM/YYYY"
                clearable
                InputProps={{
                    startAdornment: <DateRange style={{ color: '#999999' }} />,
                }}
                minDate={minDate}
                maxDate={new Date()}
                clearLabel="Limpar"
                cancelLabel="Cancelar"
                onChange={(date: any) => {
                    setMaxDate(date ? new Date(date.toISOString()) : undefined);
                }}
            />
        </>
    );
}
export default Pickers