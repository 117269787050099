import {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import {
  Button,
  Modal,
  Fade,
  makeStyles,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core';

import ReactCrop, {
  Crop as ReactCropType,
  PercentCrop,
  PixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import AlertContext from 'core/contexts/Alert';
import { getCroppedImg2 } from '../../utils/cropImage';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';

export interface CropProps {
  crop: {
    size: {
      width: number;
      height: number;
    };
    showGrid?: boolean;
  };
  open: boolean;
  src?: string;
  onClose(file?: File | Blob): void;
}

export interface CropRef {
  setLoading(value: boolean): void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 4,
    outline: 'unset',
    maxWidth: '90%',
    maxHeight: '90%',
  },
  cropWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  cancelButton: {
    backgroundColor: 'transparent',
    border: '1px solid #456AEF',
    color: '#456AEF',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 105,
  },
  saveOriginalButton: {
    backgroundColor: '#456AEF',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
    padding: '0px 20px',
    '&:hover': {
      backgroundColor: '#456AEF',
      color: 'white',
    },
  },

  wrapperTabContent: {
    margin: theme.spacing(2, 2, 2),
    maxHeight: '60vh',
  },
}));

export default forwardRef<CropRef, CropProps>(function Crop(props, ref) {
  const { open } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop | null>(
    null
  );
  const imageRef = useRef<HTMLImageElement>(null);

  const [crop, setCrop] = useState<ReactCropType>({
    unit: 'px',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });

  const alert = useContext(AlertContext);

  const [currentTab, setCurrentTab] = useState<number>(0);

  function onClose(file?: File | Blob) {
    setCroppedAreaPixels(null);
    setCrop({
      unit: 'px',
      width: 50,
      height: 50,
      x: 25,
      y: 25,
    });
    setCurrentTab(0);
    props.onClose(file);
  }

  function onCropChange(crop: ReactCropType) {
    setCrop(crop);
  }

  function onCropComplete(crop: PixelCrop, percentageCrop: PercentCrop) {
    setCroppedAreaPixels(crop);
  }

  useImperativeHandle(ref, () => ({
    setLoading,
  }));

  const onSaveOriginal = async () => {
    if (!props.src) {
      alert.error('Selecione uma imagem');
      return;
    }

    setLoading(true);
    axios
      .get(props.src, {
        responseType: 'blob',
      })
      .then((res) => {
        const file = new File([res.data], 'image.png', { type: 'image/png' });
        onClose(file);
      })
      .catch((err) => {
        alert.error('Erro ao salvar a imagem');
      });
  };

  const onSaveCroppedImage = async () => {
    if (croppedAreaPixels !== null && imageRef.current) {
      const croppedImage = await getCroppedImg2(
        imageRef.current,
        croppedAreaPixels
      );

      if (croppedImage) {
        onClose(croppedImage);
      } else {
        alert.error('Erro ao recortar a imagem');
      }
    } else {
      alert.warning('Selecione uma área para cortar.');
    }
  };

  return (
    <Modal
      open={open}
      className={classes.modal}
      onClose={() => !loading && onClose()}
    >
      <Fade in={open}>
        <Box className={classes.modalWrapper}>
          <Box
            display="flex"
            flexDirection="column"
            marginLeft="20px"
            marginRight="20px"
            minWidth="30vw"
          >
            <Box
              style={{
                borderBottom: '1px solid #e0e0e0',
                paddingBottom: '2px',
              }}
            >
              <Tabs
                value={currentTab}
                onChange={(e, v) => setCurrentTab(v)}
                indicatorColor="primary"
              >
                <Tab label="ADICIONAR IMAGEM" />
                <Tab label="EDITAR IMAGEM" />
                <Button
                  style={{
                    color: '#3b62ed',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: 'auto',
                  }}
                  onClick={() => onClose()}
                >
                  <ClearIcon />
                </Button>
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              <Box className={classes.wrapperTabContent}>
                <Box justifyContent="center" display="flex">
                  <img
                    src={props.src}
                    style={{
                      maxHeight: '50vh',
                    }}
                    alt="preview"
                  />
                </Box>
                <Box justifyContent="center" display="flex" marginTop="15px">
                  <Button
                    disabled={loading}
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={loading}
                    className={classes.saveOriginalButton}
                    onClick={() => onSaveOriginal()}
                  >
                    Salvar imagem original
                  </Button>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Box className={classes.wrapperTabContent}>
                <Typography>Ajuste o enquadramento desejado.</Typography>

                <Box className={classes.cropWrapper}>
                  <ReactCrop
                    crop={crop}
                    onChange={onCropChange}
                    onComplete={onCropComplete}
                  >
                    <Box justifyContent="center" display="flex">
                      <img
                        ref={imageRef}
                        src={props.src}
                        style={{
                          maxHeight: '50vh',
                        }}
                        alt="preview"
                      />
                    </Box>
                  </ReactCrop>
                </Box>

                <Box justifyContent="center" display="flex" marginTop="15px">
                  <Button
                    disabled={loading}
                    className={classes.cancelButton}
                    children="Cancelar"
                    onClick={() => onClose()}
                  />
                  <Button
                    disabled={loading}
                    className={classes.saveOriginalButton}
                    onClick={() => onSaveCroppedImage()}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="1.5rem"
                      />
                    ) : (
                      'Cortar e salvar imagem'
                    )}
                  </Button>
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
});
