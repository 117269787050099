import SelectBase, { SelectProps } from '@material-ui/core/Select';
import { createStyles, Theme, withStyles } from '@material-ui/core';

export default withStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: 10.5,
            paddingBottom: 10.5,
            fontSize: theme.typography.pxToRem(15),
            background: 'white',
        },
    })
)((props: SelectProps) => <SelectBase variant="outlined" {...props} />);
