import { makeStyles, Paper } from '@material-ui/core';
interface Props {
  title: string;
  height?: string;
  titleComponent?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    display: 'inline-block',
  },
}));

const CardGraph: React.FC<Props> = ({
  children,
  title,
  height,
  titleComponent,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        marginRight: '20px',
      }}
    >
      <Paper
        style={{
          padding: '10px 20px',
          margin: '10px 17px',
          height: height || '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <h1 className={classes.title}>{title}</h1>
          {titleComponent}
        </div>
        <span
          style={{
            width: '100%',
            display: 'block',
            borderBottom: '1px solid #E0E0E0',
            marginBottom: '5px',
          }}
        />
        {children}
      </Paper>
    </div>
  );
};

export default CardGraph;
