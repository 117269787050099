import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, ButtonBase } from '@material-ui/core';

interface Props {
    title: ReactNode;
    onClickTitle?: () => void;
    dictionary?: {
        action?: ReactNode;
    };
    slot?: {
        start?: JSX.Element;
        end?: JSX.Element;
    };
    symbol?: string;
}

const useStyles = makeStyles({
    body: {
        borderTop: '1px solid rgb(221, 221, 221)',
        borderBottom: '1px solid rgb(221, 221, 221)',
        padding: '15px 30px',
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        '--height': '84px',
        height: 'var(--height)',
        top: 'calc(var(--height) - 20px)',
        zIndex: 1000,
        backgroundColor: 'white',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 22,
        margin: '12px 0',
        lineHeight: '1.3',
        color: '#333333',
    },
    action: {
        fontWeight: 700,
        fontSize: 22,
        margin: '12px 0',
        lineHeight: '36px',
        color: '#333333',
    },
});

export default function BreadCrumb(props: Props) {
    const { title, onClickTitle, dictionary, slot, symbol = '/' } = props;
    const classes = useStyles();

    return (
        <div className={classes.body}>
            {slot?.start}
            <Breadcrumbs
                separator={<span style={{ fontSize: '1.5rem' }}>{symbol}</span>}
            >
                <ButtonBase onClick={onClickTitle} disableRipple>
                    <Typography variant="h1" noWrap className={classes.title}>
                        {title}
                    </Typography>
                </ButtonBase>
                {dictionary?.action && (
                    <Typography variant="h2" noWrap className={classes.action}>
                        {dictionary.action}
                    </Typography>
                )}
            </Breadcrumbs>
            {slot?.end}
        </div>
    );
}
