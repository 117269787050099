import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Button,
    Modal,
    Fade,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { SearchOutlined as SearchOutlinedIcon } from '@material-ui/icons';

import Checkbox from 'core/components/inputs/Checkbox';

export interface SearchAttributesProps {
    attributes: any[];
    open: boolean;
    onClose(attributes: any[]): void;
}

let interval: any = null;

export default function SearchAttributes(props: SearchAttributesProps) {
    const { open, onClose } = props;
    const classes = useStyles();
    const [attributes, setAttributes] = useState(props.attributes);
    const [search, setSearch] = useState('');

    function filterAttributes() {
        return attributes.filter((attribute) => {
            if (search) {
                if (
                    new RegExp(`^.*${search}.*$`, 'i').test(
                        attribute.name.toLowerCase()
                    )
                ) {
                    return true;
                }

                return false;
            }

            return true;
        });
    }

    function onSearch(event: any) {
        clearInterval(interval);

        interval = setTimeout(() => {
            const { value: search } = event.nativeEvent.target;

            setSearch(search);
        }, 500);
    }

    useEffect(() => {
        setAttributes(props.attributes);
    }, [props.attributes]);

    return (
        <Modal
            open={open}
            onClose={() => onClose([])}
            className={classes.modal}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography>Adicionar atributos</Typography>
                        <TextField
                            variant="outlined"
                            placeholder="Pesquisar nome do atributo"
                            size="small"
                            style={{ width: '100%', backgroundColor: 'white' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlinedIcon htmlColor="#999999" />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={onSearch}
                        />
                    </div>
                    <div className={classes.modalBody}>
                        {filterAttributes().map((attribute, index) => (
                            <div key={index} className={classes.modalAttribute}>
                                <Checkbox
                                    checked={attribute.checked || false}
                                    onChange={({ target: { checked } }) => {
                                        attribute.checked = checked;
                                        setAttributes([...attributes]);
                                    }}
                                />
                                <Typography>{attribute.name}</Typography>
                            </div>
                        ))}
                    </div>
                    <div className={classes.modalFooter}>
                        <Button
                            children="Adicionar"
                            className={classes.modalButton}
                            onClick={() => {
                                const attributesChecked = attributes.filter(
                                    (attribute) => {
                                        if (attribute.checked) {
                                            return true;
                                        }

                                        return false;
                                    }
                                );

                                onClose(attributesChecked);
                            }}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        outline: 'unset',
        alignItems: 'center',
        position: 'relative',
        width: 335,
        height: 510,
    },
    modalAttribute: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: 15,
        '& p': {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 'bold',
            color: '#456AEF',
            marginLeft: 10,
            lineHeight: 1,
        },
    },
    modalButton: {
        backgroundColor: '#456AEF',
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        height: 40,
        padding: '6px 16px',
        width: 100,
        '&:hover': {
            transition: 'background-color 500ms linear',
            backgroundColor: 'rgba(69, 106, 239, 0.5)',
        },
        '&.cancel': {
            border: '1px solid #456AEF',
            background: 'white',
            color: '#456AEF',
        },
        '& + button': {
            marginLeft: '20px',
        },
    },
    modalHeader: {
        padding: '15px 30px',
        display: 'flex',
        alignItems: 'start',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        flexDirection: 'column',
        backgroundColor: '#F9F9F9',
        width: '100%',
        '&>p': {
            fontSize: theme.typography.pxToRem(17),
            fontWeight: 'bold',
            color: '#333333',
            marginBottom: 10,
        },
    },
    modalBody: {
        padding: '30px 30px 20px',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        scrollbarColor: '#456AEF',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: 6,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#456AEF',
        },
        overflowY: 'auto',
    },
    modalFooter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '20px 0 30px',
        marginTop: 'auto',
        borderTop: '1px solid rgb(221, 221, 221)',
    },
}));
