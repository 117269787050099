import Excel from 'exceljs';

export class ExcelReader {
    constructor(private readonly file: File) {}

    read(): Promise<Excel.Row[]> {
        const wb = new Excel.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(this.file);

        return new Promise((resolve, reject) => {
            try {
                reader.onload = async () => {
                    const buffer = reader.result;
                    const rows: Excel.Row[] = [];

                    if (buffer) {
                        const workbook = await wb.xlsx.load(buffer as any);
                        const sheet = workbook.worksheets[0];

                        sheet.eachRow((row) => {
                            rows.push(row);
                        });
                    }

                    resolve(rows);
                };
            } catch (err) {
                reject(err);
            }
        });
    }
}
