import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { SwitchProps as SwitchBaseProps } from '@material-ui/core/Switch';

import SwitchBase from '../components/inputs/Switch';

export interface SwitchProps extends Omit<SwitchBaseProps, 'name' | 'value'> {
    name: string;
}

export default function Switch(props: SwitchProps) {
    const { name, defaultChecked = false, ...restProps } = props;
    const { fieldName, defaultValue, registerField } = useField(name);
    const [value, setValue] = useState(defaultValue ?? defaultChecked);

    function onChange(
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setValue(event.target.checked);

        restProps.onChange?.(event, checked);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value;
                },
                setValue(_: unknown, avalue: boolean) {
                    setValue(avalue);
                },
            });
        }
    }, [fieldName, registerField, value]);

    return (
        <SwitchBase
            {...restProps}
            name={name}
            checked={value}
            onChange={onChange}
        />
    );
}
