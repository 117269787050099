import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import { useContext, useEffect, useRef, useState } from 'react';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';

import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import disableFields from 'core/lib/disableFields';
import { FileCopy } from '@material-ui/icons';
import AuthContext from 'core/contexts/Auth';

const VALIDATIONS = {
  name: Yup.string()
    .required('Nome é obrigatório')
    .typeError('Nome é obrigatório'),
  email: Yup.string().required('E-mail é obrigatório').email('E-mail inválido'),
  code: Yup.string()
    .required('ID do afiliado é obrigatório')
    .typeError('ID do afiliado é obrigatório'),
  commission: Yup.number()
    .required('Comissão percentual é obrigatório')
    .typeError('Comissão percentual é obrigatório'),
};

const Details = (props: any) => {
  const {
    isNew,
    page: { label, route, routes, permissions },
    instance,
  } = props;

  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const auth = useContext(AuthContext);
  const history = useHistory();

  const [code, setCode] = useState(instance?.code?.trim() || '');

  let domain = auth.extra!.marketplace?.domain;

  const onSubmit = async (data: Record<string, any>) => {
    if (isNew) {
      api
        .post(routes.create, data)
        .then((response) => {
          history.replace(route);
          alert.success('Cupom criado com sucesso');
        })
        .catch((error) => {
          alert.error(error.message);
        });
    } else {
      const routeUpdate = routes.update(instance.id);

      api
        .put(routeUpdate, data)
        .then((response) => {
          history.replace(route);
          alert.success('Cupom atualizado com sucesso');
        })
        .catch((error) => {
          alert.error(error.message);
        });
    }
  };

  const onError = (error: Yup.ValidationError) => {
    alert.error(error.message);
  };

  const copyUrl = () => {
    const affiliateLink = `${domain}/?tag=${code}`.replace(
      /^(?:http(s)?:\/\/)?(?:www\.)?(?:\\)?/,
      'https://'
    );

    navigator.clipboard.writeText(affiliateLink);
    alert.success('URL copiada para a área de transferência');
  };

  useEffect(() => {
    disableFields(permissions);
  }, []);

  useEffect(() => {
    formRef.current?.setFieldValue(
      'affiliate_link',
      `${domain}/?tag=${code || ''}`
    );
  }, [code]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions.update}
      />

      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome completo"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            slot="4"
          />

          <Field
            label="E-mail"
            name="email"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            slot="4"
          />

          <Field
            label="ID do afiliado (Referal ID)"
            name="code"
            description="É um código de controle para indentificar o afiliado no marketplace"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            onChange={(e) => setCode(e?.trim())}
            slot="4"
          />

          <Field
            label="Comissão percentual (%)"
            name="commission"
            description="Comissão percentual que será paga ao afiliado por cada venda realizada"
            field={{
              type: 'number',
              numberFormat: {
                allowNegative: false,
                decimalSeparator: '.',
                decimalScale: 2,
                fixedDecimalScale: true,
                isNumericString: true,
                suffix: '%',
                limit: {
                  min: 0,
                  max: 100,
                },
              },
            }}
            required
            slot="4"
          />

          <Field
            label="Link do afiliado"
            name="affiliate_link"
            description="O link do afiliado servirá para indicar/promover produtos das lojas e ganhar comissões sobre as vendas."
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  endAdornment: (
                    <FileCopy
                      style={{
                        cursor: 'pointer',
                        color: '#6B7280',
                        marginRight: '10px',
                        fontSize: '1.2rem',
                      }}
                      onClick={() => {
                        copyUrl();
                      }}
                    />
                  ),
                  inputProps: {
                    maxLength: 200,
                    disabled: true,
                  },
                },
              },
              style: {
                backgroundColor: '#F3F4F6',
              },
            }}
            required
            slot="4"
          />

          <Field
            label="Afiliado ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
        </Container>
      </Form>
    </>
  );
};

export default Details;
