import { Page } from 'core/interfaces/page';
import Details from './Details';

const PagSeguro: Page = {
  label: 'Meios De Pagamento',
  route: 'payments-methoads',
  onlyDetails: true,
  routes: {
    update: 'marketplaces/payment-methoad',
    findOne: 'marketplaces/payment-methoad',
  },
  Details,
};

export default PagSeguro;
