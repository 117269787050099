import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';

import InputResponsive from 'core/components/InputResponsive';
import InputContainer from 'core/components/InputContainer';
import AutocompleteAsync from 'core/components/inputs/AutocompleteAsync';
import { api } from 'core/lib/api';
import { getUserData } from 'core/lib/getUserData';

export default function NewBrand() {
  const classes = useStyles();
  const { fieldName, defaultValue = {}, registerField } = useField('brand');
  const [brand, setBrand] = useState<any>(defaultValue);
  const marketplaceId = getUserData().marketplace.id;
  useEffect(() => {
    registerField({
      name: fieldName,
      getValue() {
        return brand;
      },
      setValue(_, brand) {
        setBrand(brand || {});
      },
    });
  }, [fieldName, registerField, brand]);

  return (
    <InputResponsive slot="4">
      <InputContainer label="Marca" classes={{ content: classes.labelBottom }}> 
        <AutocompleteAsync
          value={brand}
          options={async () =>
            (await api.get('brands/autocomplete')).data.sort((a: any, b: any) =>
              a.name.localeCompare(b.name)
            )
          }
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name || ''}
          onChange={(_, value) => {
            if (value) {
              if (typeof value === 'string') {
                delete brand.id;

                brand.name = value;
              } else {
                brand.id = value.id;
                brand.name = value.name;
              }
            } else {
              delete brand.id;

              brand.name = '';
            }

            setBrand({ ...brand });
          }}
          onBlur={async ({ target }) => {
            // @ts-ignore
            if (target.value) {
              if (!brand.id) {
                const userData = getUserData();
                const { data } = await api.post(
                  `brands?sellerId=${userData.seller_id}`,
                  {
                    // @ts-ignore
                    name: target.value,
                  }
                );

                brand.id = data.id;
                brand.name = data.name;
              }
            } else if (typeof brand === 'object') {
              delete brand.id;

              brand.name = '';
            }

            setBrand({ ...brand });
          }}
          freeSolo
          delayLoad={500}
          noOptionsText="Sem resultados"
        />
      </InputContainer>
    </InputResponsive>
  );
}

const useStyles = makeStyles((theme) => ({
  labelBottom: {
    alignItems: 'unset',
    flexDirection: 'column',
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
}));
