import { useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';

import BreadCrumb from 'core/components/BreadCrumb';
import ButtonAction from 'core/components/ButtonAction';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import disableFields from 'core/lib/disableFields';

const useStyles = makeStyles({
    content: {
        border: 'none',
    },
    wrap: {
        width: 'calc(100% + 20px)',
    },
});

const VALIDATIONS = {
    footer_info: Yup.string().required(
        'O campo informações do rodapé está vazio'
    ),
};

export default function Details(props: any) {
    const {
        page: { routes, label, permissions },
        instance,
    } = props;
    const classes = useStyles();
    const formRef = useRef<FormHandles>(null);
    const alert = useContext(AlertContext);

    async function onSubmit(data: Record<string, any>) {
        try {
            await api.put(routes.update, data);

            alert.success('Informações alteradas com sucesso');
        } catch (err) {
            alert.error(
                'Não foi possível salvar as informações do marketplace'
            );
        }
    }

    function onError(error: Yup.ValidationError) {
        alert.error(error.message);
    }

    useEffect(() => {
        disableFields(permissions);
    }, [instance]);

    return (
        <>
            <BreadCrumb
                title={label}
                slot={{
                    end: (
                        <ButtonAction
                            title="Salvar"
                            style={{ marginLeft: 'auto', display: !permissions.update ? 'none' : '' }}
                            onClick={() => formRef.current?.submitForm()}
                        />
                    ),
                }}
            />
            <Form
                ref={formRef}
                style={{ display: 'contents' }}
                initialData={instance}
                validations={VALIDATIONS}
                onSubmit={onSubmit}
                onError={onError}
                abortEarly
            >
                <ContainerCustom
                    title="Informações do Rodapé"
                    subtitle="Coloque as informações que devem aparecer no rodapé da página: Razão social ou Nome Fantasia, Endereço, CNPJ."
                    classes={{ wrap: classes.wrap }}
                >
                    <Field
                        label=""
                        name="footer_info"
                        field={{
                            type: 'ckeditor',
                            componentProps: {
                                config: {
                                    language: 'pt-br',
                                },
                                onReady({ editing: { view } }) {
                                    view.change(({ setStyle, document }) => {
                                        setStyle(
                                            'height',
                                            '300px',
                                            document.getRoot()
                                        );
                                    });
                                },
                            },
                        }}
                    />
                </ContainerCustom>
            </Form>
        </>
    );
}
