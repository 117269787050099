import { useContext, useState } from 'react';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import { DialogText } from 'components/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Dialog as DialogBase,
  DialogTitle,
  DialogContent,
  Button as ButtonBase,
  DialogActions,
  makeStyles,
  IconButton,
  Box,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { getUserData } from 'core/lib/getUserData';
import { DeleteOutlineOutlined } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  'MuiPaper-root': {
    // width: 450,
    width: 420,
    minHeight: 241,
  },
  'MuiDialogActions-root': {
    justifyContent: 'center',
    padding: theme.spacing(2, 1),
  },
  'MuiButtonBase-root': {
    width: 100,
    border: '1px solid',
    fontWeight: 600,
    textTransform: 'unset',
  },
  'MuiDialogTitle-root': {
    fontWeight: 800,
  },
  wrapperDelete: {
    display: 'flex',
  },
  trash: {
    color: '#d30000',
  },
  buttonCancel: {
    borderColor: '#456AEF',
    color: '#456AEF',
    textTransform: 'unset',
    padding: '0.5rem 1rem',
    fontWeight: 600,
    '&:hover': {
      background: 'transparent',
      color: '#456AEF',
    },
  },
  buttonDelete: {
    borderColor: '#456AEF',
    background: '#456AEF',
    color: 'white',
    padding: '0.5rem 1rem',
    textTransform: 'unset',
    '&:hover': {
      background: '#456AEF',
      color: 'white',
    },
  },
  wrapperButtons: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: '1rem',
  },
}));

interface DialogDeleteAllProps {}

export default function DialogDeleteAll(props: DialogDeleteAllProps) {
  const alert = useContext(AlertContext);
  const [open, setOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  const getAllCheckBox = () =>
    [...document.querySelectorAll('input[type="checkbox"]')].filter(
      (i) => i.id
    ) as HTMLInputElement[];

  const deleteAllSelectItems = () => {
    const checkboxs = getAllCheckBox();
    const checkedCheckboxs = checkboxs.filter((i) => i.checked);
    const userData = getUserData();
    api
      .post(`/products/delete-all-logically?sellerId=${userData.seller_id}`, {
        ids: checkedCheckboxs.map((i) => parseInt(i.id)),
      })
      .then((r) => {
        alert.success(`Deletado: ${r.data.count} produto(s)`);
        setOpen(false);

        window.location.reload();
      })
      .catch((e: AxiosError) => {
        alert.error('Erro ao excluir produto(s)');
      });
  };

  const onOpenModalDelete = () => {
    const checkboxs = getAllCheckBox();
    const checkedCheckboxs = checkboxs.filter((i) => i.checked);
    if (checkedCheckboxs.length === 0) {
      alert.error('Selecione pelo menos um produto');
    } else {
      setOpen(true);
    }
  };

  const onSelectAll = () => {
    const checkboxs = getAllCheckBox();
    if (allChecked) {
      checkboxs.forEach((i) => {
        i.checked = true;
        i.dispatchEvent(new Event('change'));
        i.click();
      });
    } else {
      checkboxs.forEach((i) => {
        i.checked = false;
        i.dispatchEvent(new Event('change'));
        i.click();
      });
    }
  };

  const classes = useStyles();

  return (
    <>
      <Box className={classes.wrapperButtons}>
        <Checkbox
          onClick={() => onSelectAll()}
          name="delete_all"
          checked={allChecked}
          onChange={() => setAllChecked(!allChecked)}
          style={{
            color: '#456aef',
          }}
        />

        <IconButton
          onClick={() => onOpenModalDelete()}
          children={<DeleteOutlineOutlined />}
          title="Excluir"
        />
      </Box>

      <DialogBase
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes['MuiPaper-root'] }}
      >
        <DialogTitle
          classes={{
            root: classes['MuiDialogTitle-root'],
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 700,
              fontSize: 18,
            }}
          >
            <DeleteIcon
              style={{
                color: '#d60606',
                marginRight: 16,
                width: 32,
                height: 32,
                backgroundColor: '#FFE9EC',
                borderRadius: '50%',
                padding: '2px 6px',
              }}
            />
            <span>Deseja excluir todos os produtos selecionados?</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogText
            children="Estes produtos selecionados serão apagados de forma permanente."
            style={{ color: '#000000', fontSize: 14 }}
          />
          <DialogText
            children="Esta ação não poderá ser desfeita."
            style={{ color: '#d60606', fontSize: 14 }}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes['MuiDialogActions-root'] }}>
          <ButtonBase
            onClick={(e) => setOpen(false)}
            classes={{
              root: classes.buttonCancel,
            }}
          >
            Cancelar
          </ButtonBase>
          <ButtonBase
            onClick={(e) => deleteAllSelectItems()}
            classes={{ root: classes.buttonDelete }}
          >
            Apagar selecionados
          </ButtonBase>
        </DialogActions>
      </DialogBase>
    </>
  );
}
