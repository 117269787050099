import {
  Button as ButtonCore,
  Modal,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import { useContext, useEffect, useRef, useState } from 'react';
import { getUserData } from 'core/lib/getUserData';
import Button from 'core/components/Button';
import * as Yup from 'yup';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';

interface IProps {
  open: {
    open: boolean;
    instance?: any;
  };
  onClose(): void;
  handleSubmit?(data: any): void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: theme.spacing(2, 4, 1),
    outline: 'none',
    width: 540,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '10px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    borderTop: '1px solid #E5E5E5',
    paddingTop: theme.spacing(3),
    gap: '20px',
  },
  videoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  videoTitle: {
    fontSize: '0.9375rem',
    fontWeight: 400,
    marginBottom: 8,
  },
  videoFrame: {
    border: 'none',
    borderRadius: 4,
  },
  cancelButton: {
    backgroundColor: 'transparent',
    border: '1px solid #456AEF',
    color: '#456AEF',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '100%',
  },
  saveButton: {
    backgroundColor: '#456AEF',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
    padding: '0px 20px',
    '&:hover': {
      backgroundColor: '#456AEF',
      color: 'white',
    },
    width: '100%',
  },
}));

const VALIDATIONS = {
  midia_path: Yup.string().matches(
    /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:\S+)?/,
    {
      message: 'O link do vídeo deve ser do Youtube',
    }
  ),
  midia_thumb_path: Yup.string().url().nullable(),
};

const AddVideoModal = ({ open, onClose, handleSubmit }: IProps) => {
  const classes = useStyles();
  const formRef = useRef<FormHandles>(null);
  const userData = getUserData();
  const [video, setVideo] = useState('');
  const [loading, setLoading] = useState(false);

  const alert = useContext(AlertContext);

  const handleSetVideo = (value?: string) => {
    const id = 
      value?.match(
        /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:\S+)?/
      )?.[1];

    if (id) {
      setVideo(
        `https://www.youtube.com/embed/${id}?enablejsapi=0&amp;autoplay=0&amp;modestbranding=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=0;`
      );

      setLoading(true);

      api.get(`/productmidias/get-youtube-video-info/${id}`).then((resp: any) => {
        const info = resp.data;

        const thumbnails =
          info.player_response.videoDetails.thumbnail.thumbnails;

        formRef.current?.setFieldValue(
          'midia_thumb_path',
          thumbnails[thumbnails.length - 1].url
        );

        setLoading(false);
      })
      .catch(() => {
        alert.error('Não foi possível obter as informações do vídeo');
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    if (open) {
      setVideo('');
    }

    if (open.instance) {
      handleSetVideo(open.instance.midia_path);
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open.open}
        onClose={onClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box className={classes.modal}>
          <Box className={classes.modalWrapper}>
            <Box className={classes.modalHeader}>
              <Typography className={classes.modalTitle}>
                Adicionar Vídeo
              </Typography>
              <ButtonCore onClick={onClose}>
                <ClearIcon />
              </ButtonCore>
            </Box>
            <Box className={classes.modalBody}>
              <Form
                ref={formRef}
                initialData={open.instance}
                style={{ display: 'contents' }}
                abortEarly
                onSubmit={handleSubmit}
                validations={VALIDATIONS}
              >
                <Field
                  label="Link do vídeo"
                  name="midia_path"
                  description="Inclua um vídeo do Youtube"
                  field={{
                    type: 'text',
                    componentProps: {
                      InputProps: {
                        inputProps: {
                          maxLength: 200,
                        },
                      },
                    },
                  }}
                  onChange={(e) => handleSetVideo(e)}
                  required
                />

                <Box>
                  <Typography className={classes.videoTitle}>
                    Prévia do vídeo
                  </Typography>

                  {video && (
                    <iframe
                      width="394px"
                      height="220.45px"
                      src={video}
                      title="Video Player"
                      className={classes.videoFrame}
                      allowFullScreen
                    />
                  )}

                  {!video && (
                    <img
                      src="/no-image.jpg"
                      alt="No thumb"
                      width="394px"
                      height="220.45px"
                      className={classes.videoFrame}
                    />
                  )}
                </Box>

                <Field
                  name="midia_thumb_path"
                  label="Miniatura personalizada do vídeo"
                  description="A imagem da miniatura será usada como uma capa ou prévia do vídeo, caso não seja selecionada, será usada a miniatura padrão do Youtube."
                  field={{
                    type: 'upload',
                    accept: 'image/*',
                    route: `productmidias/upload/${userData.marketplace.slug_name}`,
                    loading,
                  }}
                  onChange={(value) => {
                    formRef.current?.setFieldValue(
                      'midia_thumb_path',
                      value.url
                    );
                  }}
                  slot="12"
                />
              </Form>
            </Box>
            <Box className={classes.modalFooter}>
              <Button className={classes.cancelButton} onClick={onClose}>
                Cancelar
              </Button>

              <Button
                className={classes.saveButton}
                onClick={() => {
                  formRef.current?.submitForm();
                }}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddVideoModal;
