import {
  useState,
  ReactNode,
  MouseEvent,
  ChangeEvent,
  CSSProperties,
} from 'react';
import {
  makeStyles,
  Paper,
  LabelDisplayedRowsArgs,
  Typography,
  Table as TableCore,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import { NotInterestedOutlined } from '@material-ui/icons';
import clsx from 'clsx';

import Tooltip from './Tooltip';

type Cell = { index: number; row: Record<string, any>; column: ColDef };

export type ColDef = {
  field: string;
  headerName?: string;
  reference?: string;
  description?: string;
  width?: string | number;
  style?: CSSProperties;
  onClick?(
    event: MouseEvent<HTMLTableHeaderCellElement, globalThis.MouseEvent>,
    { index, row, column }: Cell
  ): void;
  renderHeader?({ index, column }: Omit<Cell, 'row'>): ReactNode;
  renderCell?({ index, row, column }: Cell): ReactNode;
  disableByPermission?: 'update' | 'delete' | 'create' | 'read';
};

interface LocaleText {
  labelDisplayedRows?(paginationInfo: LabelDisplayedRowsArgs): ReactNode;
  labelRowsPerPage?: string;
  labelNoRows?: string;
}

interface TableProps {
  columns: ColDef[];
  readonly rows: Record<string, any>[];
  pagination?: true;
  paginationMode?: 'client' | 'server';
  page?: number;
  rowCount?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onPageChange?(
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ): void;
  onPageSizeChange?(
    event: ChangeEvent<HTMLInputElement>,
    rowsPerPage: number
  ): void;
  localeText?: LocaleText;
  components?: {
    NoRowsOverlay?: ReactNode;
  };
  style?: CSSProperties;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    minHeight: 250,
    scrollbarColor: '#456bef',
    scrollbarWidth: 'thin',
    overflowX: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#456bef',
      borderRadius: 20,
    },
    '& table': {
      '& thead': {
        '& tr': {
          '& th': {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(15),
            color: '#444444',
            border: 'none',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            '&:first-of-type': {
              paddingLeft: 0,
            },
            '&:last-of-type': {
              paddingRight: 0,
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '&:not(:last-of-type)': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          },
          '& td': {
            border: 0,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            fontSize: theme.typography.pxToRem(14),
            color: '#444444',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 160,
            height: 50,
            '&:first-of-type': {
              paddingLeft: 0,
            },
            '&:last-of-type': {
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
      },
    },
  },
  cellDescription: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  noRowsWrapper: {
    width: '100%',
    position: 'absolute',
    height: '100%',
    top: 0,
    paddingTop: 40,
  },
  noRowsContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Table(props: TableProps) {
  const {
    columns,
    pagination,
    paginationMode = 'client',
    rowsPerPageOptions,
    onPageChange,
    onPageSizeChange,
    localeText,
  } = props;
  const classes = useStyles();

  const [page, setPage] = useState(props.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10);

  let { rows, components } = props;

  function generateIdRow() {
    return Math.random().toString(36).substring(2);
  }

  function handleChangePage(
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ) {
    if (pagination && paginationMode === 'server') {
      onPageChange?.(event, page);
    }

    setPage(page);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    if (pagination && paginationMode === 'server') {
      onPageSizeChange?.(event, +event.target.value);
    }

    setRowsPerPage(+event.target.value);

    setPage(0);
  }

  if (pagination && paginationMode === 'client') {
    rows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }

  return (
    <Paper elevation={0}>
      <TableContainer
        style={props.style}
        className={clsx(classes.tableContainer, props.className)}
      >
        <TableCore>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                const children =
                  column.renderHeader?.({ index, column }) ||
                  column.headerName ||
                  column.field;

                return (
                  <TableCell
                    key={`column:${index}:${column.field}`}
                    width={column.width}
                    style={column.style}
                  >
                    {column.description ? (
                      <Tooltip
                        arrow
                        title={column.description}
                        children={<span children={children} />}
                      />
                    ) : (
                      children
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .filter((row) => row.$deleted !== true)
              .map((row, indexRow) => (
                <TableRow key={`row:${indexRow}:${generateIdRow()}`}>
                  {columns.map((column, indexColumn) => {
                    return (
                      <TableCell
                        key={`column:${
                          indexRow * indexColumn
                        }:${generateIdRow()}`}
                        onClick={(event) =>
                          column.onClick?.(event, {
                            index: indexRow,
                            row,
                            column,
                          })
                        }
                        children={
                          column.renderCell?.({
                            index: indexRow,
                            row,
                            column,
                          }) || <span children={row[column.field]} />
                        }
                      />
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </TableCore>
        {!rows.length && (
          <div className={classes.noRowsWrapper}>
            {components?.NoRowsOverlay || (
              <div className={classes.noRowsContent}>
                <NotInterestedOutlined
                  style={{
                    color: '#CFCFCF',
                    fontSize: 30,
                    marginBottom: 10,
                  }}
                />
                <Typography style={{ color: '#A9A9A9' }}>
                  {localeText?.labelNoRows || 'Sem resultados'}
                </Typography>
              </div>
            )}
          </div>
        )}
      </TableContainer>
      {pagination && rowsPerPageOptions !== undefined && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={props.rowCount || rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={localeText?.labelRowsPerPage || 'Linhas por página'}
          labelDisplayedRows={(page) =>
            localeText?.labelDisplayedRows?.(page) ||
            `${page.from}-${page.to} de ${page.count}`
          }
        />
      )}
    </Paper>
  );
}
