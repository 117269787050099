import * as yup from 'yup';

export default function (Yup: typeof yup) {
    Yup.addMethod(Yup.string, 'cnpj', function (message = '') {
        return this.test('cnpj', message, function (value = '') {
            value = value.replace(/[^\d]+/g, '');

            if (value.length >= 15 || /^(\d)\1+$/.test(value)) {
                return false;
            }

            // Valide CPF with 11 numbers

            if (value.length === 11) {
                let s = 0;
                let r = 0;

                for (let i = 1; i <= 9; i++) {
                    s += parseInt(value.substring(i - 1, i)) * (11 - i);
                }

                r = (s * 10) % 11;

                if (r === 10 || r === 11) {
                    r = 0;
                }

                if (r !== parseInt(value.substring(9, 10))) {
                    return false;
                }

                return true;
            }

            // Valide CPF with 10 numbers

            if (value.length === 10) {
                let s = 0;
                let r = 0;

                for (let i = 1; i <= 10; i++) {
                    s += parseInt(value.substring(i - 1, i)) * (12 - i);
                }

                r = (s * 10) % 11;

                if (r === 10 || r === 11) r = 0;
                if (r !== parseInt(value.substring(10, 11))) {
                    return false;
                }

                return true;
            }

            // Valide CNPJ

            const t = value.length - 2;
            const d = value.substring(t);
            const d1 = parseInt(d.charAt(0));
            const d2 = parseInt(d.charAt(1));

            const calc = (x: any) => {
                const n = value.substring(0, x);
                let y = x - 7;
                let s = 0;
                let r = 0;

                for (let i = x; i >= 1; i--) {
                    // @ts-ignore
                    s += n.charAt(x - i) * y--;

                    if (y < 2) y = 9;
                }

                r = 11 - (s % 11);

                return r > 9 ? 0 : r;
            };

            return calc(t) === d1 && calc(t + 1) === d2;
        });
    });
}
