import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
  Zoom,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface IProps {
  full: any;
}

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: 25,
    border: '1px solid rgb(221, 221, 221)',
    borderRadius: 4,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: theme.spacing(2.5, 0),

    '& .wrapper': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2.5),
      width: '100%',
      '& img': {
        height: 40,
      },
      '& .integrateBox': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        '& p': {
          fontSize: theme.typography.pxToRem(13),
          color: '#444444',
          marginRight: 15,
          textAlign: 'right',
          height: 35,
        },
        '& button': {
          backgroundColor: '#456AEF',
          fontWeight: 'bold',
          fontSize: theme.typography.pxToRem(13),
          textTransform: 'unset',
          color: 'white',
          width: 80,
          height: 35,
          paddingBottom: 8,
          border: '1px solid #456AEF',
        },
      },
      '& .noIntegrateBox': {
        '& p': {
          color: '#4AA629 !important',
        },
        '& button': {
          backgroundColor: 'transparent !important',
          color: '#E74A4A !important',
          border: '1px solid #D9D9D9 !important',
        },
      },
    },
    '& .key': {
      padding: theme.spacing(0, 2.5),
      width: '100%',
      borderTop: '1px solid rgb(221, 221, 221)',
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(-1.5),
      paddingTop: theme.spacing(1),
      color: '#666666',
      fontSize: theme.typography.pxToRem(13),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    borderRadius: 4,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    outline: 'unset',
    alignItems: 'center',
    position: 'relative',
    width: 300,
  },
}));

const PagSeguro: React.FC<IProps> = ({ full }) => {
  const classes = useStyles();

  const { search } = useLocation();
  const history = useHistory();
  const [refresh, setRefresh] = useState(true);
  const alert = useContext(AlertContext);

  const [loading, setLoading] = useState(false);

  async function authorize() {
    try {
      const {
        data: { authorizationRequest },
      } = await api.get(`users/connections/pagseguro/authorize`);

      window.location.href = `https://pagseguro.uol.com.br/v2/authorization/request.jhtml?code=${authorizationRequest.code}`;
    } catch (error: any) {
      alert.error('Não foi possível recuperar o código');
    }
  }

  async function removeIntegration() {
    try {
      await api.delete(`users/connections/pagseguro/remove`);

      delete full.payment_config;

      alert.success('Código removido');
    } catch (error: any) {
      alert.error('Não foi possível remover o código');
    } finally {
      setRefresh(!refresh);
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(search);
    const notificationCode = query.get('notificationCode');

    if (notificationCode) {
      query.delete('notificationCode');

      (async () => {
        try {
          setLoading(true);

          const { data } = await api.get(
            `users/connections/pagseguro/register`,
            { params: { notificationCode } }
          );

          full.payment_config = data;

          alert.success('Integração concluida');
        } catch (error: any) {
          alert.error('Não foi possível registrar o código');
        } finally {
          setLoading(false);

          history.replace({ search: query.toString() });
        }
      })();
    }
  }, []);

  return (
    <>
      <Box className={classes.box}>
        <Box className="wrapper">
          <img src="/logo-uol.png" alt="Logo uol" />
          <Box
            className={clsx('integrateBox', {
              noIntegrateBox: full.payment_config,
            })}
          >
            {full.payment_config && (
              <Typography>
                A sua conta PagSeguro está
                <br />
                integrada ao marketplace
              </Typography>
            )}
            <Button
              disabled={!full.marketplace_payment_config}
              onClick={() =>
                full.payment_config ? removeIntegration() : authorize()
              }
            >
              {full.payment_config ? 'Remover' : 'Integrar'}
            </Button>
          </Box>
        </Box>
        {full.payment_config && (
          <div className="key">
            Chave pública:{' '}
            <strong>{full.payment_config.pagseguro_publickey}</strong>
          </div>
        )}
      </Box>
      <Modal open={loading} className={classes.modal}>
        <Zoom in={loading}>
          <div className={classes.paper}>
            <img
              src="https://assets.pagseguro.com.br/ps-bootstrap/v6.78.1/img/logos/pagbank/pagbank-logo-animado_30px@4x.gif"
              alt="Uol logo"
              style={{
                width: '80%',
              }}
            />
            <CircularProgress
              style={{
                marginTop: 15,
                color: '#456AEF',
              }}
            />
            <Typography
              children="Integrando"
              style={{
                fontSize: 17,
                color: '#444444',
                marginTop: 15,
              }}
            />
          </div>
        </Zoom>
      </Modal>
    </>
  );
};

export default PagSeguro;
