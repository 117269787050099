export default function cpfValidation(value = '') {
    value = value.replace(/[^\d]+/g, '');

    if (value.length !== 11 || /^(\d)\1+$/.test(value)) {
        return false;
    }

    let sum = 0;
    let rest: any;

    for (let i = 1; i <= 9; i++) {
        sum += parseInt(value.substring(i - 1, i)) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(value.substring(9, 10))) {
        return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
        sum += parseInt(value.substring(i - 1, i)) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
        rest = 0;
    }

    if (rest !== parseInt(value.substring(10, 11))) {
        return false;
    }

    return true;
}
