import { useState, useImperativeHandle, forwardRef } from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import CustomEditor from '@smookeydev/ckeditor5-build-custom'

type Languages =
    | 'af'
    | 'ar'
    | 'ast'
    | 'az'
    | 'bg'
    | 'ca'
    | 'cs'
    | 'da'
    | 'de-ch'
    | 'de'
    | 'el'
    | 'en-au'
    | 'en-gb'
    | 'eo'
    | 'es'
    | 'et'
    | 'eu'
    | 'fa'
    | 'fi'
    | 'fr'
    | 'gl'
    | 'gu'
    | 'he'
    | 'hi'
    | 'hr'
    | 'hu'
    | 'id'
    | 'it'
    | 'ja'
    | 'km'
    | 'kn'
    | 'ko'
    | 'ku'
    | 'lt'
    | 'lv'
    | 'ms'
    | 'nb'
    | 'ne'
    | 'nl'
    | 'no'
    | 'oc'
    | 'pl'
    | 'pt-br'
    | 'pt'
    | 'ro'
    | 'ru'
    | 'si'
    | 'sk'
    | 'sl'
    | 'sq'
    | 'sr'
    | 'sr-latn'
    | 'sv'
    | 'th'
    | 'tk'
    | 'tr'
    | 'tt'
    | 'ug'
    | 'uk'
    | 'vi'
    | 'zh-cn'
    | 'zh';

export interface Root {}

export interface Document {
    getRoot(): Root;
    isComposing: boolean;
    isFocused: boolean;
}

export interface Event {
    name: string;
    isFocused: boolean;
    document: Document;
}

export interface Writer {
    setStyle(property: string, value: string, root: Root): void;
    document: Document;
}

export interface Editor {
    editing: {
        view: {
            change(callback: (writer: Writer) => void): void;
            document: Document;
        };
    };
    focus(): void;
    getData(): string;
    setData(data: string): void;
    destroy(): Promise<Array<any>>;
}

export interface Toolbar {
    items?: Array<string>;
    removeItems?: Array<string>;
    viewportTopOffset?: number;
    shouldNotGroupWhenFull?: boolean;
}

export interface Config {
    plugins?: any[];
    toolbar?: Toolbar | Array<string>;
    image?: any;
    mediaEmbed?: any;
    table?: {
        contentToolbar?: Array<string>;
    };
    fontSize?: {
        options?: Array<number|string>;
    };
    language?: Languages | { ui?: Languages; content?: Languages };
}

export type CKEditorRef = Editor;

export interface CKEditorProps {
    defaultValue?: string;
    value?: string;
    config?: Config;
    id?: string;
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    onReady?(editor: Editor): void;
    onChange?(event: Event, editor: Editor): void;
    onBlur?(event: Event, editor: Editor): void;
    onFocus?(event: Event, editor: Editor): void;
    onError?(
        phase: 'initialization' | 'runtime',
        willEditorRestart: boolean
    ): void;
}

export default forwardRef<CKEditorRef, CKEditorProps>(function (props, ref) {
    const {
        defaultValue,
        value,
        config = {},
        id,
        name,
        disabled,
        placeholder,
        onChange,
        onBlur,
        onFocus,
        onError,
    } = props;
    const [editor, setEditor] = useState<CKEditorRef | undefined>();
    const [waitLanguage, setWaitLanguage] = useState(Boolean(config.language));

    // @ts-ignore: Inject placeholder
    if (!config.placeholder) {
        // @ts-ignore: Inject placeholder
        config.placeholder = placeholder;
    }

    function onReady(editor: Editor) {
        setEditor(editor);

        props.onReady?.(editor);
    }

    useImperativeHandle(ref, () => editor!, [editor]);

    if (waitLanguage) {
        // @ts-ignore
        import(
            `@smookeydev/ckeditor5-build-custom/build/translations/${config.language}`
        )
            .catch(() => {
                console.error(`The language ${config.language}, not exists`);
            })
            .finally(() => {
                setWaitLanguage(false);
            });

        return null;
    }

    return (
        <CKEditor
            editor={CustomEditor}
            data={value ?? defaultValue}
            config={config}
            id={id}
            name={name}
            disabled={disabled}
            onReady={onReady}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onError={onError}
        />
    );
});
