import * as React from 'react';
import {
  Box,
  makeStyles,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import Select from 'core/components/inputs/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Images from './VariationsMedia/Images';
import { Permissions } from 'core/interfaces/page';

interface IProps {
  instance: Record<string, any>;
  permissions: Permissions;
}

interface IRef {
  getData(): Record<string, any>;
}

const useStyles = makeStyles((theme) => ({
  firstText: {
    marginTop: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#0F172A',
  },
  secondText: {
    marginTop: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#6B7280',
  },
  labelSelectVariation: {
    marginTop: '4px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#475569',
  },
  selectVarition: {
    marginTop: '8px',
    padding: '0px',
    width: '350px',
    marginRight: '10px',
  },
  accordionSummary: {
    background: '#E5E7EB',
    border: '1px solid #D1D5DB',
    borderRadius: '4px 4px 0px 0px',
    height: '48px',
    maxHeight: '48px',
    minHeight: '48px !important',
  },
  accordionSummaryText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#0F172A',
  },
  accordionDetails: {},
}));

export interface Variation {
  id: number;
  name: string;
  order: number;
  variationValues: VariationValue[];
}

export interface VariationValueMidia {
  id?: number;
  midia_type: number;
  midia_path: string;
  midia_thumb_path?: string;
  deleted?: boolean;
  order?: number;
}

export interface VariationValue {
  id: number;
  name: string;
  price_factor: number;
  order: any;
  blocked: boolean;
  variation_value_midias: VariationValueMidia[];
}

interface IInstance {
  variations: Variation[];
  selected_variation_id?: number;
}

const VariationsMedia = React.forwardRef<IRef, IProps>((props, ref) => {
  const instance = props.instance as IInstance;

  const [selectedVariationId, setSelectedVariationId] = React.useState<
    number | null
  >(instance.selected_variation_id ?? null);

  const variationSelected = instance.variations.find(
    (v) => v.id === selectedVariationId
  );

  React.useImperativeHandle(
    ref,
    () => ({
      getData() {
        return { variations: instance.variations };
      },
    }),
    []
  );

  React.useEffect(() => {
    if (selectedVariationId) {
      instance.selected_variation_id = selectedVariationId;
    }
  }, [selectedVariationId]);

  const classes = useStyles();
  return (
    <Box p="24px">
      <Typography className={classes.firstText} variant="h1">
        Galeria de mídia das variações
      </Typography>
      <Typography className={classes.secondText} variant="h3">
        Inclua as imagens ou vídeos para cada uma das variações (Opcional).
      </Typography>
      <Typography className={classes.labelSelectVariation} variant="h2">
        Selecione uma variação para incluir fotos e vídeos
      </Typography>
      <Select
        displayEmpty
        className={classes.selectVarition}
        placeholder="Selecione uma variação"
        aria-placeholder="Selecione uma variação"
        value={selectedVariationId || ''}
        onChange={(e) => setSelectedVariationId(e.target.value as number)}
      >
        {!selectedVariationId && (
          <MenuItem value={''}>Selecione uma variação</MenuItem>
        )}
        {instance.variations.map((variation) => (
          <MenuItem key={variation.id} value={variation.id}>
            {variation.name}
          </MenuItem>
        ))}
      </Select>
      <Box mt="28px">
        {!variationSelected && (
          <Typography className={classes.firstText} variant="h1">
            Nenhuma variação selecionada
          </Typography>
        )}
        {variationSelected &&
          variationSelected.variationValues.map((variationValue, i) => (
            <Box mt="12px" key={variationValue.id}>
              <Accordion square>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.accordionSummaryText}>
                    Cor: <strong>{variationValue.name}</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Images
                    onChange={(data) => {
                      variationValue.variation_value_midias = data;
                    }}
                    variationValue={variationValue}
                    permissions={props.permissions}
                    key={variationValue.id}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
      </Box>
    </Box>
  );
});

export default VariationsMedia;
