import { useState, useRef, FormEvent, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Container,
  Typography,
  TextField,
  Box,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';

import { api } from 'core/lib/api';
import AlertContext from '../core/contexts/Alert';
import AuthContext from '../core/contexts/Auth';
import { Form } from '../utils/validator';
import useMarketplaceDetails from 'core/lib/useMarketplaceDetails';
import Loading from 'core/components/Loading';
import { environment } from '../environment';

const useStyles = makeStyles((theme) => ({
  main: {
    margin: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: 'fit-content',
  },
  containerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconLock: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  formLogin: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  buttonSend: {
    margin: theme.spacing(3, 0, 1.5),
  },
  resetPassword: {
    background: 'transparent',
    boxShadow: 'none',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.54)',
    textDecoration: 'none',
  },
  showHidePassword: {
    position: 'relative',
    float: 'right',
    marginTop: '-60px',
    marginLeft: '-25px',
    zIndex: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const validations = new Form(null, {
  email: {
    required: 'Preencha o campo de email',
  },
  password: {
    required: 'Preencha o campo de senha',
  },
});

export default function () {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const alert = useContext(AlertContext);
  const refs = {
    email: useRef<HTMLInputElement>(),
    password: useRef<HTMLInputElement>(),
  };
  const [showHidePassword, setShowHidePassword] = useState({
    showPassword: false,
  });

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      const instance = {
        email: refs.email.current?.value,
        password: refs.password.current?.value,
      };

      validations.resetData(instance);

      const userValidation = await validations.isValid();

      if (userValidation?.error) {
        throw userValidation?.message;
      }

      const {
        data: { user, id: token, ttl, ...extra },
      } = await api.post('users/login', instance);

      auth.login(user, token, ttl, extra);

      history.push('/');
    } catch (error: any) {
      let message = '';

      if (error.response) {
        switch (error.response.status) {
          case 401: {
            message = 'E-mail ou senha incorretos';
            break;
          }

          default:
            message = 'Erro no servidor por favor tente mais tarde';
            break;
        }
      } else message = String(error);

      alert.error(message);
    }
  }

  function handleClickShowPassword() {
    setShowHidePassword({
      ...showHidePassword,
      showPassword: !showHidePassword.showPassword,
    });
  }

  function handleMouseDownPassword(event: any) {
    event.preventDefault();
  }

  const handlePasswordChange = (prop: any) => (event: any) => {
    setShowHidePassword({
      ...showHidePassword,
      [prop]: event.target.value,
    });
  };

  const { data: details, isLoading } = useMarketplaceDetails();

  if (isLoading) return <Loading />;

  return (
    <Container component="main" maxWidth="xs" className={classes.main}>
      <div className={classes.containerWrapper}>
        <img
          alt={details.name}
          title={details.name}
          src={details.logo_path}
          style={{
            width: '180px',
            marginBottom: 24,
            minHeight: '50px',
          }}
        />
        <form className={classes.formLogin} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            id="email"
            type="email"
            autoComplete="email"
            autoFocus
            inputRef={refs.email}
          />
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Senha"
              id="password"
              type={showHidePassword.showPassword ? 'text' : 'password'}
              onChange={handlePasswordChange('password')}
              autoComplete="current-password"
              inputRef={refs.password}
            />
            <IconButton
              color="inherit"
              style={{ color: '#999999' }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="start"
              className={clsx(classes.showHidePassword)}
            >
              {showHidePassword.showPassword ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </IconButton>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.buttonSend}
            children="Entrar"
          />
          <Link
            to="/password"
            className={classes.resetPassword}
            children="Esqueci minha senha"
          />
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {`Todos os Direitos Reservados © ${new Date().getFullYear()}`}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ marginTop: 10, fontWeight: 600 }}
        >
          Versão {environment.version}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ marginTop: 10, fontWeight: 600 }}
        >
          Versão API {details.version}
        </Typography>
      </Box>
    </Container>
  );
}
