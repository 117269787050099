import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Box } from '@material-ui/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { api } from 'core/lib/api';
import TextField from '../unform/TextField';
import AuthContext from '../contexts/Auth';
import AlertContext from '../contexts/Alert';
import Button from '../components/Button';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        height: 'fit-content',
    },
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonSend: {
        margin: theme.spacing(3, 0, 1.5),
        width: '100%',
    },
    buttonResetPassword: {
        background: 'transparent',
        boxShadow: 'none',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.54)',
        textDecoration: 'none',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(15),
    },
    labelCopyright: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(14),
    },
}));

export default function (props: { logo: string; name: string }) {
    const { logo, name } = props;
    const classes = useStyles();

    const alert = useContext(AlertContext);
    const { login } = useContext(AuthContext);

    async function onSubmit(data: any) {
        try {
            const schema = Yup.object({
                email: Yup.string()
                    .email('Preencha com um email válido')
                    .required('Preencha o campo de email'),
                password: Yup.string().required('Preencha o campo de senha'),
            });

            await schema.validate(data, {
                abortEarly: true,
            });

            const {
                data: { user, id: token, ttl, ...extra },
            } = await api.post('users/login', data);

            login(user, token, ttl, extra);
        } catch (error: any) {
            let message;

            if (error instanceof Yup.ValidationError) {
                message = error.message;
            } else {
                switch (error.response.status) {
                    case 401:
                        message = 'Senha incorreta';
                        break;

                    case 404:
                        message = 'O email digitado não foi encontrado';
                        break;

                    default:
                        message = 'Erro no servidor por favor tente mais tarde';
                        break;
                }
            }

            alert.error(message);
        }
    }

    return (
        <Container component="main" maxWidth="xs" className={classes.main}>
            <div className={classes.containerWrapper}>
                <img
                    src={logo}
                    alt={name}
                    title={name}
                    style={{ width: '180px', marginBottom: 24 }}
                />
                <Form onSubmit={onSubmit} style={{ width: '100%' }}>
                    <TextField
                        name="email"
                        fullWidth
                        type="text"
                        autoComplete="email"
                        autoFocus
                        placeholder="Email"
                        style={{
                            marginBottom: 10,
                        }}
                    />
                    <TextField
                        name="password"
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        placeholder="Senha"
                    />
                    <Button
                        type="submit"
                        className={classes.buttonSend}
                        color="#333333"
                        children="Entrar"
                    />
                    <Link
                        to="/password"
                        className={classes.buttonResetPassword}
                        children="Esqueci minha senha"
                    />
                </Form>
            </div>
            <Box mt={8}>
                <Typography
                    className={classes.labelCopyright}
                    variant="body2"
                    align="center"
                >
                    Todos os Direitos Reservados © {new Date().getFullYear()}
                </Typography>
            </Box>
        </Container>
    );
}
