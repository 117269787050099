import { Typography, IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import Checkbox from 'core/components/inputs/Checkbox';

export interface AttributeProps {
    id: number;
    name: string;
    attributeValues: { id: number; name: string; checked: boolean }[];
    onChecked(attributeValue: any, checked: boolean): void;
    onRemove(): void;
}

export default function Attribute(props: AttributeProps) {
    const { name, attributeValues } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Typography className="title">
                {name}
                <IconButton
                    className="close"
                    size="small"
                    onClick={() => props.onRemove()}
                >
                    <CloseIcon className="icon" htmlColor="#ffffff" />
                </IconButton>
            </Typography>
            {attributeValues !== null &&
                attributeValues.map((value, index) => (
                    <div key={index} className="value">
                        <Checkbox
                            checked={value.checked}
                            onChange={(_, checked) =>
                                props.onChecked(value, checked)
                            }
                        />
                        <Typography>{value.name}</Typography>
                    </div>
                ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: 180,
        marginRight: 60,
        marginBottom: 30,
        '&>p': {
            fontWeight: 700,
            color: '#444444',
            fontSize: theme.typography.pxToRem(15),
            marginBottom: 15,
            lineHeight: 1,
        },
        '& .title': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .close': {
                backgroundColor: '#CCCCCC',
                '& .icon': {
                    fontSize: theme.typography.pxToRem(15),
                },
            },
        },
        '& .value': {
            display: 'flex',
            alignItems: 'center',
            '& p': {
                fontWeight: 400,
                color: '#444444',
                fontSize: theme.typography.pxToRem(15),
                marginLeft: theme.spacing(1.5),
                lineHeight: 1,
            },
            '&:not(:last-of-type)': {
                marginBottom: theme.spacing(1.5),
            },
        },
    },
}));
