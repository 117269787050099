import { useState, useContext, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field, { FieldRef } from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import AuthContext from 'core/contexts/Auth';
import Categories, { CategoriesRef } from 'components/Categories';
import Sellers, { SellersRef } from 'components/Sellers';
import Products from './Products';
import useIsSeller from 'core/lib/useIsSeller';
import Loading from 'core/components/Loading';
import { AxiosError } from 'axios';
import RegisterLives from './RegisterLives';
import { Box } from '@material-ui/core';
import disableFields from 'core/lib/disableFields';

const VALIDATIONS = {
  name: Yup.string().required('O campo título está vazio'),
  showcase_type: Yup.object()
    .required('O campo tipo de vitrine está vazio')
    .nullable(),
  showcase_filter: Yup.object()
    .when('showcase_type', (showcase_type, schema) =>
      showcase_type.id === 2
        ? schema.required('O campo tipo de filtro está vazio')
        : schema.optional()
    )
    .nullable(),
  showcase_ordering: Yup.object()
    .when('showcase_type', (showcase_type, schema) =>
      showcase_type.id === 1 || showcase_type.id === 2
        ? schema.required('O campo tipo de filtro está vazio')
        : schema.optional()
    )
    .nullable(),
  display_type: Yup.number()
    .when('showcase_type', (showcase_type, schema) =>
      showcase_type.id === 1 || showcase_type.id === 2
        ? schema.required('O campo Layout da Vitrine mobile está vazio')
        : schema.optional()
    )
    .nullable(),
  categories: Yup.array().when('showcase_type', (showcase_type, schema) =>
    showcase_type.id === 1
      ? schema.min(1, 'O campo categorias está vazio')
      : schema.optional()
  ),
  sellers: Yup.array().when('showcase_type', (showcase_type, schema) => {
    return showcase_type.id === 3
      ? schema.min(1, 'O campo lojas está vazio')
      : schema.optional();
  }),
};

export default function Details(props: any) {
  const {
    id,
    page: { label, route, routes, permissions },
  } = props;
  const [[isNew, instance], setState] = useState([props.isNew, props.instance]);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);

  const auth = useContext(AuthContext);
  const showcaseOrderingRef = useRef<FieldRef>(null);
  const displayTypeRef = useRef<FieldRef>(null);
  const totalProductsRef = useRef<FieldRef>(null);
  const showcaseFilterRef = useRef<FieldRef>(null);
  const imageBackgroundPathRef = useRef<FieldRef>(null);
  const backgroundColorRef = useRef<FieldRef>(null);
  const descriptionRef = useRef<FieldRef>(null);
  const showCaseTypeIdRef = useRef<FieldRef>(null);
  const categoriesRef = useRef<CategoriesRef>(null);
  const categoryAreasRef = useRef<CategoriesRef>(null);
  const sellersRef = useRef<SellersRef>(null);
  const [isSeller, isLoading] = useIsSeller();

  async function onSubmit(data: Record<string, any>) {
    const {
      create: routeCreate = `${route}/custom`,
      update: routeUpdate = `${route}/custom/${id}`,
    } = routes || {};

    try {
      if (isNew) {
        data = (await api.post(routeCreate, data)).data;

        setState([false, data]);

        history.replace(`/${route}/${data.id}`);

        alert.success('Informações salvas com sucesso', 10000);
      } else {
        await api.put(routeUpdate, { ...data, id });

        alert.success('Alterações salvas com sucesso', 10000);
      }
    } catch (err: any) {
      const {
        request: { status },
      } = err as AxiosError<any>;
      let message;

      switch (status) {
        default:
          message = 'Erro no servidor ';
          break;
      }

      alert.error(message);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    sellersRef.current?.setValue(instance.sellers);
    disableFields(permissions);
  }, [instance]);

  if (isLoading) return <Loading />;

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions?.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Tipo de vitrine"
            name="showcase_type"
            field={{
              type: 'autocomplete',
              relation: 'showcase_type',
              field: 'name',
              // @ts-ignore
              route: 'showcases/custom/autocomplete/showcase_type',
              // @ts-ignore
              componentProps: {
                disabled: !isNew,
              },
            }}
            required
            slot="4"
            onChange={(value) => {
              formRef.current?.setFieldValue('showcase_ordering', null);

              showcaseOrderingRef.current?.setEditable(
                value && (value.id === 1 || value.id === 2)
              );

              displayTypeRef.current?.setEditable(
                value && (value.id === 1 || value.id === 2)
              );

              showcaseFilterRef.current?.setEditable(
                value && (value.id === 2 || value.id === 6)
              );

              categoriesRef.current?.setEditable(
                value && (value.id === 1 || value.id === 4 || value.id === 5)
              );

              categoriesRef.current?.setLabel(
                value &&
                  (value.id === 1
                    ? 'Carregar produtos da(s) categoria(s)'
                    : 'Categorias que serão exibidas nas vitrines')
              );

              totalProductsRef.current?.setEditable(
                value && (value.id === 1 || value.id === 2 || value.id === 6)
              );

              imageBackgroundPathRef.current?.setEditable(
                value && value.id === 4
              );

              categoryAreasRef.current?.setEditable(value && value.id !== 6);

              backgroundColorRef.current?.setEditable(value && value.id === 4);

              descriptionRef.current?.setEditable(value && value.id === 4);

              sellersRef.current?.setEditable(value && value.id === 3);
              showCaseTypeIdRef.current?.setEditable(value && value.id !== 6);
            }}
          />
          <Field
            label="Título"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            required
            slot="4"
          />
          <Field
            ref={totalProductsRef}
            label="Número de itens"
            name="total_products"
            field={{
              type: 'number',
              numberFormat: {
                limitOfBytes: '4',
                allowNegative: false,
              },
            }}
            editable={
              instance.showcase_type_id === 1 ||
              instance.showcase_type_id === 2 ||
              instance.showcase_type_id === 6
            }
            slot="4"
          />
          <Field
            ref={showcaseFilterRef}
            label="Tipo de filtro"
            name="showcase_filter"
            field={{
              type: 'autocomplete',
              relation: 'showcase_filter',
              field: 'name',
              // @ts-ignore
              route: () => {
                const value = formRef.current?.getData().showcase_type.id;

                return `showcases/custom/autocomplete/showcase_filter?showcase_type_id=${value}`;
              },
              // @ts-ignore
              componentProps: {
                disabled: !isNew,
              },
            }}
            required
            slot="4"
            editable={
              instance.showcase_type_id === 2 || instance.showcase_type_id === 6
            }
          />
          <Field
            ref={showcaseOrderingRef}
            label="Ordenação"
            name="showcase_ordering"
            field={{
              type: 'autocomplete',
              relation: 'showcase_ordering',
              field: 'name',
              // @ts-ignore
              route: () => {
                const value = formRef.current?.getData().showcase_type.id;

                return `showcases/custom/autocomplete/showcase_ordering?showcase_type_id=${value}`;
              },
            }}
            required
            slot="4"
            editable={
              instance.showcase_type_id === 1 || instance.showcase_type_id === 2
            }
          />
          {/* Fix this field */}
          <Field
            ref={displayTypeRef}
            label="Layout da Vitrine mobile"
            name="display_type"
            field={{
              type: 'list',
              data: [
                { value: '0', label: 'Layout padrão (Grade)' },
                { value: '1', label: 'Carrossel' },
              ],
            }}
            required
            slot="4"
            editable={
              instance.showcase_type_id === 1 || instance.showcase_type_id === 2
            }
          />
          <Field
            ref={descriptionRef}
            label="Subtitulo"
            name="description"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  multiline: true,
                  rows: 5,
                  inputProps: {
                    maxLength: 1000,
                  },
                },
              },
            }}
            editable={instance.showcase_type_id === 4}
          />
          <Field
            ref={imageBackgroundPathRef}
            label="Imagem de fundo"
            name="image_background_path"
            field={{
              type: 'upload',
              accept: 'image/*',
              crop: {
                size: {
                  height: 600,
                  width: 1050,
                },
              },
              route: `showcases/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue(
                'image_background_path',
                value.url
              );
            }}
            editable={instance.showcase_type_id === 4}
            slot="4"
          />
          <Field
            ref={backgroundColorRef}
            label="Cor de fundo"
            name="background_color"
            field={{
              type: 'color',
            }}
            editable={instance.showcase_type_id === 4}
            slot="4"
          />
          <Categories
            ref={categoriesRef}
            label="Selecione as categorias"
            relation="categories"
            instance={instance}
            editable={
              instance.showcase_type_id === 1 ||
              instance.showcase_type_id === 4 ||
              instance.showcase_type_id === 5
            }
            required
          />
          <Field
            label="Ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
          {/* <Field
            label="Será exibido na home ?"
            name="show_home"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
            editable={!isSeller}
          /> */}
          <Sellers
            ref={sellersRef}
            label="Lojas"
            editable={instance.showcase_type_id === 3}
            required
          />

          <Categories
            ref={categoryAreasRef}
            label="Exibir essa vitrine na(s) categoria(s)"
            relation="category_areas"
            instance={instance}
            editable={!isSeller && instance.showcase_type_id !== 6}
          />

          <Products
            instance={instance}
            editable={
              instance.showcase_type_id === 2 &&
              instance.showcase_filter_id === 1
            }
          />
          {instance.showcase_type_id === 6 &&
            instance.showcase_filter_id === 6 && (
              <Box mt="120px" width="100%">
                <RegisterLives showcase_id={instance.id} />
              </Box>
            )}
        </Container>
      </Form>
    </>
  );
}
