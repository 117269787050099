import { useState, forwardRef, useImperativeHandle } from 'react';

import Attribute from './Attribute';

export interface AddedProps {
    attributes: any[];
    onRemove(attribute: any): void;
}

export interface AddedRef {
    addAttribute(attribute: any): void;
    getData(): any[];
}

export default forwardRef<AddedRef, AddedProps>(function Added(props, ref) {
    const [attributes, setAttributes] = useState(props.attributes || []);

    useImperativeHandle(ref, () => ({
        addAttribute(attribute) {
            if (Array.isArray(attribute)) {
                attributes.push(...attribute);
            } else {
                attributes.push(attribute);
            }

            setAttributes([...attributes]);
        },
        getData() {
            return attributes;
        },
    }));

    return (
        <>
            {attributes.map((attribute: any, index: number) => (
                <Attribute
                    key={attribute.id}
                    id={attribute.id}
                    name={attribute.name}
                    attributeValues={attribute.attributeValues}
                    onChecked={(attributeValue, checked) => {
                        attributeValue.checked = checked;

                        setAttributes([...attributes]);
                    }}
                    onRemove={() => {
                        attributes.splice(index, 1);

                        props.onRemove(attribute);

                        setAttributes([...attributes]);
                    }}
                />
            ))}
        </>
    );
});
