import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
} from '@material-ui/lab';

import AutocompleteAsync, {
    AutocompleteProps as AutocompleteBaseProps,
} from '../components/inputs/AutocompleteAsync';

export interface AutocompleteProps
    extends Omit<AutocompleteBaseProps, 'name' | 'value'> {
    name: string;
}

export default function Autocomplete(props: AutocompleteProps) {
    const { name, defaultValue = '', ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);

    function onChange(
        event: React.ChangeEvent<{}>,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any>
    ) {
        setValue(value);

        restProps.onChange?.(event, value, reason, details);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value || (restProps.multiple ? [] : null);
                },
                setValue(_: unknown, value) {
                    setValue(value ?? (restProps.multiple ? [] : null));
                },
            });
        }
    }, [fieldName, registerField, value]);

    return (
        <AutocompleteAsync {...restProps} value={value} onChange={onChange} />
    );
}
