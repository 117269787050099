import {
    useEffect,
    useRef,
    useContext,
    useState,
    ForwardRefExoticComponent,
    RefAttributes,
} from 'react';
import { IconButton } from '@material-ui/core';
import { DeleteOutlineOutlined, EditOutlined } from '@material-ui/icons';

import { Page } from 'core/interfaces/page';
import Table, { ColDef } from 'core/components/Table';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';

export interface QuickAddProps {
    onInsert(data: Record<string, any>): void;
    page: Page;
    extra?: Record<string, any>;
}

export interface QuickAddRef {
    setDisabled(value: boolean): void;
}

export interface SegmentationProps {
    parent: {
        id: number;
        route: string;
    };
    page: Page;
    QuickAdd?: ForwardRefExoticComponent<
        QuickAddProps & RefAttributes<QuickAddRef>
    >;
    quickAddExtra?: Record<string, any>;
    disabledEdit?: boolean;
    disabledRemove?: boolean;
    onError?(error: any): void;
}

export default function Segmentation(props: SegmentationProps) {
    const {
        parent: { id, route },
        page: pageCustom,
        QuickAdd: QuickAddCustom,
        quickAddExtra,
        disabledEdit,
        disabledRemove,
    } = props;
    const colDef: ColDef[] = [];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState<{ rows: any[]; count: number }>({
        rows: [],
        count: 0,
    });
    const quickAddRef = useRef<QuickAddRef>(null);
    const alert = useContext(AlertContext);
    const limitControlled = pageCustom.listProps?.limitControlled;
    const { find: routeFind = `${route}/custom/${id}/${pageCustom.route}` } =
        pageCustom.routes || {};

    function getMax(count: number, controlled?: number[]) {
        const max = (controlled || [5, 10, 15, 25, 50, 100]).filter(
            (size) => size < count
        );

        max.push(count);

        return max;
    }

    async function loadData(search?: string) {
        try {
            const params: Record<string, any> = {};

            params.limit = rowsPerPage;

            params.skip = page * rowsPerPage;

            if (search) {
                params.search = search;
            }

            const {
                data: { data, count },
            } = await api.get(routeFind, { params });

            setData({ rows: data, count });
        } catch (error: any) {
            alert.error('Erro ao listar os dados');
        }
    }

    async function onInsert(data: Record<string, any>, isNew = true) {
        try {
            quickAddRef.current?.setDisabled(true);

            if (isNew) {
                await api.post(
                    `${route}/custom/${id}/${pageCustom.route}`,
                    data
                );

                alert.success('Dados salvos com sucesso', 10000);
            } else {
                await api.put(
                    `${route}/custom/${id}/${pageCustom.route}/${data.id}`,
                    data
                );

                alert.success('Alterações salvas com sucesso', 10000);
            }

            loadData();
        } catch (err) {
            if (typeof err === 'string') {
                alert.error(err);
            }

            props.onError?.(err);
        } finally {
            quickAddRef.current?.setDisabled(false);
        }
    }

    function onEdit(id: number) {
        // TODO: Implement edit function
    }

    async function onDelete(id: number) {
        try {
            let { delete: route = `${pageCustom.route}/custom/${id}` } =
                pageCustom.routes || {};

            if (typeof route === 'function') {
                route = route(id);
            }

            await api.delete(route);

            loadData();
        } catch (error: any) {
            alert.error('Erro ao excluir');
        }
    }

    if (pageCustom.listProps?.fields) {
        for (const key in pageCustom.listProps.fields) {
            const field = pageCustom.listProps.fields[key];

            const col: ColDef = {
                field: key,
                ...field,
            };

            colDef.push(col);
        }
    }

    if (
        pageCustom.permissions?.update !== false ||
        pageCustom.permissions?.delete !== false
    ) {
        colDef.push({
            field: 'edit',
            width: 115 / (disabledEdit || disabledRemove ? 2 : 1),
            renderHeader: () => <></>,
            renderCell: ({ row }: any) => (
                <>
                    {pageCustom.permissions?.update && !disabledEdit && (
                        <IconButton
                            onClick={() => onEdit(row.id)}
                            children={<EditOutlined />}
                            title="Editar"
                        />
                    )}
                    {pageCustom.permissions?.delete && !disabledRemove && (
                        <IconButton
                            onClick={() => onDelete(row.id)}
                            children={<DeleteOutlineOutlined />}
                            title="Excluir"
                        />
                    )}
                </>
            ),
        });
    }

    useEffect(() => {
        loadData();
    }, [rowsPerPage, page]);

    return (
        <div style={{ width: '100%' }}>
            {QuickAddCustom && (
                <QuickAddCustom
                    ref={quickAddRef}
                    onInsert={onInsert}
                    page={pageCustom}
                    extra={quickAddExtra}
                />
            )}
            <Table
                columns={colDef}
                rows={data.rows}
                pagination
                paginationMode="server"
                page={page}
                rowCount={data.count}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={getMax(
                    data.count,
                    typeof limitControlled === 'object'
                        ? limitControlled
                        : undefined
                )}
                onPageChange={(_, page) => {
                    setPage(page);
                }}
                onPageSizeChange={(_, rowsPerPage) => {
                    setRowsPerPage(rowsPerPage);
                    setPage(0);
                }}
            />
        </div>
    );
}
