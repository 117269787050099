import { makeStyles } from '@material-ui/core/styles';
import Chart from 'react-apexcharts';

interface Serie {
  name: string;
  data: number[];
}

interface Props {
  series: Serie[];
  categories: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '100%',
  },
}));

const BarGraph: React.FC<Props> = ({ series, categories }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Chart
        options={{
          plotOptions: {
            bar: {
              columnWidth: series.length > 1 ? '90%' : '60%',
              dataLabels: {
                position: 'top', // top, center, bottom
                hideOverflowingLabels: false,
              },
            },
          },

          dataLabels: {
            textAnchor: 'middle',
            offsetY: -18,
            style: {
              colors: ['#1a1a1a'],
            },
          },

          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 500,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],

          xaxis: {
            categories,
          },
        }}
        series={series}
        type="bar"
        height={400}
      />
    </div>
  );
};

export default BarGraph;
