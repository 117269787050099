import { Permissions } from "core/interfaces/page";

const disableFields = (permissions: Permissions) => {
    const canUpdate = permissions.update
    const fieldElement = document.querySelectorAll(`input, textarea, select`) as NodeListOf<HTMLInputElement>;

    for (const element of fieldElement) {
        if (!canUpdate) {
            element.disabled = !canUpdate;
            element.style.color = "#6c757d"
            element.style.fontWeight = "400"
            element.style.cursor = "not-allowed"
        }
    }
}

export default disableFields;