import { useContext, useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb from 'core/toolbox/BreadCrumb';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import AlertContext from 'core/contexts/Alert';
import AuthContext from 'core/contexts/Auth';
import MarketplaceUrl from 'components/MarketplaceUrl';
import { slugify } from 'utils/slugify';
import { api } from 'core/lib/api';
import disableFields from 'core/lib/disableFields';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import clsx from 'clsx';

const VALIDATIONS = {
  name: Yup.string().required('O campo nome está vazio'),
  slug_name: Yup.string().required('O campo URL amigável está vazio'),
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '100%',
    margin: '20px 20px 0px 0px',
    '& .MuiCardHeader-root': {
      padding: '6px',
    },
    '& .MuiCardHeader-content > span': {
      fontWeight: 700,
      paddingLeft: '12px',
    },
    '& .MuiCardHeader-action': {
      margin: '0px',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  headerTypography: {
    fontWeight: 100,
  },
  boxText: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '6px',
    marginBottom: '12px',
  },
  titleInput: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 1.5,
    color: '#475569',
  },
}));

export default function Details(props: any) {
  const {
    isNew,
    id,
    page: { label, route, routes, permissions },
    instance,
  } = props;
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);
  const auth = useContext(AuthContext);

  const [expanded, setExpanded] = useState(true);

  const classes = useStyles();

  async function onSubmit(data: Record<string, any>) {
    const {
      create: routeCreate = `${route}/custom`,
      update: routeUpdate = `${route}/custom/${id}`,
    } = routes || {};

    try {
      if (isNew) {
        data = (await api.post(routeCreate, data)).data;

        alert.success('Informações salvas com sucesso', 10000);
      } else {
        await api.put(routeUpdate, { ...data, id });

        alert.success('Alterações salvas com sucesso', 10000);
      }

      history.push(`/${route}`);
    } catch ({ request: { status } }) {
      let message;

      switch (status) {
        case 409:
          message = 'URL amigável já está em uso!';
          break;

        default:
          message = 'Erro no servidor';
          break;
      }

      alert.error(message);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumb
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => formRef.current?.submitForm()}
        disableSave={!permissions.update}
      />
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            }}
            required
            slot={!isNew && !instance.parent_id ? '12' : '7'}
            onChange={(value) => {
              formRef.current?.setFieldValue('slug_name', slugify(value));
            }}
          />
          {!(!isNew && !instance.parent_id) && (
            <Field
              label="Categoria pai"
              name="parent"
              description="Deixe este campo vazio caso este registro seja uma categoria principal"
              field={{
                type: 'autocomplete',
                relation: 'parent',
                field: 'name',
                // @ts-ignore
                route: `categorys/autocomplete-custom?${
                  instance.parent_id ? `&categoryId=${instance.parent_id}` : ''
                }`,
                // @ts-ignore
                componentProps: {
                  disabled: !isNew,
                },
              }}
              slot="5"
            />
          )}
          <Field
            label="URL amigável"
            name="slug_name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  startAdornment: <MarketplaceUrl />,
                  inputProps: {
                    maxLength: 200,
                  },
                },
              },
            }}
            slot="10"
            onChange={(value) => {
              formRef.current?.setFieldValue('slug_name', slugify(value));
            }}
            required
          />
          <Field
            label="Sequência"
            name="order"
            description="Sequência modifica a ordem em que as categorias aparecem"
            field={{
              type: 'number',
              numberFormat: {
                limitOfBytes: '2',
                allowNegative: false,
              },
            }}
            slot="2"
          />
          <Field
            label="Cor de fundo"
            name="background_color"
            field={{
              type: 'color',
            }}
            slot="4"
          />
          <Field
            label="Ícone"
            name="icon_path"
            field={{
              type: 'upload',
              accept: 'image/*',
              crop: {
                size: {
                  height: 256,
                  width: 256,
                },
              },
              route: `categorys/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue('icon_path', value.url);
            }}
            slot="4"
          />
          <Field
            label="Imagem da categoria"
            name="image_path"
            description="Recomendamos que a imagem seja quadrada com o tamanho de 200x208 pixels."
            field={{
              type: 'upload',
              accept: 'image/*',
              crop: {
                size: {
                  height: 256,
                  width: 256,
                },
              },
              route: `categorys/upload/${auth.extra!.marketplace?.slug_name}`,
            }}
            onChange={(value) => {
              formRef.current?.setFieldValue('image_path', value.url);
            }}
            slot="4"
          />
          <Field
            label="Descrição"
            name="description"
            description="Máximo de 500 caracteres"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  multiline: true,
                  rows: 5,
                  inputProps: {
                    maxLength: 500,
                  },
                },
              },
            }}
          />
          <Field
            label="Ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
          {!instance.parent_id && (
            <Field
              label="Exibir no Menu ?"
              name="show_menu"
              field={{
                type: 'boolean',
                isSwitch: true,
              }}
              positionLabel="right"
            />
          )}

          <Card className={classes.cardRoot}>
            <CardHeader
              title="SEO"
              subtitle="Configurações de SEO"
              action={
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={() => setExpanded(!expanded)}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreOutlined />
                </IconButton>
              }
              style={{
                backgroundColor: '#E5E7EB',
              }}
              titleTypographyProps={{
                variant: 'inherit',
              }}
            />

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box className={classes.boxText}>
                  <Typography className={classes.titleInput}>
                    Título para SEO
                  </Typography>
                  <Typography className={classes.subTitle}>
                    O título para SEO deve ter no máximo 70 caracteres.
                  </Typography>
                  <Field
                    name="seo_title"
                    field={{
                      type: 'text',
                      componentProps: {
                        InputProps: {
                          inputProps: {
                            maxLength: 70,
                          },
                        },
                      },
                    }}
                  />
                </Box>
                <Box className={classes.boxText}>
                  <Typography className={classes.titleInput}>
                    Descrição para SEO
                  </Typography>
                  <Typography className={classes.subTitle}>
                    A descrição para SEO deve ter no máximo 170 caracteres.
                  </Typography>
                  <Field
                    name="seo_description"
                    field={{
                      type: 'text',
                      componentProps: {
                        multiline: true,
                        rows: 4,
                        InputProps: {
                          inputProps: {
                            maxLength: 170,
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </CardContent>
            </Collapse>
          </Card>
        </Container>
      </Form>
    </>
  );
}
