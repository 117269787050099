import { FormEvent, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form } from '@unform/web';
import {
    SubmitHandler,
    FormProps,
    FormHandles as FormHandlesCore,
    FormHelpers,
} from '@unform/core';
import { AnySchema, object, ValidationError } from 'yup';

interface FormEvents {
    onError?: any;
    onBeforeSaveBeforeValidation?: any;
    onBeforeSaveAfterValidation?: any;
    onAfterSave?: any;
}

interface Props
    extends Omit<FormProps, 'onError' | 'ref' | 'onSubmit'>,
        FormEvents {
    onSubmit?: SubmitHandler<any>;
    abortEarly?: boolean;
    validations?: Record<string, AnySchema>;
}

export type FormHandles = FormHandlesCore;

export default forwardRef<FormHandles, Props>(function (props, ref) {
    const {
        onSubmit,
        abortEarly = false,
        validations,
        onError,
        onBeforeSaveBeforeValidation,
        onBeforeSaveAfterValidation,
        onAfterSave,
        ...rest
    } = props;

    const formRef = useRef<FormHandles>(null);

    async function handleSubmit(
        data: Record<string, any>,
        helpers: FormHelpers,
        event?: FormEvent
    ) {
        if (formRef.current) {
            try {
                formRef.current.setErrors({});

                await onBeforeSaveBeforeValidation?.(formRef.current);

                if (validations) {
                    const schema = object(validations);

                    await schema.validate(data, {
                        abortEarly: Boolean(abortEarly),
                    });
                }

                await onBeforeSaveAfterValidation?.(formRef.current);

                onSubmit?.(data, helpers, event);

                onAfterSave?.(formRef.current);
            } catch (error: any) {
                const validationErrors: Record<string, any> = {};

                if (error instanceof ValidationError) {
                    error.inner.forEach((error) => {
                        validationErrors[error.path || ''] = error.message;
                    });

                    formRef.current.setErrors(validationErrors);

                    onError?.(error);
                }

                throw error;
            }
        }
    }

    useImperativeHandle(ref, () => formRef.current!);

    return (
        <Form ref={formRef} onSubmit={handleSubmit} {...rest}>
            {rest.children}
        </Form>
    );
});
