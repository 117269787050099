import { ReactNode, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { SelectProps as SelectBaseProps } from '@material-ui/core/Select';

import SelectCore from '../components/inputs/Select';

export interface SelectProps extends Omit<SelectBaseProps, 'name' | 'value'> {
    name: string;
}

export default function Select(props: SelectProps) {
    const { name, defaultValue = '', ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue = defaultValue,
        registerField,
    } = useField(name);
    const defaultInputValue = isNaN(defaultFieldValue)
        ? defaultFieldValue
        : String(defaultFieldValue);
    const inputRef = useRef({ value: defaultInputValue });
    const [value, setValue] = useState(defaultInputValue);

    function onChange(
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: ReactNode
    ) {
        inputRef.current.value = event.target.value;

        restProps.onChange?.(event, child);

        setValue(inputRef.current.value);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                ref: inputRef.current,
                getValue(ref) {
                    return ref.value ?? '';
                },
                setValue(ref, value: string) {
                    ref.value = value ?? '';

                    setValue(ref.value);
                },
            });
        }
    }, [fieldName, registerField]);

    return (
        <SelectCore
            {...restProps}
            name={name}
            defaultValue={inputRef.current.value}
            value={value}
            onChange={onChange}
        />
    );
}
