import { Box, Typography } from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  helperText?: string;
}
const ColorPickerField: React.FC<IProps> = ({
  value,
  onChange,
  label,
  helperText,
}) => {
  return (
    <Box mt="16px">
      <Box display="flex" flexDirection="row">
        {/* <Box
          width="48px"
          height="24px"
          style={{
            backgroundColor: value,
            borderRadius: '2px',
          }}
        /> */}
        <Box
          width="48px"
          height="24px"
          style={{
            width: '48px',
            height: '24px',
            borderRadius: '2px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ColorPicker
            hideTextfield={true}
            value={value}
            onChange={(v) => onChange('#' + v.hex)}
            defaultValue="transparent"
          />
        </Box>
        <Typography
          style={{
            marginLeft: '8px',
            fontSize: '14px',
            color: '#374151',
          }}
        >
          {label}
        </Typography>
      </Box>
      <Typography style={{ fontSize: '14px', color: '#94A3B8' }}>
        {helperText}
      </Typography>
    </Box>
  );
};

export default ColorPickerField;
