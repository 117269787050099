import HomeAdmin from './Admin';
import HomeSeller from './Seller';
import useIsSeller from 'core/lib/useIsSeller';
import Loading from 'core/components/Loading';

export default function Details() {
    const [isSeller, isLoading] = useIsSeller();

    if (isLoading) return <Loading />;

    return <>{!isSeller ? <HomeAdmin /> : <HomeSeller />}</>;
}
