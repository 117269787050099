import { Page } from 'core/interfaces/page';
import Switchable from 'components/Switchable';
import Details from './Details';
import { Typography } from '@material-ui/core';

const Showcase: Page = {
  label: 'Vitrines',
  route: 'showcases',
  Details,
  listProps: {
    fields: {
      name: {
        headerName: 'Nome',
      },
      showcase_type: {
        headerName: 'Tipo de vitrine',
        renderCell({ column, row }) {
          const value = row[column.field];
          return <Typography id={row.id}>{value.name}</Typography>;
        },
      },
      is_active: {
        headerName: 'Ativo?',
        width: 100,
        renderCell: ({ row: { id, is_active } }) => (
          <Switchable
            id={id}
            value={is_active}
            column="is_active"
            route="showcases"
            permissions={!Showcase.permissions?.update}
          />
        ),
      },
    },
    limitControlled: [100, 150, 200, 250],
    dialogOnDelete: 'Deseja apagar a vitrine?',
  },
};

export default Showcase;
