import ButtonCore, { ButtonProps } from '@material-ui/core/Button';
import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles<Theme, { color?: string; outline?: true }>({
    root: ({ color, outline }) => ({
        backgroundColor: outline ? 'transparent' : color,
        color: outline ? color : 'white',
        border: `1px solid ${color}`,
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontSize: '0.95rem',
        padding: '6px 16px',
        height: 40,
        width: 105,
        '&:hover': {
            borderColor: `${color}80`,
            color: `${color}80`,
            backgroundColor: outline ? 'transparent' : `${color}80`,
            transition: 'background-color 300ms linear',
        },
    }),
});

export default function Button({
    className,
    ...props
}: Omit<ButtonProps, 'color'> & { color?: string; outline?: true }) {
    const propsCustom = { ...props, outline: undefined };
    const classes = useStyles({ color: props.color, outline: props.outline });

    return (
        <ButtonCore
            className={`${classes.root} ${className}`}
            {...propsCustom}
            color="default"
        />
    );
}
