import { makeStyles, Paper } from '@material-ui/core';
import palette from '../utils/palette';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: theme.spacing(2),
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        minHeight: '105px',
    },
    header: {},
    body: {},
    title: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '21px',
        paddingBottom: '5px',
    },
    content: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '36px',
    },
}));

interface IProps {
    title: string;
    content: string | number;
    color: 'primary' | 'success' | 'warning' | 'danger' | 'neutral';
}

function Card(props: IProps) {
    const { title, content, color } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <div className={classes.header}>
                <div className={classes.title}>
                    <span>{title}</span>
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.content}>
                    <span style={{ color: palette[color] }}>{content}</span>
                </div>
            </div>
        </Paper>
    );
}

export default Card;
