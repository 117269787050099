import { useEffect, useState, useContext, createElement } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Button, Dialog, makeStyles } from '@material-ui/core';
import { DeleteOutlineOutlined, EditOutlined } from '@material-ui/icons';

import { Page } from '../interfaces/page';
import Table, { ColDef } from '../components/Table';
import AlertContext from '../contexts/Alert';
import BreadCrumbAdd from '../components/BreadCrumbAdd';
import { api } from 'core/lib/api';
import useIsSeller from 'core/lib/useIsSeller';

const useStyles = makeStyles({
  dialog: {
    position: 'relative',
    '& .MuiPaper-root': {
      padding: 20,
      '& p': {
        color: 'rgb(68, 68, 68)',
        fontWeight: 700,
        fontSize: '1.2em',
        textAlign: 'center',
        marginTop: 0,
      },
      '& button': {
        backgroundColor: '#456AEF',
        color: 'white',
        fontWeight: 700,
        width: 100,
        '&.cancel': {
          border: '1px solid #456AEF',
          background: 'white',
          color: '#456AEF',
        },
        '& + button': {
          marginLeft: '20px',
        },
      },
      '& .buttonsFooter': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
});

export interface ListProps {
  page: Page;
  route?: string;
  disabledEdit?: boolean;
  disabledRemove?: boolean;
  onEdit?(id: number): void;
  onDelete?(id: number): void;
  masterEdit?(id: number): void;
}

export default function List(props: ListProps) {
  const { page: pageCustom, disabledEdit, disabledRemove } = props;
  const classes = useStyles();
  const history = useHistory();
  const colDef: ColDef[] = [];
  const [data, setData] = useState<{ rows: any[]; count: number }>({
    rows: [],
    count: 0,
  });
  const [dialog, setDialog] = useState<boolean | number>(false);
  const [page, setPage] = useState(() => {
    const url = new URL(window.location.href);
    const page = url.searchParams.get('page') || '0';
    return parseInt(page);
  });
  const [searchText, setSearchText] = useState<string | undefined>();
  const alert = useContext(AlertContext);
  const limitControlled = pageCustom.listProps?.limitControlled;
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    if (Array.isArray(limitControlled) && limitControlled.length > 0) {
      return limitControlled[0];
    }

    const url = new URL(window.location.href);

    const limit = url.searchParams.get('limit') || '15';

    return parseInt(limit);
  });
  const dialogOnDelete = pageCustom.listProps?.dialogOnDelete;
  const { find: route = `${pageCustom.route}/custom` } =
    pageCustom.routes || {};

  const [isSeller] = useIsSeller();

  const getLocalStorageSeller = localStorage.getItem('@Auth:extra') as string;
  const updateLocalStorage = JSON.parse(getLocalStorageSeller);

  if (pageCustom.listProps?.fields) {
    for (const key in pageCustom.listProps.fields) {
      const field: any = pageCustom.listProps.fields[key];
      const permissions: any = pageCustom.permissions;
      const disableByPermission = field?.disableByPermission;

      if (field.reference === 'delete' && !permissions?.delete) continue;

      if (disableByPermission && !permissions?.[disableByPermission]) continue;

      const col: ColDef = {
        field: key,
        ...field,
      };

      colDef.push(col);
    }
  }

  if (colDef[0]) {
    let userCanUpdate = false;

    if (
      colDef[0].reference === 'Atributo' &&
      pageCustom.permissions?.update === true
    ) {
      userCanUpdate = true;
    }

    if (!colDef[0].renderCell) {
      colDef[0].renderCell = ({ column: { field }, row }) => {
        return (
          <span
            onClick={() =>
              pageCustom.permissions?.update === true || userCanUpdate === true
                ? history.push(`${pageCustom.route}/${row.id}`)
                : alert.error('Você não tem permissão para editar')
            }
            style={{ color: '#456AEF', cursor: 'pointer' }}
            children={row[field]}
          />
        );
      };
    }
  }

  if (
    (pageCustom.permissions?.update !== false ||
      pageCustom.permissions?.delete !== false) &&
    pageCustom.listProps?.removeEditAndDelete !== true
  ) {
    pageCustom.permissions?.update !== false &&
      !disabledEdit &&
      colDef.push({
        field: 'edit',
        width: 1,
        reference: 'Edit',
        headerName: 'Editar',
        renderCell: ({ row }: any) => (
          <>
            <IconButton
              onClick={() => onEdit(row.id)}
              children={<EditOutlined />}
              title="Editar"
            />
          </>
        ),
      });

    pageCustom.permissions?.delete !== false &&
      !disabledRemove &&
      colDef.push({
        field: 'delete',
        width: 1,
        reference: 'Delete',
        headerName: 'Excluir',
        renderCell: ({ row }: any) => (
          <>
            <IconButton
              onClick={() => onDelete(row.id)}
              children={<DeleteOutlineOutlined />}
              title="Excluir"
            />
          </>
        ),
      });
  }

  function onEdit(id: number) {
    if (props.onEdit) {
      props.onEdit(id);
    } else {
      history.push(`${pageCustom.route}/${id}`);
    }
  }

  async function onDelete(id: number, enterWithoutDialog?: true) {
    if (props.onDelete) {
      props.onDelete(id);
    } else if (dialogOnDelete && !enterWithoutDialog) {
      setDialog(id);
    } else {
      try {
        let route = pageCustom.routes?.delete
          ? pageCustom.routes?.delete
          : `${pageCustom.route}/custom/${id}`;

        if (typeof route === 'function') {
          route = route(id);
        }

        const params: Record<string, any> = {};

        if (!isSeller && updateLocalStorage.seller_id !== null) {
          params.seller = updateLocalStorage.seller_id;
        }

        await api.delete(route, { params });

        getData();

        if (enterWithoutDialog) {
          setDialog(false);
        }

        alert.success('Registro excluído com sucesso');
      } catch (error: any) {
        alert.error('Erro ao excluir registro');
      }
    }
  }

  function getMax(count: number, controlled?: number[]) {
    const max = (controlled || [5, 10, 15, 25, 50, 100]).filter(
      (size) => size < count
    );

    max.push(count);

    return max;
  }

  useEffect(() => {
    // save page and limit in url
    const url = new URL(window.location.href);
    url.searchParams.set('limit', rowsPerPage.toString());
    url.searchParams.set('page', page.toString());
    window.history.pushState({}, '', url.toString());
  }, [page, rowsPerPage]);

  async function getData() {
    try {
      const params: Record<string, any> = {};

      params.limit = rowsPerPage;

      params.skip = page * rowsPerPage;

      if (!isSeller && updateLocalStorage.seller_id !== null) {
        params.seller = updateLocalStorage.seller_id;
      }

      if (searchText) {
        params.search = searchText;
      }

      const {
        data: { data, count },
      } = await api.get(route, { params });

      setData({ rows: data, count });
    } catch (error: any) {
      alert.error('Erro ao listar os dados');
    }
  }

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, searchText]);

  const Wrapper: React.FC = ({ children }) =>
    pageCustom.listProps?.wrapper ? (
      pageCustom.listProps?.wrapper({ children })
    ) : (
      <>{children}</>
    );

  return (
    <>
      {pageCustom.components?.BreadCrumbAdd ? (
        createElement(pageCustom.components.BreadCrumbAdd, {
          onTextChange: (text) => setSearchText(text),
        })
      ) : (
        <BreadCrumbAdd
          title={pageCustom.label}
          description={pageCustom.description}
          route={`${pageCustom.route}/new`}
          onTextChange={(text) => setSearchText(text)}
          disableButtonAdd={
            pageCustom.permissions?.create === false ? true : undefined
          }
        />
      )}
      <div style={{ padding: 30 }}>
        <Wrapper>
          <Table
            columns={colDef}
            rows={data.rows}
            pagination
            paginationMode="server"
            page={page}
            rowCount={data.count}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={getMax(
              data.count,
              typeof limitControlled === 'object' ? limitControlled : undefined
            )}
            onPageChange={(_, page) => {
              setPage(page);
            }}
            onPageSizeChange={(_, rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
              setPage(0);
            }}
          />
          {dialogOnDelete && (
            <Dialog
              className={classes.dialog}
              onClose={() => setDialog(false)}
              open={dialog !== false}
            >
              <p>{dialogOnDelete}</p>
              <div className="buttonsFooter">
                <Button
                  children="Cancelar"
                  className="cancel"
                  onClick={() => setDialog(false)}
                />
                <Button
                  children="Sim"
                  onClick={() =>
                    typeof dialog === 'number' && onDelete(dialog, true)
                  }
                />
              </div>
            </Dialog>
          )}
        </Wrapper>
      </div>
    </>
  );
}
