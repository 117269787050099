import { useContext, useState, forwardRef, useImperativeHandle } from 'react';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Drawer,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import * as Icons from '@material-ui/icons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AuthContext from '../core/contexts/Auth';

export interface Menu {
  route?: string;
  title: string;
  icon: string;
  collapsed?: boolean;
  submenu?: Omit<Menu, 'submenu' | 'collapsed'>[];
}

export interface SidebarProps {
  menus: Menu[];
  logo: string;
  onClickItemMenu: (route: string) => void;
}

export interface SidebarRef {
  open: boolean;
  toggle(): void;
}

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    background: '#f9f9f9 !important',
  },
  drawerHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    width: '100%',
  },
  drawerHeaderImage: {
    objectFit: 'contain',
    objectPosition: 'center',
    height: '80px',
    width: '100%',
    marginTop: theme.spacing(4),
    padding: theme.spacing(0, 4),
    cursor: 'pointer',
  },
  drawerHeaderName: {
    fontSize: '14px',
    marginTop: theme.spacing(1.5),
    fontWeight: 'bold',
    textAlign: 'center',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  drawerList: {
    padding: theme.spacing(0, 0, 2),
    marginTop: theme.spacing(2),
    scrollbarColor: '#E8E8E8',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E8E8E8',
    },
    overflow: 'auto',
  },
  drawerListItemText: {
    '& span': {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      marginBottom: 1,
    },
  },
  drawerListItemIcon: {
    minWidth: 30,
    '& span': {
      fontSize: theme.typography.pxToRem(20),
      margin: 2,
    },
  },
  drawerListItemChildIcon: {
    marginLeft: 25,
  },
  drawerListItem: {
    height: '40px !important',
    padding: `${theme.spacing(0.5, 3, 0.5, 4)} !important`,
    color: '#374151 !important',
    '&:hover': {
      backgroundColor: '#DCE5FD',
    },
  },
  drawerListItemFather: {
    height: '40px !important',
    padding: `${theme.spacing(0.5, 3, 0.5, 4)} !important`,
  },
  drawerListItemFatherCollapsed: {
    backgroundColor: '#456AEF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#456AEF',
    },
  },
  drawerListItemIsolatedSelected: {
    color: '#FFFFFF !important',
    fontWeight: 600,
  },
  drawerListItemSelected: {
    color: '#334EB0 !important',
    fontWeight: 600,
  },
  drawerListItemChildSelected: {
    backgroundColor: '#DCE5FD !important',
  },
}));

export default forwardRef<SidebarRef, SidebarProps>(function (props, ref) {
  const { menus, onClickItemMenu } = props;
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const currentMenu = pathname.split('/').slice(1)[0];
  const [open, setOpen] = useState(true);
  const auth = useContext(AuthContext);

  function ItemIcon(props: { icon: string; color?: string }) {
    try {
      const { icon, color } = props;
      // @ts-ignore
      const IconCustom = Icons[icon];

      return <IconCustom style={{ color }} />;
    } catch (error: any) {
      return null;
    }
  }

  function ListItemFake(props: {
    route?: string;
    children: any;
    onClick?: () => any;
    collapsed?: boolean;
    isChild?: boolean;
  }) {
    const { route, children, onClick, collapsed, isChild } = props;

    function toPage() {
      history.push(`/${route}`);
      onClickItemMenu(route || '');
    }

    if (route) {
      return (
        <ListItem
          button
          onClick={toPage}
          className={clsx(classes.drawerListItem, {
            [classes.drawerListItemChildSelected]:
              currentMenu === route && isChild,
            [classes.drawerListItemFatherCollapsed]:
              currentMenu === route && !isChild,
          })}
        >
          {children}
        </ListItem>
      );
    }
    return (
      <ListItem
        button
        onClick={onClick}
        className={clsx(classes.drawerListItemFather, {
          [classes.drawerListItemFatherCollapsed]: collapsed,
        })}
      >
        {children}
      </ListItem>
    );
  }

  function Menu(
    props: Menu & {
      index: number;
      hasChilds?: true;
      onClick?: () => any;
      isChild?: true;
    }
  ): any {
    const {
      index,
      route,
      title,
      icon,
      collapsed,
      submenu,
      hasChilds,
      onClick,
      isChild,
    } = props;

    const [menuCollapse, setMenuCollapse] = useState(collapsed);

    function handleClick() {
      menus[index].collapsed = !menuCollapse;
      setMenuCollapse(!menuCollapse);
    }

    //child menu
    if (submenu) {
      return (
        <>
          <Menu
            index={index}
            route={route}
            title={title}
            icon={icon}
            hasChilds
            onClick={() => handleClick()}
          />
          <Collapse in={menuCollapse} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {submenu.map((menu, index: number) => (
                <Menu
                  key={index}
                  index={index}
                  route={menu.route}
                  title={menu.title}
                  icon={menu.icon}
                  isChild
                />
              ))}
            </List>
          </Collapse>
        </>
      );
    }

    const collapsedMenu = menus[index]?.collapsed || false;

    return (
      <ListItemFake
        route={route}
        onClick={onClick}
        collapsed={hasChilds && menus[index].collapsed}
        isChild={isChild}
      >
        <ListItemIcon
          className={clsx(classes.drawerListItemIcon, {
            [classes.drawerListItemChildIcon]: isChild,
          })}
        >
          <ItemIcon
            icon={icon}
            color={
              currentMenu === route
                ? isChild
                  ? '#334EB0'
                  : '#FFFFFF'
                : collapsedMenu && hasChilds
                ? '#FFFFFF'
                : '#374151'
            }
          />
        </ListItemIcon>
        <ListItemText
          className={clsx(classes.drawerListItemText, {
            [classes.drawerListItemSelected]: currentMenu === route && isChild,
            [classes.drawerListItemIsolatedSelected]:
              currentMenu === route && !isChild,
          })}
          primary={title}
        />
        {hasChilds &&
          (menus[index].collapsed ? <ExpandLess /> : <ExpandMore />)}
      </ListItemFake>
    );
  }

  function handleHomePage(): void {
    history.push('/');
  }

  useImperativeHandle(ref, () => ({
    open,
    toggle() {
      setOpen(!open);
    },
  }));

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.drawerHeader}>
        <img
          className={classes.drawerHeaderImage}
          src={
            auth.extra?.seller?.logo_path || auth.extra?.marketplace?.logo_path
          }
          alt="Logo"
          onClick={handleHomePage}
        />
        <span className={classes.drawerHeaderName}>
          {`${
            auth?.extra?.seller?.name === undefined
              ? 'Admin'
              : auth?.extra?.seller?.name
          }
                            / ${auth.extra?.marketplace?.name}`}
        </span>
      </div>
      <List className={classes.drawerList}>
        <Menu
          key={0}
          index={0}
          route="inicio"
          title="início"
          onClick={() => console.log('g')}
          icon="HomeOutlined"
        />
        {menus.map((menu, index) => {
          let collapsed;

          if (menu.submenu && menu.submenu.length) {
            menu.submenu = menu.submenu.filter(
              ({ route }: any) => route !== 'brands'
            );

            collapsed =
              menu.submenu.some((menu) => currentMenu === menu.route) ||
              menu.collapsed;
          }

          return (
            <Menu
              key={index}
              index={index}
              route={menu.route}
              title={menu.title}
              icon={menu.icon}
              collapsed={collapsed}
              submenu={menu.submenu}
            />
          );
        })}
      </List>
    </Drawer>
  );
});
