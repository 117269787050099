import { useEffect, useState } from 'react';
import DashTable from 'core/components/DashTable';
import { Box, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IDashTable, { exampleTable } from 'core/interfaces/IDashTable';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
  Box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 10,
  },
}));

const StyledRating = withStyles({
  iconFilled: {
    color: '#456AEF',
  },
})(Rating);

const RecentsRatings = ({ data }: any) => {
  const classes = useStyles();
  const [table, setTable] = useState<IDashTable>(exampleTable);

  useEffect(() => {
    setTable({
      title: 'Últimas avaliações',
      columns: ['Produto', 'Avaliação', 'Loja'],
      rows: data?.map((rating: any) => {
        return [
          {
            value: (
              <Box className={classes.Box}>
                <img
                  src={rating.product_midia}
                  alt="product"
                  width="50px"
                  height="50px"
                />
                <span>{rating.product_name}</span>
              </Box>
            ),
          },
          {
            value: (
              <Box className={classes.Box}>
                <StyledRating
                  value={rating.product_score}
                  size="small"
                  readOnly
                />
                <span style={{ whiteSpace: 'pre-line' }}>
                  {rating.product_comment}
                </span>
              </Box>
            ),
          },
          { value: rating.seller_name },
        ];
      }),
    });
  }, [data]);

  return <DashTable {...table} />;
};

export default RecentsRatings;
