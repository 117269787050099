import { useState, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  makeStyles,
  Typography,
  IconButton,
  ListItem,
  Collapse,
  List,
  Box,
} from '@material-ui/core';

import {
  Delete,
  ExpandLess,
  ExpandMore,
  ControlPoint,
} from '@material-ui/icons';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import BreadCrumb from 'core/toolbox/BreadCrumb';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import moment from 'moment';

function ItemMenu(props: {
  name: string;
  children: Record<string, any>[];
  onClickAdd(child: any): void;
}) {
  const { name, children, onClickAdd } = props;
  const classes = useStylesItem();
  const [menuCollapse, setMenuCollapse] = useState(false);

  function handleClick() {
    setMenuCollapse(!menuCollapse);
  }

  return (
    <div className={classes.wrap}>
      <ListItem
        disableGutters
        onClick={() => handleClick()}
        style={{
          color: '#0F172A',
          padding: '16px 13px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#e5e7eb',
          fontSize: '14px',
        }}
      >
        {name}
        <IconButton size="small">
          {menuCollapse ? (
            <ExpandLess fontSize="small" />
          ) : (
            <ExpandMore fontSize="small" />
          )}
        </IconButton>
      </ListItem>
      <Collapse
        in={menuCollapse}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
      >
        <List component="div" disablePadding>
          {children.map((child, index) => (
            <ListItem key={index} disableGutters>
              <span
                style={{
                  color: '#456AEF',
                  fontWeight: '500',
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: 5,
                }}
              >
                {child.name}
                {child.images_count && (
                  <Typography className={classes.addSubTitleText}>
                    Banner: {child.images_count} imagens
                  </Typography>
                )}
                {/* {showcaseName && (
                  <Typography className={classes.addSubTitleText}>
                    {showcaseName}
                  </Typography>
                )} */}
              </span>
              <IconButton
                size="small"
                style={{ marginLeft: 'auto' }}
                onClick={() => onClickAdd(child)}
              >
                <ControlPoint fontSize="small" htmlColor="#456AEF" />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
}

export default function Details(props: any) {
  const { instance } = props;
  const classes = useStyles();
  const [items, setItems] = useState<any[]>(instance.config);
  const alert = useContext(AlertContext);

  function addItem(type: '0' | '1', id: number, extra: any) {
    items.push({
      col: 1,
      row: items.length + 1,
      type: +type,
      id,
      ...extra,
    });

    setItems([...items]);
  }

  function removeItem(index: number) {
    items.splice(index, 1);

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      item.row = i + 1;
    }

    setItems([...items]);
  }

  async function onSubmit() {
    try {
      await api.put('marketplacearchitectureconfigs/home-builder', items);

      alert.success('Alterações no layout realizadas com sucesso');
    } catch (error: any) {
      alert.error('Não foi possivel salvar a alteração no layout');
    }
  }

  function onDragEnd(result: any) {
    if (result.destination) {
      const _items: any[] = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      for (let i = 0; i < _items.length; i++) {
        const item = _items[i];

        item.row = i + 1;
      }

      setItems(_items);
    }
  }

  function reorder(list: any, startIndex: any, endIndex: any) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  }

  return (
    <>
      <BreadCrumb
        label="Editor da homepage"
        isNew={false}
        route="theme-configs"
        disableCancel
        onSave={onSubmit}
      />
      <div className={classes.wrap}>
        <div className={classes.builder}>
          <Typography className="title">
            Editar blocos ou seções da página
          </Typography>
          <Typography className="subtitle">
            Clique e arraste as seções para ordená-los. Arraste para cima ou
            para baixo para modificar sua posição.
          </Typography>

          <div className="top">Topo - Cabeçalho</div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="list"
                >
                  {items.map((item: any, index: number) => (
                    <Draggable
                      key={`item-${index}`}
                      draggableId={`item-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.bannerItemWrapper}
                        >
                          <Box className={classes.dragIndicatorWrapper}>
                            <DragIndicatorIcon
                              className={classes.dragIndicator}
                            />
                          </Box>
                          <Box className={classes.bannerItemContainer}>
                            <Box display="flex">
                              <Typography className={classes.bannerTitlePrefix}>
                                {item.type === 0 ? 'Banners:' : 'Vitrine:'}
                              </Typography>

                              <Box ml="4px">
                                <Typography className={classes.bannerTitle}>
                                  {item.type === 0
                                    ? item.bannerGroup.name
                                    : item.showcaseType.name}
                                </Typography>
                              </Box>
                            </Box>

                            <Box mt="4px" display="flex">
                              <Typography className={classes.subTitlePrefix}>
                                {item.type === 0
                                  ? item?.bannerGroup?.expire_at
                                    ? 'Banner programado:'
                                    : `Banners: ${Number(
                                        item?.bannerGroup?.images_count
                                      )} imagens`
                                  : item.showcaseType.showcase.name}
                              </Typography>

                              {item.type === 0 &&
                                item?.bannerGroup?.expire_at &&
                                moment().isAfter(
                                  item.bannerGroup.expire_at
                                ) && (
                                  <Box display="flex" ml="8px">
                                    <Typography
                                      className={classes.subTitleExpired}
                                    >
                                      Expirado em{' '}
                                      {moment(
                                        item.bannerGroup.expire_at
                                      ).format('DD/MM/YYYY')}
                                    </Typography>

                                    <Box ml="8px">
                                      <Typography
                                        className={
                                          classes.subTitleExpiredSuffix
                                        }
                                      >
                                        (Este banner ficará oculto no
                                        marketplace)
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}

                              {item.type === 0 &&
                                item?.bannerGroup?.expire_at &&
                                moment().isBefore(
                                  item.bannerGroup.expire_at
                                ) && (
                                  <Box display="flex" ml="8px">
                                    <Typography
                                      className={classes.subTitleExpiresAt}
                                    >
                                      Vencimento em{' '}
                                      {moment(
                                        item.bannerGroup.expire_at
                                      ).format('DD/MM/YYYY')}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          </Box>
                          <Box ml="auto" className={classes.trashWrapper}>
                            <IconButton
                              style={{
                                width: 55,
                                height: 55,
                                color: '#0F172A',
                              }}
                              onClick={() => removeItem(index)}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="bottom">Rodapé</div>
        </div>
        <div className={classes.toolbox}>
          <Typography className="title">Adicionar blocos ao layout</Typography>
          <List component="div" disablePadding>
            {instance.showcaseTypes.map((showcaseType: any, index: number) => (
              <>
                {Boolean(showcaseType.showcases?.length) && (
                  <ItemMenu
                    key={index}
                    name={`Vitrine: ${showcaseType.name}`}
                    children={showcaseType.showcases}
                    onClickAdd={(showcase) => {
                      addItem('1', showcase.id, {
                        showcaseType: {
                          name: showcaseType.name,
                          showcase: {
                            name: showcase.name,
                          },
                        },
                      });
                    }}
                  />
                )}
              </>
            ))}
            {Boolean(instance.bannerGroups?.length) && (
              <ItemMenu
                name="Grupo de banners"
                children={instance.bannerGroups.filter(
                  (bannerGroup: any) =>
                    !bannerGroup.expire_at ||
                    moment().isBefore(bannerGroup.expire_at)
                )}
                onClickAdd={(bannerGroup) => {
                  addItem('0', bannerGroup.id, {
                    bannerGroup: {
                      name: bannerGroup.name,
                    },
                  });
                }}
              />
            )}
          </List>
        </div>
      </div>
    </>
  );
}

const WIDTH = 400;

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
  },
  builder: {
    width: `calc(100% - ${WIDTH}px)`,
    padding: 30,
    '& .title': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(17),
      color: '#444444',
    },
    '& .subtitle': {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(15),
      color: '#444444',
    },
    '& .top, & .bottom': {
      background: '#F2F2F2',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      color: '#858585',
      padding: theme.spacing(1.5, 0),
      textAlign: 'center',
      fontWeight: 600,
      borderRadius: 5,
    },
    '& .top': {
      margin: theme.spacing(4, 0, 2),
    },
    '& .bottom': {
      marginTop: theme.spacing(2),
    },
    '& .list': {
      width: '100%',
      '& .item': {
        border: '2px #D2D7DC',
        borderStyle: 'dashed',
        borderRadius: 5,
        display: 'flex',
        background: 'white',
        '&:not(:last-of-type)': {
          marginBottom: theme.spacing(2),
        },
        '& .indicator': {
          margin: 'auto 0 auto 10px',
          color: '#444444',
        },
        '& .itemWraper': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          padding: '5px 0 5px 10px',
          '& .itemType': {
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(15),
            color: '#444444',
          },
          '& .itemTitle': {
            fontSize: theme.typography.pxToRem(15),
            color: '#444444',
          },
        },
      },
    },
  },
  toolbox: {
    width: WIDTH,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 30,
    '& .title': {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(17),
      color: '#444444',
      marginBottom: theme.spacing(4),
    },
  },
  bannerTitlePrefix: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#0F172A',
  },

  bannerTitle: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#0F172A',
  },

  subTitlePrefix: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#6B7280',
  },

  subTitleExpired: {
    fontWeight: 600,
    fontSize: '12px',
    color: '#F22B24',
  },
  subTitleExpiredSuffix: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#F22B24',
  },
  subTitleExpiresAt: {
    fontWeight: 600,
    fontSize: '12px',
    color: '#FF850A',
  },

  bannerItemWrapper: {
    width: '100%',
    display: 'flex',
    background: 'white',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    marginBottom: '24px',
    borderRadius: 5,
  },

  bannerItemContainer: {
    padding: '20px 24px 20px 10px',
  },

  dragIndicator: {
    color: '#6B7280',
  },

  dragIndicatorWrapper: {
    background: '#D1D5DB',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '6px',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },

  trashWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const useStylesItem = makeStyles((theme) => ({
  wrap: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 5,
    background: 'white',
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  collapse: {
    padding: '5px 10px',
  },
  addSubTitleText: {
    color: '#6B7280',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },
}));
