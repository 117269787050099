import {
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    ReactNode,
    useContext,
} from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';

import Modal from '../../components/Modal';
import Container from '../Container';
import Form, { FormHandles } from '../../components/Form';
import AlertContext from '../../contexts/Alert';
import FooterButtons, { FooterButtonsRef } from './FooterButtons';

export interface ModalBuilderProps {
    title: string;
    validations?: Record<string, Yup.AnySchema>;
    onClose?(instance?: Record<string, any>, extra?: any): void;
    children: ReactNode;
}

export interface ModalBuilderRef {
    open(instance?: Record<string, any>, extra?: any): void;
    setFieldValue(fieldName: string, value: any): void;
    getFieldValue(fieldName: string): any;
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: theme.typography.pxToRem(17.5),
        color: '#333333',
        borderBottom: '1px solid #DDDDDD',
        paddingBottom: theme.spacing(1.25),
        marginBottom: theme.spacing(4),
        width: '100%',
    },
}));

export default forwardRef<ModalBuilderRef, ModalBuilderProps>(
    function ModalBuilder(props, ref) {
        const { title, validations, children } = props;
        const classes = useStyles();
        const [state, setState] = useState<{
            open: boolean;
            instance?: Record<string, any>;
            extra?: any;
        }>({ open: false });
        const formRef = useRef<FormHandles>(null);
        const footerButtonsRef = useRef<FooterButtonsRef>(null);
        const alert = useContext(AlertContext);

        function onSubmit(data: Record<string, any>) {
            onClose(data);
        }

        function onClose(instance?: Record<string, any>) {
            props.onClose?.(instance, state.extra);

            setState({
                open: false,
                instance: undefined,
                extra: undefined,
            });
        }

        function onError(error: Yup.ValidationError) {
            alert.error(error.message);
        }

        useImperativeHandle(ref, () => ({
            open(instance?: Record<string, any>, extra?: any) {
                setState({
                    open: true,
                    instance,
                    extra,
                });
            },
            setFieldValue(fieldName: string, value: any) {
                formRef.current?.setFieldValue(fieldName, value);
            },
            getFieldValue(fieldName: string): any {
                return formRef.current?.getFieldValue(fieldName);
            }
        }));

        return (
            <Modal animation="Fade" open={state.open} onClose={() => onClose()}>
                <>
                    <Typography
                        variant="h3"
                        children={title}
                        className={classes.title}
                    />
                    <Form
                        ref={formRef}
                        style={{ display: 'contents' }}
                        initialData={state.instance}
                        validations={validations}
                        onSubmit={onSubmit}
                        onError={onError}
                        abortEarly
                    >
                        <Container noPadding>{children}</Container>
                    </Form>
                    <FooterButtons
                        ref={footerButtonsRef}
                        action={state.instance ? 'Salvar' : 'Adicionar'}
                        onCancel={() => onClose()}
                        onSubmit={() => formRef.current?.submitForm()}
                    />
                </>
            </Modal>
        );
    }
);
