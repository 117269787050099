import { makeStyles, Theme } from '@material-ui/core';
import LinearProgress, {
    LinearProgressProps,
} from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#456AEF',
    },
}));

export default function Loading(props: LinearProgressProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LinearProgress
                classes={{
                    colorPrimary: classes.colorPrimary,
                    bar: classes.bar,
                }}
                {...props}
            />
        </div>
    );
}
