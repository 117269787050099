import Tab, { TabProps } from '@material-ui/core/Tab';
import { createStyles, Theme, withStyles } from '@material-ui/core';

export default withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: '#333333',
            opacity: 1,
            fontSize: theme.typography.pxToRem(15),
            textTransform: 'uppercase',
            minWidth: 72,
            fontWeight: 600,
            marginRight: theme.spacing(4),
            padding: '6px 0',
        },
        selected: {},
    })
)((props: TabProps) => <Tab disableRipple {...props} />);
