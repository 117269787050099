import { useRef, useContext, FormEvent } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
} from '@material-ui/core';
import { api } from 'core/lib/api';
import AlertContext from '../core/contexts/Alert';

import { Form } from '../utils/validator';
import { environment } from '../environment';

import useMarketplaceDetails from 'core/lib/useMarketplaceDetails';
import Loading from 'core/components/Loading';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: 'auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        height: 'fit-content',
    },
    containerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formLogin: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    buttonSend: {
        margin: theme.spacing(3, 0, 1.5),
    },
    backPage: {
        background: 'transparent',
        boxShadow: 'none',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.54)',
        textDecoration: 'none',
    }
}));

const validations = new Form(null, {
    firstPassword: {
        required: 'Preencha o campo de senha',
    },
    secondPassword: {
        required: 'Preencha o campo de senha novamente',
        compareWith: {
            field: 'firstPassword',
            message: 'As senhas não coincidem',
        },
    }
});

export default function () {
    const classes = useStyles();
    const history = useHistory();
    const { token } = useParams<{ token: string }>();
    const alert = useContext(AlertContext);
    const refs = {
        firstPassword: useRef<HTMLInputElement>(),
        secondPassword: useRef<HTMLInputElement>(),
    };

    async function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        try {
            const instance = {
                firstPassword: refs.firstPassword.current?.value,
                secondPassword: refs.secondPassword.current?.value,
            };

            validations.resetData(instance);

            const userValidation = await validations.isValid();

            if (userValidation?.error) {
                throw new Error(userValidation?.message);
            }

            api.defaults.headers['X-Access-Token'] = token;

            const { data } = await api.post('users/reset-password-user', {
                password: instance.firstPassword,
            });

            if (data.resetPassword) {
                history.push('/');

                alert.success('Senhas alteradas com sucesso');
            } else {
                throw Error('Erro ao enviar email');
            }
        } catch (error: any) {
            if (error.response) {
                switch (error.response.status) {
                    default:
                        alert.error(
                            'Erro no servidor por favor tente mais tarde'
                        );
                        break;
                }
            } else {
                alert.error(error?.message || error);
            }
        }
    }

    const {data: details, isLoading} = useMarketplaceDetails();

    if (isLoading) return <Loading />;

    return (
        <Container component="main" maxWidth="xs" className={classes.main}>
            <div className={classes.containerWrapper}>
                <img
                    alt={details.name}
                    title={details.name}
                    src={details.logo_path}
                    style={{ width: '180px', marginBottom: 24 }}
                />
                <form
                    className={classes.formLogin}
                    noValidate
                    onSubmit={onSubmit}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Senha"
                        id="password"
                        type="password"
                        autoComplete="current-password"
                        inputRef={refs.firstPassword}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Digite a senha novamente"
                        id="password"
                        type="password"
                        autoComplete="current-password"
                        inputRef={refs.secondPassword}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        // eslint-disable-next-line react/no-children-prop
                        children="Enviar"
                        className={classes.buttonSend}
                    />
                    <Link
                        to="/"
                        className={classes.backPage}
                        children="Voltar para o login"
                    />
                </form>
            </div>
            <Box mt={8}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                >
                    {`Todos os Direitos Reservados © ${new Date().getFullYear()}`}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ marginTop: 10, fontWeight: 600 }}
                >
                    Versão {environment.version}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ marginTop: 10, fontWeight: 600 }}
                >
                    Versão API {details.version}
                </Typography>
            </Box>
        </Container>
    );
}
