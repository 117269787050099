import { makeStyles } from '@material-ui/core/styles';
import { Typography, Icon } from '@material-ui/core';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import CpfTable from './CpfTable';
import Installment from './Installment';
import Pagarme from './Pagarme';
import PagSeguro from './PagSeguro';
import useSWR from 'swr';
//import { api } from 'core/lib/api';
//import { useAsync } from 'react-use';
//import { getUserData } from 'core/lib/getUserData';

export interface ConnectionProps {
  instance?: Record<string, any>;
}

const useStyles = makeStyles((theme) => ({
  exclamation: {
    color: '#E74A4A',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '&, & span': {
      fontSize: 13,
    },
    '& span': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

export default function Connection(props: ConnectionProps) {
  const { instance: full = {} } = props;
  const classes = useStyles();

  const { data: paymentMethoad } = useSWR('/marketplaces/payment-gateway');

  return (
    <>
      <ContainerCustom title="Integrações">
        {!full.marketplace_payment_config && (
          <Typography className={classes.exclamation}>
            <Icon className="fas fa-exclamation-circle" />
            Intergração não pode ser concluida, pois está faltando informações
            do marketplace
          </Typography>
        )}
        {paymentMethoad?.payment_gateway_id === 1 && <PagSeguro full={full} />}
        {paymentMethoad?.payment_gateway_id === 2 && <Pagarme />}
        {full.marketplace_boleto_installment_actived && (
          <Installment
            actived={
              full?.payment_config?.boleto_installment_actived === null
                ? false
                : full?.payment_config?.boleto_installment_actived
            }
          />
        )}
      </ContainerCustom>
      {full?.marketplace_boleto_installment_actived &&
        full?.payment_config?.boleto_installment_actived && (
          <ContainerCustom title="CPFs aprovados">
            <CpfTable />
          </ContainerCustom>
        )}
    </>
  );
}
