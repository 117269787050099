import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Zoom,
  makeStyles,
} from '@material-ui/core';
import Modal from 'core/components/Modal';
import Dialog from 'core/components/NewDialog';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import { getUserData } from 'core/lib/getUserData';
import Field from 'core/toolbox/Field';
import { useEffect, useState, useContext } from 'react';
import { useAsyncFn } from 'react-use';


const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: 25,
    border: '1px solid rgb(221, 221, 221)',
    borderRadius: 4,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: theme.spacing(2.5, 0),
    '& .wrapper': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2.5),
      width: '100%',
      '& img': {
        height: 70,
      },
      '& .integrateBox': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        '& p': {
          fontSize: theme.typography.pxToRem(13),
          color: '#444444',
          marginRight: 15,
          textAlign: 'right',
          height: 35,
        },
        '& button': {
          backgroundColor: '#456AEF',
          fontWeight: 'bold',
          fontSize: theme.typography.pxToRem(13),
          textTransform: 'unset',
          color: 'white',
          width: 80,
          height: 35,
          paddingBottom: 8,
          border: '1px solid #456AEF',
        },
      },
      '& .noIntegrateBox': {
        '& p': {
          color: '#4AA629 !important',
        },
        '& button': {
          backgroundColor: 'transparent !important',
          color: '#E74A4A !important',
          border: '1px solid #D9D9D9 !important',
        },
      },
    },
    '& .key': {
      padding: theme.spacing(0, 2.5),
      width: '100%',
      borderTop: '1px solid rgb(221, 221, 221)',
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(-1.5),
      paddingTop: theme.spacing(1),
      color: '#666666',
      fontSize: theme.typography.pxToRem(13),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& div:nth-child(3)': {
      padding: 0,
      width: '30vw',
    },
  },
  paper: {
    backgroundColor: '#fff',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    outline: 'unset',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    maxHeight: '80vh',
    padding: theme.spacing(2.5, 2.5, 5),
  },
  modalDescription: {
    fontSize: theme.typography.pxToRem(13),
    color: '#666666',

    marginBottom: theme.spacing(2),
  },
  integrationButton: {
    backgroundColor: 'transparent !important',
    color: '#E74A4A !important',
    border: '1px solid #D9D9D9 !important',
  },
  integrationText: {
    color: '#4AA629 !important',
  },
  wrapperBalanceWithdraw: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '10px 20px',
    flexDirection: 'row',
    ' & > * + *': {
      marginLeft: '20px',
    },
  },
  wrapperBalance: {
    backgroundColor: '#DCDCDC',
    borderRadius: '4px',
    padding: '10px 20px',
    '& .title': {
      fontSize: '13px',
      color: '#666666',
      fontWeight: 'bold',
    },
    '& .value': {
      fontSize: '13px',
      color: '#666666',
      fontWeight: 'bold',
    },
  },
  btnWithdraw: {
    backgroundColor: '#456AEF',
    fontWeight: 'bold',
    fontSize: '13px',
    textTransform: 'unset',
    color: 'white',
    padding: '8px 20px',
    border: '1px solid #456AEF',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      backgroundColor: '#456AEF',
      color: 'white',
      opacity: 0.8,
    },
    '&:disabled': {
      backgroundColor: '#456AEF',
      color: 'white',
      opacity: 0.5,
    },
  },
}));

const Pagarme = () => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogConfirmRemoveIntegration, setDialogConfirmRemoveIntegration] =
    useState(false);
  const [dialogConfirmWithdraw, setDialogConfirmWithdraw] = useState(false);
  const [loading, setLoading] = useState(false);

  const [documentType, setDocumentType] = useState('');

  const alert = useContext(AlertContext);

  const [pagarmeIntegration, fetchPagarmeIntegration] = useAsyncFn(async () => {
    const useData = getUserData();
    return (
      await api.get('marketplaces/integrations/pagarme', {
        params: {
          seller_id: useData?.seller_id || null,
        },
      })
    ).data;
  }, []);

  const [pagarmeBalance, fetchPagarmeBalance] = useAsyncFn(async () => {
    const useData = getUserData();
    return (
      await api.get('marketplaces/integrations/pagarme/balance', {
        params: {
          seller_id: useData?.seller_id || null,
        },
      })
    ).data;
  }, []);

  const isPagarMeIntegrated = !!pagarmeIntegration.value?.receiver_id;


   useEffect(() => {
    fetchPagarmeIntegration();
    fetchPagarmeBalance();
  }, []); 

/*   useEffect(() => {
    fetchPagarmeIntegration();
    fetchPagarmeBalance();
    console.log('DocumentType changed:('+ documentType +')');
  }, [documentType]); */

  const withdrawBalance = async () => {
    setLoading(true);

    try {
      await api.post('marketplaces/integrations/pagarme/withdraw', {
        seller_id: getUserData()?.seller_id,
        amount: pagarmeBalance.value!.available_amount
      });

      alert.success('Saque realizado com sucesso!');
      setDialogConfirmWithdraw(false);
      await fetchPagarmeBalance();
    } catch (error: any) {
      alert.error(error.response.data.message);
    }

    setLoading(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    try {
      await api.post('marketplaces/integrations/pagarme', {
        holder_type: e.target.holder_type.value,
        holder_name: e.target.holder_name.value,
        phone : e.target.phone.value,
        e_mail : e.target.e_mail.value,
        holder_document: e.target.holder_document.value,
        birthdate : e.target.birthdate.value,
        monthly_income : e.target.monthly_income.value,
        professional_occupation : e.target.professional_occupation.value,
        annual_revenue :  e.target.annual_revenue ? e.target.annual_revenue.value : null,
        bank: e.target.bank.value,
        branch_number: e.target.branch_number.value,
        branch_check_digit: e.target.branch_check_digit.value,
        account_number: e.target.account_number.value,
        account_check_digit: e.target.account_check_digit.value,
        account_type: e.target.account_type.value
      });

      alert.success('Integração realizada com sucesso!');
      setModalOpen(false);
      setDocumentType('');
    } catch (error: any) {
      console.log('Error:'+ error.message); // Exibe a mensagem de erro
      alert.error('Não foi possível realizar a integração.');
    }

    await fetchPagarmeIntegration();
    setLoading(false);
  };

  const removeIntegration = async () => {
    setLoading(true);

    try {
      await api.delete('marketplaces/integrations/pagarme');
      alert.success('Integração removida com sucesso!');
    } catch (error: any) {
      alert.error('Não foi possível remover a integração.');
    }

    await fetchPagarmeIntegration();
    setLoading(false);
  };

  return (
    <Box className={classes.box}>
      <Box className="wrapper">
        <img src="/logo-pagar-me.png" alt="Logo Pagar.me" />
        <Box className={'integrateBox'}>
          {isPagarMeIntegrated ? (
            <>
              <Typography className={classes.integrationText}>
                A sua conta Pagar.me está
                <br />
                integrada ao marketplace
              </Typography>
              <Button
                className={classes.integrationButton}
                onClick={() => setDialogConfirmRemoveIntegration(true)}
              >
                Remover
              </Button>
              <Dialog
                title="Remover integração com Pagar.me"
                description="Atenção! Você tem certeza que deseja remover a integração com o Pagar.me?"
                open={dialogConfirmRemoveIntegration}
                onClose={() => setDialogConfirmRemoveIntegration(false)}
                cancelButton={{
                  text: 'Cancelar',
                  color: '#456AEF',
                  onClick: () => setDialogConfirmRemoveIntegration(false),
                }}
                confirmButton={{
                  text: 'Confirmar',
                  backgroundColor: '#456AEF',
                  onClick: () => removeIntegration(),
                }}
              />
            </>
          ) : (
            <Button onClick={() => { 
                setModalOpen(true);
                setDocumentType(''); 
              }}>
              Integrar
          </Button>
          )}
        </Box>
      </Box>

      {isPagarMeIntegrated && (
        <Box className={classes.wrapperBalanceWithdraw}>
          {pagarmeBalance.value && (
            <>
              <Box className={classes.wrapperBalance}>
                <Typography className="title">Saldo disponível</Typography>
                <Typography className="value">
                  {(
                    pagarmeBalance.value!.available_amount / 100
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Box>
              <Box className={classes.wrapperBalance}>
                <Typography className="title">Saldo em espera</Typography>
                <Typography className="value">
                  {(
                    pagarmeBalance.value!.waiting_funds_amount / 100
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
              </Box>
              <Button
                className={classes.btnWithdraw}
                disabled={pagarmeBalance.value!.available_amount === 0}
                onClick={() => setDialogConfirmWithdraw(true)}
              >
                Solicitar saque
              </Button>
              <Dialog
                title="Solicitar saque"
                description="Atenção! Você tem certeza que deseja solicitar um saque?"
                open={dialogConfirmWithdraw}
                onClose={() => setDialogConfirmWithdraw(false)}
                cancelButton={{
                  text: 'Cancelar',
                  color: '#456AEF',
                  onClick: () => setDialogConfirmWithdraw(false),
                }}
                confirmButton={{
                  text: 'Confirmar',
                  backgroundColor: '#456AEF',
                  onClick: () => withdrawBalance(),
                }}
              />
            </>
          )}
        </Box>
      )}

      {isPagarMeIntegrated && (
        <Box className="key">
          Id recebedor: <strong>{pagarmeIntegration.value?.receiver_id}</strong>
        </Box>
      )}

      <Modal
        open={modalOpen}
        className={classes.modal}
        onClose={() => setModalOpen(false)}
      >
        <Zoom in={modalOpen}>
          <Box className={classes.paper}>
            <img
              src="/logo-pagar-me.png"
              alt="Pagarme logo"
              style={{
                width: '30%',
              }}
            />
            <Typography className={classes.modalDescription}>
              Configure a sua conta bancária que receberá o dinheiro das vendas
              por TED.
            </Typography>
            <form onSubmit={handleSubmit}>

              <Field
                  label="Tipo de conta"
                  name="holder_type"
                  description="Tipo de conta. 'pessoal' ou 'empresarial'"
                  field={{
                    type: 'list',
                    data: [
                      { label: 'Individual', value: 'individual' },
                      { label: 'Empresa', value: 'corporation' },
                    ],
                  }}
                  onChange={(event) => {
                    //console.log('Event:', event); // Verifique o evento
                    const value = event; // Ajuste para capturar diretamente o valor
                    //console.log('Document Type Changed:', value); // Verifique o valor
                    if (value) {
                      setDocumentType(value);
                    }
                  }}
              />

              {documentType !==''  && (
                <Field
                label="Código do banco"
                name="bank"
                description="Código do banco. Max 3 caracteres numéricos"
                field={{
                  type: 'text',
                  componentProps: {
                    inputProps: {
                      maxLength: 3,
                    },
                  },
                }}
             />
              )}
              {documentType !=='' && (
                <Field
                  label="Número da agência"
                  name="branch_number"
                  description="Número da agência. Max 4 caracteres"
                  field={{
                    type: 'text',
                    componentProps: {
                      inputProps: {
                        maxLength: 4,
                      },
                    },
                  }}
                />
              )}
               {documentType !==''  && (
                 <Field
                 label="Digito validador da agência"
                 name="branch_check_digit"
                 description="Dígito validador da agência. Max: 1 caracter numérico"
                 field={{
                   type: 'text',
                   componentProps: {
                     inputProps: {
                       maxLength: 1,
                     },
                   },
                 }}
               />
              )}
               {documentType !==''  && (
                <Field
                label="Número da conta bancária"
                name="account_number"
                description="Número da conta. Max: 13 caracteres numéricos"
                field={{
                  type: 'text',
                  componentProps: {
                    inputProps: {
                      maxLength: 13,
                    },
                  },
                }}
              />
              )}
               {documentType !==''  && (
                <Field
                label="Dígitos validadores da conta bancária"
                name="account_check_digit"
                description="Dígitos validadores da conta. Max 2 caracteres numéricos"
                field={{
                  type: 'text',
                  componentProps: {
                    inputProps: {
                      maxLength: 2,
                    },
                  },
                }}
             />
              )}
               {documentType !==''  && (
                <Field
                  label="Tipo da conta"
                  name="account_type"
                  description="Tipo da conta bancária"
                  field={{
                    type: 'list',
                    data: [
                      {
                        label: 'Corrente',
                        value: 'checking',
                      },
                      {
                        label: 'Poupança',
                        value: 'savings',
                      },
                    ],
                  }}
               />
              )}

              



              {documentType !==''  && documentType === 'corporation' && (
                <Field
                  label="Receita anual"
                  name="annual_revenue"
                  description="Receita anual da empresa"
                  field={{
                    type: 'text',
                    componentProps: {
                      inputProps: {
                        maxLength: 10,
                      },
                    },
                  }}
                /> 
              )}



              {documentType !==''  && (
                 <Field
                 label={documentType !== 'individual' ? 'Nome do representante legal' : 'Nome do titular'}
                 name="holder_name"
                 description={documentType !== 'individual' ? 'Nome do representante legal' : 'Nome do titular'}
                 field={{
                   type: 'text',
                   componentProps: {
                     inputProps: {
                       maxLength: 100,
                     },
                   },
                 }}
               />
              )}
              {documentType !==''  && (
                <Field
                label= {documentType !== 'individual' ? 'CPF do representante legal' : 'CPF do titular'}    
                name="holder_document"
                description={documentType !== 'individual' ? 'CPF do representante legal' : 'CPF do titular'} 
                field={{
                  type: 'text',
                  componentProps: {
                    inputProps: {
                      maxLength: 14, // Limita o número de caracteres para o formato XXX.XXX.XXX-XX
                      onChange: (e) => {
                        const input = e.target as HTMLInputElement;
                        let value = input.value;
                        // Remove qualquer caractere que não seja número
                        value = value.replace(/\D/g, '');
                        // Adiciona os pontos e o traço na posição correta
                        if (value.length > 3) {
                          value = value.slice(0, 3) + '.' + value.slice(3);
                        }
                        if (value.length > 6) {
                          value = value.slice(0, 7) + '.' + value.slice(7);
                        }
                        if (value.length > 9) {
                          value = value.slice(0, 11) + '-' + value.slice(11);
                        }
                        input.value = value;
                      },
                    },
                  },
                }}
              /> 
              )}
              {documentType !==''  && (
                 <Field
                 label="Telefone"
                 name="phone"
                 description="Telefone"
                 field={{
                   type: 'text',
                   maskFormat: {
                     mask: (value) => {
                       const rawValue = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
                       if (rawValue.length > 10) {
                         return [
                           '(',
                           /[1-9]/,
                           /\d/,
                           ')',
                           ' ',
                           /\d/,
                           /\d/,
                           /\d/,
                           /\d/,
                           /\d/,
                           '-',
                           /\d/,
                           /\d/,
                           /\d/,
                           /\d/,
                         ];
                       } else {
                         return [
                           '(',
                           /[1-9]/,
                           /\d/,
                           ')',
                           ' ',
                           /\d/,
                           /\d/,
                           /\d/,
                           /\d/,
                           '-',
                           /\d/,
                           /\d/,
                           /\d/,
                           /\d/,
                         ];
                       }
                     },
                   },
                 }}
               />
              )}
              {documentType !==''  && (
                 <Field
                 label="E-mail"
                 name="e_mail"
                 description="E-mail"
                 field={{
                   type: 'text',
                   componentProps: {
                     InputProps: {
                       inputProps: {
                         maxLength: 150,
                       },
                     },
                   },
                 }}
               />
              )}
              {documentType !==''  && (
                <Field
                label="Data de nascimento"
                name="birthdate"
                description={documentType !== 'individual' ? 'Data de nascimento do representante legal' : 'Data de nascimento do titular'} 
                field={{
                  type: 'text',
                  componentProps: {
                    inputProps: {
                      maxLength: 10, // Limita o número de caracteres para uma data
                      onChange: (e) => {
                        const input = e.target as HTMLInputElement; // Faz o cast para HTMLInputElement
                        let value = input.value;
                        // Remove qualquer caractere que não seja número
                        value = value.replace(/\D/g, '');
                        // Adiciona as barras na posição correta
                        if (value.length > 2) {
                          value = value.slice(0, 2) + '/' + value.slice(2);
                        }
                        if (value.length > 5) {
                          value = value.slice(0, 5) + '/' + value.slice(5);
                        }
                        input.value = value;
                      },
                    },
                  },
                }}
              />
              )}   
               {documentType !==''  && (
                <Field
                label={documentType !== 'individual' ? 'Renda mensal do representante legal' : 'Renda mensal do titular'} 
                name="monthly_income"
                description={documentType !== 'individual' ? 'Renda média mensal do representante legal' : 'Renda mensal do titular'} 
                field={{
                  type: 'text',
                  componentProps: {
                    inputProps: {
                      maxLength: 100,
                    },
                  },
                }}
              />
              )}   
               {documentType !==''  && (
                 <Field
                 label="Ocupação profissional"
                 name="professional_occupation"
                 description={documentType !== 'individual' ? 'Ocupação profissional do representante legal' : 'Ocupação profissional do titular'}
                 field={{
                   type: 'text',
                   componentProps: {
                     inputProps: {
                       maxLength: 100,
                     },
                   },
                 }}
               />
              )}   


           

          
           

           
            
              
             
            
                {/*{documentType !== '' && documentType !== 'individual' && (
               
               )} */}
              
             
                
              {documentType !==''  && (
                <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                style={{
                  marginTop: 15,
                  width: '290px',
                }}
              >
                Integrar
              </Button>
              )}  
              
            </form>
            {loading && (
              <Box>
                <CircularProgress
                  style={{
                    marginTop: 15,
                    color: '#456AEF',
                  }}
                />
                <Typography
                  children="Integrando"
                  style={{
                    fontSize: 17,
                    color: '#444444',
                    marginTop: 15,
                  }}
                />
              </Box>
            )}
          </Box>
        </Zoom>
      </Modal>
    </Box>
  );
};

export default Pagarme;
