import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { TimePickerProps as TimePickerBaseProps } from '@material-ui/pickers';

import TimePickerCore from '../components/inputs/TimePicker';

export interface TimePickerProps
    extends Omit<TimePickerBaseProps, 'name' | 'value' | 'onChange'> {
    name: string;
    onChange?: TimePickerBaseProps['onChange'];
}

export default function TimePicker(props: TimePickerProps) {
    const { name, defaultValue = null, ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);
    const [defaultInputValue, setDefaultInputValue] = useState(value);

    const onChange = (time: any) => {
        setValue(time ? time.toISOString() : null);

        setDefaultInputValue(time);

        restProps.onChange?.(time);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value ?? null;
                },
                setValue(_: unknown, value: any) {
                    onChange(value);
                },
            });
        }
    }, [fieldName, registerField, defaultInputValue]);

    return (
        <TimePickerCore
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
            format="HH:mm"
        />
    );
}
