import { useEffect, useState } from 'react';
import { useField } from '@unform/core';

import TextFieldBase, {
    TextFieldProps as TextFieldBaseProps,
} from '../components/inputs/TextField';

export interface TextFieldProps
    extends Omit<TextFieldBaseProps, 'name' | 'value'> {
    name: string;
}

export default function TextField(props: TextFieldProps) {
    const { name, defaultValue = '', ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const [value, setValue] = useState(defaultFieldValue ?? defaultValue);

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value);

        restProps.onChange?.(event);
    }

    useEffect(() => {
        if (fieldName) {
            registerField({
                name: fieldName,
                getValue() {
                    return value ?? '';
                },
                setValue(_: unknown, value: string) {
                    setValue(value ?? '');
                },
            });
        }
    }, [fieldName, registerField, value]);

    return (
        <TextFieldBase
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
        />
    );
}
