import {
  forwardRef,
  useRef,
  useImperativeHandle,
  useContext,
  useEffect,
  useState,
} from 'react';
import { api } from 'core/lib/api';
import Form, { FormHandles } from 'core/components/Form';
import InputContainer from 'core/components/InputContainer';
import AutocompleteAsync from 'core/components/inputs/AutocompleteAsync';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertContext from 'core/contexts/Alert';

export interface CategoriesBlingProps {
  instance: Record<string, any>;
  integrated?: any;
  isNew?: boolean;
  permissions?: any;
}

export interface CategoriesBlingRef {
  getData(): Record<string, any>;
}

export default forwardRef<CategoriesBlingRef, CategoriesBlingProps>(
  function Categories(props, ref) {
    const { instance, permissions } = props;
    const classes = useStyles();
    const formRef = useRef<FormHandles>(null);
    const alert = useContext(AlertContext);

    const [sellerCategories, setSellerCategories] = useState<any>([]);
    const [sellerBlingCategories, setSellerBlingCategories] = useState<any>([]);
    const [relationData, setRelationData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useState<any>([]);

    useEffect(() => {
      (async () => {
        setIsLoading(true);

        const getSellerCategories = (
          await api.get('categorys/autocomplete-path-custom')
        ).data;
        setSellerCategories(getSellerCategories);

        const getBlingCategories = (
          await api.get('sellerblingcategories/get-bling-categories')
        ).data;
        setSellerBlingCategories(getBlingCategories);

        const getRelationedCategories = (
          await api.get('sellerblingcategories/get-categories')
        ).data;
        setValue(getRelationedCategories);

        if (getSellerCategories.length > 0 && getBlingCategories.length > 0) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        getData() {
          try {
            const data = formRef.current!.getData();

            return data;
          } catch (error: any) {
            throw error;
          }
        },
      }),
      []
    );

    async function saveRelationData() {
      if (relationData.length > 0) {
        try {
          const { data } = await api.post(
            'sellerblingcategories/save-relation-data',
            relationData
          );

          if (data) {
            alert.success('Categoria vinculada com sucesso', 10000);
          }
        } catch (error: any) {
          alert.error('Erro ao vincular categoria', 10000);
          throw error;
        }
      }
    }

    async function removeRelationData(cat: number) {
      try {
        const params: Record<string, any> = {};
        params.category_id = cat;

        const { data } = await api.delete(
          'sellerblingcategories/remove-relation-data',
          { params }
        );

        if (data) {
          alert.warning('Categoria desvinculada com sucesso', 10000);
        }
      } catch (error: any) {
        alert.error('Erro ao desvincular categoria', 10000);
        throw error;
      }
    }

    return (
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        abortEarly
      >
        <ContainerCustom
          title="Vincular categorias"
          subtitle="Vincule categorias do seu marketplace às categorias do Bling"
          classes={{ content: classes.content, wrap: classes.wrap }}
        >
          {
            <div className={classes.blingCategories}>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '20px',
                }}
              >
                <div style={{ width: '50%' }}>
                  <span style={{ fontWeight: '700' }}>Categorias do Bling</span>
                </div>

                <div style={{ width: '50%' }}>
                  <span style={{ fontWeight: '700' }}>
                    Categorias do Marketplace{' '}
                  </span>
                  <span>
                    (As categorias abaixo serão associadas à categoria do Bling)
                  </span>
                </div>
              </div>

              {!isLoading ? (
                sellerBlingCategories.length > 0 ? (
                  sellerBlingCategories.map((blingCat: any) => {
                    // TODO: Refatorar e criar componente recursivo

                    const categoriesOfBling = value.find(
                      (v: any) =>
                        String(v.bling_category_id) === String(blingCat.id)
                    );
                    const defaultValues = sellerCategories.find(
                      (cat: any) => cat.id === categoriesOfBling?.category_id
                    );

                    const hasSubCategories =
                      blingCat && blingCat?.subCategories?.length > 0;
                    const hasSubCategoriesWithChields =
                      blingCat &&
                      blingCat?.subCategories?.length > 0 &&
                      blingCat?.subCategories?.some(
                        (sub: any) => sub.childrens?.length > 0
                      );

                    return (
                      <div key={blingCat.id} className={classes.categories}>
                        <div
                          key={blingCat.id}
                          className={classes.inputContainer}
                        >
                          <span className={classes.catName}>
                            {blingCat.descricao}
                          </span>
                          <InputContainer
                            label=""
                            classes={{
                              content: classes.labelBottom,
                            }}
                          >
                            <AutocompleteAsync
                              key={blingCat.id}
                              defaultValue={defaultValues}
                              options={async () => {
                                return await sellerCategories;
                              }}
                              getOptionSelected={(option, value) =>
                                option.name === value.name
                              }
                              getOptionLabel={(option) => option.name}
                              onChange={(_: unknown, value, reason) => {
                                if (reason === 'clear') {
                                  setRelationData(
                                    relationData.filter(
                                      (v: any) =>
                                        v.bling_category_id !==
                                        blingCat.idCategoria
                                    )
                                  );
                                  removeRelationData(blingCat.id);
                                }

                                if (value !== null && value.id !== undefined) {
                                  setRelationData([
                                    ...relationData,
                                    {
                                      bling_category_id: blingCat.id,
                                      bling_category_name: blingCat.descricao,
                                      category_id: value.id,
                                    },
                                  ]);
                                }
                              }}
                              onBlur={() => {
                                saveRelationData();
                              }}
                              delayLoad={500}
                              loadingText="Carregando..."
                              noOptionsText="Sem resultados"
                              disabled={!permissions.create}
                            />
                          </InputContainer>
                        </div>

                        {hasSubCategories &&
                          blingCat?.subCategories.map((sub: any) => {
                            // TODO: Refatorar e criar componente recursivo

                            const categoriesOfBling = value.find(
                              (v: any) =>
                                String(v.bling_category_id) === String(sub.id)
                            );
                            const defaultValues = sellerCategories.find(
                              (cat: any) =>
                                cat.id === categoriesOfBling?.category_id
                            );

                            return (
                              <div
                                key={sub.id}
                                className={classes.inputContainer}
                              >
                                <span
                                  className={classes.catName}
                                >{`${blingCat.descricao} - ${sub.descricao}`}</span>
                                <InputContainer
                                  label=""
                                  classes={{
                                    content: classes.labelBottom,
                                  }}
                                >
                                  <AutocompleteAsync
                                    key={sub.id}
                                    defaultValue={defaultValues}
                                    options={async () => {
                                      return await sellerCategories;
                                    }}
                                    getOptionSelected={(option, value) =>
                                      option.name === value.name
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_: unknown, value, reason) => {
                                      if (reason === 'clear') {
                                        setRelationData(
                                          relationData.filter(
                                            (v: any) =>
                                              v.bling_category_id !==
                                              sub.idCategoria
                                          )
                                        );
                                        removeRelationData(sub.id);
                                      }

                                      if (
                                        value !== null &&
                                        value.id !== undefined
                                      ) {
                                        setRelationData([
                                          ...relationData,
                                          {
                                            bling_category_id: sub.id,
                                            bling_category_name: sub.descricao,
                                            category_id: value.id,
                                          },
                                        ]);
                                      }
                                    }}
                                    onBlur={() => {
                                      saveRelationData();
                                    }}
                                    delayLoad={500}
                                    loadingText="Carregando..."
                                    noOptionsText="Sem resultados"
                                  />
                                </InputContainer>
                              </div>
                            );
                          })}

                        {hasSubCategoriesWithChields &&
                          blingCat?.subCategories.map((sub: any) =>
                            sub.childrens.map((child: any) => {
                              // TODO: Refatorar e criar componente recursivo

                              const categoriesOfBling = value.find(
                                (v: any) =>
                                  String(v.bling_category_id) ===
                                  String(child.id)
                              );
                              const defaultValues = sellerCategories.find(
                                (cat: any) =>
                                  cat.id === categoriesOfBling?.category_id
                              );

                              return (
                                <div
                                  key={child.id}
                                  className={classes.inputContainer}
                                >
                                  <span
                                    className={classes.catName}
                                  >{`${blingCat.descricao} - ${sub.descricao} - ${child.descricao}`}</span>
                                  <InputContainer
                                    label=""
                                    classes={{
                                      content: classes.labelBottom,
                                    }}
                                  >
                                    <AutocompleteAsync
                                      key={child.id}
                                      defaultValue={defaultValues}
                                      options={async () => {
                                        return await sellerCategories;
                                      }}
                                      getOptionSelected={(option, value) =>
                                        option.name === value.name
                                      }
                                      getOptionLabel={(option) => option.name}
                                      onChange={(_: unknown, value, reason) => {
                                        if (reason === 'clear') {
                                          setRelationData(
                                            relationData.filter(
                                              (v: any) =>
                                                v.bling_category_id !==
                                                sub.idCategoria
                                            )
                                          );
                                          removeRelationData(child.id);
                                        }

                                        if (
                                          value !== null &&
                                          value.id !== undefined
                                        ) {
                                          setRelationData([
                                            ...relationData,
                                            {
                                              bling_category_id: child.id,
                                              bling_category_name:
                                                child.descricao,
                                              category_id: value.id,
                                            },
                                          ]);
                                        }
                                      }}
                                      onBlur={() => {
                                        saveRelationData();
                                      }}
                                      delayLoad={500}
                                      loadingText="Carregando..."
                                      noOptionsText="Sem resultados"
                                    />
                                  </InputContainer>
                                </div>
                              );
                            })
                          )}
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.empty}>
                    <h3>Nenhuma categoria Bling encontrada</h3>
                  </div>
                )
              ) : (
                <div className={classes.empty}>
                  <CircularProgress />
                </div>
              )}
            </div>
          }
        </ContainerCustom>
      </Form>
    );
  }
);

const useStyles = makeStyles({
  content: {
    border: 'none',
  },
  wrap: {
    width: 'calc(100% + 20px)',
  },
  title: {
    marginLeft: '10px',
  },
  blingCategories: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% + -19px)',
  },
  categoriesWithChildren: {},
  labelBottom: {
    alignItems: 'unset',
    flexDirection: 'column',
    marginBottom: 10,
    '& p': {
      marginBottom: '0px',
    },
  },
  categories: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e5e5e5',
    marginBottom: '10px',
  },
  catName: {
    width: '100%',
  },
  empty: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
});
