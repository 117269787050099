import { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import BreadCrumbCore from 'core/components/BreadCrumb';
import Button from 'core/components/Button';
import ContainerCustom from 'core/toolbox/ContainerCustom';
import Form, { FormHandles } from 'core/components/Form';
import Field from 'core/toolbox/Field';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import { useHistory } from 'react-router-dom';
import disableFields from 'core/lib/disableFields';

const useStyles = makeStyles({
  content: {
    border: 'none',
  },
  wrap: {
    width: 'calc(100% + 20px)',
  },
  informations: {
    display: 'contents',
  },
  informationsContent: {
    display: 'flex',
    marginLeft: 'auto',
  },
  integrationButton: {
    '&:disabled': {
      backgroundColor: '#f1f1f1',
      border: '1px solid rgb(153, 153, 153)',
    },
  },
});

const VALIDATIONS = {
  payment_email: Yup.string().email(
    'E-mail para contato do PagSeguro inválido'
  ),
  boleto_installment_actived: Yup.boolean(),
  pagseguro_email: Yup.string()
    .email()
    .when('boleto_installment_actived', {
      is: true,
      then: Yup.string().email().required('Campo E-mail obrigatório'),
    }),
  pagseguro_token: Yup.string().when('boleto_installment_actived', {
    is: true,
    then: Yup.string().required('Campo Token obrigatório'),
  }),
};

const Installment = (props: { actived: boolean; permissions: any }) => {
  const [actived, setActived] = useState(props.actived);

  const { permissions } = props;

  return (
    <>
      {/* <Field
        label="Deseja utilizar o boleto parcelado como forma de pagamento?"
        name="boleto_installment_actived"
        onChange={(value) => {
          setActived(value);
        }}
        field={{
          type: 'boolean',
          isSwitch: true,
          disabled: !permissions?.create,
        }}
        slot="12"
      /> */}
      {actived && (
        <Field
          label="E-mail"
          name="pagseguro_email"
          field={{
            type: 'text',
            componentProps: {
              InputProps: {
                inputProps: {
                  maxLength: 150,
                },
              },
            },
            disabled: !permissions?.create,
          }}
          slot="6"
          required
        />
      )}
      {actived && (
        <Field
          label="Token"
          name="pagseguro_token"
          field={{
            type: 'text',
            componentProps: {
              InputProps: {
                inputProps: {
                  maxLength: 150,
                },
              },
            },
            disabled: !permissions?.create,
          }}
          slot="6"
          required
        />
      )}
    </>
  );
};

const Details = (props: any) => {
  const {
    page: { routes, label, permissions },
    instance,
  } = props;

  const alert = useContext(AlertContext);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [paymentMethoad, setPaymentMethoad] = useState<number>(
    instance?.payment_gateway_id
  );

  const classes = useStyles();

  const onSubmit = async (data: Record<string, any>) => {
    try {
      await api.put(routes.update, data);

      alert.success('Configurações salvas com sucesso');
    } catch (err) {
      alert.error('Não foi possível salvar as configurações do marketplace');
    }
  };

  const onError = (error: Yup.ValidationError) => {
    alert.error(error.message);
  };

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <>
      <BreadCrumbCore
        title={<span children={'Integrações'} style={{ color: '#456AEF' }} />}
        dictionary={{ action: label }}
        slot={{
          end: (
            <div className={classes.informations}>
              <div className={classes.informationsContent}>
                <Button
                  children="Voltar"
                  color="#999999"
                  outline
                  onClick={() => history.goBack()}
                  style={{ marginRight: 20 }}
                />
                <Button
                  children="Integrar"
                  color="#456AEF"
                  onClick={() => formRef.current?.submitForm()}
                  style={{ marginRight: 20 }}
                  className={classes.integrationButton}
                  disabled={!permissions?.create}
                />
              </div>
            </div>
          ),
        }}
      />

      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <ContainerCustom
          title="Meio de pagamentos"
          subtitle="Configure o meio de pagamento do marketplace"
          classes={{ content: classes.content, wrap: classes.wrap }}
        >
          <Field
            label="Meio de pagamento"
            name="payment_gateway_id"
            defaultValue={instance?.payment_gateway_id}
            field={{
              type: 'list',
              data: [
                { label: 'PagSeguro', value: '1' },
                { label: 'Pagar.me', value: '2' },
              ],
              componentProps: {
                disabled: !permissions?.create,
                inputProps: {
                  onChange: (event: any) => {
                    setPaymentMethoad(parseInt(event.target.value));
                  },
                },
              },
            }}
            slot="6"
            required
          />
        </ContainerCustom>
        {paymentMethoad === 1 && (
          <ContainerCustom
            title="Integração com PagSeguro"
            subtitle="Configure uma conta do pagseguro"
            classes={{ content: classes.content, wrap: classes.wrap }}
          >
            <Field
              label="AppID"
              name="pagseguro_appid"
              field={{
                type: 'text',
                componentProps: {
                  InputProps: {
                    inputProps: {
                      maxLength: 150,
                    },
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="AppKey"
              name="pagseguro_appkey"
              field={{
                type: 'text',
                componentProps: {
                  InputProps: {
                    inputProps: {
                      maxLength: 150,
                    },
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Quantidade de parcelas sem juros"
              name="interest_free"
              field={{
                type: 'number',
                numberFormat: {
                  limitOfBytes: '2',
                  allowNegative: false,
                  limit: {
                    min: 1,
                    max: 12,
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Quantidade de parcelas"
              name="installment"
              field={{
                type: 'number',
                numberFormat: {
                  limitOfBytes: '2',
                  allowNegative: false,
                  limit: {
                    min: 1,
                    max: 12,
                  },
                },
              }}
              slot="6"
            />
            <Installment
              actived={
                instance?.boleto_installment_actived === null
                  ? false
                  : instance?.boleto_installment_actived
              }
              permissions={permissions}
            />
          </ContainerCustom>
        )}

        {paymentMethoad === 2 && (
          <ContainerCustom
            title="Integração com Pagar.me"
            subtitle="Configure uma conta do Pagar.me"
            classes={{ content: classes.content, wrap: classes.wrap }}
          >
            <Field
              label="Chave pública"
              name="pagarme_public_key"
              field={{
                type: 'text',
                componentProps: {
                  InputProps: {
                    inputProps: {
                      maxLength: 150,
                    },
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Chave privada"
              name="pagarme_private_key"
              field={{
                type: 'text',
                componentProps: {
                  InputProps: {
                    inputProps: {
                      maxLength: 150,
                    },
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Quantidade de parcelas sem juros"
              name="interest_free"
              field={{
                type: 'number',
                numberFormat: {
                  limitOfBytes: '2',
                  allowNegative: false,
                  limit: {
                    min: 1,
                    max: 12,
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Quantidade de parcelas"
              name="installment"
              field={{
                type: 'number',
                numberFormat: {
                  limitOfBytes: '2',
                  allowNegative: false,
                  limit: {
                    min: 1,
                    max: 12,
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Taxa de juros de 1x (cobrada pelo Pagar.me)"
              name="pagarme_interest_rate_1"
              field={{
                type: 'number',
                numberFormat: {
                  limitOfBytes: '2',
                  allowNegative: false,
                  limit: {
                    max: 100,
                    min: 0,
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Taxa de juros de 2x à 6x (cobrada pelo Pagar.me)"
              name="pagarme_interest_rate_2_to_6"
              field={{
                type: 'number',
                numberFormat: {
                  limitOfBytes: '2',
                  allowNegative: false,
                  limit: {
                    max: 100,
                    min: 0,
                  },
                },
              }}
              slot="6"
            />
            <Field
              label="Taxa de juros de 7x à 12x (cobrada pelo Pagar.me)"
              name="pagarme_interest_rate_7_to_12"
              field={{
                type: 'number',
                numberFormat: {
                  limitOfBytes: '2',
                  allowNegative: false,
                  limit: {
                    max: 100,
                    min: 0,
                  },
                },
              }}
              slot="6"
            />
          </ContainerCustom>
        )}
      </Form>
    </>
  );
};

export default Details;
