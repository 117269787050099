import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Form, { FormHandles } from 'core/components/Form';
import BreadCrumb, { BreadCrumbRef } from 'core/toolbox/BreadCrumb';
import Field from 'core/toolbox/Field';
import Container from 'core/toolbox/Container';
import Tabs, { TabsRef } from 'core/components/Tabs';
import AlertContext from 'core/contexts/Alert';
import Categories from 'components/Categories';
import Segmentation from 'core/toolbox/Segmentation';
import { api } from 'core/lib/api';
import QuickAddAttribute from './QuickAddAttribute';
import disableFields from 'core/lib/disableFields';

const VALIDATIONS = {
  name: Yup.string().required('O campo nome está vazio'),
};

export default function Details(props: any) {
  const {
    id,
    page: { label, route, permissions },
  } = props;

  const [[isNew, instance], setState] = useState([props.isNew, props.instance]);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const breadCrumbRef = useRef<BreadCrumbRef>(null);
  const tabsRef = useRef<TabsRef>(null);
  const alert = useContext(AlertContext);

  async function onSubmit(data: Record<string, any>) {
    if (isNew) {
      data = (await api.post(`${route}/custom`, data)).data;

      setState([false, data]);

      history.replace(`/${route}/${data.id}`);

      tabsRef.current?.setController(1);

      breadCrumbRef.current?.setTitle('Salvar');
    } else {
      await api.put(`${route}/custom/${id}`, { ...data, id });

      setState([false, data]);

      history.replace(`/${route}/`);

      alert.success('Alterações salvas com sucesso', 10000);
    }
  }

  function onError(error: Yup.ValidationError) {
    alert.error(error.message);
  }

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  function Geral() {
    return (
      <Form
        ref={formRef}
        style={{ display: 'contents' }}
        initialData={instance}
        validations={VALIDATIONS}
        onSubmit={onSubmit}
        onError={onError}
        abortEarly
      >
        <Container>
          <Field
            label="Nome"
            name="name"
            field={{
              type: 'text',
              componentProps: {
                InputProps: {
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            }}
            required
          />
          <Categories
            label="Categorias"
            relation="categories"
            instance={instance}
          />
          <Field
            label="Ativo?"
            name="is_active"
            field={{
              type: 'boolean',
              isSwitch: true,
            }}
            positionLabel="right"
          />
        </Container>
      </Form>
    );
  }

  function Values() {
    return (
      <div style={{ padding: 30 }}>
        <Segmentation
          parent={{
            id,
            route,
          }}
          page={{
            label: 'Valores',
            route: 'attributevalues',
            permissions: {
              create: false,
              read: true,
              update: false,
              delete: true,
            },
            listProps: {
              fields: {
                name: {
                  headerName: 'Nome',
                },
              },
            },
          }}
          QuickAdd={QuickAddAttribute}
          disabledEdit
          onError={(error) => {
            if (error && error.response.status === 409) {
              alert.error('Valor já está adicionado');
            }
          }}
        />
      </div>
    );
  }

  return (
    <>
      <BreadCrumb
        ref={breadCrumbRef}
        route={route}
        label={label}
        isNew={isNew}
        creator={{
          name: instance.createby?.name,
          when: instance.created_at,
        }}
        onSave={() => {
          if (tabsRef.current!.controller === 1) {
            history.replace(`/${route}/`);
            alert.success('Informações salvas com sucesso', 10000);
          } else {
            formRef.current?.submitForm();
          }
        }}
        localeText={{
          buttonSaveLabel: isNew ? 'Continuar' : 'Salvar',
        }}
        disableSave={!permissions.update}
      />
      <Tabs
        ref={tabsRef}
        tabs={[{ label: 'Geral' }, { label: 'Valores' }]}
        disableClick={isNew ? 'right' : false}
      >
        {(controller) => {
          switch (controller) {
            case 0: {
              return <Geral />;
            }

            case 1: {
              return <Values />;
            }

            default: {
              return <></>;
            }
          }
        }}
      </Tabs>
    </>
  );
}
