import { Component, FocusEvent } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import TextFieldCore, {
    OutlinedTextFieldProps as TextFieldPropsCore,
} from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

interface NumberFormatExtraProps {
    precision?: number;
    limit?: {
        min?: number;
        max?: number;
    };
    limitOfBytes?: '2' | '4' | '8';
}

export interface NumberFormatProps extends NumberFormatExtraProps {
    thousandSeparator?: boolean | string;
    decimalSeparator?: boolean | string;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    displayType?: 'input' | 'text';
    prefix?: string;
    suffix?: string;
    format?: string | ((inputValue: string) => string);
    isNumericString?: boolean;
    customInput?: React.ComponentType<any>;
    allowNegative?: boolean;
    allowEmptyFormatting?: boolean;
    allowLeadingZeros?: boolean;
}

interface NumberFormatCustomProps {
    name: string;
    inputRef: (instance: NumberFormat<number> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    onBlur: React.FocusEventHandler<HTMLInputElement>;
}

export interface NumberFieldProps
    extends Omit<TextFieldPropsCore, 'variant' | 'size' | 'type'> {
    numberFormat?: NumberFormatProps;
    defaultValue?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'white',
        '& input, & textarea': {
            fontSize: theme.typography.pxToRem(15),
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 0,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
        },
        '& textarea': {
            resize: 'vertical',
        },
    },
}));

class NumberFormatCustom extends Component<
    NumberFormatCustomProps & {
        numberFormat: NumberFormatProps;
        defaultValue: any;
    }
> {
    state = {
        value: this.props.defaultValue,
    };

    getByte(num: number) {
        if (num >= -32768 && num <= 32768) return 2;
        if (num >= -2147483648 && num <= 2147483648) return 4;
        if (num >= -9223372036854775808 && num <= 9223372036854775808) return 8;

        return 0;
    }

    isAllowed(inputObj: NumberFormatValues, extra: NumberFormatExtraProps) {
        const { value } = inputObj;
        const { precision, limitOfBytes, limit } = extra;

        if (
            (precision && String(value).replace('.', '').length > precision) ||
            (limitOfBytes && +limitOfBytes < this.getByte(Number(value))) ||
            (limit?.min && limit.min > Number(value)) ||
            (limit?.max && limit.max < Number(value))
        ) {
            return false;
        }

        return true;
    }

    render() {
        const {
            inputRef,
            onChange,
            onBlur,
            numberFormat: { precision, limitOfBytes, limit, ...numberFormat },
            ...other
        } = this.props;

        return (
            // @ts-ignore
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    this.setState({ value: values.value });

                    onChange({
                        target: {
                            name: this.props.name,
                            value: values.value,
                        },
                    });
                }}
                onBlur={(e: FocusEvent<HTMLInputElement, Element>) => {
                    e.target.value = this.state.value;

                    onBlur(e);
                }}
                {...numberFormat}
                isAllowed={(values) =>
                    this.isAllowed(values, { precision, limitOfBytes, limit })
                }
            />
        );
    }
}

export default function NumberField(props: NumberFieldProps) {
    const { numberFormat, ...restProps } = props;
    const classes = useStyles();

    if (numberFormat) {
        restProps.InputProps = {
            ...restProps.InputProps,
            inputComponent: NumberFormatCustom as any,
            inputProps: {
                numberFormat,
                defaultValue: restProps.defaultValue || restProps.value,
            },
        };
    }

    return (
        <TextFieldCore
            {...restProps}
            type={numberFormat ? 'text' : 'number'}
            classes={{
                root: classes.root,
            }}
            variant="outlined"
            size="small"
        />
    );
}
