import {
  useState,
  useEffect,
  useContext,
  useCallback,
  useReducer,
} from 'react';
import {
  makeStyles,
  Typography,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import BreadCrumbAdd from 'core/components/BreadCrumbAdd';
import Form from 'core/components/Form';
import DatePicker from 'core/unform/DatePicker';
import Select from 'core/unform/Select';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import { currency } from 'utils/currency';
import useIsSeller from 'core/lib/useIsSeller';
import Loading from 'core/components/Loading';

interface IFilterReducer {
  search: any;
  payment_status_id: any;
  shipping_status_id: any;
  date_start: any;
  date_end: any;
}

enum EFilterType {
  SetFilter = 'SET',
  AddFilter = 'ADD',
}

const COLORS_PAYMENT = {
  1: '#FF8A00',
  2: '#456AEF',
  3: '#3AB956',
  4: '#E74A4A',
};

interface IFilterAction {
  type: EFilterType;
  key?: keyof IFilterReducer;
  value: any;
}

function reducer(state: Partial<IFilterReducer>, action: IFilterAction) {
  if (action.type === EFilterType.SetFilter) {
    return { ...action.value };
  }

  if (action.type === EFilterType.AddFilter && action.key !== undefined) {
    state[action.key] = action.value;

    return { ...state };
  }

  return state;
}

function Pickers(props: { classes: Record<string, string> }) {
  const { classes } = props;
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  return (
    <>
      <DatePicker
        name="date_start"
        disableToolbar
        className={classes.dateField}
        placeholder="Período inicial"
        format="DD/MM/YYYY"
        clearable
        InputProps={{
          startAdornment: <DateRange style={{ color: '#999999' }} />,
        }}
        maxDate={maxDate || new Date()}
        clearLabel="Limpar"
        cancelLabel="Cancelar"
        onChange={(date: any) => {
          setMinDate(date ? new Date(date.toISOString()) : undefined);
        }}
      />
      <DatePicker
        name="date_end"
        disableToolbar
        className={classes.dateField}
        placeholder="Período final"
        format="DD/MM/YYYY"
        clearable
        InputProps={{
          startAdornment: <DateRange style={{ color: '#999999' }} />,
        }}
        minDate={minDate}
        maxDate={new Date()}
        clearLabel="Limpar"
        cancelLabel="Cancelar"
        onChange={(date: any) => {
          setMaxDate(date ? new Date(date.toISOString()) : undefined);
        }}
      />
    </>
  );
}

export default function List() {
  const classes = useStyles();
  const [orders, setOrders] = useState<any[]>([]);
  const [paymentStatus, setPaymentStatus] = useState<any[]>([]);
  const [shippingStatus, setShippingStatus] = useState<any[]>([]);
  const [filter, filterDispatch] = useReducer(reducer, {});
  const alert = useContext(AlertContext);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [isSeller, isLoading] = useIsSeller();

  async function getOrders() {
    try {
      const params: any = {
        search: filter.search || undefined,
        paymentStatus: filter.payment_status_id || undefined,
        shippingStatus: filter.shipping_status_id || undefined,
        dateStart: filter.date_start || undefined,
        dateEnd: filter.date_end || undefined,
      };

      const { data } = await api.get('orders', { params });

      setPaymentStatus(data.payment_status);

      setShippingStatus(data.shipping_status);

      setOrders([...data.orders]);
    } catch (error: any) {
      alert.error('Não foi possivel carregar seus dados dos pedidos');
    }
  }

  async function exportOrders() {
    try {
      const params: any = {
        search: filter.search || undefined,
        paymentStatus: filter.payment_status_id || undefined,
        shippingStatus: filter.shipping_status_id || undefined,
        dateStart: filter.date_start || undefined,
        dateEnd: filter.date_end || undefined,
      };

      const { data } = await api.get('orders/export', {
        responseType: 'blob',
        params,
      });

      const blob = new Blob([data]);
      const url = URL.createObjectURL(blob);
      const linkDownload = document.createElement('a');

      linkDownload.href = url;
      linkDownload.download = 'export-orders.xlsx';
      linkDownload.click();
    } catch (error: any) {
      alert.error('Não foi possivel exportar os pedidos');
    }
  }

  const handleFilterSubmit = useCallback((data) => {
    data.search = filter.search || undefined;

    filterDispatch({ type: EFilterType.SetFilter, value: data });
  }, []);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  useEffect(() => {
    getOrders();
  }, [filter]);

  if (isLoading) return <Loading />;

  return (
    <>
      <BreadCrumbAdd
        route=""
        title="Vendas"
        onTextChange={(text) =>
          filterDispatch({
            type: EFilterType.AddFilter,
            key: 'search',
            value: text,
          })
        }
        disableButtonAdd
      />
      <div className={classes.filters}>
        <Typography children="Filtrar:" />
        <Form
          initialData={{
            payment_status_id: '',
            shipping_status_id: '',
          }}
          className={classes.filterForm}
          onSubmit={handleFilterSubmit}
        >
          <Select
            className={classes.select}
            name="payment_status_id"
            displayEmpty
            defaultValue={filter.payment_status_id}
          >
            <MenuItem value="">Todos pagamentos</MenuItem>
            {paymentStatus.map((status: any) => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
          {/* {
                        isSeller && */}
          <Select
            className={classes.select}
            name="shipping_status_id"
            displayEmpty
            defaultValue={filter.shipping_status_id}
          >
            <MenuItem value="">Todos pedidos</MenuItem>
            {shippingStatus.map((status: any) => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
          {/* } */}
          <Pickers classes={classes} />
          <Button
            className={classes.filterButton}
            type="submit"
            children="Filtrar"
          />
          <Button
            className={classes.exportButton}
            children="Exportar"
            onClick={exportOrders}
          />
        </Form>
      </div>
      <div className={classes.content}>
        <Table className={classes.orders}>
          <TableHead>
            <TableRow>
              <TableCell>ID ordem</TableCell>
              <TableCell>ID pedido</TableCell>
              {!isSeller && <TableCell>Loja</TableCell>}
              <TableCell>Nome Cliente</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Valor Total</TableCell>
              <TableCell>Pagamento</TableCell>
              {isSeller && <TableCell>Frete</TableCell>}
              <TableCell>Entrega</TableCell>
              {isSeller && <TableCell>Prazo</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order) => (
                <TableRow key={order.order_shipping_id}>
                  <TableCell>
                    <Link
                      className={classes.labelIdLink}
                      to={`/orders/${order.order_id}`}
                    >
                      #{order.order_id}
                    </Link>
                  </TableCell>
                  <TableCell>{order.order_shipping_id}</TableCell>
                  {!isSeller && <TableCell>{order.seller_name}</TableCell>}
                  <TableCell>{order.customer_name}</TableCell>
                  <TableCell>
                    {moment(order.date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>{currency(order.total_amount || '0')}</TableCell>
                  <TableCell
                    style={{
                      // @ts-ignore
                      color: COLORS_PAYMENT[order.payment_status_id as number],
                      fontWeight: 'bold',
                    }}
                  >
                    {order.payment_status_id !== 5 ? order.payment_status_name : '-'}
                  </TableCell>
                  {isSeller && (
                    // <TableCell>{order.shipping_type_name}</TableCell>
                    <TableCell>{order.payment_status_id !== 5 ? order.shipping_type_name : '-'}</TableCell>
                    
                  )}
                  <TableCell>{order.shipping_status_name || '-'}</TableCell>
                  {isSeller && (
                    <TableCell>
                      {/* {moment(order.shipping_deadline).format('DD/MM/YYYY')} */}
                      {order.payment_status_id !== 5 ? moment(order.shipping_deadline).format('DD/MM/YYYY') : '-'}
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <tr>
              <TablePagination
                count={orders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `Mostrando ${from}-${to} de ${count} registros`
                }
              />
            </tr>
          </TableFooter>
        </Table>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '25px 30px',
  },
  orders: {
    width: '100%',
    padding: '0 30px',
    '& th': {
      fontWeight: 'bold',
      fontSize: '15px',
    },
    '& thead': {
      '& tr': {
        '& th': {
          fontWeight: 600,
          fontSize: theme.typography.pxToRem(15),
          color: '#444444',
          border: 'none',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          '&:first-of-type': {
            paddingLeft: 0,
          },
          '&:last-of-type': {
            paddingRight: 0,
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:last-of-type td': {
          border: 'none',
        },
        '&:not(:last-of-type)': {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        '& td': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          fontSize: theme.typography.pxToRem(14),
          color: '#444444',
          height: 50,
          '&:first-of-type': {
            paddingLeft: 0,
          },
          '&:last-of-type': {
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  },
  labelIdLink: {
    textDecoration: 'none',
    color: '#456AEF',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
  },
  filters: {
    background: '#F2F2F2',
    width: '100%',
    height: 60,
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > p': {
      marginRight: 20,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  filterForm: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
  },
  filterButton: {
    backgroundColor: '#456AEF',
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    padding: '6px 16px',
    height: 40,
    width: 80,
    '&:hover': {
      backgroundColor: '#456aef84',
      transition: 'background-color 500ms linear',
    },
  },
  dateField: {
    width: 185,
    marginRight: 20,
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: theme.spacing(1.5),
      cursor: 'pointer',
    },
    '& input': {
      paddingLeft: theme.spacing(1.5),
      '&::-webkit-input-placeholder, &::placeholder': {
        opacity: 1,
        color: '#444444',
      },
      cursor: 'pointer',
    },
  },
  select: {
    marginRight: 20,
    width: 185,
  },
  formControl: {
    height: 40,
  },
  exportButton: {
    backgroundColor: '#456AEF',
    color: 'white',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    padding: '6px 16px',
    marginLeft: '15px',
    height: 40,
    width: 80,
    '&:hover': {
      backgroundColor: '#456aef84',
      transition: 'background-color 500ms linear',
    },
  },
}));
