import { Box, Button, Switch, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import Loading from 'core/components/Loading';
import AlertContext from 'core/contexts/Alert';
import { api } from 'core/lib/api';
import { useContext, useEffect } from 'react';
import { useAsyncFn, useMap } from 'react-use';
import disableFields from 'core/lib/disableFields';
import UploadComponent from './UploadComponent';

const styles = makeStyles((theme) => ({
  mainTitle: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#0F172A',
  },
  mainTitle2: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '150%',
    color: '#475569',
    fontFamily: 'Inter',
    letterSpacing: '-0.01em',
  },
  mainSubtitle: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '0em',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#9CA3AF',
    fontFamily: 'Open Sans',
    letterSpacing: '-0.01em',
  },
  subTitle2: {
    color: '#6B7280',
    fontSize: '14px',
    lineHeight: '150%',
    fontFamily: 'Inter',
    letterSpacing: '-0.01em',
  },
  boxCardInfo: {
    padding: '18px',
    color: '#1863B5',
    backgroundColor: '#EEFAFF',
    borderRadius: '4px',
  },

  inputText: {
    '& input': {
      padding: '6px',
    },
    '& div': {
      padding: '6px',
    },
  },

  textEnableInMarketplace: {
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Inter',
    letterSpacing: '-0.01em',
    fontWeight: 600,
    color: '#0F172A',
  },
  textEnableInMarketplace2: {
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'Inter',
    fontWeight: 600,
    letterSpacing: '-0.01em',
    color: '#0F172A',
  },
  switch: {
    color: '#00A245',
    '&.Mui-disabled': {
      color: '#00A245',
    },
    '& .Mui-checked': {
      color: '#00A245',
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#00A245',
    },
  },
}));

const GeneralConfigDetails = (props: any) => {
  const {
    page: { permissions },
  } = props;
  const [generalConfig, generalConfigControl] = useMap({
    title: '',
    description: '',
    wholesale: false,
  });

  const classes = styles();

  const alert = useContext(AlertContext);

  const [apiGeneralConfig, fetchApiGeneralConfig] = useAsyncFn(
    async () =>
      await api
        .get('/marketplaceseos/marketplace-seo')
        .then(({ data }) => data),
    []
  );

  const [apiSaveGeneralConfig, saveApiGeneralConfig] = useAsyncFn(
    async (title: string, description: string, wholesale: boolean) =>
      await api.put('/marketplaceseos/marketplace-seo', {
        title,
        description,
        wholesale,
      }),
    []
  );

  useEffect(() => {
    fetchApiGeneralConfig();
  }, []);

  useEffect(() => {
    const value = apiGeneralConfig?.value;
    if (value) {
      generalConfigControl.set('title', value.title);
      generalConfigControl.set('description', value.description);
      generalConfigControl.set('wholesale', value.wholesale);
    }
  }, [apiGeneralConfig]);

  const onCancel = () => {
    fetchApiGeneralConfig();
  };

  const onSave = () => {
    saveApiGeneralConfig(
      generalConfig.title,
      generalConfig.description,
      generalConfig.wholesale
    )
      .then(() => {
        alert.success('Configurações salvas com sucesso!');
      })
      .catch(() => {
        alert.error('Erro ao salvar configurações!');
      })
      .finally(() => {
        fetchApiGeneralConfig();
      });
  };

  useEffect(() => {
    disableFields(permissions);
  }, [generalConfig]);

  if (apiGeneralConfig.loading) {
    return <Loading />;
  }

  return (
    <Box width="100%">
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        py="20px"
        px="20px"
        style={{
          borderBottom: '1px solid #E5E7EB',
        }}
      >
        <Typography
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          Configurações gerais
        </Typography>

        <Box ml="auto">
          <Button
            onClick={() => onCancel()}
            style={{
              color: '#456AEF',
              border: '1px #456AEF solid',
              fontSize: '15px',
              textTransform: 'none',
            }}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => onSave()}
            style={{
              marginLeft: '10px',
              color: 'white',
              backgroundColor: '#456AEF',
              padding: '5px 20px',
              fontSize: '15px',
              textTransform: 'none',
            }}
            disabled={apiSaveGeneralConfig.loading}
          >
            {apiSaveGeneralConfig.loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </Box>
      </Box>

      {/* Content */}
      <Box px="20px" py="20px" width="100%">
        <Box width="100%" display="flex" flexDirection="column">
          <Typography className={classes.mainTitle}>
            SEO - Marketplace
          </Typography>
          <Typography className={classes.mainSubtitle}>
            Título e meta descrição ajudam a definir como as informações do site
            são exibidas nos campos de busca.
          </Typography>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" mt="24px">
          <Typography className={classes.mainTitle2}>
            Título da página inicial
          </Typography>
          <Typography className={classes.subTitle}>
            {generalConfig.title?.length} a 70 caracteres usados
          </Typography>
          <Box mt="8px" width="100%" display="flex" flexDirection="column">
            <TextField
              variant="outlined"
              value={generalConfig.title}
              className={classes.inputText}
              InputProps={{
                inputProps: {
                  maxLength: 70,
                },
              }}
              onChange={(e) =>
                generalConfigControl.set('title', e.target.value)
              }
            />
          </Box>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" mt="18px">
          <Typography className={classes.mainTitle2}>
            Meta descrição da página inicial
          </Typography>
          <Typography className={classes.subTitle}>
            {generalConfig.description?.length} a 170 caracteres usados
          </Typography>
          <Box mt="8px" width="100%" display="flex" flexDirection="column">
            <TextField
              variant="outlined"
              minRows={8}
              multiline
              className={classes.inputText}
              value={generalConfig.description}
              InputProps={{
                inputProps: {
                  maxLength: 170,
                },
              }}
              onChange={(e) =>
                generalConfigControl.set('description', e.target.value)
              }
            />
          </Box>
        </Box>

        <Box width="100%" display="flex" flexDirection="column" mt="96px">
          <Typography className={classes.mainTitle}>Tipo de venda</Typography>
          <Typography className={classes.subTitle2}>
            Ative as opções de tipo de vendas utilizados. A venda no varejo está
            configurada como padrão, você pode acrescentar outros tipos de
            venda.
          </Typography>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            className={classes.boxCardInfo}
            mt="32px"
          >
            <InfoIcon />
            <Box ml="18px">
              <Typography>
                Ao ativar a opção Venda por atacado, todas as lojas dentro do
                marketplace poderão comercializar seus produtos no atacado para
                seus clientes pessoa jurídica.
              </Typography>
            </Box>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt="25px"
          >
            <Typography className={classes.textEnableInMarketplace}>
              Disponibilizar Venda por Atacado no Marketplace
            </Typography>

            <Box ml="40px">
              <Typography className={classes.textEnableInMarketplace}>
                {generalConfig.wholesale ? 'Sim' : 'Não'}
              </Typography>
            </Box>
            <Switch
              className={classes.switch}
              value={generalConfig.wholesale}
              checked={generalConfig.wholesale}
              onChange={(e) =>
                generalConfigControl.set('wholesale', e.target.checked)
              }
            />
          </Box>
        </Box>

        {/* <Box width="100%" display="flex" flexDirection="column" mt="41px">
          <Typography className={classes.mainTitle}>Cupons</Typography>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            className={classes.boxCardInfo}
            mt="32px"
          >
            <InfoIcon />
            <Box ml="18px">
              <Typography>
                Ao ativar a opção de cupons, todas as lojas dentro do
                marketplace poderão criar cupons.
              </Typography>
            </Box>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt="25px"
          >
            <Typography className={classes.textEnableInMarketplace}>
              Permitir a utilização e a criação de cupons
            </Typography>

            <Box ml="40px">
              <Typography className={classes.textEnableInMarketplace}>
                {generalConfig.wholesale ? 'Sim' : 'Não'}
              </Typography>
            </Box>
            <Switch
              className={classes.switch}
              value={generalConfig.wholesale}
              checked={generalConfig.wholesale}
              onChange={(e) =>
                generalConfigControl.set('wholesale', e.target.checked)
              }
            />
          </Box>
        </Box> */}

        <Box width="100%" display="flex" flexDirection="column" mt="65px">
          <Typography className={classes.mainTitle}>
            Imagem de capa para compartilhamento
          </Typography>
          <Typography className={classes.subTitle2}>
            Ao comprartilhar o link do marketplace, mostrará a imagem de capa
            desejada. (caso não seja enviado nenhuma imagem, por padrão mostrará
            a logo)
          </Typography>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt="25px"
            mb="32px"
          >
            {/* Image upload */}
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              border="solid 1px #F9FAFB"
            >
              <UploadComponent
                onUpload={(url, name) => {
                  fetchApiGeneralConfig();
                }}
                uploadUrl={'/marketplaceseos/upload-image'}
                deleteUrl={'/marketplaceseos/delete-image'}
                value={
                  apiGeneralConfig?.value?.seoImage
                    ? apiGeneralConfig?.value?.seoImage + '?v=' + Date.now()
                    : ''
                }
                name={'ig image'}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralConfigDetails;
