import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import DashTable from 'core/components/DashTable';
import Select from 'core/components/inputs/Select';
import { useAsync } from 'react-use';
import IDashTable from 'core/interfaces/IDashTable';
import { api } from 'core/lib/api';
import Loading from 'core/components/Loading';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#F9F9F9',
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  },
  headerTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
  },
  boxFilters: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  select: {
    padding: '0px',
    width: '350px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

interface IProps {
  startDate: Date;
  endDate: Date;
  isAdmin?: boolean;
}

interface ISeller {
  name: string;
  id: number;
  is_active: boolean;
}

interface IWishlistItemResp {
  ranking: number;
  product: string;
  count: number;
  seller?: string;
}

const Withlist: React.FC<IProps> = ({ startDate, endDate, isAdmin }) => {
  const [filterSellerId, setFilterSellerId] = useState<number>(0);
  const [filterCategoryId, setFilterCategoryId] = useState<number>(0);

  const allSellers = useAsync(
    async () =>
      (await api.get<ISeller[]>('/sellers/autocomplete-custom')).data.filter(
        (s) => s.is_active
      ),
    []
  );

  const allCategories = useAsync(
    async () =>
      (
        await api.get<{ name: string; id: number }[]>(
          '/categorys/autocomplete-custom'
        )
      ).data,
    []
  );

  const wishlist = useAsync(async () => {
    const res = await api.get<IWishlistItemResp[]>('/initials/wishlist', {
      params: {
        start_date: startDate,
        end_date: endDate,
        filter_seller_id: filterSellerId || null,
        filter_category_id: filterCategoryId || null,
      },
    });

    const ret: IDashTable = {
      columns: [
        'Ranking',
        'Produto',
        'Vezes adicionadas',
        isAdmin && 'Loja',
      ].filter((c) => c) as string[],
      rows: res.data.map((d: any) => [
        {
          value: `${d.ranking}º`,
        },
        { value: d.product },
        // eslint-disable-next-line eqeqeq
        { value: `${d.count} ${d.count == 1 ? 'Vez' : 'Vezes'}` },
        { value: d.seller },
      ]),
    };

    return ret;
  }, [startDate, endDate, filterSellerId, filterCategoryId]);

  const classes = useStyles();

  if (
    !wishlist.value ||
    !allSellers.value ||
    !allCategories.value ||
    wishlist.loading
  ) {
    return <Loading />;
  }

  return (
    <>
      <Box className={classes.header}>
        <Typography variant="h6" className={classes.headerTitle}>
          Produtos mais adicionados na lista de desejos
        </Typography>

        <Box className={classes.boxFilters}>
          {isAdmin && (
            <Select
              value={filterSellerId}
              style={{
                marginRight: '10px',
              }}
              className={classes.select}
              onChange={(e) => setFilterSellerId(e.target.value as number)}
            >
              <MenuItem value={0}>Todas as lojas</MenuItem>
              {allSellers.value
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          <Select
            value={filterCategoryId}
            className={classes.select}
            label="Categoria"
            onChange={(e) => setFilterCategoryId(e.target.value as number)}
          >
            <MenuItem value={0}>Todas as categorias</MenuItem>
            {allCategories.value
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Box>
      <DashTable {...wishlist.value} />
    </>
  );
};

export default Withlist;
