import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { Page } from 'core/interfaces/page';
import Details from './Details';

const ImportProduct: Page = {
    label: 'Importações',
    route: 'importproducts',
    permissions: {
        create: false,
        delete: false,
        update: true,
    },
    routes: {
        findOne: (id) => `importproducts/${id}`,
        find: 'importproducts',
    },
    listProps: {
        removeEditAndDelete: true,
        fields: {
            id: {
                headerName: 'Id',
            },
            date: {
                headerName: 'Data',
                width: 100,
                renderCell({ row: { created_at } }) {
                    return moment(created_at).format('DD/MM/YYYY');
                },
            },
            hour: {
                headerName: 'Hora',
                renderCell({ row: { created_at } }) {
                    return moment(created_at).format('HH:mm');
                },
            },
            number_lines: {
                headerName: 'Número de linhas',
            },
            number_lines_warning: {
                headerName: 'Número de alertas',
            },
            number_lines_error: {
                headerName: 'Número de erros',
            },
            process_status: {
                headerName: 'Status',
                renderCell({ row: { process_status } }) {
                    switch (process_status) {
                        case 0:
                            return (
                                <span
                                    style={{ color: '#456AEF' }}
                                    children="Aguardando Processamento"
                                />
                            );

                        case 1:
                            return (
                                <span
                                    style={{ color: '#FF8A00' }}
                                    children="Em Processamento"
                                />
                            );

                        case 2:
                            return (
                                <span
                                    style={{ color: '#3AB956' }}
                                    children="Processamento Finalizado"
                                />
                            );
                    }
                },
            },
            see: {
                headerName: ' ',
                width: 1,
                renderCell({ row: { id } }) {
                    return (
                        // @ts-ignore
                        <IconButton
                            component={Link}
                            to={`/importproducts/${id}`}
                        >
                            <VisibilityOutlined />
                        </IconButton>
                    );
                },
            },
        },
    },
    Details,
};

export default ImportProduct;
