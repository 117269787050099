import {
  useContext,
  useState,
  forwardRef,
  useImperativeHandle,
  MouseEventHandler,
} from 'react';
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { MenuOutlined, ExitToAppOutlined } from '@material-ui/icons';
import clsx from 'clsx';

import AuthContext from '../../contexts/Auth';
import QuestionsNotifications from '../QuestionsNotifications/QuestionsNotifications';

export interface HeaderProps {
  onClickMenu?: MouseEventHandler<HTMLButtonElement>;
}

export interface HeaderRef {
  open: boolean;
  toggle(): void;
}

const DRAWER_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
    color: '#ddd',
    boxShadow: 'none',
    borderBottom: '1px solid rgb(221, 221, 221)',
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default forwardRef<HeaderRef, HeaderProps>(function (props, ref) {
  const { onClickMenu } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { user = {}, logout } = useContext(AuthContext);

  useImperativeHandle(ref, () => ({
    open,
    toggle() {
      setOpen(!open);
    },
  }));

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          style={{ color: '#999999' }}
          onClick={onClickMenu}
          edge="start"
          className={clsx(classes.menuButton)}
          children={<MenuOutlined />}
        />
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            style={{
              color: '#333333',
              margin: 0,
              textAlign: 'end',
              marginRight: 20,
              fontSize: '14px',
              lineHeight: '24px',
            }}
          >
            Olá, {user.name}
          </Typography>
          <QuestionsNotifications />
          <Button
            size="large"
            style={{
              color: '#456AEF',
              textTransform: 'none',
              padding: 0,
              margin: 0,
              fontSize: '14px',
              fontWeight: 'bold',
            }}
            startIcon={<ExitToAppOutlined />}
            onClick={() => logout()}
            children="Sair"
          />
        </div>
      </Toolbar>
    </AppBar>
  );
});
