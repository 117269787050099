import { Box, makeStyles, Typography } from '@material-ui/core';
import Form, { FormHandles } from 'core/components/Form';
import BreadCrumbCore from 'core/components/BreadCrumb';
import Button from 'core/components/Button';
import { useContext, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Field from 'core/toolbox/Field';
import { api } from 'core/lib/api';
import AlertContext from 'core/contexts/Alert';
import disableFields from 'core/lib/disableFields';

const useStyles = makeStyles((theme) => ({
  informations: {
    display: 'contents',
  },
  informationsContent: {
    display: 'flex',
    marginLeft: 'auto',
  },
  integrationButton: {
    '&:disabled': {
      backgroundColor: '#f1f1f1',
      border: '1px solid rgb(153, 153, 153)',
    },
  },
  boxField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
  },
  boxText: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '6px',
    marginBottom: '12px',
  },
  titleInfo: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 2,
  },
  titleInput: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 1.5,
    color: '#475569',
  },
}));

interface IField {
  name: string;
  title: string;
  description: string;
  title2: string;
  description2: string;
}

const fields: IField[] = [
  {
    name: 'google_tag',
    title: 'Google Tag Manager',
    description:
      'Google Tag Manager é uma ferramenta gratuita do Google, que ajuda a centralizar a gestão de códigos no GTM, o marketing ganha autonomia.',
    title2: 'Google Tag Manager ID (Container ID)',
    description2: 'Seu ID deve conter o prefixo GTM.',
  },
  {
    name: 'meta_pixel_id',
    title: 'Pixel da Meta',
    description:
      'O Pixel da Meta (Facebook Pixel) rastreia o comportamento dos clientes no marketplace. Esses dados melhoram o marketing, inclusive os anúncios de remarketinge.',
    title2: 'Pixel ID',
    description2: 'Informe o código identificador do Pixel',
  },
  {
    name: 'facebook_domain_verification',
    title: 'Facebook Domain Verification',
    description:
      'O Facebook Domain Verification é um processo de verificação de domínio que permite que você demonstre que possui um domínio e que você tenha acesso a ele.',
    title2: 'Facebook Domain Verification ID',
    description2:
      'Informe o código identificador do Facebook Domain Verification',
  },
  {
    name: 'google_domain_verification',
    title: 'Google Domain Verification',
    description:
      'O Google Domain Verification é um processo de verificação de domínio que permite que você demonstre que possui um domínio e que você tenha acesso a ele.',
    title2: 'Google Domain Verification ID',
    description2:
      'Informe o código identificador do Google Domain Verification',
  },
];

const Details = (props: any) => {
  const classes = useStyles();
  const {
    page: { label, routes, permissions },
    instance,
  } = props;

  const formRef = useRef<FormHandles>(null);
  const alert = useContext(AlertContext);

  const history = useHistory();

  const handleSave = async () => {
    const data = formRef.current?.getData();

    api
      .put(routes.update, data)
      .then(() => {
        alert.success('Integração atualizada com sucesso!');
        history.push('/integration');
      })
      .catch(() => {
        alert.error('Erro ao atualizar integração!');
      });
  };

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <Box>
      <BreadCrumbCore
        title={<span children={'Integrações'} style={{ color: '#456AEF' }} />}
        dictionary={{ action: label }}
        slot={{
          end: (
            <div className={classes.informations}>
              <div className={classes.informationsContent}>
                <Button
                  children="Voltar"
                  color="#999999"
                  outline
                  onClick={() => history.goBack()}
                  style={{ marginRight: 20 }}
                />
                <Button
                  children="Salvar"
                  color="#456AEF"
                  onClick={() => handleSave()}
                  style={{
                    marginRight: 20,
                    display: !permissions?.update ? 'none' : '',
                  }}
                  className={classes.integrationButton}
                />
              </div>
            </div>
          ),
        }}
      />
      <Form ref={formRef} initialData={instance}>
        <Box className={classes.contentContainer}>
          {fields.map((field) => (
            <Box className={classes.boxField} key={field.name}>
              <Box
                className={classes.boxText}
                style={{ width: '40%', marginRight: '80px' }}
              >
                <Typography variant="h6" className={classes.titleInfo}>
                  {field.title}
                </Typography>
                <Typography className={classes.subTitle}>
                  {field.description}
                </Typography>
              </Box>

              <Box className={classes.boxText} style={{ width: '50%' }}>
                <Typography className={classes.titleInput}>
                  {field.title2}
                </Typography>
                <Typography className={classes.subTitle}>
                  {field.description2}
                </Typography>
                <Field
                  name={field.name}
                  field={{
                    type: 'text',
                  }}
                />
              </Box>
            </Box>
          ))}

          {/* <Box className={classes.boxField}>
            <Box
              className={classes.boxText}
              style={{ width: '40%', marginRight: '80px' }}
            >
              <Typography variant="h6" className={classes.titleInfo}>
                Google Tag Manager
              </Typography>
              <Typography className={classes.subTitle}>
                Google Tag Manager é uma ferramenta gratuita do Google, que
                ajuda a centralizar a gestão de códigos no GTM, o marketing
                ganha autonomia.
              </Typography>
            </Box>

            <Box className={classes.boxText} style={{ width: '50%' }}>
              <Typography className={classes.titleInput}>
                Google Tag Manager ID (Container ID)
              </Typography>
              <Typography className={classes.subTitle}>
                Seu ID deve conter o prefixo GTM.
              </Typography>
              <Field
                name="google_tag"
                field={{
                  type: 'text',
                }}
              />
            </Box>
          </Box> */}
          {/* <Box className={classes.boxField}>
            <Box
              className={classes.boxText}
              style={{ width: '40%', marginRight: '80px' }}
            >
              <Typography className={classes.titleInfo}>
                Pixel da Meta
              </Typography>
              <Typography className={classes.subTitle}>
                O Pixel da Meta (Facebook Pixel) rastreia o comportamento dos
                clientes no marketplace. Esses dados melhoram o marketing,
                inclusive os anúncios de remarketing.
              </Typography>
            </Box>

            <Box className={classes.boxText} style={{ width: '50%' }}>
              <Typography className={classes.titleInput}>Pixel ID</Typography>
              <Typography className={classes.subTitle}>
                Informe o código identificador do Pixel
              </Typography>
              <Field
                name="meta_pixel_id"
                field={{
                  type: 'text',
                }}
              />
            </Box>
          </Box> */}
        </Box>
      </Form>
    </Box>
  );
};

export default Details;
