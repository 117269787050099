import { useState, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Typography,
  Grid,
  IconButton,
  Button,
  makeStyles,
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';
import * as Yup from 'yup';

import Switch from 'core/components/inputs/Switch';
import ContainerMaster from 'core/toolbox/Container';
import Container from 'core/components/Container';
import Tooltip from 'core/components/Tooltip';
import Autocomplete from 'core/components/inputs/Autocomplete';
import AutocompleteAsync from 'core/components/inputs/AutocompleteAsync';
import { api } from 'core/lib/api';
import InputContainer from 'core/components/InputContainer';
import AlertContext from 'core/contexts/Alert';
import { Permissions } from 'core/interfaces/page';
import disableFields from 'core/lib/disableFields';

export interface VariationsProps {
  instance: Record<string, any>;
  onSubmit(
    data: Record<string, any>[],
    hasVariation: boolean,
    gridConfig: Record<string, any>
  ): void;
  addTask(task: Promise<void>): void;
  permissions: Permissions
}

export interface VariationsRef {
  getData(): any[];
}

export default forwardRef<VariationsRef, VariationsProps>(function Variations(
  props,
  ref
) {
  const { instance, permissions } = props;
  const classes = useStyles();
  const alert = useContext(AlertContext);

  const [{ hasVariation, variations, gridConfig }, setState] = useState({
    hasVariation: instance.has_variation || (false as boolean),
    variations: (instance.variations || []) as any[],
    gridConfig: (instance.gridConfig || {
      variationX: { name: '' },
      variationY: { name: '' },
    }) as Record<string, any>,
  });

  const onDragEnd = (result: any, variation: any) => {
    if (result.destination) {
      const _items: any[] = reorder(
        variation.variationValues,
        result.source.index,
        result.destination.index
      );

      for (let i = 0; i < _items.length; i++) {
        const item = _items[i];

        item.order = i + 1;
      }

      variation.variationValues = _items;

      setState({ hasVariation, variations: [...variations], gridConfig });
    }
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const generateKey = () => {
    return (Math.random() + 1).toString(36).substring(7);
  };

  const addVariation = () => {
    variations.push({
      key: generateKey(),
      name: '',
      variationValues: [],
      order: variations.length,
    });

    setState({ hasVariation, variations: [...variations], gridConfig });
  };

  const removeVariation = (index: number) => {
    if (variations[index].id === gridConfig.variationX.id) {
      gridConfig.variationX = { name: '' };

      gridConfig.variation_id_x = undefined;
    }

    if (variations[index].id === gridConfig.variationY.id) {
      gridConfig.variationY = { name: '' };

      gridConfig.variation_id_y = undefined;
    }

    if (variations.length - 1 < 2) {
      gridConfig.variationX = { name: '' };

      gridConfig.variationY = { name: '' };

      gridConfig.variation_id_x = undefined;

      gridConfig.variation_id_y = undefined;
    }

    variations.splice(index, 1);

    setState({
      hasVariation,
      variations: [...variations],
      gridConfig: { ...gridConfig },
    });
  };

  const addVariationValue = (variation: any) => {
    variation.variationValues.push({
      key: generateKey(),
      name: '',
      price_factor: 1,
    });

    setState({ hasVariation, variations: [...variations], gridConfig });
  };

  const removeVariationValue = (variation: any, index: number) => {
    variation.variationValues.splice(index, 1);

    setState({ hasVariation, variations: [...variations], gridConfig });
  };

  const createVariationFather = async (
    name: string,
    variation: { id: string; name: string }
  ) => {
    try {
      const { data } = await api.post('variations/create-with-find', {
        name,
      });

      variation.id = data.id;
      variation.name = data.name;
    } catch (error) {
      console.error('create-with-find', error);
      alert.error('Error ao criar nova variação, tente novamente mais tarde.');
    }

    setState({
      hasVariation,
      variations: [...variations],
      gridConfig,
    });
  };

  const createVariationChild = async (
    name: string,
    id: number,
    variation: { id: string; name: string }
  ) => {
    try {
      const { data } = await api.post(`variations/${id}/variations-values`, {
        name,
      });
      variation.id = data.id;
      variation.name = data.name;
    } catch (error) {
      console.error('variations-values', error);
      alert.error('Error ao criar nova variação, tente novamente mais tarde.');
    }

    setState({
      hasVariation,
      variations: [...variations],
      gridConfig,
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      getData() {
        try {
          if (hasVariation) {
            VALIDATIONS.validateSync(
              {
                hasVariation,
                variations,
                gridConfig,
              },
              {
                abortEarly: true,
                context: {
                  variationLength: variations.length,
                },
              }
            );
          }

          return [variations, hasVariation, gridConfig];
        } catch (error: any) {
          throw error;
        }
      },
    }),
    [hasVariation, variations, gridConfig]
  );

  useEffect(() => {
    disableFields(permissions);
  }, [instance]);

  return (
    <ContainerMaster>
      <Container
        title="Variações"
        subtitle={
          <>
            Adicione até <span>3 variações</span> e quantos valores precisar
            para cada uma.
          </>
        }
        classes={{
          content: {
            borderTop: 'none !important',
            paddingTop: 10,
            width: 'calc(100% + 40px)',
          },
          wrapper: {
            marginBottom: -30,
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <Typography style={{ marginRight: 12 }}>Possui variações?</Typography>
          <Switch
            checked={hasVariation}
            disabled={instance.hasSku}
            onChange={({ target: { checked } }) => {
              const _variations = checked ? variations : [];

              const _gridConfig = checked
                ? gridConfig
                : {
                    variationX: { name: '' },
                    variationY: { name: '' },
                  };

              setState({
                hasVariation: checked,
                variations: _variations,
                gridConfig: _gridConfig,
              });
            }}
          />
        </div>
        {hasVariation && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Grid container spacing={7} style={{ margin: '0 25px 0 -30px' }}>
              {variations.map((variation: any, index: number) => (
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, variation)}
                >
                  <Grid
                    key={variation.id || variation.key}
                    item
                    xs={4}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 20,
                      }}
                    >
                      <AutocompleteAsync
                        options={async () => {
                          let { data: _variations } = await api.get<any[]>(
                            'variations/autocomplete'
                          );

                          _variations = _variations.filter((_variations) => {
                            return !variations.some((variation: any) => {
                              return variation.id === _variations.id;
                            });
                          });

                          return _variations;
                        }}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma variação"
                        defaultValue={variation}
                        disabled={instance.hasSku}
                        onChange={(_, value) => {
                          if (value) {
                            if (typeof value === 'string') {
                              delete variation.id;

                              variation.name = value;
                            } else {
                              variation.id = value.id;
                              variation.name = value.name;
                            }
                          } else {
                            delete variation.id;

                            variation.name = '';
                            variation.variationValues = [];
                          }

                          setState({
                            hasVariation,
                            variations: [...variations],
                            gridConfig,
                          });
                        }}
                        onBlur={({ target }) => {
                          // @ts-ignore
                          const { value: name } = target;
                          if (typeof name === 'string' && !variation.id)
                            props.addTask(
                              createVariationFather(name, variation)
                            );
                        }}
                        freeSolo
                        delayLoad={500}
                        loadingText="Carregando..."
                        noOptionsText="Sem resultados"
                      />
                      {!instance.hasSku && (
                        <Tooltip title="Remover variação">
                          <IconButton
                            size="small"
                            style={{
                              position: 'absolute',
                              right: -35,
                            }}
                            onClick={() => removeVariation(index)}
                            children={
                              <DeleteIcon
                                style={{
                                  color: '#999999',
                                }}
                              />
                            }
                          />
                        </Tooltip>
                      )}
                    </div>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {variation.variationValues.map(
                            (variationValue: any, index: number) => (
                              <Draggable
                                key={`item-${index}`}
                                draggableId={`item-${index}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    key={
                                      variationValue.id || variationValue.key
                                    }
                                    className={classes.variationValueWrapper}
                                  >
                                    <IconButton
                                      {...provided.dragHandleProps}
                                      size="small"
                                      style={{
                                        marginRight: 10,
                                      }}
                                      children={
                                        <DragIndicatorIcon
                                          style={{
                                            color: '#999999',
                                          }}
                                        />
                                      }
                                    />
                                    <AutocompleteAsync
                                      options={async () => {
                                        let { data: variationValues } =
                                          await api.get<any[]>(
                                            `variations/autocomplete/${variation.id}/variations-values`
                                          );

                                        variationValues =
                                          variationValues.filter(
                                            (variationValue) => {
                                              return !variation.variationValues.some(
                                                (_variationValue: any) => {
                                                  return (
                                                    variationValue.id ===
                                                    _variationValue.id
                                                  );
                                                }
                                              );
                                            }
                                          );

                                        return variationValues;
                                      }}
                                      getOptionSelected={(option, value) =>
                                        option.name === value.name
                                      }
                                      getOptionLabel={(option) => option.name}
                                      style={{
                                        width: '100%',
                                        marginRight: 10,
                                      }}
                                      placeholder="Selecione um valor de variação"
                                      defaultValue={variationValue}
                                      disabled={variationValue.blocked}
                                      onChange={(_, value) => {
                                        if (value) {
                                          if (typeof value === 'string') {
                                            delete variationValue.id;

                                            variationValue.name = value;
                                          } else {
                                            variationValue.id = value.id;
                                            variationValue.name = value.name;
                                          }
                                        } else {
                                          delete variationValue.id;

                                          variationValue.name = '';
                                        }

                                        setState({
                                          hasVariation,
                                          variations: [...variations],
                                          gridConfig,
                                        });
                                      }}
                                      onBlur={async ({ target }) => {
                                        // @ts-ignore
                                        const name = target.value;
                                        if (
                                          typeof name === 'string' &&
                                          variation.id
                                        )
                                          props.addTask(
                                            createVariationChild(
                                              name,
                                              variation.id,
                                              variationValue
                                            )
                                          );
                                      }}
                                      freeSolo
                                      delayLoad={500}
                                      loadingText="Carregando..."
                                      noOptionsText="Sem resultados"
                                    />
                                    {!variationValue.blocked && (
                                      <Tooltip title="Remover variação">
                                        <IconButton
                                          size="small"
                                          style={{
                                            position: 'absolute',
                                            right: -35,
                                          }}
                                          onClick={() =>
                                            removeVariationValue(
                                              variation,
                                              index
                                            )
                                          }
                                          children={
                                            <DeleteIcon
                                              style={{
                                                color: '#999999',
                                              }}
                                            />
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    {!!variation.id && (
                      <Button
                        onClick={() => addVariationValue(variation)}
                        className={classes.addVariationValueButton}
                        startIcon={
                          <AddCircleIcon
                            style={{
                              color: '#456AEF',
                            }}
                          />
                        }
                      >
                        Novo item
                      </Button>
                    )}
                  </Grid>
                </DragDropContext>
              ))}
              {!instance.hasSku && variations.length < 3 && (
                <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 20 }}>
                  <Button
                    className={classes.addVariationButton}
                    onClick={() => addVariation()}
                  >
                    <AddCircleIcon
                      fontSize="large"
                      style={{ color: '#456AEF' }}
                    />
                    Nova variação
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </Container>
      {hasVariation &&
        !!variations.length &&
        !!variations.find((variation) => variation.id) && (
          <Container
            title="Visualização em grade"
            subtitle="Com esta opção o usuário poderá ver a grade com os diferentes itens."
            classes={{
              content: {
                paddingBottom: 0,
                width: 'calc(100% + 40px)',
                marginBottom: 10,
              },
              wrapper: {
                flexDirection: 'column',
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Typography style={{ marginRight: 12 }}>Ativar grade?</Typography>
              <Switch
                checked={!!gridConfig.display_in_grid}
                onChange={({ target: { checked } }) => {
                  setState({
                    hasVariation,
                    variations,
                    gridConfig: {
                      ...gridConfig,
                      display_in_grid: checked,
                    },
                  });
                }}
              />
            </div>
            {gridConfig.display_in_grid && variations.length >= 2 && (
              <div style={{ width: 600, display: 'flex' }}>
                <InputContainer
                  label="Variação horizontal"
                  required
                  classes={{ content: classes.labelBottom }}
                  style={{ content: { marginRight: 20 } }}
                >
                  <Autocomplete
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    value={gridConfig.variationX}
                    options={variations.reduce((acc, cur) => {
                      if (
                        gridConfig.variationY &&
                        cur.id !== gridConfig.variationY.id
                      ) {
                        acc.push(cur);
                      }

                      return acc;
                    }, [])}
                    onChange={(_, value) => {
                      setState({
                        hasVariation,
                        variations,
                        gridConfig: {
                          ...gridConfig,
                          variationX: value,
                          variation_id_x: value.id,
                        },
                      });
                    }}
                  />
                </InputContainer>
                <InputContainer
                  label="Variação vertical"
                  required
                  classes={{ content: classes.labelBottom }}
                >
                  <Autocomplete
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    value={gridConfig.variationY}
                    options={variations.reduce((acc, cur) => {
                      if (
                        gridConfig.variationX &&
                        cur.id !== gridConfig.variationX.id
                      ) {
                        acc.push(cur);
                      }

                      return acc;
                    }, [])}
                    onChange={(_, value) => {
                      setState({
                        hasVariation,
                        variations,
                        gridConfig: {
                          ...gridConfig,
                          variationY: {
                            id: value.id,
                            name: value.name,
                          },
                          variation_id_y: value.id,
                        },
                      });
                    }}
                  />
                </InputContainer>
              </div>
            )}
          </Container>
        )}
    </ContainerMaster>
  );
});

const useStyles = makeStyles((theme) => ({
  addVariationValueButton: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#456AEF',
    textTransform: 'capitalize',
  },
  addVariationButton: {
    border: '1px solid #456aef',
    display: 'flex',
    flexDirection: 'column',
    width: 170,
    height: 150,
    fontWeight: 'bold',
    fontSize: 15,
    color: '#456AEF',
    textTransform: 'none',
    '& .MuiButton-label': {
      flexDirection: 'inherit',
    },
  },
  variationValueWrapper: {
    display: 'flex',
    marginBottom: 10,
    position: 'relative',
    alignItems: 'center',
  },
  labelBottom: {
    alignItems: 'unset',
    flexDirection: 'column',
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const VALIDATIONS = Yup.object().shape({
  hasVariation: Yup.boolean().test(
    'hasVariation',
    'Por favor selecione uma variação',
    (hasVariation, context) => {
      return hasVariation ? context.options.context!.variationLength > 0 : true;
    }
  ),
  variations: Yup.array().of(
    Yup.object({
      variationValues: Yup.array()
        .of(
          Yup.object({
            id: Yup.number().required(
              'Preencha todos os valores das variações'
            ),
          })
        )
        .min(1, 'Preencha ao menos um valor para cada variação'),
    })
  ),
  gridConfig: Yup.object({
    variation_id_x: Yup.number()
      .test(
        'emptyVariationX',
        'Por favor selecionar uma variação horizontal',
        (variation_id_x, context) => {
          return context.parent.display_in_grid &&
            context.options.context!.variationLength > 1
            ? !!variation_id_x
            : true;
        }
      )
      .nullable(),
    variation_id_y: Yup.number()
      .test(
        'emptyVariationY',
        'Por favor selecionar uma variação vertical',
        (variation_id_y, context) => {
          return context.parent.display_in_grid &&
            context.options.context!.variationLength > 1
            ? !!variation_id_y
            : true;
        }
      )
      .nullable(),
  }),
});
