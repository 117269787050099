import {
  Box,
  Button,
  Link,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useRef, useState } from 'react';

import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ModalQuestion from './ModalQuestion';
import useSWR, { useSWRConfig } from 'swr';
import Loading from 'core/components/Loading';
import moment from 'moment';
import AlertContext from 'core/contexts/Alert';
import { useAsyncFn } from 'react-use';
import { api } from 'core/lib/api';
import { useUserData } from 'core/lib/getUserData';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useLocation } from 'react-router-dom';

const styles = makeStyles((theme) => ({
  mainTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',

    color: '#0F172A',

    margin: '21px 24px',
  },
  mainTitleWrapper: {
    border: '1px solid #E5E7EB',
  },
  tabs: {
    marginLeft: '16px',

    '& .MuiTabs-indicator': {
      backgroundColor: '#456aef',
      height: '3px',
    },
  },
  tabsWrapper: {
    border: '1px solid #E5E7EB',
  },
  tab: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
  },
  insertResponseInput: {
    width: '100%',
    '& .MuiInputBase-root': {
      padding: '8px 8px 8px 16px',
      border: '1px solid #E5E7EB',
      borderRadius: '4px',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& textarea': {
      resize: 'vertical',
      maxHeight: '300px',
    },
  },

  labelField: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.01em',
    color: '#374151',
  },

  valueField: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.01em',

    color: '#1F2937',
  },
  deleteQuestion: {
    /* Cores da Marca/Royal Blue/500 */
    color: '#456AEF',
    marginLeft: '5px',
    marginTop: '7px',
    textTransform: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      borderRadius: '4px',
    },
  },
  answerButton: {
    background: '#456AEF',

    padding: '8px 58px',

    borderRadius: '4px',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    alignItems: 'center',
    letterSpacing: '-0.01em',
    textTransform: 'none',
    float: 'right',

    color: '#F9FAFB',

    '&:hover': {
      cusor: 'pointer',
      background: '#456AEF',

      opacity: '0.8',
    },
  },

  removeAnswerButton: {
    borderColor: '#456AEF',

    padding: '8px 58px',

    borderRadius: '4px',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    alignItems: 'center',
    letterSpacing: '-0.01em',
    textTransform: 'none',
    float: 'right',
    borderWidth: '1px',

    color: '#456AEF',
    whiteSpace: 'nowrap',

    '&:hover': {
      opacity: '0.8',
    },
  },
  answeredBy: {
    marginLeft: '4px',
    color: '#456AEF',
    fontWeight: 600,
  },
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface Item {
  id: number;
  product_id: number;
  seller_id: number;
  name: string;
  midia_path: string;
  created_at: Date;
  question: string;
  responsed: boolean;
  product_url: string;
  answer: {
    id?: number;
    answer?: string;
    answered_by?: string;
    created_at?: Date;
  };
}

export interface Permissions {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export interface Page {
  label: string;
  route: string;
  onlyDetails: boolean;
  passInstance: boolean;
  permissions: Permissions;
}

export interface QuestionsDetailsProps {
  isNew: boolean;
  page: Page;
  instance: any;
}

const QuestionsDetails: React.FC<QuestionsDetailsProps> = ({ page }) => {
  const classes = styles();
  const [tab, setTab] = useState(0);

  const [modalDeleteQuestion, setModalDeleteQuestion] = useState(false);
  const [modalDeleteAnswer, setModalDeleteAnswer] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<number>();
  const [currentAnswerId, setCurrentAnswerId] = useState<number>();

  const { data: questions, mutate: mutateQuestions } =
    useSWR<Item[]>('/productQuestions');

  const alert = useContext(AlertContext);
  const userData = useUserData();

  const location = useLocation();
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (questions) {
      // scroll to question id
      const parameters = new URLSearchParams(location.search);
      const questionId = parameters.get('questionId');
      if (questionId) {
        const question = questions.find((q) => q.id === Number(questionId));
        if (question) {
          const questionElement = document.getElementById(
            `question-${question.id}`
          );
          if (questionElement) {
            questionElement.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });
            questionElement.style.backgroundColor = '#F9FAFB';
            setTimeout(() => {
              questionElement.style.backgroundColor = 'transparent';
            }, 500);
          }
        }
      }
    }
  }, [questions, location]);

  const onCloseModalDeleteQuestion = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown' | 'confirmClick' | 'cancelClick'
  ) => {
    if (reason === 'confirmClick') {
      api
        .delete(
          `/productQuestions/${currentQuestionId}?seller_id=${
            userData?.seller_id || null
          }`
        )
        .then(() => {
          alert.success('Pergunta deletada com sucesso!');
          mutate('/productQuestions/getNotifications');
          mutate('/productQuestions/countNotifications');
          mutateQuestions();
        })
        .catch(() => {
          alert.error('Erro ao excluir pergunta!');
        })
        .finally(() => {
          setModalDeleteQuestion(false);
        });
    } else {
      setModalDeleteQuestion(false);
    }
  };

  const onCloseModalDeleteAnswer = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown' | 'confirmClick' | 'cancelClick'
  ) => {
    if (reason === 'confirmClick') {
      api
        .delete(
          `/productAnswers/${currentAnswerId}?seller_id=${
            userData?.seller_id || null
          }&question_id=${currentQuestionId}`
        )
        .then(() => {
          alert.success('Resposta deletada com sucesso!');
          mutate('/productQuestions/getNotifications');
          mutate('/productQuestions/countNotifications');
          mutateQuestions();
        })
        .catch(() => {
          alert.error('Erro ao excluir resposta!');
        })
        .finally(() => {
          setModalDeleteAnswer(false);
        });
    } else {
      setModalDeleteAnswer(false);
    }
  };

  const isLoading = !questions;

  interface ItemQuestionProps {
    id: number;
    name: string;
    midia_path: string;
    created_at: Date;
    question: string;
    product_url: string;
    answer?: {
      id?: number;
      answer?: string;
      answered_by?: string;
      created_at?: Date;
    };
  }

  const Item: React.FC<ItemQuestionProps> = ({
    id,
    midia_path,
    created_at,
    question,
    product_url,
    name,
    answer,
  }) => {
    const refInput = useRef<HTMLInputElement>(null);

    const [answerQuestion, doAnswerQuestion] = useAsyncFn(
      async () =>
        api
          .post(`/productAnswers`, {
            product_question_id: id,
            answer: refInput.current?.value,
            seller_id: userData?.seller_id || null,
          })
          .then(() => {
            alert.success('Resposta enviada com sucesso!');
            mutate('/productQuestions/getNotifications');
            mutate('/productQuestions/countNotifications');
            mutateQuestions();
          })
          .catch(() => {
            alert.error('Erro ao enviar resposta!');
          }),
      [refInput]
    );

    return (
      <Box
        display="flex"
        key={id}
        pt="25px"
        px="15px"
        pb="32px"
        borderBottom="solid 1px #E5E7EB"
        id={`question-${id}`}
      >
        <Box display="flex" width="6%">
          <img src={midia_path} alt="Imagem" width="76px" height="80px" />
        </Box>
        <Box display="flex" flexDirection="column" width="14%">
          <Link
            href={'https://' + product_url}
            target="_blank"
            rel="noreferrer"
            className={classes.labelField}
          >
            {name}
          </Link>

          <Box display="flex" flexDirection="row" mt="5px">
            <Box marginRight="10px">
              <WatchLaterIcon style={{ color: '#475569' }} />
            </Box>
            <Typography className={classes.valueField}>
              {moment(created_at).format('DD/MM/YYYY HH:mm:SS')}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="35%">
          <Typography className={classes.labelField}>Pergunta: </Typography>
          <Box mt="4px" pr="40px">
            <Typography className={classes.valueField}>{question}</Typography>
          </Box>
          <Box>
            <DeleteOutlineIcon
              className={classes.deleteQuestion}
              onClick={() => {
                if (page.permissions.delete) {
                  setModalDeleteQuestion(true);
                  setCurrentQuestionId(id);
                } else {
                  alert.error(
                    'Você não possui permissão para excluir perguntas'
                  );
                }
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="45%"
          alignItems="flex-start"
        >
          <Box>
            <Typography className={classes.labelField}>Resposta: </Typography>
          </Box>
          <Box mt="12px" display="flex" width="100%" flexDirection="column">
            <TextField
              multiline
              minRows={3}
              variant="outlined"
              placeholder="Insira sua resposta aqui"
              disabled={!!answer || !page.permissions.create}
              value={answer?.answer}
              className={classes.insertResponseInput}
              inputRef={refInput}
              InputProps={{
                inputProps: {
                  maxLength: 500,
                },
              }}
            />
          </Box>
          <Box
            mt="12px"
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            <Box>
              {answer && (
                <Box display="flex">
                  <Typography>Respondido por:</Typography>
                  <Typography className={classes.answeredBy}>
                    {answer.answered_by}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              {answer ? (
                <Button
                  variant="outlined"
                  className={classes.removeAnswerButton}
                  disabled={!page.permissions.delete}
                  onClick={() => {
                    setCurrentQuestionId(id);
                    setCurrentAnswerId(answer.id);
                    setModalDeleteAnswer(true);
                  }}
                >
                  Excluir resposta
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={classes.answerButton}
                  disabled={answerQuestion.loading || !page.permissions.create}
                  onClick={(e) => doAnswerQuestion()}
                >
                  {answerQuestion.loading ? 'Enviando...' : 'Responder'}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Box className={classes.mainTitleWrapper}>
        <Typography className={classes.mainTitle}>Perguntas</Typography>
      </Box>
      <Box>
        <Box className={classes.tabsWrapper}>
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={(e, v) => setTab(v)}
            aria-label="Perguntas"
          >
            <Tab
              className={classes.tab}
              label="Não respondidas"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tab}
              label="Respondidas"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          {questions
            .filter((question) => !question.responsed)
            .map((q) => (
              <Item
                key={q.id}
                id={q.id}
                midia_path={q.midia_path}
                created_at={q.created_at}
                product_url={q.product_url}
                question={q.question}
                name={q.name}
              />
            ))}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {questions
            .filter((question) => question.responsed)
            .sort(
              (a, b) =>
                new Date(b.answer?.created_at || b.created_at).getTime() -
                new Date(a.answer?.created_at || a.created_at).getTime()
            )
            .map((q) => (
              <Item
                key={q.id}
                id={q.id}
                midia_path={q.midia_path}
                product_url={q.product_url}
                created_at={q.answer?.created_at || q.created_at}
                question={q.question}
                name={q.name}
                answer={q.answer}
              />
            ))}
        </TabPanel>
      </Box>
      <ModalQuestion
        title="Apagar Pergunta"
        subtitle="Tem certeza que deseja apagar a pergunta?
Ao apagar a pergunta, a resposta também será apagada."
        confirmTitle="Apagar pergunta"
        open={modalDeleteQuestion}
        onClose={onCloseModalDeleteQuestion}
      />
      <ModalQuestion
        title="Apagar resposta"
        subtitle="Tem certeza que deseja apagar a resposta?"
        confirmTitle="Apagar resposta"
        open={modalDeleteAnswer}
        onClose={onCloseModalDeleteAnswer}
      />
    </Box>
  );
};

export default QuestionsDetails;
