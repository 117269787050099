import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import RadioGroupCore, {
    RadioGroupProps as RadioGroupBaseProps,
} from '@material-ui/core/RadioGroup';

export interface RadioGroupProps
    extends Omit<RadioGroupBaseProps, 'name' | 'value'> {
    name: string;
}

export default function RadioGroup(props: RadioGroupProps) {
    const { name, defaultValue = '', ...restProps } = props;
    const {
        fieldName,
        defaultValue: defaultFieldValue,
        registerField,
    } = useField(name);
    const defaultInputValue =
        (isNaN(defaultFieldValue)
            ? defaultFieldValue
            : String(defaultFieldValue)) || defaultValue;
    const [value, setValue] = useState(defaultInputValue);

    function onChange(
        event: React.ChangeEvent<HTMLInputElement>,
        value: string
    ) {
        setValue(value);

        restProps.onChange?.(event, value);
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue() {
                return value ?? '';
            },
            setValue(_: unknown, value: string) {
                setValue(value ?? '');
            },
        });
    }, [fieldName, registerField, value]);

    return (
        <RadioGroupCore
            {...restProps}
            name={name}
            value={value}
            onChange={onChange}
        />
    );
}
