import {
  MouseEvent,
  ChangeEvent,
  useState,
  Ref,
  forwardRef,
  useImperativeHandle,
  SetStateAction,
} from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';

export interface UploadProps {
  id?: string;
  name?: string;
  inputRef?: Ref<HTMLInputElement>;
  /**
   * - file_extension: It Specify the file extension(s) like .gif, .jpg, .png, .doc) the user can pick from.
   * - audio/*: The user can pick all sound files.
   * - video/*: The user can pick all video files.
   * - image/*: :A valid media type, with no parameters. Look at IANA Media Types for a complete list tandard media types
   * - media_type: A valid media type without parameters.
   */
  accept?: string;
  disabled?: boolean;
  value?: string;
  onClick?(event: MouseEvent<HTMLInputElement, globalThis.MouseEvent>): void;
  onChange?(event: ChangeEvent<HTMLInputElement>, file?: File): void;
  loading?: boolean;
}

export interface UploadRef {
  setFile(value: SetStateAction<File | undefined>): void;
}

const useStyles = makeStyles((theme) => ({
  upload: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    padding: '0 16px',
    height: 40,
    width: '100%',
    '& .MuiButton-label': {
      fontSize: theme.typography.pxToRem(15),
      textTransform: 'none',
      justifyContent: 'start',
      display: 'flex',
      '& .MuiButton-startIcon': {
        marginLeft: 0,
      },
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5',
    marginRight: 'auto',
  },
  remove: {
    marginRight: -theme.spacing(0.5),
  },
}));

export default forwardRef<UploadRef, UploadProps>(function (props, ref) {
  const { id, name, inputRef, accept = 'image/jpeg', disabled, value } = props;
  const classes = useStyles();
  const [file, setFile] = useState<File>();

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const _file = event.target.files?.[0];

    setFile(_file);

    props.onChange?.(event, _file);
  }

  useImperativeHandle(ref, () => ({
    setFile,
  }));

  return (
    <>
      <input
        ref={inputRef}
        id={id}
        name={name}
        accept={accept}
        type="file"
        style={{ display: 'none' }}
        disabled={disabled}
        onClick={props.onClick}
        onChange={onChange}
      />
      <label htmlFor={id} style={{ display: 'contents' }}>
        <Button
          disabled={disabled}
          className={classes.upload}
          component="span"
          disableFocusRipple
          startIcon={<PanoramaOutlinedIcon htmlColor="#333333" />}
          title={value || file?.name || 'Selecionar'}
          children={
            <>
              <span className={classes.text} style={{ maxWidth: 200 }}>
                {value || file?.name || 'Selecionar'}
              </span>
              {props.loading && (
                <CircularProgress
                  style={{
                    color: '#333333',
                    minWidth: 20,
                    minHeight: 20,
                  }}
                  size={20}
                />
              )}
            </>
          }
        />
      </label>
    </>
  );
});
