import { useContext, useEffect, useState, createElement } from 'react';
import {
  BrowserRouter,
  Switch,
  Route as RouteLink,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider, ThemeOptions, CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';

import AuthContext, { AuthProvider, AuthContextData } from './contexts/Auth';
import AlertContext, { AlertProvider } from './contexts/Alert';
import LayoutBase from './components/Layout';
import HeaderBase from './components/Layout/Header';
import MainBase from './components/Layout/Main';
import SidebarBase from './components/Layout/Sidebar';
import { Page } from './interfaces/page';
import Routes from './Routes';
import Loading from './components/Loading';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ApiCrash from './pages/ApiCrash';
import { api } from './lib/api';
import { SWRConfig } from 'swr';

export interface CoreProps {
  logo: string;
  name: string;
  theme?: ThemeOptions;
  components?: {
    Layout?: typeof LayoutBase;
    Header?: typeof HeaderBase;
    Main?: typeof MainBase;
    Sidebar?: typeof SidebarBase;
  };
  pages: {
    [key: string]: Page;
    // @ts-ignore
    Login?: JSX.Element;
    // @ts-ignore
    ForgotPassword?: JSX.Element;
    // @ts-ignore
    ResetPassword?: JSX.Element;
  };
}

function Logged(props: AuthContextData & Omit<CoreProps, 'environment'>) {
  const { isLogged, logo, name, pages, components } = props;
  const alert = useContext(AlertContext);
  const [menus, setMenus] = useState<any[]>();
  const [routes, setRoutes] = useState<any[]>();
  const [error, setError] = useState(false);

  function getMenus(menus: any[], aux: any[]) {
    for (const menu of menus!) {
      if (menu.route === null) {
        aux.push({
          icon: menu.icon,
          title: menu.name,
          submenu: getMenus(menu.childs, []),
        });
      } else if (menu.permission?.can_read) {
        aux.push({
          route: menu.route,
          icon: menu.icon,
          title: menu.name,
        });
      }
    }

    return aux;
  }

  useEffect(() => {
    if (isLogged) {
      (async () => {
        try {
          const {
            data: { menus, routes },
          } = await api.get('menus/get-menus');

          const newMenus = menus.map((menu: any) =>
            menu.childs && menu.childs.length > 0
              ? {
                  ...menu,
                  childs: menu.childs.sort(
                    (a: any, b: any) => b.order - a.order
                  ),
                }
              : menu
          );

          console.log({
            menus: newMenus,
          });

          setRoutes(routes);
          setMenus(newMenus);
        } catch (error: any) {
          console.error(error);

          alert.error('Realize o login novamente');

          setError(true);
        }
      })();
    }
  }, [isLogged]);

  if (error) {
    return <ApiCrash />;
  }

  if (isLogged) {
    if (!menus || !routes) {
      return <Loading />;
    }

    const Layout = components?.Layout || LayoutBase;

    return (
      <Routes menus={menus} routes={routes} pages={pages}>
        {(Children) => (
          <Layout
            menus={getMenus(menus, [])}
            logo={logo}
            children={Children}
            components={components}
          />
        )}
      </Routes>
    );
  }

  return (
    <Switch>
      <RouteLink exact path="/">
        {pages.Login ? (
          // @ts-ignore
          createElement(pages.Login, { logo, name })
        ) : (
          <Login logo={logo} name={name} />
        )}
      </RouteLink>
      <RouteLink
        path="/password/:token?"
        render={(route) => {
          const { token } = route.match.params;

          if (token) {
            return pages.ResetPassword ? (
              // @ts-ignore
              createElement(pages.ResetPassword, {
                logo,
                name,
                token,
              })
            ) : (
              <ResetPassword logo={logo} name={name} token={token} />
            );
          }

          return pages.ForgotPassword ? (
            // @ts-ignore
            createElement(pages.ForgotPassword, { logo, name })
          ) : (
            <ForgotPassword logo={logo} name={name} />
          );
        }}
      />
      <RouteLink path="*" children={<Redirect to="/" />} />
    </Switch>
  );
}

export default function Core(props: CoreProps) {
  const { theme: _theme, ...rest } = props;
  const theme = createTheme(_theme);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AuthProvider>
            <SWRConfig
              value={{
                fetcher: (url) => api.get(url).then((res) => res.data),
              }}
            >
              <AlertProvider>
                <AuthContext.Consumer>
                  {
                    //@ts-ignore
                    (auth) => <Logged {...auth} {...rest} />
                  }
                </AuthContext.Consumer>
              </AlertProvider>
            </SWRConfig>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
